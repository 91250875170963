import { helper } from '@ember/component/helper';

interface DasherizeArgumentObject {
  transformString: string;
  separatorString: string;
  dasherize: boolean;
}

// (de)dasherize a string. params are a mix of strings and a boolean
export function dasherize(_params: any, { transformString, separatorString, dasherize }: DasherizeArgumentObject): string | undefined {
  if (transformString === undefined || typeof transformString !== 'string') {
    return;
  }
  if (separatorString === undefined) {
    separatorString = ' ';
  }
  if (dasherize === undefined) {
    dasherize = true;
  }
  if (dasherize) {
    return transformString
      .toLowerCase()
      .split(separatorString)
      .join('-');
  } else {
    return transformString
      .toLowerCase()
      .split('-')
      .join(separatorString);
  }
}

export default helper(dasherize);
