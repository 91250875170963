define("@square/glass-ui/components/svg/icon-arrow-next", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-arrow-next"], function (_exports, _svgIcon, _iconArrowNext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconArrowNext.default,
    width: 18,
    height: 18,
    viewBox: '0 0 16 16',
    classNames: ['svg-icon--arrow-next']
  });

  _exports.default = _default;
});