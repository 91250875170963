define("@square/glass-ui/templates/components/deprecated/sq-flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hu5hcCSw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"banner-flash__wrapper\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"banner-flash__icon\"],[12],[1,[30,[36,5],[[35,4]],[[\"color\"],[[35,3]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"banner-flash__body\"],[12],[2,\"\\n\"],[6,[37,7],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[2,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"banner-flash__close\"],[4,[38,0],[[32,0],\"dismiss\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"size\",\"width\",\"height\"],[\"small\",\"10\",\"10\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"svg/icon-close-x\",\"message\",\"iconColor\",\"iconComponentName\",\"component\",\"shouldShowIcon\",\"if\",\"dismiss\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/deprecated/sq-flash-message.hbs"
    }
  });

  _exports.default = _default;
});