import DS from 'ember-data';
import Route from '@ember/routing/route';
import { EngineRouterService } from 'ember-engines-router-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import SyncService from '../../../services/sync';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import Auth from 'bridge-dashboard/app/services/auth';

export default class AuthorizedRouteIntegrationAudit extends Route {
  @service auth!: Auth;
  @service currentUser!: CurrentUser;
  @service router!: EngineRouterService;
  @service store!: DS.Store;
  @service sync!: SyncService;

  @action
  refreshCurrentRoute() {
    this.refresh();
  }

  model() {}
}
