define("@square/glass-ui/components/sq-dropdown", ["exports", "jquery", "@square/glass-ui/templates/components/sq-dropdown"], function (_exports, _jquery, _sqDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqDropdown.default,
    classNames: ['dropdown'],
    classNameBindings: ['isActive:dropdown-is-active'],
    isDisabled: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.openByDefault) {
        this.send('open');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unbindApplicationClick();
    },
    bindApplicationClick: function bindApplicationClick() {
      var _this = this;

      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(window).on("click.".concat(this.elementId, " touchend.").concat(this.elementId), function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        Ember.run(function () {
          return _this.applicationClick.apply(_this, args);
        });
      });
    },
    unbindApplicationClick: function unbindApplicationClick() {
      (0, _jquery.default)(window).off(".".concat(this.elementId)); // eslint-disable-line ember/no-jquery
    },
    applicationClick: function applicationClick(e) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      var inPopover = (0, _jquery.default)(this.element).find(e.target).length; // eslint-disable-line ember/no-jquery

      if (!inPopover) {
        this.send('close');
      }
    },
    actions: {
      toggleActive: function toggleActive() {
        if (this.get('isActive')) {
          this.send('close');
        } else {
          this.send('open');
        }
      },
      open: function open() {
        var _this2 = this;

        if (this.onOpen) {
          this.onOpen();
        }

        this.set('isActive', true);
        Ember.run.next(function () {
          return _this2.bindApplicationClick();
        });
      },
      close: function close() {
        this.set('isActive', false);

        if (this.onClose) {
          this.onClose();
        }

        this.unbindApplicationClick();
      }
    }
  });

  _exports.default = _default;
});