define("@square/glass-ui/templates/components/segmented-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RWgzlTRn",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[\"button segmented-control__segment \",[34,3],\" \",[30,[36,2],[[30,[36,1],[[35,0],[32,1,[\"value\"]]],null],\"segmented-control__segment--selected\"],null]]]],[24,4,\"button\"],[4,[38,4],[[32,0],[35,4],[32,1,[\"value\"]]],null],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"eq\",\"if\",\"segmentClass\",\"action\",\"labelsAndValues\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/segmented-control.hbs"
    }
  });

  _exports.default = _default;
});