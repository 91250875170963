define("@square/glass-ui/components/sq-card/header/subtitle", ["exports", "@square/glass-ui/templates/components/sq-card/header/subtitle"], function (_exports, _subtitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _subtitle.default,
    tagName: 'p',
    classNames: ['card__header__subtitle'],
    subtitle: null
  });

  _exports.default = _default;
});