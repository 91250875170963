/**
## PageHeader

A heading composed of a page title and drop-down menu that allows navigation between integrations within an account.
Intended to be used across almost all Bridge pages.


```example
<PageHeader
  @heading={{this.pageName}}
  @integrationSetupComplete={{this.integrationSetupComplete}}
  @currentIntegrationName={{this.currentIntegrationName}}
  @integrations={{this.currentUser.currentAccount.integrations}}
  @integrationChangeHandler={{this.handleIntegrationChange}}
/>
```

### Parameters
 * @param {String} [heading] [The header text]
 * @param {Array<Integration>} [integrations] [An array of an account's integrations.]
 * @param {string} [currentIntegrationName] [Does what it says on the tin!]
 * @param {boolean} [integrationSetupComplete] [Boolean indicating if integration is configured with all needed mappings and at least one sync definition.]
 * @param {(string) => void} [integrationChangeHandler] [Handles selection of another integration in the page header.]

*/

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EngineRouterService } from 'ember-engines-router-service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';

interface PageHeaderArgs {
  heading: string;
  integrationIds: Array<string>;
}

export default class PageHeader extends Component<PageHeaderArgs> {
  @service currentUser!: CurrentUser;
  @service router!: EngineRouterService;

  @action
  transitionToIntegrationCreationRoute(): void {
    this.router.transitionTo('authorized-route.new-integration');
  }
}
