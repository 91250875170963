import Service, { inject as service } from '@ember/service';
import { EngineRouterService } from 'ember-engines-router-service';
import ENV from '@bridge/home-engine/config/environment';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { ApplicationType } from '../models/services/source/application-type';

export default class SquareAuth extends Service {
  @service currentUser!: CurrentUser;
  @service router!: EngineRouterService;

  getSquarePluginAuthorizationLink(): string {
    return (
      `${ENV.frontEndServiceUrl}/api/v1/oauth/square/authorize` +
      `?bridge_account_id=${this.currentUser!.currentAccount!.id}` +
      `&redirect_path=${this.router.urlFor(
        'authorized-route.plugins.new'
      )}` +
      `&application_type=${ApplicationType.PLUGIN}`
    );
  }

  getSquareIntegrationAuthorizationLink(): string {
    return (
      `${ENV.frontEndServiceUrl}/api/v1/oauth/square/authorize` +
      `?bridge_account_id=${this.currentUser!.currentAccount!.id}` +
      `&redirect_path=${this.router.urlFor(
        'authorized-route.new-integration'
      )}` +
      `&application_type=${ApplicationType.INTEGRATION}`
    );
  }

  getSquareIntegrationReauthorizationLink(): string {
    return (
      `${ENV.frontEndServiceUrl}/api/v1/oauth/square/authorize` +
      `?bridge_account_id=${this.currentUser!.currentAccount!.id}` +
      `&redirect_path=${this.router.urlFor(
        'authorized-route.integration.settings.source'
      )}` +
      `&application_type=${ApplicationType.INTEGRATION}`
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'square-auth': SquareAuth;
  }
}
