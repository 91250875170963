import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AuthorizedRouteIntegrationSyncDataInventoryLevelsShow extends Controller {

  @action
  close() {
    this.transitionToRoute('authorized-route.integration.sync-data.inventory-levels');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/inventory-levels/show': AuthorizedRouteIntegrationSyncDataInventoryLevelsShow;
  }
}
