/**
  ## Sync Schedule Settings Table

  Table for managing automated sync schedules. 

  ```hbs
  <SyncScheduleSettingsTable @syncSchedules={{this.model}} />
  ```

  ### Parameters
    * @param {SyncSchedule[]} [syncSchedules] [Array of SyncSchedule objects
      for display and configuration.]
*/

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';

import { capitalize } from '../helpers/capitalize';
import { DropdownFilterUnit } from '../models/interface/dropdown-filter-unit';
import { FilterCriterion } from '../models/interface/filter-criteria';
import {
  FILTER_CRITERION_TYPE,
  syncTypeUnits,
} from '../models/interface/filter-criterion-type';
import { FilterTableColumnObject } from '../models/interface/filter-table-column-object';
import { SaveSyncSchedulesRequest } from '../models/services/data/save-sync-schedules-request';
import { SyncSchedule } from '../models/services/sync-schedule/sync-schedule';
import { SyncScheduleView } from '../models/services/sync-schedule/sync-schedule-view';
import FlashService from '@square/glass-ui/addon/services/flash';

interface SyncSchedulesByName {
  [key: string]: Array<SyncScheduleView>;
}

interface ButtonProperties {
  rank: string;
  variant?: string;
  label: string;
  action: () => any;
}

interface SyncScheduleSettingsTableArgs {
  syncSchedules: SyncSchedule[];
}

export default class SyncScheduleSettingsTable extends Component<SyncScheduleSettingsTableArgs> {
  constructor(owner: unknown, args: SyncScheduleSettingsTableArgs) {
    super(owner, args);
    this.data
      .getSyncSchedules()
      .then((syncSchedules) => {
        this.isTableLoading = false;
        this.oldRows = this.formatSyncScheduleArray(syncSchedules);

        this.locations = this.createLocationsFilterUnits(this.oldRows);
        this.selectedLocations = this.locations;
        this.filterCriteria = [];
        this.filterCriteria.push({ ...this.syncJobFilterCriterion });
        const populatedLocationFilterCriterion = {
          ...this.locationFilterCriterion,
        };
        populatedLocationFilterCriterion.selectedUnits = this.locations;
        populatedLocationFilterCriterion.units = this.locations;
        this.locationFilterCriterion = populatedLocationFilterCriterion;
        this.filterCriteria.push({ ...populatedLocationFilterCriterion });

        this.rowSubset = this.filterSelectedRows(
          this.formatSyncScheduleArray(syncSchedules)
        );
      })
      .catch((error) => {
        this.flash.globalError(error, {
          dismiss: () => {
            this.flash.clearGlobalMessage();
          },
        });
        this.rowSubset = [];
        this.oldRows = [];
      });
  }

  @service data!: Data;
  @service flash!: FlashService;

  @tracked
  isTableLoading = true;

  @tracked
  rowSubset: Array<SyncScheduleView> = [];

  loadingText = 'Loading sync schedule settings...';

  locations: Array<DropdownFilterUnit> = [];

  oldRows: Array<SyncScheduleView> = [];

  columns: Array<FilterTableColumnObject> = [
    {
      label: 'Sync Type',
      key: 'syncTypeLabel',
      formatSuccess: false,
    },
    {
      label: 'Location',
      key: 'location_name',
      formatSuccess: false,
    },
    {
      label: 'Enabled',
      key: 'sync_schedule_enabled',
      formatSuccess: false,
      isSwitch: true,
      isSwitchToggleEnabledKey: 'sync_definition_enabled',
    },
  ];

  syncTypes: Array<DropdownFilterUnit> = syncTypeUnits;

  createLocationsFilterUnits(
    syncSchedules: Array<SyncSchedule>
  ): Array<DropdownFilterUnit> {
    const locationFilterUnits: Array<DropdownFilterUnit> = [];
    const syncSchedulesByLocationName =
      this.organizeSyncSchedulesByLocationName(syncSchedules);
    Object.keys(syncSchedulesByLocationName).forEach((schedulesKey) => {
      if (schedulesKey !== 'All Locations') {
        const locationFilterUnit = {
          type: FILTER_CRITERION_TYPE.LOCATION,
          label: schedulesKey,
          value: schedulesKey,
          unitActive: true,
        };
        locationFilterUnits.push(locationFilterUnit);
      }
    });
    return locationFilterUnits;
  }

  selectedLocations: Array<DropdownFilterUnit> = [];
  selectedSyncTypes: Array<DropdownFilterUnit> = this.syncTypes;

  switchDelay = 2000;

  syncJobFilterCriterion: FilterCriterion = {
    key: 'sync_type',
    label: 'Sync Type',
    multiple: true,
    selectedUnits: this.selectedSyncTypes,
    units: this.syncTypes,
  };

  locationFilterCriterion: FilterCriterion = {
    key: 'location',
    label: 'Location',
    multiple: true,
    selectedUnits: this.selectedLocations,
    units: [],
  };

  undoButtonProperties: ButtonProperties = {
    rank: 'secondary',
    label: 'Undo',
    action: this.undo,
  };

  saveButtonProperties: ButtonProperties = {
    rank: 'primary',
    label: 'Save',
    action: this.save,
  };

  uniqueIdentifierKey = 'sync_definition_id';

  @tracked
  isButtonsDisabled = true;

  @tracked
  isSaveButtonLoading = false;

  @tracked
  filterCriteria: Array<FilterCriterion> = [
    this.syncJobFilterCriterion,
    this.locationFilterCriterion,
  ];

  @action
  undo(): void {
    this.rowSubset = this.filterSelectedRows(
      this.oldRows.map((row) => ({ ...row }))
    );
    this.isButtonsDisabled = true;
  }

  @action
  save(): void {
    this.isButtonsDisabled = true;
    this.isSaveButtonLoading = true;
    this.saveSyncSchedule();
  }

  formatSyncScheduleArray(
    syncSchedules: Array<SyncSchedule>
  ): Array<SyncScheduleView> {
    const formattedSyncSchedules: Array<SyncScheduleView> = [];
    const syncSchedulesByLocationName =
      this.organizeSyncSchedulesByLocationName(syncSchedules);
    Object.keys(syncSchedulesByLocationName).forEach((schedulesKey) => {
      if (schedulesKey === 'All Locations') {
        formattedSyncSchedules.unshift(
          ...syncSchedulesByLocationName[schedulesKey]
        );
      } else {
        formattedSyncSchedules.push(
          ...syncSchedulesByLocationName[schedulesKey]
        );
      }
    });
    return formattedSyncSchedules;
  }

  organizeSyncSchedulesByLocationName(
    syncSchedules: Array<SyncSchedule>
  ): SyncSchedulesByName {
    const syncSchedulesByLocationName: SyncSchedulesByName = {};
    syncSchedules.forEach((syncSchedule) => {
      const formattedSyncSchedule: SyncScheduleView = {
        ...syncSchedule,
        frequencyLabel: capitalize([syncSchedule.frequency], {
          eachWord: false,
        }),
        syncTypeLabel: capitalize([syncSchedule.sync_type], {
          eachWord: true,
          delimiter: '_',
        }),
      };
      if (syncSchedulesByLocationName[formattedSyncSchedule.location_name]) {
        syncSchedulesByLocationName[formattedSyncSchedule.location_name].push(
          formattedSyncSchedule
        );
      } else {
        syncSchedulesByLocationName[formattedSyncSchedule.location_name] = [
          formattedSyncSchedule,
        ];
      }
    });
    return syncSchedulesByLocationName;
  }

  @action
  handleFilterChange(
    selectedUnits: Array<DropdownFilterUnit>,
    type: string
  ): void {
    if (type === FILTER_CRITERION_TYPE.LOCATION) {
      this.locationFilterCriterion.selectedUnits = selectedUnits;
    } else if (type === FILTER_CRITERION_TYPE.SYNC_TYPE) {
      this.syncJobFilterCriterion.selectedUnits = selectedUnits;
    }
    if (selectedUnits.length === 0) {
      this.rowSubset = [];
      return;
    }
    this.rowSubset = this.filterSelectedRows(this.oldRows);
  }

  filterSelectedRows(rows: Array<SyncScheduleView>): Array<SyncScheduleView> {
    const selectedSyncTypeLabels = this.syncJobFilterCriterion.selectedUnits
      .map((unit) => unit.value)
      .join(',');
    let selectedLocationLabels = this.locationFilterCriterion.selectedUnits
      .map((unit) => unit.value)
      .join(',');
    if (
      this.locationFilterCriterion.units.length ===
      this.locationFilterCriterion.selectedUnits.length
    ) {
      selectedLocationLabels += 'All Locations';
    }
    return rows.filter((syncSchedule) => {
      return (
        selectedSyncTypeLabels.includes(syncSchedule.sync_type) &&
        selectedLocationLabels.includes(syncSchedule.location_name)
      );
    });
  }

  @action
  handleRowSwitch(
    updatedRow: SyncSchedule,
    enabled: boolean,
    column: FilterTableColumnObject
  ): void {
    this.isButtonsDisabled = false;
    const newRows = [];
    for (const row of this.rowSubset) {
      const newRow: SyncScheduleView = { ...row };
      if (
        newRow[column.isSwitchToggleEnabledKey! as keyof SyncScheduleView] &&
        row[this.uniqueIdentifierKey as keyof SyncScheduleView] ===
          updatedRow[this.uniqueIdentifierKey as keyof SyncSchedule]
      ) {
        // @ts-ignore TODO: fixme
        newRow[column.key] = enabled;
      }
      newRows.push(newRow);
    }
    this.rowSubset = newRows;
  }

  saveSyncSchedule(): void {
    const syncSchedules = this.rowSubset.map((row) => new SyncSchedule(row));
    const request = new SaveSyncSchedulesRequest(syncSchedules);
    this.data
      .saveSyncSchedules(request)
      .then(() => {
        this.data.getSyncSchedules().then((syncSchedules) => {
          this.oldRows = this.formatSyncScheduleArray(syncSchedules);
          this.rowSubset = this.filterSelectedRows(
            this.formatSyncScheduleArray(syncSchedules)
          );
          this.isSaveButtonLoading = false;
          this.flash.globalSuccess(
            'Automated sync schedule settings successfully saved!'
          );
        });
      })
      .catch((error) => {
        this.flash.globalError(error, {
          dismiss: () => {
            this.flash.clearGlobalMessage();
          },
        });
        this.rowSubset = this.filterSelectedRows(
          this.oldRows.map((row) => ({ ...row }))
        );
        this.isSaveButtonLoading = false;
        this.isButtonsDisabled = false;
      });
  }

  @action
  handleHeaderSwitch(enabled: boolean, column: FilterTableColumnObject): void {
    this.isButtonsDisabled = false;
    const newRows = [];
    for (const row of this.rowSubset) {
      const newRow = { ...row };
      if (newRow[column.isSwitchToggleEnabledKey as keyof SyncScheduleView]) {
        // @ts-ignore TODO: fixme
        newRow[column.key] = enabled;
      }
      newRows.push(newRow);
    }
    this.rowSubset = newRows;
  }
}
