/**
## ConfirmationModal

A modal based on Glass UI that is toggled using the Glass UI modal service.
Passes the button selection election as a resolved promise value of the modal service's .open method.
Is not incorporated in component template. Instead, called from component class.


```example
this.modal
  .open('confirmation-modal', {
    title: 'Confirm Changes',
    text: 'Are you sure you want to change the integration configuration?',
  })
  .then((confirmed) => {
    if (confirmed) {
      // do one thing
    } else {
      // do another thing
    }
  });
```

### Parameters
 * None directly, but this.modal.open accepts the path-name of the modal,
 * and an object referred to as this.args.modalOptions within the component

*/

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import FlashService from '@square/glass-ui/addon/services/flash';
import ModalService from '@square/glass-ui/addon/services/modal';
interface ConfirmationModalArgs {}

export default class ConfirmationModal extends Component<
  ConfirmationModalArgs
> {
  // these services are injected in accordance with Glass UI docs on modals
  @service flash!: FlashService;
  @service modal!: ModalService;

  // the confirmed boolean is true if the right, primary button was selected, and
  // false if the left, secondary button was selected.
  @action
  confirm(confirmed: boolean): void {

    // @ts-ignore TODO: fixme
    this.modal.close(confirmed);
  }
}
