export interface SyncTypeStatus {
  integration_id: string;
  sync_type: SyncTypeState;
  status: string;
  details: string;
  last_updated: string;
}

export enum SyncTypeState {
  OPERATIONAL = 'Operational',
  ACTION_REQUIRED = 'Action Required',
  DISABLED = 'Disabled',
}
