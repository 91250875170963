define("@square/glass-ui/templates/components/sq-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bUGhaf1v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,6],null,[[\"trigger\",\"popover\",\"isDisabled\",\"isActive\",\"close\",\"toggle\"],[[30,[36,5],[\"sq-dropdown-trigger\"],[[\"isDisabled\",\"isActive\",\"click\"],[[35,1],[35,3],[30,[36,2],[[35,1],[30,[36,0],[[32,0],\"toggleActive\"],null]],null]]]],[30,[36,5],[\"sq-popover\"],[[\"classNames\",\"isActive\",\"closePopoverOnClick\",\"close\"],[\"dropdown__popover\",[35,3],[35,4],[30,[36,0],[[32,0],\"close\"],null]]]],[35,1],[35,3],[30,[36,0],[[32,0],\"close\"],null],[30,[36,2],[[35,1],[30,[36,0],[[32,0],\"toggleActive\"],null]],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isDisabled\",\"unless\",\"isActive\",\"closePopoverOnClick\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-dropdown.hbs"
    }
  });

  _exports.default = _default;
});