define("@square/glass-ui/templates/components/sq-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JXMDw/dk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"field\",\"field-with-link\"],[[30,[36,2],[\"sq-form-field\"],[[\"flashContext\",\"showValidation\",\"useTopLabelVariant\"],[[35,1],[35,1,[\"showingValidation\"]],[35,0]]]],[30,[36,2],[\"sq-form-field-with-link\"],[[\"flashContext\",\"showValidation\",\"useTopLabelVariant\"],[[35,1],[35,1,[\"showingValidation\"]],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"useTopLabelVariant\",\"flashContext\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-form.hbs"
    }
  });

  _exports.default = _default;
});