import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AuthorizedRoutePluginsNew extends Controller {

  @action
  refreshPluginsRoute():void {
    this.send('refreshPlugins');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/plugins/new': AuthorizedRoutePluginsNew;
  }
}
