/**
  ## Audit::Table

  Glass-UI-powered table that can programmatically display select attributes 
  of instances of a provided model.

  On click, each cell routes to a given path. Similar to SettingsTable, 
  but with support for pagination + infinite scrolling.

  ```example
  <Audit::Table 
    @columns={{@columns}} 
    @loadHandler={{@loadMore}}
    @headerSwitchHandler={{@headerSwitchHandler}}
    @isTableLoading={{@isTableLoading}}
    @tableLoadingText={{@tableLoadingText}}
    @nextPageIsLoading={{@nextPageIsLoading}}
    @rowRoute={{@rowRoute}}
    @rows={{@rows}} 
    @rowSwitchHandler={{@rowSwitchHandler}}
  />
  ```

  ### Parameters
    * @param {Array<FilterTableColumnObject>} [columns] [Objects specifying table columns.]
    * @param {boolean} [isTableLoading] [Whether table is in loading state.]
    * @param {IntegrationAuditTableButtonObject} [primaryButton] [OPTIONAL.
      Object specifying primary (right) button.]
    * @param {string} [rowRoute] [OPTIONAL. Route to navigate to on row click.]
    * @param {Array<any>} [rows] [Objects containing data for rows.
      Must be in agreement with columns.]
    * @param {(row: any, checked: boolean, column: FilterTableColumnObject) 
      => void} [rowSwitchHandler] [OPTIONAL. Event handler for switches 
      in switch column (if present).]
    * @param {IntegrationAuditTableButtonObject} [secondaryButton] [OPTIONAL.
      Object specifying secondary (left) button.]
    * @param {(checked: boolean, column: FilterTableColumnObject) 
      => void} [headerSwitchHandler] [OPTIONAL. Event handler for switch 
        in header of switch column (if present).]
*/

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { EngineRouterService } from 'ember-engines-router-service';

import { FilterTableColumnObject } from '../../models/interface/filter-table-column-object';

interface IntegrationAuditTableButtonObject {
  label: string;
  disabled: boolean;
  action: Function;
}

interface IntegrationAuditTableArgs {
  columns: Array<FilterTableColumnObject>;
  isTableLoading: boolean;
  primaryButton: IntegrationAuditTableButtonObject;
  rowRoute: string;
  rows: Array<any>;
  rowSwitchHandler: (
    row: any,
    checked: boolean,
    column: FilterTableColumnObject
  ) => void;
  secondaryButton: IntegrationAuditTableButtonObject;
  headerSwitchHandler: (
    checked: boolean,
    column: FilterTableColumnObject
  ) => void;
}

export default class IntegrationAuditTable extends Component<IntegrationAuditTableArgs> {
  @service router!: EngineRouterService;

  get isAllSwitchOff(): boolean {
    const enabledRows = this.args.rows.filter(
      (row) => row[this.switchColumn!.key]
    );
    return enabledRows.length === 0;
  }

  get isAllSwitchOn(): boolean {
    const enabledRows = this.args.rows.filter(
      (row) => row[this.switchColumn!.key]
    );
    const enableableRowsLength = this.args.rows.filter(
      (row) => row[this.switchColumn?.isSwitchToggleEnabledKey!]
    ).length;
    return !this.isAllSwitchOff && enabledRows.length === enableableRowsLength;
  }

  get isAllSwitchDisabled(): boolean {
    return this.args.rows.length === 0;
  }

  switchColumn = this.args.columns.find((column) => column.isSwitch);

  // on click, go to route provided
  @action
  assignClickToAction(sourceRow: any): void {
    if (this.args.rowRoute) {
      this.router.transitionTo(this.args.rowRoute, sourceRow);
    }
  }

  // method for extracting values from keys of Ember Data model record
  @action
  extractValue(sourceRow: any, attribute: string): any {
    return sourceRow[attribute];
  }

  @action
  headerSwitchToggleHandler(
    column: FilterTableColumnObject,
    checked: boolean
  ): void {
    if (this.args.headerSwitchHandler) {
      this.args.headerSwitchHandler(checked, column);
    }
  }

  @action
  headerSwitchToggleHandlerMarket(
    column: FilterTableColumnObject,
    event: CustomEvent
  ): void {
    if (this.args.headerSwitchHandler) {
      this.args.headerSwitchHandler(event.detail.current, column);
    }
  }

  @action
  handleRowSwitchToggle(
    column: FilterTableColumnObject,
    enabledRow: any,
    checked: boolean
  ): void {
    if (this.args.rowSwitchHandler) {
      this.args.rowSwitchHandler(enabledRow, checked, column);
    }
  }

  @action
  handleRowSwitchToggleMarket(
    column: FilterTableColumnObject,
    enabledRow: any,
    event: CustomEvent
  ): void {
    if (this.args.rowSwitchHandler) {
      this.args.rowSwitchHandler(enabledRow, event.detail.current, column);
    }
  }
}
