define("ember-on-modifier/helpers/prevent-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.preventDefault = preventDefault;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function preventDefault(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        handler = _ref2[0];

    (false && !(false) && Ember.deprecate('`(prevent-default)` has been moved to `ember-event-helpers`.', false, {
      id: 'ember-on-modifier.prevent-default',
      until: '1.0.0',
      url: 'https://github.com/buschtoens/ember-event-helpers'
    }));
    (false && !(!handler || typeof handler === 'function') && Ember.assert("Expected '".concat(handler, "' to be a function, if present."), !handler || typeof handler === 'function'));
    return function (event) {
      (false && !(event && typeof event.preventDefault === 'function') && Ember.assert("Expected '".concat(event, "' to be an Event and have a 'preventDefault' method."), event && typeof event.preventDefault === 'function'));
      event.preventDefault();
      if (handler) handler(event);
    };
  }

  var _default = Ember.Helper.helper(preventDefault);

  _exports.default = _default;
});