import Controller from '@ember/controller';

export default class AuthorizedRouteIntegrationSettingsSource extends Controller {
  queryParams: Array<string> = ['source_state'];

  source_state!: string | null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/settings/source': AuthorizedRouteIntegrationSettingsSource;
  }
}
