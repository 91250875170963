define("@square/glass-ui/components/dialog-header/subheader", ["exports", "@square/glass-ui/templates/components/dialog-header/subheader"], function (_exports, _subheader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _subheader.default,
    classNames: ['dialog__header__subheader'],
    classNameBindings: ['hasTabs:dialog__header__subheader--with-tabs']
  });

  _exports.default = _default;
});