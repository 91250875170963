define("@square/glass-ui/components/sq-card/attributes-table/body", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-table/body"], function (_exports, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _body.default,
    tagName: 'tbody'
  });

  _exports.default = _default;
});