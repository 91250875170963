define("@square/glass-ui/components/responsive-image", ["exports", "@square/glass-ui/templates/components/responsive-image"], function (_exports, _responsiveImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _responsiveImage.default,
    tagName: 'img',
    attributeBindings: ['width', 'height', 'description:alt', 'srcset', 'src.1x:src'],
    width: null,
    height: null,
    src: null,
    description: null,
    srcset: Ember.computed('src', function () {
      var src = this.get('src');
      var srcset = "".concat(src['1x']);

      for (var size in src) {
        if (size !== '1x') {
          srcset = "".concat(srcset, ", ").concat(src[size], " ").concat(size);
        }
      }

      return srcset;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      (false && !(this.get('src.1x')) && Ember.assert('You must pass at least one image path with a `1x` key to `responsive-image`', this.get('src.1x')));
    }
  });

  _exports.default = _default;
});