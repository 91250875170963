import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { NavigationServiceObject } from 'bridge-dashboard/app/services/navigation';

export default class AuthorizedRoutePlugins extends Controller {
  @service currentUser!: CurrentUser;
  @service navigation!: NavigationServiceObject;

  get pageName(): string {
    return this.navigation.currentPageLabel();
  }

  get areAnyPluginsActive(): boolean {
    return this.model.length > 0;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/plugins': AuthorizedRoutePlugins;
  }
}
