define("@square/glass-ui/components/svg/icon-checkmark", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-checkmark"], function (_exports, _svgIcon, _iconCheckmark) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconCheckmark.default,
    classNames: ['svg-icon--checkmark'],
    viewBox: '-2 -2 15 12',
    width: 16,
    height: 16
  });

  _exports.default = _default;
});