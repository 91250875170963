define("@square/glass-ui/templates/components/deprecated/sheet-layout/action-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7+IhjMuI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,13]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,12],null,[[\"isActions\"],[true]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[15,0,[30,[36,1],[[35,0,[\"isMobile\"]],\"sheet-layout__headerbar-item\"],null]],[12],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"button button--primary \",[30,[36,2],[[35,0,[\"isMobile\"]],\"sheet-layout__footer-button\"],null]]]],[16,\"disabled\",[34,8]],[4,[38,6],[[32,0],[35,9]],null],[12],[2,\"\\n      \"],[1,[34,10]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,0,[30,[36,1],[[35,0,[\"isMobile\"]],\"sheet-layout__headerbar-item\"],null]],[12],[2,\"\\n      \"],[11,\"button\"],[16,0,[31,[\"button button--secondary \",[30,[36,2],[[35,3],\"button--destructive-secondary\",\"button--secondary\"],null],\" \",[30,[36,2],[[35,0,[\"isMobile\"]],\"sheet-layout__footer-button\"],null]]]],[16,\"disabled\",[34,4]],[4,[38,6],[[32,0],[35,5]],null],[12],[2,\"\\n        \"],[1,[34,7]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"screen\",\"unless\",\"if\",\"secondaryButtonIsWarning\",\"secondaryButtonDisabled\",\"secondaryAction\",\"action\",\"secondaryButton\",\"confirmButtonDisabled\",\"confirmAction\",\"confirmButton\",\"confirm\",\"hash\",\"hasCustomizedActions\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/deprecated/sheet-layout/action-buttons.hbs"
    }
  });

  _exports.default = _default;
});