import Location from '@bridge/home-engine/addon/models/services/location/location';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Data from 'bridge-dashboard/app/services/data';
import Transition from "@ember/routing/-private/transition";
export default class AuthorizedRouteIntegrationSettingsLocations extends Route {
  @service data!: Data;

  @action
  refreshCurrentRoute(): void {
    this.refresh();
  }

  @action
  willTransition(transition: Transition): void {
    if (
      transition.from!.name.includes('locations.location') &&
      transition.to.name.includes('locations.index')
    ) {
      this.refresh();
    }
  }

  model(): Promise<Location[]> {
    return this.data.getLocations();
  }
}
