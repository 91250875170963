define("@square/glass-ui/templates/components/more-actions-menu-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vY3nO+/V",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg/icon-triple-dot\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/more-actions-menu-trigger.hbs"
    }
  });

  _exports.default = _default;
});