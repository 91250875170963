import { helper } from '@ember/component/helper';
import slugify from 'ember-slugify';

// capitalize first character in a string
export function capitalize(params: Array<string>): string {
  const [string] = params;

  return slugify(string);
}

export default helper(capitalize);
