define("@square/glass-ui/components/sq-card/attributes-list/attribute/value", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-list/attribute/value"], function (_exports, _value) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'dd',
    layout: _value.default,
    classNames: ['card__attributes-list__attribute__value'],
    attributeBindings: ['data-test-card-attribute-value'],
    'data-test-card-attribute-value': true
  });

  _exports.default = _default;
});