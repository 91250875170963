import Route from '@ember/routing/route';
import Ember from 'ember';

// pass destination route's query params to loading substate for optimistic rendering
export default class AuthorizedRouteSyncDataCustomersLoading extends Route {
  setupController(controller: any/*, model, transition*/) {
    controller.set('model', Ember.Object.create({
      state: controller.target.targetState.routerJsState.queryParams.state,
      entity_id: controller.target.targetState.routerJsState.queryParams.entity_id
    }))
  }
}
