import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { DropdownFilterUnit } from './../../../../models/interface/dropdown-filter-unit';
import { EngineRouterService } from 'ember-engines-router-service';

export default class AuthorizedRouteIntegrationSyncDataOrders extends Controller {
  @service router!: EngineRouterService;

  queryParams: Array<string> = ['state', 'entity_id', 'entity_type'];

  entity_id: string | null = null;
  state: string | null = null;
  entity_type: string | null = null;

  entityTypes: Array<DropdownFilterUnit> = [
    { label: 'All Types', value: undefined },
    { label: 'Order', value: 'ORDER' },
    { label: 'Payment', value: 'PAYMENT' },
    { label: 'Credit Memo', value: 'CREDIT_MEMO' },
    { label: 'Refund', value: 'REFUND' },
  ];

  @action
  refreshModel() {
    this.send('refreshCurrentRoute');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/sync-data/orders': AuthorizedRouteIntegrationSyncDataOrders;
  }
}
