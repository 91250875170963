/**
## IntegrationWizardSheet

Manages settings forms by handling the retrieval and completion of forms and
decoupling them from a page or sheet for drier code.

```example
<IntegrationWizardSheet
  @code={{this.code}}
  @squareSources={{this.squareSources}}
  @externalSources={{this.externalSources}}
  @sourceState={{this.sourceState}}
/>
```

### Parameters
 * @param {Source[]} [squareSources] [A list of Square sources associated with the account for selection as part of integration.]
 * @param {Source[]} [externalSources] [A list of external sources associated with the account]
 * @param {string} [code] [Business Central OAuth code]
 * @param {SourceState} [sourceState] [FSC or external source state with status and message.]
*/

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import Data from 'bridge-dashboard/app/services/data';
import BridgeApiException from 'bridge-dashboard/app/types/bridge-api-exception';
import _ from 'lodash';

import { GatewaySystemType, getExternalTypes } from '@bridge/home-engine/models/services/source/gateway-system-type';
import Integration from '../models/services/integration/integration';
import BridgeSquareLocation from '../models/services/location/square_location';
import { CloudElementsInstance, friendlies, Gateway, Source } from '../models/services/source/source';
import Config from '../services/config';
import formNamesObjectParser from '../utils/form-names-object-parser';
import SourceState from '../models/services/source/source-state';
import getNameFromIntegrationSource from '@bridge/home-engine/utils/integration-source-util';
import FscAuth from '../services/fsc-auth';
import SquareAuth from '../services/square-auth';
import { Router } from '@ember/routing';
import FlashService from '@square/glass-ui/addon/services/flash';
import { PostIntegrationSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-integration-settings'

import { PostLocationSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-location-settings'
// @ts-ignore no exported types from lodash
interface IntegrationWizardSheetArgs {
  squareSources: Source[];
  externalSources: Source[];
  code: string;
  sourceState: SourceState;
}

interface FormMap {
  [k: string]: string
}


export default class IntegrationWizardSheet extends Component<
  IntegrationWizardSheetArgs
> {
    @service config!: Config;
    @service currentUser!: CurrentUser;
    @service data!: Data;
    @service flash!: FlashService;
    @service fscAuth!: FscAuth;
    @service router!: Router;
    @service squareAuth!: SquareAuth;



  squareAuthSheetNavStep = { key: 'SQUARE_KEY', label: 'Square Account' };
  externalSourceSheetNavStep = {
    key: 'EXTERNAL_KEY',
    label: 'External Source',
  };
  confirmIntegrationSheetNavStep = {
    key: 'CONFIRM_KEY',
    label: 'Confirm Integration',
  };
  integrationConfigSheetNavStep = {
    key: 'INTEGRATION_KEY',
    label: 'Configure Integration',
  };
  locationConfigSheetNavStep = {
    key: 'LOCATION_KEY',
    label: 'Configure Location',
  };
  lastSheetNavStep = { key: 'RUN_KEY', label: 'Next Steps' };

  selectorControlObjects = [
    this.squareAuthSheetNavStep,
    this.externalSourceSheetNavStep,
    this.confirmIntegrationSheetNavStep,
    this.integrationConfigSheetNavStep,
    this.locationConfigSheetNavStep,
    this.lastSheetNavStep,
  ];
  selectorControlItems = this.selectorControlObjects.map(
    (object) => object.key
  );

  externalGatewaySystemTypes: GatewaySystemType[] = getExternalTypes();

  @tracked
  currentStep = this.selectorControlObjects[0];

  @tracked
  externalSource?: Source;

  @tracked
  externalSourceSettingsFormMap:FormMap = {};

  didCompletedFormChange?: boolean;

  @tracked
  hasSelectedLocation: boolean = false;

  @tracked
  integrationSettingsFormMap: FormMap = {};

  @tracked
  locationSettingsFormMap: FormMap = {};

  @tracked
  nextStepDisabled: boolean = true;

  @tracked
  nextStepLoading: boolean = false;

  @tracked
  newIntegration?: Integration;

  @tracked
  selectedSquareSource?: Source;

  @tracked
  selectedGatewaySystemType: GatewaySystemType | undefined;

  @tracked
  selectedLocationOption?: BridgeSquareLocation;

  constructor(owner: unknown, args: IntegrationWizardSheetArgs) {
    super(owner, args);
    if (
      !_.isEmpty(this.currentUser.currentIntegration) &&
      !this.currentUser.isCompletelyConfigured()
    ) {
      this.nextStepDisabled = false;
      this.newIntegration = this.currentUser.currentIntegration!;
      this.selectedSquareSource = this.currentUser.currentSquareSource!;
      this.externalSource = this.currentUser.currentExternalSource!;
      if (this.externalSource) {
        this.selectedGatewaySystemType = this.externalGatewaySystemTypes.find(option => this.isSelectedSource(option));
      }
      if (this.currentUser.isAnyLocationConfigured()) {
        const configuredLocation = this.externalSource!.cloud_elements_instance!.instance_variables!.location_mappings[0];
        this.data.syncLocations().then((square_locations) => {
          this.selectedLocationOption = square_locations.find(
            (location) =>
              location.location_id === configuredLocation.square_location_id
          );
        });
      }
    } else if (
      _.isEmpty(this.currentUser.currentIntegration) &&
      this.args.squareSources.length > 0
    ) {
      // select newest Square source if one exists
      this.selectedSquareSource = this.args.squareSources.sort(
        (a, b) => b.square_account!.id - a.square_account!.id
      )[0];
      this.nextStepDisabled = false;

      if (this.args.code) {
        this.initializeWithBusinessCentralAuthorizationCode();
      } else if (this.args.sourceState) {
        this.initializeWithSourceState();
      }
    }
  }

  initializeWithBusinessCentralAuthorizationCode() {
    this.selectedGatewaySystemType = GatewaySystemType.BUSINESS_CENTRAL;
    this.currentStep = this.selectorControlObjects[1];
    this.selectedSquareSource = this.args.squareSources.find(
      (source) => source.id === localStorage.getItem('selectedSquareSourceId')
    );
  }

  initializeWithSourceState() {
    this.selectedSquareSource = this.args.squareSources.find(
      (source) => source.id === localStorage.getItem('selectedSquareSourceId')
    );
    this.selectedGatewaySystemType =
      GatewaySystemType.FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT;
    if (this.args.sourceState.status === 200) {
      const newIntegrationSource: Source = this.args.sourceState.message.integration_source;
      this.externalSource = newIntegrationSource;
      this.currentStep = this.selectorControlObjects[2];
    } else {
      let errorMessage = this.args.sourceState.message
        ? this.args.sourceState.message
        : 'There was a problem connecting to Finance & Supply Chain Management';
      this.flash.sheetError(errorMessage, {
        persistent: true,
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
      this.currentStep = this.selectorControlObjects[1];
    }
  }

  @action
  async handleContinue(selectCallback: Function): Promise<void> {
    this.nextStepLoading = true;
    this.nextStepDisabled = true;

    try {
      if (this.currentStep.key === this.squareAuthSheetNavStep.key) {
        localStorage.setItem(
          'selectedSquareSourceId',
          this.selectedSquareSource!.id!
        );
      } else if (
        this.currentStep.key === this.externalSourceSheetNavStep.key &&
        this.didCompletedFormChange
      ) {
        if (
          this.selectedGatewaySystemType ===
          GatewaySystemType.FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT
        ) {
          window.location.href =
            this.fscAuth.generateAuthorizationLink(this.externalSourceSettingsFormMap.resource_url);
          return;
        } else {
          // Adding a new external source account
          this.externalSource = await this.handleExternalSourceSave().catch(
            (error: BridgeApiException) => {
              this.nextStepDisabled = false;
              throw error;
            }
          );
          this.nextStepDisabled = false;
        }
      } else if (
        this.currentStep.key === this.confirmIntegrationSheetNavStep.key &&
        !this.newIntegration
      ) {
        // Confirming integration
        this.newIntegration = await this.handleIntegrationSave();
        this.currentUser.updateIntegration(this.newIntegration);
      } else if (
        this.currentStep.key === this.integrationConfigSheetNavStep.key &&
        (!this.currentUser.isIntegrationConfigured() ||
          this.didCompletedFormChange)
      ) {
        // Submitting integration settings
        this.newIntegration = await this.handleIntegrationConfigurationSave();
        this.currentUser.updateIntegration(this.newIntegration);
      } else if (
        this.currentStep.key === this.locationConfigSheetNavStep.key &&
        this.selectedLocationOption &&
        (!this.currentUser.isLocationConfigured(
          this.locationSettingsFormMap['location_mapping[square_location_id]']
        ) ||
          this.didCompletedFormChange)
      ) {
        this.newIntegration = await this.handleLocationConfigurationSave(this.selectedLocationOption.location_id);
        this.currentUser.updateIntegration(this.newIntegration);
      } else if (this.currentStep.key === this.lastSheetNavStep.key) {
        this.router.transitionTo('authorized-route');
      }

      // Calls didSelectTab()
      selectCallback();
    } catch (error) {
      this.nextStepLoading = false;
      this.flash.sheetError(error.message, {
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
    }
  }

  @action
  async didSelectTab(tab: string): Promise<void> {
    // If the user clicks on the same breadcrumb they are currently on, return
    if (this.currentStep.key === tab) {
      return;
    }

    this.setContinueDisabled(true);

    // Clear all error messages
    this.flash.clearSheetMessage();

    // Change the current step to the new step
    this.currentStep = this.selectorControlObjects[
      this.selectorControlItems.indexOf(tab)
    ];

    if (tab === this.squareAuthSheetNavStep.key && this.selectedSquareSource) {
      this.setContinueDisabled(false);
    }

    // Exceptions to disabling sheet "Continue" button
    const unvalidatedSteps = [
      this.confirmIntegrationSheetNavStep.key,
      this.lastSheetNavStep.key,
    ];

    if (unvalidatedSteps.includes(this.currentStep.key)) {
      this.setContinueDisabled(false);
    }

    this.didCompletedFormChange = false;
    this.nextStepLoading = false;
  }

  @action
  handleClose(): void {
    this.router.transitionTo('authorized-route.integration.overview');
  }

  @action
  setContinueDisabled(newContinueButtonDisabledState: boolean): void {
    this.nextStepDisabled = newContinueButtonDisabledState;
  }

  @action
  handleCompletedExternalSettingsForm(
    formMap: FormMap,
    isSaveDisabled: boolean
  ): void {
    this.handleSettingsFormUpdate(
      this.externalSourceSettingsFormMap,
      formMap,
      isSaveDisabled
    );
  }

  @action
  handleCompletedIntegrationSettingsForm(
    formMap: FormMap,
    isSaveDisabled: boolean
  ): void {
    this.handleSettingsFormUpdate(
      this.integrationSettingsFormMap,
      formMap,
      isSaveDisabled
    );
  }

  @action
  handleCompletedLocationSettingsForm(
    formMap: FormMap,
    isSaveDisabled: boolean
  ): void {
    this.handleSettingsFormUpdate(
      this.locationSettingsFormMap,
      formMap,
      isSaveDisabled
    );
  }

  handleSettingsFormUpdate(currentFormMap: {[k: string]: string}, newFormMap: {[k: string]: string}, isSaveDisabled: boolean) {
    /*
     * flag a change in a completed form if form map objects are different and
     * if there is no source (ex: newly completed form for new source)
     * or current form map not empty (ex: loaded completed form for existing source)
     */
    if (
      !_.isEqual(currentFormMap, newFormMap) &&
      (!this.externalSource || !_.isEmpty(currentFormMap))
    ) {
      this.didCompletedFormChange = true;
    }
    Object.assign(currentFormMap, newFormMap);
    this.setContinueDisabled(isSaveDisabled);
  }

  get squareAuthorizationLink(): string {
    return this.squareAuth.getSquareIntegrationAuthorizationLink();
  }

  get isSquareAccountDropdownDisabled(): boolean {
    return !_.isEmpty(this.currentUser.currentIntegration);
  }

  @action
  handleSquareAccountDropdownSelection(source: Source): void {
    this.selectedSquareSource = source;
    this.setContinueDisabled(false);
  }

  @action
  handleExternalSourceDropdownSelection(source: GatewaySystemType): void {
    this.selectedGatewaySystemType = source;
  }

  @action
  handleLocationSelection(location: BridgeSquareLocation): void {
    this.selectedLocationOption = location;
  }

  // WIP: handles clicking on the "Save" button within a SettingsSheet
  @action
  handleExternalSourceSave(): Promise<Source> {
    if (this.externalSource) {
      const requestBody = formNamesObjectParser(
        this.externalSourceSettingsFormMap
      );

      return this.data.postSourceSettings(requestBody, this.externalSource.id!);
    }

    if (!this.selectedGatewaySystemType) {
      throw new Error("Expected gateway system type to have a value");
    }

    const cloudElementsInstance: CloudElementsInstance = {
      gateway_system_type: this.selectedGatewaySystemType,
      name: friendlies[this.selectedGatewaySystemType],
    };

    if (
      cloudElementsInstance.gateway_system_type ===
      GatewaySystemType.SAP_BUSINESS_ONE
    ) {
      cloudElementsInstance.sapb1_configuration = this.externalSourceSettingsFormMap;
    } else if (
      cloudElementsInstance.gateway_system_type === GatewaySystemType.NETSUITE
    ) {
      cloudElementsInstance.netsuite_configuration = this.externalSourceSettingsFormMap;
    } else if (
      cloudElementsInstance.gateway_system_type ===
      GatewaySystemType.BUSINESS_CENTRAL
    ) {
      cloudElementsInstance.business_central_configuration = this.externalSourceSettingsFormMap;
      cloudElementsInstance.business_central_configuration.oauthCode = this.args.code;
    }

    // settings required for successful CE API queries (even if empty)
    cloudElementsInstance.instance_variables = {
      decompose_taxes: false,
      location_mappings: [],
      currency_mappings: [],
      measurement_mappings: [],
      item_mappings: []
    };

    const externalSource: Source = ({
      bridge_account_id: this.currentUser!.currentAccount!.id,
      gateway: Gateway.CLOUD_ELEMENTS,
      cloud_elements_instance: cloudElementsInstance,
    });

    return this.data.postSource({ source: externalSource });
  }


  @action
  async handleIntegrationSave(): Promise<Integration> {
    const integration: Integration = {
      bridge_account_id: this.currentUser!.currentAccount!.id,
      sources: [this.selectedSquareSource!, this.externalSource!],
      name: this.externalSource
        ? getNameFromIntegrationSource(this.externalSource)
        : '',
    };
    return await this.data.postIntegration({
      integration
    });
  }

  @action
  async handleIntegrationConfigurationSave(): Promise<Integration> {


    const requestBody = formNamesObjectParser(this.integrationSettingsFormMap) as PostIntegrationSettingsRequest;
    return await this.data.postIntegrationSettings(requestBody);
  }

  @action
  async handleLocationConfigurationSave(locationId: string): Promise<Integration> {
    const requestBody = formNamesObjectParser(this.locationSettingsFormMap) as PostLocationSettingsRequest;
    return await this.data.postLocationSettings(locationId, requestBody);
  }

  private isSelectedSource(gatewaySystemType: GatewaySystemType) {
    const selected = this.externalSource?.cloud_elements_instance?.gateway_system_type
      || this.externalSource?.external_source?.gateway_system_type;
    return gatewaySystemType == selected;
  }

}
