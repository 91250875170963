define("@square/glass-ui/templates/components/sq-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qitrHwIL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h3\"],[14,0,\"type-heading-extra-large\"],[12],[1,[30,[36,2],[[35,1]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"sq-progress-bar__progress-bar\"],[12],[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"sq-progress-bar__progress-bar__detail\"],[15,5,[34,5]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"sq-progress-bar__status\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"statusText\",\"percent\",\"format-percent\",\"showPercentage\",\"if\",\"percentStyle\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-progress-bar.hbs"
    }
  });

  _exports.default = _default;
});