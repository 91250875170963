define("@square/glass-ui/components/media-block/button", ["exports", "@square/glass-ui/components/sq-button", "@square/glass-ui/templates/components/sq-button"], function (_exports, _sqButton, _sqButton2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sqButton.default.extend({
    layout: _sqButton2.default,
    classNames: ['media-block__button'],
    classNameBindings: ['mediaBlockButtonRankClass'],
    mediaBlockButtonRankClass: Ember.computed('rank', function () {
      if (this.rank) {
        return "media-block__button--".concat(this.rank);
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});