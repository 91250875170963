define("@square/glass-ui/utils/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fullscreenChangeEventName = fullscreenChangeEventName;
  _exports.getFullscreenElement = getFullscreenElement;
  _exports.requestFullscreen = requestFullscreen;

  function fullscreenChangeEventName(video) {
    if (video.requestFullscreen) {
      return 'fullscreenchange';
    } else if (video.webkitRequestFullscreen) {
      return 'webkitfullscreenchange';
    } else if (video.mozRequestFullScreen) {
      return 'mozfullscreenchange';
    } else if (video.msRequestFullscreen) {
      return 'msfullscreenchange';
    }

    return null;
  }

  function getFullscreenElement() {
    return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement;
  }

  function requestFullscreen(video) {
    if (video.requestFullscreen) {
      return video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      return video.mozRequestFullScreen(); // Firefox
    } else if (video.webkitRequestFullscreen) {
      return video.webkitRequestFullscreen(); // Chrome and Safari
    } else if (video.webkitEnterFullscreen) {
      video.webkitEnterFullscreen(); // Mobile Safari
    } else if (video.msRequestFullscreen) {
      return video.msRequestFullscreen(); // IE11
    }

    return null;
  }
});