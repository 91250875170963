define("@square/glass-ui/components/sq-sorter", ["exports", "@square/glass-ui/templates/components/sq-sorter", "@square/glass-ui/utils/json-equality"], function (_exports, _sqSorter, _jsonEquality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isAscending = isAscending;
  _exports.isDescending = isDescending;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function isAscending(sortKey) {
    return sortKeyWithDirection(sortKey).direction === 'asc';
  }

  function isDescending(sortKey) {
    return sortKeyWithDirection(sortKey).direction === 'desc';
  }

  function sortKeyWithDirection(sortKey) {
    if (sortKey.endsWith(':desc')) {
      return {
        sortKey: sortKey.slice(0, sortKey.lastIndexOf(':desc')),
        direction: 'desc'
      };
    } else {
      if (sortKey.endsWith(':asc')) {
        sortKey = sortKey.slice(0, sortKey.lastIndexOf(':asc')); // eslint-disable-line no-param-reassign
      }

      return {
        sortKey: sortKey,
        direction: 'asc'
      };
    }
  }

  function reverse(originalSortKey) {
    var _sortKeyWithDirection = sortKeyWithDirection(originalSortKey),
        sortKey = _sortKeyWithDirection.sortKey,
        direction = _sortKeyWithDirection.direction;

    var newDirection = direction === 'asc' ? 'desc' : 'asc';
    return "".concat(sortKey, ":").concat(newDirection);
  }

  function formatAsArray(key) {
    if (Ember.isNone(key)) {
      return [];
    }

    return Ember.isArray(key) ? _toConsumableArray(key) : [key];
  }

  var _default = Ember.Component.extend({
    layout: _sqSorter.default,
    tagName: '',
    content: null,
    sorterButtonTagName: 'div',
    sorterButtonClass: '',
    sortKey: null,
    sortDefault: true,
    sortDefinition: Ember.computed(function () {
      return [];
    }),
    _sortKey: null,
    // Holds the primary keys to sort by and is used with sortDefault to determine column header caret directions.
    _sortDefault: true,
    // Represents the sort direction of sortKey
    _sortDefinition: Ember.computed(function () {
      // Holds all keys (primary and secondary) to sort by.
      return [];
    }),
    sortedContent: Ember.computed.sort('content', '_sortDefinition'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_sortKey', this.get('sortKey'));
      this.set('_sortDefault', this.get('sortDefault'));

      if (Ember.isPresent(this.get('sortDefinition'))) {
        this.set('_sortDefinition', this.get('sortDefinition'));
      } else {
        var key = formatAsArray(this.get('sortKey'));
        this.set('_sortDefinition', this.get('_sortDefault') ? key : key.map(reverse));
      }
    },
    actions: {
      toggleSort: function toggleSort(sortKey) {
        var newKeys = formatAsArray(sortKey);
        var oldKeys = this.get('_sortDefinition');
        var uniqueKeys;

        if ((0, _jsonEquality.deepEquals)(sortKey, this.get('_sortKey'))) {
          this.toggleProperty('_sortDefault');
          uniqueKeys = [].concat(_toConsumableArray(oldKeys.slice(0, newKeys.length).map(reverse)), _toConsumableArray(oldKeys.slice(newKeys.length)));
        } else {
          this.setProperties({
            _sortKey: sortKey,
            _sortDefault: true
          });
          uniqueKeys = [].concat(_toConsumableArray(newKeys), _toConsumableArray(oldKeys)).map(sortKeyWithDirection).reduce(function (uniqueKeysWithDirection, keyWithDirection) {
            if (uniqueKeysWithDirection.every(function (_ref) {
              var sortKey = _ref.sortKey;
              return sortKey !== keyWithDirection.sortKey;
            })) {
              uniqueKeysWithDirection.push(keyWithDirection);
            }

            return uniqueKeysWithDirection;
          }, []).map(function (_ref2) {
            var sortKey = _ref2.sortKey,
                direction = _ref2.direction;
            return "".concat(sortKey, ":").concat(direction);
          });
        }

        this.set('_sortDefinition', uniqueKeys);

        if (this.didSort) {
          this.didSort(sortKey, this.get('_sortDefault'), this.get('_sortDefinition'));
        }
      }
    }
  });

  _exports.default = _default;
});