define("@square/glass-ui/components/sq-choice-block", ["exports", "@square/glass-ui/templates/components/sq-choice-block"], function (_exports, _sqChoiceBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  ## Choice Block
  
  Large blocky selection areas, with (optional) form elements inside of
  each block, revealed when selected. These elements must be enclosed in
  a selected-content component.
  
  Note a choice block is not a general use form element option. It is not a
  substitute for a standard radio option element. It only should be used in
  selective cases on a single important/standout choice.
  
  ```example
  @title Basic Usage
  @description Set a property in your controller to control the current value for the field
  your choice block is controlling. Add an action for selecting an option and attach it to
  the click event on `sq-choice-block` and pass the option's value to it. Inside the action
  set the property that controls the value for this field to the passed option.
  
  @controller-properties
  {
    selectedOption: 'option-1',
    actions: {
      selectOption(option) {
        this.set('selectedOption', option);
      }
    }
  }
  
  @hbs
  {{sq-choice-block selected=(eq selectedOption "option-1")
                    label="Check Me"
                    labelSecondary="I'm the best choice. You betchya."
                    action=(action "selectOption" "option-1")}}
  {{sq-choice-block selected=(eq selectedOption "option-2")
                    label="No, check me instead"
                    labelSecondary="I'm way better than that choice up there."
                    action=(action "selectOption" "option-2")}}
  
  ```
  
  ```example
  @title Checkboxes
  
  @controller-properties
  {
    optioneOneSelected: true,
    optioneTwoSelected: false,
    actions: {
      optionOne() {
        this.set('optioneOneSelected', !this.get('optioneOneSelected'));
      },
      optionTwo() {
        this.set('optioneTwoSelected', !this.get('optioneTwoSelected'));
      }
    }
  }
  
  @hbs
  {{sq-choice-block action=(action "optionOne")
                    label="Check Me"
                    labelSecondary="I'm the best choice. You betchya."
                    selected=optioneOneSelected
                    style="checkbox"}}
  {{sq-choice-block action=(action "optionTwo")
                    label="And check me!"
                    labelSecondary="Why not have both?"
                    selected=optioneTwoSelected
                    style="checkbox"}}
  
  ```
  
  ```example
  @title Advanced Usage
  @description Includes a selected-content block for display when the block is selected,
  and advanced elements for the label and selected label.
  
  @controller-properties
  {
    selectedOption: 'option-1',
    actions: {
      selectOption(option) {
        this.set('selectedOption', option);
      }
    }
  }
  
  @hbs
  {{#sq-choice-block selected=(eq selectedOption "option-1")
                     action=(action "selectOption" "option-1") as |choice-block|}}
    {{#choice-block.label}}
      <div class="responsive-columns">
        <div class="responsive-column responsive-column--half">
          Select this option because...
        </div>
        <div class="responsive-column responsive-column--half">
          I have two columns in my label!
        </div>
      </div>
    {{/choice-block.label}}
    {{#choice-block.label-secondary}}
      {{#media-block as |block|}}
        {{#block.content as |content|}}
          {{#content.area}}
            <div class="product-logo">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6576 0H3.34242C1.49668 0 0 1.49623 0 3.34242V16.6576C0 18.5038 1.49668 20 3.34242 20H16.6576C18.5038 20 20 18.5038 20 16.6576V3.34242C20.0005 1.49623 18.5042 0 16.6576 0ZM16.364 15.31C16.364 15.8926 15.8917 16.3644 15.3095 16.3644H4.69275C4.11008 16.3644 3.63785 15.8926 3.63785 15.31V4.69275C3.63785 4.11008 4.11008 3.63785 4.69275 3.63785H15.3095C15.8917 3.63785 16.364 4.11008 16.364 4.69275V15.31ZM7.87747 12.727H12.1216C12.457 12.727 12.727 12.4543 12.727 12.1184V7.88065C12.727 7.54477 12.457 7.27207 12.1216 7.27207H7.87747C7.54295 7.27207 7.27207 7.54477 7.27207 7.88065V12.1184C7.27207 12.4543 7.54295 12.727 7.87747 12.727Z" fill="black"></path>
              </svg>
              <span class="logotype">Square's BEST Option</span>
            </div>
          {{/content.area}}
        {{/block.content}}
      {{/media-block}}
    {{/choice-block.label-secondary}}
    {{#choice-block.selected-content}}
      {{#sq-form-field label="First Name"}}
        {{sq-field-input placeholder=(t "common.firstName")}}
      {{/sq-form-field}}
      {{#sq-form-field label="Last Name"}}
        {{sq-field-input placeholder=(t "common.lastName")}}
      {{/sq-form-field}}
    {{/choice-block.selected-content}}
  {{/sq-choice-block}}
  {{#sq-choice-block selected=(eq selectedOption "option-2")
                     label="No, check me instead"
                     labelSecondary="But here you could click a check box!"
                     action=(action "selectOption" "option-2") as |choice-block|}}
    {{#choice-block.selected-content}}
      {{sq-option-checkbox name="option-checkbox"
                           label="This is the best option."}}
    {{/choice-block.selected-content}}
  {{/sq-choice-block}}
  
  ```
  
  See [sq-option-radio](./sq-option-radio.html) for parameters
  */
  var _default = Ember.Component.extend({
    layout: _sqChoiceBlock.default,
    style: 'radio',
    tagName: '',
    didRecieveAttrs: function didRecieveAttrs() {
      this._super.apply(this, arguments);

      (false && !(Ember.isPresent(this.label) || this.hasBlock) && Ember.assert('You must pass a `label` to choice block', Ember.isPresent(this.label) || this.hasBlock));
    }
  });

  _exports.default = _default;
});