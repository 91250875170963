import { helper } from '@ember/component/helper';
import _ from 'lodash';

interface CapitalizeArgumentObject {
  eachWord: boolean;
  delimiter?: string;
}

// capitalize first character in a string OR for each each word in a string
export function capitalize(
  params: Array<string>,
  { eachWord, delimiter = ' ' }: CapitalizeArgumentObject
): string {
  const [string] = params;

  if (!eachWord) {
    return _.capitalize(string);
  } else {
    const stringArray = string.split(delimiter);
    const capitalizedStringArray = stringArray.map((string) =>
      _.capitalize(string)
    );
    return capitalizedStringArray.join(' ');
  }
}

export default helper(capitalize);
