// @ts-nocheck
// Market exports global types. This requires no-hoisting since we are in a monorepo.
// Glass-ui due to their direct importing of market for styles.
// We would then need to be no-hoisted which creates issues for manual-modal-sync.
// TODO: When glass is removed, no-hoist market or hopefully they fix this in a newer version.

import { later } from '@ember/runloop';
import Ember from 'ember';
import Modifier from 'ember-modifier';

interface Args {
  positional: unknown[];
  named: {
    delay?: boolean;
    enabled?: boolean;
    select?: boolean;
  };
}

export default class AutoFocus extends Modifier<Args> {
  static isMarketTextInput(
    element: Element
  ): element is HTMLMarketInputTextElement {
    const tagName = element.tagName.toLocaleLowerCase();
    return tagName === 'market-input-text';
  }

  focusMarketInput(element: HTMLMarketInputTextElement): void {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }
    element.getInputElement().then((e) => e.focus());
  }

  async didReceiveArguments(): Promise<void> {
    // If `enabled` is excluded, assume we want to autofocus
    if (this.args.named.enabled === false) {
      return;
    }

    if (AutoFocus.isMarketTextInput(this.element)) {
      // Need to wait for the component to be hydrated before attempting to focus
      await Promise.all([
        customElements.whenDefined('market-input-text'),
        this.element.componentOnReady(),
      ]);

      // It appears there's an issue w/ auto-focusing market inputs whereby
      // sometimes the input focus incorrectly triggers a scroll event on the
      // parent container. The browser appears to be attempting to scroll the
      // input into view but is doing so at inconsistent times, which results
      // in the parent container sometimes being scrolled (even if the parent
      // container has overflow:hidden)
      // Does not go through delay during testing as it would cause issue
      if (this.args.named.delay && !Ember.testing) {
        later(
          () =>
            this.focusMarketInput(this.element as HTMLMarketInputTextElement),
          100
        );
        return;
      }

      this.focusMarketInput(this.element);
    } else if (this.element instanceof HTMLInputElement) {
      if (this.args.named.select) {
        this.element.select();
      } else {
        this.element.focus();
      }
    } else {
      throw new TypeError(
        `cannot focus ${this.element}, it is not HTMLElement`
      );
    }
  }
}
