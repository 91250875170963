export type Matcher = (s: string) => boolean;

export default function getFieldNameMatcher(field_name: string): Matcher {
  if (field_name.includes(`*`)) {
    let escaped = field_name.replace(
      /[\[\]]/g, '\\$&'
    );
    escaped = escaped.replace(/\*/g, '\\d+');
    const regex = new RegExp(escaped);
    return (name: string) => !!name.match(regex);
  } else {
    return (name: string) => name == field_name;
  }
};
