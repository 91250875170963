define("@square/glass-ui/templates/components/sq-field-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7Fpz48E",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-field-date__label\"],[15,\"onclick\",[30,[36,7],[[35,6],[30,[36,0],[[32,0],\"toggleCalendar\"],null]],null]],[15,\"tabindex\",[30,[36,8],[[35,6],false,0],null]],[12],[2,\"\\n  \"],[1,[30,[36,8],[[35,11],[35,10],[35,9]],null]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"form-field__caret\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,14],null,[[\"class\",\"constrainInParent\",\"isActive\"],[\"form-field-date__calendar-popover\",[35,13],[35,12]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"mode\",\"beginDate\",\"endDate\",\"allowPastSelections\",\"allowPastPageBack\",\"allowFutureSelections\",\"allowFuturePageForward\",\"class\",\"disabledRange\",\"action\"],[\"SINGLE\",[35,4],[35,4],[35,3],[35,3],[35,2],[35,2],\"form-field-date__calendar\",[35,1],[30,[36,0],[[32,0],\"selectDate\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"disabledRange\",\"enableFutureDates\",\"enablePastDates\",\"mutableDateCopy\",\"sq-input-calendar\",\"isDisabled\",\"unless\",\"if\",\"placeholder\",\"valueFormatted\",\"value\",\"shouldShowCalendar\",\"constrainCalendarInParent\",\"sq-popover\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-field-date.hbs"
    }
  });

  _exports.default = _default;
});