define("@square/glass-ui/components/dialog-actions", ["exports", "@square/glass-ui/templates/components/dialog-actions"], function (_exports, _dialogActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CONTEXT = void 0;
  var CONTEXT = {
    MODAL: 'MODAL',
    BLADE: 'BLADE',
    SHEET: 'SHEET'
  };
  _exports.CONTEXT = CONTEXT;

  var _default = Ember.Component.extend({
    layout: _dialogActions.default,
    isShowingSpinner: false,
    context: CONTEXT.MODAL,
    classNames: ['dialog__actions'],
    classNameBindings: ['contextClass'],
    contextClass: Ember.computed('context', function () {
      switch (this.get('context')) {
        case CONTEXT.BLADE:
          return 'dialog--blade__actions';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});