define("@square/glass-ui/components/sq-draggable-list", ["exports", "jquery", "@square/glass-ui/templates/components/sq-draggable-list"], function (_exports, _jquery, _sqDraggableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  ## Draggable List
  
  `sq-draggable-list` can be used to add drag-and-drop ordering functionality to
  table/list views. Pass it an array of items and it will create a copy of that
  array and yield out a customizable `row` component for each item in it, and a
  `handle` component for rendering a drag handle.
  
  Once dragging has stopped, the new order will be visually persisted, and the
  passed `didReorder` action will be called with the reordered array copy. From
  there you can choose to persist the order to the server, modify it, or revert
  it — whatever you decide, any changes to the passed `listItems` array will
  immediately clobber the dragged ordering.
  
  Here's an example of how you might persist the new order by saving the updated
  indices to an `ordinal` attribute on the model:
  
  ```js
  didReorder(reorderedItems) {
    const items = this.get('items');
    items.forEach(item => {
      item.set('ordinal', reorderedItems.indexOf(item));
    });
    items.adapter.saveRecords(items.slice());
  }
  ```
  
  This component can be used to render any kind of element, be it a `<table>` or
  anything else. It defaults to using divs but each element can be passed a
  `tagName`. It makes some styling assumptions that assume we're in a table-like
  styling context, but should work well in other contexts.
  
  This component (via draggabilly) supports both mouse and touch events, so it
  **totally works on mobile!**
  
  ```example
  @title List
  
  @controller-properties
  {
    items: [
      { name: 'Alpha' },
      { name: 'Bar' },
      { name: 'Beta' },
      { name: 'Bonn' },
      { name: 'Charlie' },
      { name: 'Delta' }
    ],
  
    actions: {
      edit() {
        alert('This is the edit action. Just showing you can attach click handlers.')
      },
  
      didReorder(reorderedItems) {
        console.log('Reordered items:', reorderedItems);
        alert('Check the console for the reordered items');
      }
    }
  }
  
  @hbs
  {{! Note that `draggable-list` and `myBackingModel` can be called whatever
      you want here. }}
  
  {{#sq-draggable-list listItems=items
                       didReorder=(action "didReorder")
                       tagName="ul"
                       as |draggable-list myBackingModel|}}
    {{#draggable-list.row tagName="li"
                          staticClick=(action "edit" myBackingModel)}}
      {{~draggable-list.handle~}}
      {{myBackingModel.name}}
    {{/draggable-list.row}}
  {{/sq-draggable-list}}
  ```
  
  ```example
  @title Table
  
  @controller-properties
  {
    items: [
      { name: 'Alpha' },
      { name: 'Bar' },
      { name: 'Beta' },
      { name: 'Bonn' },
      { name: 'Charlie' },
      { name: 'Delta' }
    ],
  
    actions: {
      edit() {
        alert('This is the edit action. Just showing you can attach click handlers.')
      },
  
      didReorder(reorderedItems) {
        console.log('Reordered items:', reorderedItems);
        alert('Check the console for the reordered items');
      }
    }
  }
  
  @hbs
  <table class="table">
    <thead>
      <tr>
        <th class="table-cell-header table-cell-header--col-12-24">
          {{t "pointOfSale.floorPlans.name.label"}}
        </th>
        <th class="table-cell-header table-cell-header--col-12-24">
          {{t "common.location"}}
        </th>
      </tr>
    </thead>
  
    {{#sq-draggable-list listItems=items
                         tagName="tbody"
                         didReorder=(action "didReorder")
                         as |draggable-list myBackingModel|}}
      {{#draggable-list.row tagName="tr"
                            class="table-row table-row--selectable"
                            staticClick=(action "edit" myBackingModel)}}
        <td class="table-cell table-cell--link type-strong">
          {{~draggable-list.handle~}}
          {{myBackingModel.name}}
        </td>
        <td class="table-cell">
          Dummy table cell content lol
        </td>
      {{/draggable-list.row}}
    {{/sq-draggable-list}}
  </table>
  ```
  
  ### Yielded Properties
  This component yields inner contextual components for each element in the backing list. Note that these can actually be called whatever you want when yielded in your template (i.e. `{{#sq-draggable-list as |foo bar|}}`), I just had to give them names below to make JSDoc happy.
  
  @param {Hash} contextualComponentHash - A hash containing `row` and `handle` contextual components.
  @param {Component} contextualComponentHash.row - Contextual component that renders a draggable row. You can pass a `staticClick` action to be called on click or touch events (the component is smart enough to differentiate these from drag events). Pass `class` or `tagName` to customize.
  @param {Component} contextualComponentHash.handle - Contextual component that renders a drag handle. Note that the dragOnlyOnHandle parameter below determines whether you actually have to click the handle to drag the row. Pass `class` or `tagName` to customize.
  @param {Object} backingModel - The backing object currently being iterated over
  @param {Integer} index - The integer index of the row
  
  ### Parameters
  @param {Array} listItems - Required - An array of items to display
  @param {Action} [didReorder] - Optional - Will be called once dragging has ended with one argument, which is an exact copy of the passed `listItems` with the new ordering. You can loop over this array and assign the new index for each item to `ordinal` or whatever property you're using to persist the ordering to the server.
  @param {Boolean} [dragOnlyOnHandle=false] - Optional - Whether to restrict dragging of the row to the handle component. Defaults to false.
  @param {Boolean} [shouldRenderList=true] - Optional - Whether to use a different list-based component than the default #each in the hbs. Defaults to true. If false, you must manually supply the model to the row component
  @param {Action} [getListStartIndex] - Optional - If shouldRenderList is false, there's a chance that the list component handles indices differently. If so, implement a function to calculate the start index on top of which we add the drag/drop index. Defaults to a function that returns 0
  
  Sass dependencies at: `app/assets/stylesheets/dashboard/components/_sq-draggable-list.sass`
  */
  var ROW_SELECTOR = '.draggable-list__row';
  var PLACEHOLDER_CLASSES = 'draggable-list__row draggable-list__row--placeholder';

  var _default = Ember.Component.extend({
    layout: _sqDraggableList.default,
    // for maintaining focus after drag
    attributeBindings: ['tabindex'],
    tabindex: -1,
    classNames: ['draggable-list'],
    classNameBindings: ['isDragging:draggable-list--is-dragging'],
    listItems: null,
    dragOnlyOnHandle: false,
    $placeholder: null,
    // A local copy that we visually sort on drag, but clobber whenever a new
    // array is passed
    orderedItems: Ember.computed.reads('listItems'),
    shouldRenderList: true,
    getListStartIndex: function getListStartIndex() {
      return 0;
    },
    createPlaceholder: function createPlaceholder(draggedElement) {
      // Create a placholder / drop target element that will fill the width of our
      // container. Approach differs for table rows vs. divs.
      // This function will not create a placeholder if listItems is empty.
      var $placeholder = null;
      var $parentTable = (0, _jquery.default)(this.element).parents('table'); // eslint-disable-line ember/no-jquery

      var rowHeight = draggedElement.offsetHeight;

      if ($parentTable.length > 0) {
        var colspan = $parentTable[0].rows[0].cells.length;

        if (!Ember.isNone(colspan)) {
          // eslint-disable-next-line ember/no-jquery
          $placeholder = (0, _jquery.default)("<tr class=\"".concat(PLACEHOLDER_CLASSES, "\"><td style=\"height: ").concat(rowHeight, "px\" colspan=\"").concat(colspan, "\"></td></tr>"));
        }
      } else {
        if (!Ember.isNone(rowHeight)) {
          // eslint-disable-next-line ember/no-jquery
          $placeholder = (0, _jquery.default)("<div class=\"".concat(PLACEHOLDER_CLASSES, "\" style=\"height: ").concat(rowHeight, "px\"></div>"));
        }
      }

      this.set('$placeholder', $placeholder);
    },
    actions: {
      dragStart: function dragStart(draggedElement) {
        this.createPlaceholder(draggedElement);
        var $placeholder = this.get('$placeholder');
        var $rows = (0, _jquery.default)(this.element).find(ROW_SELECTOR); // eslint-disable-line ember/no-jquery

        var dragStartIndex = $rows.index(draggedElement);
        var isDraggingLastRow = this.getListStartIndex() + dragStartIndex + 1 === this.orderedItems.length;

        if (isDraggingLastRow) {
          var $prevRow = $rows.eq(dragStartIndex);
          $prevRow.after($placeholder);
        } else {
          var $nextRow = $rows.eq(dragStartIndex + 1);
          $nextRow.before($placeholder);
        }

        this.set('isDragging', true);
      },
      dragMove: function dragMove(draggabilly, event, pointer) {
        var pointerTopOffset = pointer.clientY; // We only care about the Y coordinate of the cursor. The X coordinate can change, and so long as the
        // mouse is still down aka the user is still dragging, it will only follow the Y coordinate.

        var pointerOverElement = document.elementFromPoint(draggabilly.dragStartPoint.x, pointerTopOffset); // eslint-disable-next-line ember/no-jquery

        var $currentRow = (0, _jquery.default)(this.element).find(pointerOverElement).closest(ROW_SELECTOR);
        var $placeholder = this.get('$placeholder');

        if ($currentRow.length > 0 && $placeholder[0] !== $currentRow[0]) {
          var currentRowClientRect = $currentRow[0].getBoundingClientRect();
          var currentRowTopOffset = currentRowClientRect.top;
          var currentRowHeight = currentRowClientRect.height; // The height of the offset is the height of the draggedElement as defined in createPlaceholder

          var draggedElementHeight = $placeholder[0].offsetHeight;
          var shouldShiftDown, shouldShiftUp;
          var draggedElementTallerThanCurrentRow = draggedElementHeight > currentRowHeight;

          if (draggedElementTallerThanCurrentRow) {
            shouldShiftUp = $currentRow.index() < $placeholder.index();
            shouldShiftDown = !shouldShiftUp;
          } else {
            // If the draggedElement is smaller than the currentElement, there is a space in the middle
            // of the currentElement that we don't want it to switch places. Otherwise it will switch,
            // then switch right back, and continue to do so until the pointer leaves that area.
            // Instead we only want it to switch places when the draggedElement is on the top/bottom
            // "half" of the element, "half" being defined by the height of the draggedElement.
            // This ensures that any further movement will not trigger the element to flip back.
            shouldShiftUp = pointerTopOffset <= currentRowTopOffset + draggedElementHeight;
            shouldShiftDown = pointerTopOffset >= currentRowTopOffset + currentRowHeight - draggedElementHeight;
          }

          if (shouldShiftDown) {
            $currentRow.after($placeholder);
          } else if (shouldShiftUp) {
            $currentRow.before($placeholder);
          }
        }
      },
      dragEnd: function dragEnd(draggedElement, draggedModel) {
        var $placeholder = this.get('$placeholder');
        var orderedItems = this.get('orderedItems');
        var dropIndex = this.getListStartIndex() + // eslint-disable-next-line ember/no-jquery
        (0, _jquery.default)(this.element).find(ROW_SELECTOR).not(draggedElement).index($placeholder); // Update visual ordering without mutating backing models

        orderedItems.removeObject(draggedModel);
        orderedItems.insertAt(dropIndex, draggedModel);
        $placeholder.detach();
        this.set('isDragging', false); // for maintaining focus after drag

        (0, _jquery.default)(this.element).focus(); // eslint-disable-line ember/no-jquery

        if (this.didReorder) {
          this.didReorder(orderedItems);
        }
      }
    }
  });

  _exports.default = _default;
});