import Component from '@glimmer/component';
import ENV from '@bridge/home-engine/config/environment';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import Data from 'bridge-dashboard/app/services/data';
import { EngineRouterService } from 'ember-engines-router-service';
import { FormMap } from '../../models/interface/form-map';
import { GatewaySystemType } from '@bridge/home-engine/models/services/source/gateway-system-type';
import {
  SquareAccount,
  SquareAuthStatus,
} from '../../models/services/source/source';
import SquareAuth from '../../services/square-auth';
import formNamesObjectParser from '../../utils/form-names-object-parser';
import FscAuth from '../../services/fsc-auth';
import FlashService from '@square/glass-ui/addon/services/flash';

interface SquareAuthIndicator {
  label: string;
  reauthRank: string;
  variant: string;
}

interface SquareAuthIndicators {
  [key: string]: SquareAuthIndicator;
}

/**
 * Source Settings wrapper for SettingsContainer.
 */
export default class SettingsSourceContainer extends Component<
  Record<string, never>
> {
  @service currentUser!: CurrentUser;
  @service data!: Data;
  @service flash!: FlashService;
  @service fscAuth!: FscAuth;
  @service router!: EngineRouterService;
  @service squareAuth!: SquareAuth;

  @tracked isLoading = true;
  @tracked isSaveDisabled = true;
  @tracked isSaveRequesting = false;
  @tracked disabled = false;

  sourceSettingsFormMap: Record<string, any> = {};
  g2cDownloadUrl = `${ENV.frontEndServiceUrl}/api/v1/signed-url/sap-connector-setup`;
  netsuiteRestletUrl = `${ENV.frontEndServiceUrl}/api/v1/signed-url/netsuite/restlet/GET_items.js`;
  installingG2cDownloadUrl = `${ENV.frontEndServiceUrl}/api/v1/signed-url/installing-g2c`;

  @action
  toggleLoadingState(loading: boolean): void {
    this.isLoading = loading;
  }

  @action
  handleFormMapUpdate(formMap: FormMap): void {
    this.sourceSettingsFormMap = formMap;
  }

  @action
  async handleSave(): Promise<void> {
    const requestBody = formNamesObjectParser(this.sourceSettingsFormMap);
    this.isSaveDisabled = true;
    this.isSaveRequesting = true;

    try {
      await this.data.postSourceSettings(
        requestBody,
        this.currentUser!.currentExternalSource!.id!
      );
      this.flash.globalSuccess('Source settings successfully updated!');
    } catch (error) {
      this.flash.globalError(`Sync settings update failed. ${error.message}`, {
        dismiss: () => {
          this.flash.clearGlobalMessage();
        },
      });
      if (error.message.includes('401')) throw error;
    }
  }

  @action
  setSaveButtonDisabled(state: boolean): void {
    this.isSaveDisabled = state;
  }

  get externalSourceId(): string {
    return this.currentUser!.currentExternalSource!.id!;
  }

  get ExternalSourceName(): string {
    return this.currentUser!.currentExternalSource!.cloud_elements_instance!
      .name;
  }

  get squareSource(): SquareAccount {
    return this.currentUser!.currentSquareSource!.square_account!;
  }

  get reauthorizationLink(): string {
    return this.squareAuth.getSquareIntegrationReauthorizationLink();
  }

  get showFscReauthorization(): boolean {
    return (
      this.currentUser.currentExternalSource?.external_source
        ?.gateway_system_type ===
      GatewaySystemType.FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT
    );
  }

  get showG2cDownloadContainer(): boolean {
    return (
      this.currentUser.currentExternalSource?.cloud_elements_instance
        ?.gateway_system_type === GatewaySystemType.SAP_BUSINESS_ONE
    );
  }

  squareAuthIndicators: SquareAuthIndicators = {
    Authorized: {
      label: 'Authorized',
      reauthRank: 'secondary',
      variant: 'success',
    },
    'Authorized - Automatic refresh failed': {
      label: 'Authorized - Automatic refresh failed',
      reauthRank: 'primary',
      variant: 'warning',
    },
    Unauthorized: {
      label: 'Unauthorized',
      reauthRank: 'primary',
      variant: 'critical',
    },
  };

  get squareAuthIndicator(): SquareAuthIndicator {
    if (Date.parse(this.squareSource.expires_at) < Date.now()) {
      return this.squareAuthIndicators[SquareAuthStatus.UNAUTHORIZED];
    } else if (this.squareSource.refresh_status === 'FAILED') {
      return this.squareAuthIndicators[
        SquareAuthStatus.AUTHORIZED_REFRESH_FAIL
      ];
    } else {
      return this.squareAuthIndicators[SquareAuthStatus.AUTHORIZED];
    }
  }

  @action
  triggerSquareOAuthFlow(): void {
    this.disabled = true;
  }
}
