import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Data from 'bridge-dashboard/app/services/data';

import { MerchantApplication } from '../../models/services/merchant-application/merchant-application';

export default class AuthorizedRoutePlugins extends Route {
  @service data!: Data;

  @action
  refreshPlugins(): void {
    this.refresh();
  }

  model(): Promise<Array<MerchantApplication>> {
    return this.data.getMerchantApplicationsResponse();
  }
}
