define("@square/glass-ui/components/sq-field-input-searchable/list-element", ["exports", "@square/glass-ui/templates/components/sq-field-input-searchable/list-element"], function (_exports, _listElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _listElement.default,
    isFlipEnabled: true,
    extraPopperModifiers: Ember.computed('isFlipEnabled', function () {
      return {
        flip: {
          enabled: this.isFlipEnabled
        },
        beforeApplyStyles: {
          // This modifier will run before the applyStyles modifier, which
          // has a default order value of 900.
          order: 900 - 1,
          enabled: true,
          fn: function fn(dataObject) {
            var data = dataObject; // Popper uses `position: fixed` to unconstrain the dropdown from its
            // parent. This breaks dynamic widths set in CSS, so we have to manually
            // update the width to equal the input field's width.

            data.styles.width = data.offsets.reference.width; // If the width of the browser window is an odd number, the left position of the
            // dropdown will be off by one pixel. We fix this by setting the dropdown's
            // left position equal to the input field's left position.

            data.styles.left = data.offsets.reference.left;
            return data;
          }
        }
      };
    })
  });

  _exports.default = _default;
});