define("@square/glass-ui/components/sq-billboard/media", ["exports", "@square/glass-ui/templates/components/sq-billboard/media"], function (_exports, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TYPE = {
    SVG: 'svg',
    IMAGE: 'image'
  };

  var _default = Ember.Component.extend({
    layout: _media.default,
    classNames: ['billboard__media'],
    classNameBindings: ['overlap:billboard__media--image-overlap', 'fullBleed:billboard__media--image-full'],
    attributeBindings: ['type:data-test-billboard-media'],
    type: TYPE.SVG,
    name: null,
    src: null,
    fullBleed: false,
    overlap: false,
    didRecieveAttrs: function didRecieveAttrs() {
      this._super.apply(this, arguments);

      (false && !(this.get('type') === TYPE.SVG && !this.get('name')) && Ember.assert('`name` is required when using svg type for billboard media', this.get('type') === TYPE.SVG && !this.get('name')));
      (false && !(this.get('type') === TYPE.IMAGE && !this.get('src')) && Ember.assert('`src` is required when using image type for billboard media', this.get('type') === TYPE.IMAGE && !this.get('src')));
    }
  });

  _exports.default = _default;
});