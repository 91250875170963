define("@square/glass-ui/components/sq-field-select", ["exports", "@square/glass-ui/templates/components/sq-field-select", "@square/glass-ui/utils/field-element-support"], function (_exports, _sqFieldSelect, _fieldElementSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldElementSupport.default, {
    layout: _sqFieldSelect.default,
    classNameBindings: ['includeBaseStyling:form-field__select-container', 'includeBaseStyling:form-field__caret-container'],
    content: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    optionDisabledPath: 'disabled',
    disabled: false,
    useOptionsAsValues: false,
    enablePromptSelection: false,
    name: null,
    value: null,
    prompt: null,
    isInvalid: false,
    includeBaseStyling: true,
    selectPaddingBuffer: 0,
    optionsAreStringsOrNumbers: Ember.computed('content.[]', function () {
      var options = this.get('content');
      return Ember.isArray(options) && options.every(function (option) {
        return typeof option === 'string' || typeof option === 'number';
      });
    }),
    change: function change(e) {
      if (this.get('isDestroyed')) {
        return;
      }

      var selectIndex = e.target.selectedIndex;
      var optionsIndex = this.get('prompt') ? selectIndex - 1 : selectIndex; // Ember Arrays seem to sometimes not return the desired option if you
      // just use `[]`, so we use Ember Array's `objectAt` if present

      var selectedOption = typeof this.get('content').objectAt === 'function' ? this.get('content').objectAt(optionsIndex) : this.get('content')[optionsIndex];
      var selectedValue;

      if (this.get('useOptionsAsValues') || this.get('optionsAreStringsOrNumbers')) {
        selectedValue = selectedOption;
      } else {
        selectedValue = Ember.get(selectedOption, this.get('optionValuePath'));
      }

      this.set('value', selectedValue);

      if (this.get('onChange')) {
        this.get('onChange')(selectedOption);
      }
    }
  });

  _exports.default = _default;
});