import Service, { inject as service } from '@ember/service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import FetchHandler from 'bridge-dashboard/app/services/fetch-handler';
import { RunManualSyncResult } from '../components/manual-sync-modal';

import BatchRetrieveSyncJobsResponse from '../models/services/sync/batch-retrieve-sync-jobs-response';
import { RetrieveSyncJobResponse } from '../models/services/sync/retrieve-sync-job-response';
import { RunSyncRequest } from '../models/services/sync/run-sync-request';
import { RunSyncResponse } from '../models/services/sync/run-sync-response';
import { SearchSyncJobsResponse } from '../models/services/sync/search-sync-jobs-response';
import { SyncJob } from '../models/services/sync/sync-job';
import generateQueryParameters from '../utils/query-parameter-generator';

export interface AuditRouterParams {
  cursor?: string;
  states?: string[];
  sync_types?: string[];
  ids?: string[];
}

export default class SyncService extends Service {
  @service declare currentUser: CurrentUser;
  @service declare fetchHandler: FetchHandler;

  apiPrefix = '/api/v1';

  runSync(syncType: string, runSyncResult: RunManualSyncResult): Promise<SyncJob[]> {
    const integrationId = this.currentUser!.currentIntegration!.id;
    const runSyncUrl = `${this.apiPrefix}/jobs/run`;

    if (!integrationId) {
      throw new Error("Expected integration ID to have a value");
    }

    const request: RunSyncRequest = {
      integration_id: integrationId,
      sync_type: syncType,
      location_ids: runSyncResult.location_ids,
      execution_type: runSyncResult.execution_type,
      begin_window: runSyncResult.begin_date,
      end_window: runSyncResult.end_date,
      timezone: runSyncResult.timezone
    };

    return this.fetchHandler.doPostAndHandleResponse<RunSyncResponse>(runSyncUrl, request)
      .then((response) => response.jobs);
  }

  getById(syncId: string): Promise<SyncJob> {
    return this.fetchHandler
      .doGetAndHandleResponse<RetrieveSyncJobResponse>(
        `${this.apiPrefix}/jobs/${syncId}`
      )
      .then((response) => response.job);
  }

  search(params?: AuditRouterParams): Promise<SearchSyncJobsResponse> {
    const integrationId = this.currentUser!.currentIntegration!.id;
    let url = `${this.apiPrefix}/integrations/${integrationId}/sync-jobs`;
    if (params) {
      const paramsString = generateQueryParameters(params);
      url += paramsString;
    }
    return this.fetchHandler
      .doGetAndHandleResponse<SearchSyncJobsResponse>(url)
      .then((response) => response);
  }

  batchRetrieve(syncJobIds: string[]): Promise<SyncJob[]> {
    const integrationId = this.currentUser!.currentIntegration!.id;
    return this.fetchHandler
      .doPostAndHandleResponse<BatchRetrieveSyncJobsResponse>(
        `${this.apiPrefix}/integrations/${integrationId}/sync-jobs/batch-retrieve`,
        { job_ids: syncJobIds }
      )
      .then((response) => response.jobs);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    syncService: SyncService;
  }
}
