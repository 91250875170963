import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import AuthorizedRouteIntegrationSyncDataCatalog from '../catalog';

export default class AuthorizedRouteIntegrationSyncDataCatalogShow extends Controller {
  @controller('authorized-route/integration/sync-data/catalog')
  catalog!: AuthorizedRouteIntegrationSyncDataCatalog;

  @action
  update() {
    this.catalog.refreshModel();
  }

  @action
  close() {
    this.transitionToRoute('authorized-route.integration.sync-data.catalog');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/catalog/show': AuthorizedRouteIntegrationSyncDataCatalogShow;
  }
}
