define("@square/glass-ui/components/sq-drop-target", ["exports", "@square/glass-ui/templates/components/sq-drop-target", "jquery"], function (_exports, _sqDropTarget, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqDropTarget.default,
    classNames: ['drop-target'],
    classNameBindings: ['isDraggedOver:drop-target--is-dragged-over', 'hasFile:drop-target--has-file', 'isInvalid:drop-target--is-invalid'],
    isInvalid: false,
    isDraggedOver: false,
    file: null,
    fileTypeWhitelist: null,
    placeholderText: null,
    fileSelectLinkOr: null,
    fileSelectLinkText: null,
    hasFile: Ember.computed('file', function () {
      return this.get('file') !== null;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Ember does not attach a default handler for this event
      // for some reason, so we do it ourselves.
      // eslint-disable-next-line ember/no-jquery


      (0, _jquery.default)(this.element).on('dragover.drop-target', function (e) {
        e.preventDefault(); // ONCALL-9294 - When dragging from Chrome's download bar,
        // `e.dataTransfer.effectAllowed` will be set to 'copyMove', so we must set
        // the drop effect to one of these in order for the drop event to be triggered.

        e.dataTransfer.dropEffect = 'copy'; // eslint-disable-line no-param-reassign
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).off('.drop-target'); // eslint-disable-line ember/no-jquery
    },
    dragEnter: function dragEnter(e) {
      e.preventDefault();
      this.set('isDraggedOver', true);
    },
    dragLeave: function dragLeave() {
      this.set('isDraggedOver', false);
    },
    drop: function drop(e) {
      e.preventDefault();
      this.set('isDraggedOver', false);

      if (this.dropped) {
        this.dropped(e);
      }

      var file = e.dataTransfer.files[0];

      if (!file) {
        return;
      }

      var invalid = !this.validateFileType(file, this.get('fileTypeWhitelist'));
      this.set('isInvalid', invalid);

      if (this.fileChanged) {
        this.fileChanged(e, file, invalid);
      }
    },
    validateFileType: function validateFileType(file, whitelist) {
      if (Ember.isBlank(whitelist)) {
        return true;
      }

      var fileTypes = whitelist.split(',');
      var valid = false;

      if (Ember.isPresent(file.type)) {
        valid = fileTypes.includes(file.type);
      } // fallback to file extension if the mime type does not match


      if (!valid) {
        if (fileTypes.includes('text/csv')) {
          valid = valid || /\.csv$/.test(file.name);
        }

        if (fileTypes.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          valid = valid || /\.xlsx$/.test(file.name);
        }
      }

      return valid;
    },
    actions: {
      fileChanged: function fileChanged(e) {
        var file = this.get('file');
        var invalid = !this.validateFileType(file, this.get('fileTypeWhitelist'));
        this.set('isInvalid', invalid);

        if (this.fileChanged) {
          this.fileChanged(e, file, invalid);
        }
      },
      fileDismissed: function fileDismissed() {
        this.set('file', null);

        if (this.fileDismissed) {
          this.fileDismissed();
        }
      }
    }
  });

  _exports.default = _default;
});