/**
## SettingsSheet

Glass-UI-powered sheet (full-screen modal) that manages child form component, including requests (TODO) and validations.
See https://glass-docs.sqprod.co/components/glass/dialog-sheet.html for information on original component.

```example
<SettingsSheet
  @genericSheetTitle="Location Settings"
  @sheetTitleObjectProperty="location_name"
  @objectId={{this.model}}
  @settingsTarget="LOCATION"
/>
```

### Parameters
 * @param {string} [objectId] [ID of the object being configured for use in making the form request.]
 * @param {SettingsFormType} [settingsTarget] [String describing object type being configured for use in making the form request.]
 * @param {string} [sheetTitleObjectProperty] [The property of the target object that has value for customizing title.]
 * @param {string} [genericSheetTitle] [String that serves as the sheet title before the target object is loaded.]
*/

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// @ts-ignore ember engines has no exported types
import { EngineRouterService } from 'ember-engines-router-service';
// @ts-ignore lodash may have no exported types (TODO: find/install if so)
import _ from 'lodash';
import Config from '../services/config';
import Data from 'bridge-dashboard/app/services/data';
import formNamesObjectParser from '../utils/form-names-object-parser';
import { SettingsFormType } from '../models/interface/settings-form-type';
// @ts-ignore class path not recognized
import BridgeApiException from 'bridge-dashboard/models/bridge-api-exception';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { FormMap } from '../models/interface/form-map';
import { PostLocationSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-location-settings';

import FlashService from '@square/glass-ui/addon/services/flash';
interface SettingsSheetArgs {
  genericSheetTitle: string;
  objectId: string;
  routeOnSuccessfulSave: string;
  settingsTarget: SettingsFormType;
  sheetTitleObjectProperty: string;
}

export default class SettingsSheet extends Component<SettingsSheetArgs> {
  constructor(owner: unknown, args: SettingsSheetArgs) {
    super(owner, args);
    this.fetchAndDisplaySettingsForm(this.args.objectId);
  }

  @service config!: Config;
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;
  @service data!: Data;
  @service router!: EngineRouterService;

  @tracked configBlueprint!: Object;
  @tracked didCompletedFormChange: boolean = false;
  @tracked displayForm: boolean = false;
  @tracked isLoading: boolean = true;
  @tracked isSaveDisabled: boolean = true;
  @tracked isSaveRequesting: boolean = false;
  @tracked settingsFormLoading: boolean = false;
  @tracked sheetTitle: string = this.args.genericSheetTitle;

  supplementalObject: { [k: string]: any} = {};
  settingsFormMap: { [k: string]: any} = {};

  fetchAndDisplaySettingsForm(objectId: string) {
    this.isLoading = true;
    let configBlueprintResponsePromise;
    let supplementalObjectResponsePromse;
    switch (SettingsFormType[this.args.settingsTarget]) {

      // @ts-ignore TODO: fixme
      case SettingsFormType.LOCATION:
        configBlueprintResponsePromise = this.config.fetchLocationConfig(
          objectId
        );
        supplementalObjectResponsePromse = this.data.getSquareLocationById(
          objectId
        );
      default:
        break
    }

    Promise.all([
      configBlueprintResponsePromise,
      supplementalObjectResponsePromse,
    ]).then(
      (values) => {
        this.configBlueprint = values[0]!.configuration_blueprint;
        this.supplementalObject = values[1]!;
        this.sheetTitle = `${
          this.supplementalObject[this.args.sheetTitleObjectProperty]
        } ${this.args.genericSheetTitle}`;
        this.displayForm = true;
        this.isLoading = false;
      },
      (error: BridgeApiException) => {
        this.flash.globalError(error.message, {
          dismiss: () => {
            this.flash.clearGlobalMessage();
          },
        });
        this.router.transitionTo(
          'authorized-route.integration.settings.locations'
        );
      }
    );
  }

  @action
  handleBack() {
    this.flash.clearSheetMessage();
    window.history.back();
  }

  @action
  handleCompletedForm(newFormMap: FormMap, isSaveDisabled: boolean): void {
    if (
      !_.isEqual(this.settingsFormMap, newFormMap) &&
      (!_.isEmpty(this.settingsFormMap) ||
        !this.currentUser.isLocationConfigured(this.args.objectId))
    ) {
      this.didCompletedFormChange = true;
    }

    this.isSaveDisabled = isSaveDisabled;
    Object.assign(this.settingsFormMap, newFormMap);
  }

  @action
  async handleSave() {
    const requestBody = formNamesObjectParser(this.settingsFormMap);
    this.isSaveDisabled = true;
    this.isSaveRequesting = true;
    
    try {
      await this.routeSettingsPostRequest(requestBody);
      this.flash.globalSuccess(
        `${
          this.supplementalObject[this.args.sheetTitleObjectProperty]
        } location sync settings successfully updated!`
      );
      this.router.transitionTo(this.args.routeOnSuccessfulSave);
    } catch (error) {
      if (error.code) {
        this.flash.sheetError(
          `Settings save request failed. HTTP Response ${error.code}.`,
          {
            dismiss: () => {
              this.flash.clearSheetMessage();
            },
          }
        );
      } else {
        this.flash.sheetError(`Sync settings update failed.`, {
          dismiss: () => {
            this.flash.clearSheetMessage();
          },
        });
      }
    }

    this.isSaveDisabled = false;
    this.isSaveRequesting = false;
  }


  
  routeSettingsPostRequest(requestBody: PostLocationSettingsRequest | Record<string, any>) {
    switch (SettingsFormType[this.args.settingsTarget]) {
      case SettingsFormType.SOURCE:
        const sourceId = this.router.rootApplication.router.currentRoute.params
          .source_id;
        return this.data.postSourceSettings(requestBody, sourceId);
      case SettingsFormType.LOCATION:
        return this.data.postLocationSettings(this.args.objectId, requestBody as PostLocationSettingsRequest);
      default:
        return undefined;
    }
  }
}