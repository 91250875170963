define("@square/glass-ui/components/sq-card/attributes-list/multi-value-attribute", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-list/multi-value-attribute"], function (_exports, _multiValueAttribute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _multiValueAttribute.default,
    classNames: ['card__attributes-list__attribute'],
    classNameBindings: ['isFullWidth:card__attributes-list__attribute--is-full-width'],
    attributeBindings: ['data-test-card-attribute'],
    'data-test-card-attribute': true,
    // Passed in:
    isFullWidth: false
  });

  _exports.default = _default;
});