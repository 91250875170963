define("@square/glass-ui/components/svg/icon-mute", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-mute"], function (_exports, _svgIcon, _iconMute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconMute.default,
    classNames: ['svg-icon--mute'],
    width: 32,
    height: 32,
    viewBox: '0 0 32 32'
  });

  _exports.default = _default;
});