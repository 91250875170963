define("@square/glass-ui/components/svg/icon-radial-progress", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-radial-progress"], function (_exports, _svgIcon, _iconRadialProgress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconRadialProgress.default,
    classNames: ['svg-icon--radial-progress'],
    classNameBindings: ['isActive:loading-indicator', 'sizeClass'],
    color: 'blue-medium',
    trackColor: 'base',
    size: 'small',
    isActive: false,
    trackColorClass: Ember.computed('trackColor', function () {
      if (this.get('trackColor') === 'white') {
        return 'svg-icon--radial-progress__track--theme-color-white';
      } else {
        return 'svg-icon--radial-progress__track--theme-color-base';
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if (this.get('size') === 'large') {
        this.setProperties({
          sizeClass: 'svg-icon--radial-progress--large',
          isLarge: true,
          width: 80,
          height: 80,
          viewBox: '0 0 80 80'
        });
      } else {
        this.setProperties({
          sizeClass: 'svg-icon--radial-progress--small',
          isLarge: false,
          width: 20,
          height: 20,
          viewBox: '0 0 20 20'
        });
      }
    }
  });

  _exports.default = _default;
});