export enum EntityType {
  CATALOG_CATEGORY = 'CATALOG_CATEGORY',
  CATALOG_TAX = 'CATALOG_TAX',
  CATALOG_ITEM = 'CATALOG_ITEM',
  CATALOG_ITEM_VARIATION = 'CATALOG_ITEM_VARIATION',
  CATALOG_DISCOUNT = 'CATALOG_DISCOUNT',
  MODIFIER = 'MODIFIER',
  CUSTOMER = 'CUSTOMER',
  ORDER = 'ORDER',
  CREDIT_MEMO = 'CREDIT_MEMO',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  INVENTORY_ADJUSTMENT_UPWARD = 'INVENTORY_ADJUSTMENT_UPWARD',
  INVENTORY_ADJUSTMENT_DOWNWARD = 'INVENTORY_ADJUSTMENT_DOWNWARD',
  INVENTORY_PHYSICAL_COUNT = 'INVENTORY_PHYSICAL_COUNT',
  INVENTORY_TRANSFER = 'INVENTORY_TRANSFER',
  INVENTORY_LEVEL = 'INVENTORY_LEVEL',
  BANK_TRANSFER = 'BANK_TRANSFER',
}
