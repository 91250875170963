define("@square/glass-ui/services/screen", ["exports", "@square/glass-ui/utils/window"], function (_exports, _window) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    mobileQueryIsActive: false,
    printQueryIsActive: false,
    isNotMobile: Ember.computed.not('isMobile').readOnly(),
    isMobile: Ember.computed('currentUser.wantsDesktop', 'mobileQueryIsActive', function () {
      if (this.get('currentUser.wantsDesktop')) {
        // Merchant has forced desktop mode via the nav
        return false;
      }

      return this.get('mobileQueryIsActive');
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this.queryListenersMap = new Map(); // 600px is $medium-breakpoint in Glass's standard responsive breakpoints, from which we subtract 1 to get 599

      var mobileBreakpoint = 599;

      if (_window.matchMedia) {
        this.setupQueryActiveAttribute('mobileQueryIsActive', "screen and (max-width: ".concat(mobileBreakpoint, "px)"));
        this.setupQueryActiveAttribute('printQueryIsActive', 'print');
      }
    },
    willDestroy: function willDestroy() {
      var _iterator = _createForOfIteratorHelper(this.queryListenersMap.entries()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
              query = _step$value[0],
              listener = _step$value[1];

          query.removeListener(listener);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    setupQueryActiveAttribute: function setupQueryActiveAttribute(queryActiveAttribute, queryString) {
      var _this = this;

      var query = (0, _window.matchMedia)(queryString);

      var setQueryActiveAttribute = function setQueryActiveAttribute(e) {
        _this.set(queryActiveAttribute, e.matches);
      }; // Set `fooIsActive` attribute based on current query results


      setQueryActiveAttribute(query); // Add listener for future updates

      query.addListener(setQueryActiveAttribute); // Save these off for cleanup later

      this.queryListenersMap.set(query, setQueryActiveAttribute);
    }
  });

  _exports.default = _default;
});