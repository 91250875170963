import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { EngineRouterService } from 'ember-engines-router-service';
import SyncEntityGroupStatus from '@bridge/home-engine/addon/models/services/sync-entity-group-status/sync-entity-group-status'


export default class AuthorizedRouteSyncEntityGroupStatusesController extends Controller {

  @service router!: EngineRouterService;

  queryParams = ['state', 'entity_id'];

  @tracked state?: string;
  @tracked entity_id?: string;

  get filteredSyncEntityGroupStatuses() {

    let syncEntityGroupStatuses = this.model;

    if (this.state) {
      syncEntityGroupStatuses = syncEntityGroupStatuses.filter(
        (status: SyncEntityGroupStatus) => status.state === this.state?.toUpperCase()
      );
    }

    if (this.entity_id) {
      syncEntityGroupStatuses = syncEntityGroupStatuses.filter((status: SyncEntityGroupStatus) => {
        if (status.square_entity_id) {
          return (
            status.square_entity_id.includes(this.entity_id!.toUpperCase()) ||
            status.external_entity_id.includes(this.entity_id!.toUpperCase())
          );
        } else {
          return false;
        }
      });
    }

    return syncEntityGroupStatuses;
  }
}

declare module '@ember/controller' {
  interface ControllerRegistry {
    integrationSyncEntityGroupStatusesController: AuthorizedRouteSyncEntityGroupStatusesController;
  }
}
