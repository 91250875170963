define("@square/glass-ui/services/flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FlashContext = _exports.FLASH_TYPES = _exports.FLASH_DISMISS_MS = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var FLASH_TYPES = {
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING'
  };
  _exports.FLASH_TYPES = FLASH_TYPES;
  var FLASH_DISMISS_MS = 5000;
  _exports.FLASH_DISMISS_MS = FLASH_DISMISS_MS;
  var FlashContext = Ember.Object.extend({
    defaultFlashComponent: 'banner-flash',
    // passed in create
    FLASH_DISMISS_MS: null,
    // Manual messaging
    message: null,
    messageType: null,
    // Dynamic validation messaging
    validations: null,
    showingValidation: Ember.computed('validations.{message,warningMessage}', function () {
      return Ember.isPresent(this.get('validations.message')) || Ember.isPresent(this.get('validations.warningMessage'));
    }),
    // Field Level Errors (shown on focus via sq-form-field)
    focusedMessage: '',
    shouldShowFlash: Ember.computed.notEmpty('currentFlashObject'),
    // The message and type for the currently shown flash message. Will be `null` if nothing is actively being shown.
    currentFlashObject: Ember.computed('focusedMessage', 'message', 'messageType', 'validations.{message,warningMessage}', function () {
      // Note that we only show focused messages when there are active validation messages,
      // to avoid async issues with validation and focus states.
      if (this.get('focusedMessage') && (this.get('validations.message') || this.get('validations.warningMessage'))) {
        return {
          message: this.get('focusedMessage'),
          type: FLASH_TYPES.ERROR
        };
      }

      if (this.get('message')) {
        return {
          message: this.get('message'),
          type: this.get('messageType')
        };
      }

      if (this.get('validations.message')) {
        return {
          message: this.get('validations.message'),
          type: FLASH_TYPES.ERROR
        };
      }

      if (this.get('validations.warningMessage')) {
        return {
          message: this.get('validations.warningMessage'),
          type: FLASH_TYPES.WARNING
        };
      }

      return null;
    }),
    destroy: function destroy() {
      clearTimeout(this.autoDismissTask);

      this._super.apply(this, arguments);
    },
    // Messaging - used to manually pass a message for display
    setMessage: function setMessage(message, _ref) {
      var messageType = _ref.messageType,
          persistent = _ref.persistent,
          flashComponent = _ref.flashComponent,
          componentClass = _ref.componentClass,
          componentOptions = _ref.componentOptions,
          dismiss = _ref.dismiss;
      clearTimeout(this.autoDismissTask);
      this.setProperties({
        message: message,
        messageType: messageType,
        flashComponent: flashComponent,
        componentClass: componentClass,
        componentOptions: componentOptions,
        dismiss: dismiss
      });

      if (persistent !== true) {
        this.scheduleAutoDismiss();
      }
    },
    error: function error(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.setMessage(message, _objectSpread(_objectSpread({
        persistent: true
      }, options), {}, {
        messageType: FLASH_TYPES.ERROR
      }));
    },
    warning: function warning(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.setMessage(message, _objectSpread(_objectSpread({}, options), {}, {
        messageType: FLASH_TYPES.WARNING
      }));
    },
    success: function success(message, options) {
      this.setMessage(message, _objectSpread(_objectSpread({}, options), {}, {
        messageType: FLASH_TYPES.SUCCESS
      }));
    },
    clearMessage: function clearMessage() {
      if (this.isDestroyed) {
        return;
      }

      this.setProperties({
        message: null,
        messageType: null,
        componentOptions: {}
      });
    },
    // This is only its own method so it can be stubbed in tests
    scheduleAutoDismiss: function scheduleAutoDismiss() {
      var _this = this;

      this.autoDismissTask = setTimeout(function () {
        return _this.clearMessage();
      }, FLASH_DISMISS_MS);
    },
    // Validations - used to dynamically display the current validations
    // `validations` can be either a CP Validations object or the one generated
    // by the deprecated `validated_mixin`
    showValidation: function showValidation(validations) {
      this.set('validations', validations);
    },
    // Convert a changeset into a validation
    showChangeset: function showChangeset(changeset) {
      var _changeset$errors$;

      this.set('validations', {
        message: (_changeset$errors$ = changeset.errors[0]) === null || _changeset$errors$ === void 0 ? void 0 : _changeset$errors$.validation
      });
    },
    clearValidation: function clearValidation() {
      this.set('validations', null);
    },
    // Field-level Focused Message
    setFocusedMessage: function setFocusedMessage(message) {
      this.set('focusedMessage', message);
    },
    clearFocusedMessage: function clearFocusedMessage() {
      this.set('focusedMessage', null);
    }
  });
  _exports.FlashContext = FlashContext;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('contexts', {
        global: FlashContext.create({
          defaultFlashComponent: 'banner-flash-global'
        }),
        sheet: FlashContext.create(),
        modal: FlashContext.create(),
        blade: FlashContext.create()
      });
    },
    // Context messaging convenience methods
    globalError: function globalError(message, options) {
      this.get('contexts.global').error(message, options);
    },
    globalWarning: function globalWarning(message, options) {
      this.get('contexts.global').warning(message, options);
    },
    globalSuccess: function globalSuccess(message, options) {
      this.get('contexts.global').success(message, options);
    },
    sheetError: function sheetError(message, options) {
      this.get('contexts.sheet').error(message, options);
    },
    sheetWarning: function sheetWarning(message, options) {
      this.get('contexts.sheet').warning(message, options);
    },
    sheetSuccess: function sheetSuccess(message, options) {
      this.get('contexts.sheet').success(message, options);
    },
    modalError: function modalError(message, options) {
      this.get('contexts.modal').error(message, options);
    },
    modalWarning: function modalWarning(message, options) {
      this.get('contexts.modal').warning(message, options);
    },
    modalSuccess: function modalSuccess(message, options) {
      this.get('contexts.modal').success(message, options);
    },
    bladeError: function bladeError(message, options) {
      this.get('contexts.blade').error(message, options);
    },
    bladeWarning: function bladeWarning(message, options) {
      this.get('contexts.blade').warning(message, options);
    },
    bladeSuccess: function bladeSuccess(message, options) {
      this.get('contexts.blade').success(message, options);
    },
    clearGlobalMessage: function clearGlobalMessage() {
      this.get('contexts.global').clearMessage();
    },
    clearSheetMessage: function clearSheetMessage() {
      this.get('contexts.sheet').clearMessage();
    },
    clearModalMessage: function clearModalMessage() {
      this.get('contexts.modal').clearMessage();
    },
    clearBladeMessage: function clearBladeMessage() {
      this.get('contexts.blade').clearMessage();
    },
    // Context validation convenience methods
    showGlobalValidation: function showGlobalValidation(validations) {
      this.get('contexts.global').showValidation(validations);
    },
    clearGlobalValidation: function clearGlobalValidation() {
      this.get('contexts.global').clearValidation();
    },
    showSheetValidation: function showSheetValidation(validations) {
      this.get('contexts.sheet').showValidation(validations);
    },
    clearSheetValidation: function clearSheetValidation() {
      this.get('contexts.sheet').clearValidation();
    },
    showModalValidation: function showModalValidation(validations) {
      this.get('contexts.modal').showValidation(validations);
    },
    clearModalValidation: function clearModalValidation() {
      this.get('contexts.modal').clearValidation();
    },
    showGlobalChangeset: function showGlobalChangeset(changeset) {
      this.get('contexts.global').showChangeset(changeset);
    },
    showSheetChangeset: function showSheetChangeset(changeset) {
      this.get('contexts.sheet').showChangeset(changeset);
    },
    showModalChangeset: function showModalChangeset(changeset) {
      this.get('contexts.modal').showChangeset(changeset);
    }
  });

  _exports.default = _default;
});