import { helper } from '@ember/component/helper';

interface TimezoneifyArgumentObject {
  date: string;
}

// return the date & time string with user's timezone (e.g. 11/25/20, 11:37 AM PST)
export function timezoneify(
  _params: null,
  { date }: TimezoneifyArgumentObject
): string {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = {
    timeZoneName: 'short',
    hour: '2-digit',
    minute: '2-digit',
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  };

  return new Date(date).toLocaleString('en-us', options);
}

export default helper(timezoneify);
