define("@square/glass-ui/components/selector-control/content", ["exports", "@square/glass-ui/templates/components/selector-control/content"], function (_exports, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _content.default,
    classNames: ['selector-control__content'],
    attributeBindings: ['data-test-selector-control-content'],
    'data-test-selector-control-content': true
  });

  _exports.default = _default;
});