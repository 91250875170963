define("@square/glass-ui/components/selector-control/navigation/item/segment", ["exports", "@square/glass-ui/templates/components/selector-control/navigation/item/segment"], function (_exports, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _segment.default,
    tagName: 'button',
    classNames: ['button', 'segmented-control__segment'],
    classNameBindings: ['isActive:segmented-control__segment--selected'],
    attributeBindings: ['data-test-selector-control-segment'],
    'data-test-selector-control-segment': Ember.computed('isActive', function () {
      if (this.get('isActive')) {
        return 'active';
      } else {
        return 'inactive';
      }
    }),
    isActive: false,
    click: function click() {
      if (this.onSelect) {
        this.onSelect(this);
      }
    }
  });

  _exports.default = _default;
});