/**
## SettingsTable

Glass-UI-powered table that can programmatically display select attributes of instances of a provided model .
On click, each cell routes to a given path.

```example
<SettingsTable 
  @route="integration.settings.location" 
  @rows={{this.integrationLocations}} 
  @columns={{this.locationTableColumns}}
  @primaryButton={{this.addLocationButtonObject}}
  @secondaryButton={{this.goToSquareLocationsButtonObject}}
/>
```

### Parameters
 * @param {DS.RecordArray<any>} [rows] [Instances of an Ember data model to be displayed.]
 * @param {Array<string>} [columns] [Attributes of the instances to display.]
 * @param {string} [route] [The base route to redirect to on row click.]
 * @param {QueryParamObject} [queryParam] [An object with a query parameter's name-value pair.]
 * @param {settingsTableButtonObject} [primaryButton] [An object with data to create a primary-styled button and associated action.]
 * @param {settingsTableButtonObject} [secondaryButton] [An object with data to create a secondary-styled button and associated action.]
*/

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import DS from 'ember-data';
import RouterService from '@ember/routing/router-service';
import Location from '../models/services/location/location';
import { SettingsTableButtonObject } from '../models/interface/settings-table-button-object';

interface QueryParamObject {
  name: string;
  key: string;
}

interface SettingsTableArgs {
  rows: DS.RecordArray<any>;
  columns: Array<string>;
  resourceParam: QueryParamObject;
  route: string;
  primaryButton: SettingsTableButtonObject;
  secondaryButton: SettingsTableButtonObject;
}

export default class SettingsTable extends Component<SettingsTableArgs> {
  @service router!: RouterService;

  // on click, go to route provided
  @action
  assignClickToAction(sourceRow: Location): void {
    if (this.args.resourceParam) {
      const resourceParamValue = sourceRow[this.args.resourceParam.key as keyof Location];
      this.router.transitionTo(this.args.route, resourceParamValue);
    } else {
      this.router.transitionTo(this.args.route, sourceRow.id);
    }
  }

  // method for extracting values from keys of Ember Data model record
  @action
  extractValue(sourceRow: Location, attribute: keyof Location): any {
    return sourceRow[attribute];
  }
}
