define("@square/glass-ui/templates/components/sq-field-input-searchable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yADj7tdT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,21],null,[[\"input\",\"list\",\"isActive\",\"filter\"],[[30,[36,16],[\"sq-field-input-searchable/input-element\"],[[\"filter\",\"standaloneStyling\",\"placeholder\",\"maxlength\",\"disabled\",\"showCaret\",\"open\",\"filterChanged\"],[[35,0],[35,14],[35,20],[35,19],[35,18],[35,17],[30,[36,4],[[32,0],\"open\"],null],[30,[36,4],[[32,0],\"filterChanged\"],null]]]],[30,[36,16],[\"sq-field-input-searchable/list-element\"],[[\"allowDisabled\",\"standaloneStyling\",\"isOpen\",\"renderOptionsOnlyWhenOpen\",\"renderedOptions\",\"highlightedOption\",\"nullStateText\",\"isLoading\",\"hasHeaders\",\"labelPath\",\"optionClicked\",\"referenceElement\",\"constrainInParent\",\"setPopperInstance\"],[[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[30,[36,4],[[32,0],\"optionClicked\"],null],[35,5],false,[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]]],[35,1],[35,0]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filter\",\"isActive\",\"popper\",\"mut\",\"action\",\"input\",\"labelPath\",\"groupOptionsBy\",\"isLoading\",\"nullStateText\",\"highlightedOption\",\"formattedOptions\",\"renderOptionsOnlyWhenOpen\",\"isOpen\",\"standaloneStyling\",\"allowDisabled\",\"component\",\"shouldShowCaret\",\"disabled\",\"maxlength\",\"placeholder\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-field-input-searchable.hbs"
    }
  });

  _exports.default = _default;
});