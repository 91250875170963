/**
## Audit::Filter

A component for displaying and handling changes in state of a filter

```example
<Audit::Filter @objectTypes={{this.integrationObjectTypes}} />
```

### Parameters
  * @param {Function: void} [filterHandler] [The event handler for changes in a dropdown selector. ]
  * @param {String} [state] [The current state for filtering sync jobs. ]
  * @param {String} [sync_type] [The current type for filtering sync jobs. ]
*/

import Component from '@glimmer/component';

interface IntegrationAuditFilterArgs {
  filterHandler: Function;
  state: string;
  sync_type: string;
}

export default class IntegrationAuditFilter extends Component<IntegrationAuditFilterArgs> {}
