import Ember from 'ember';
import Route from '@ember/routing/route';

export default class AuthorizedRouteIntegrationAuditLoading extends Route {  
  // pass destination route's query params to loading substate for optimistic rendering
  setupController(controller: any/*, model, transition*/) {
    controller.set('model', Ember.Object.create({
      state: controller.target.targetState.routerJsState.queryParams.state,
      sync_type: controller.target.targetState.routerJsState.queryParams.sync_type
    }))
  }
}
