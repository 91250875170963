define("@square/glass-ui/templates/components/media-block/media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M0sNucpL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"image\",\"video\",\"block\",\"content\"],[[30,[36,0],[\"responsive-image\"],[[\"data-test-media-block-image\",\"type\",\"class\"],[true,\"IMAGE\",\"media-block__media__image\"]]],[30,[36,0],[\"video-player\"],[[\"class\",\"canInteract\",\"autoplay\",\"playsinline\",\"loop\"],[\"media-block__media__video\",[30,[36,2],[[35,1]],null],[35,1],[35,1],[35,1]]]],[30,[36,0],[\"media-block/section\"],null],[30,[36,0],[\"media-block/content\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"shouldPreventMediaInteract\",\"not\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/media-block/media.hbs"
    }
  });

  _exports.default = _default;
});