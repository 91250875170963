define("@square/glass-ui/components/sq-form-field", ["exports", "@square/glass-ui/templates/components/sq-form-field", "@square/glass-ui/components/sq-field-checkboxes", "@square/glass-ui/components/sq-option-switch", "@square/glass-ui/components/sq-field-radio-set"], function (_exports, _sqFormField, _sqFieldCheckboxes, _sqOptionSwitch, _sqFieldRadioSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    layout: _sqFormField.default,
    classNames: 'form-field',
    classNameBindings: ['shouldShowErrorState:form-field--is-invalid', 'isDisabled:form-field--is-disabled', 'isFocused:form-field--is-focused', 'isReadOnly:form-field--is-read-only', 'withCheckboxes:form-field--with-checkboxes', 'withSwitches:form-field--with-switches', 'withRadioSet:form-field--with-radio-set', 'noLabel:form-field--no-label', 'withTextOnly:form-field--with-text-only', 'useTopLabelVariant:form-field--top-label', 'responsiveColumnClass'],
    managedFields: Ember.computed(function () {
      return Ember.A([]);
    }),
    // NOTE: isInvalid, isDisabled and isReadOnly are not included here because
    // we check hasOwnProperty on them in init.
    // TODO(mattwright): Remove init aggregate behavior and add defaults here.
    noLabel: false,
    emptyLabel: false,
    errorMessage: null,
    showValidation: true,
    tooltip: null,
    // The following attributes are only relevant for the top label form variant
    useTopLabelVariant: false,
    topLabelResponsiveColumnSpan: 'half',
    // Aggregate properties
    withCheckboxes: false,
    withSwitches: false,
    withRadioSet: false,
    withTextOnly: false,
    shouldShowErrorState: Ember.computed.and('showValidation', 'isInvalid'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // FIXME: These are observers instead of computeds so that we can use
      // `Ember.run` to schedule the property updates, which is a hack to avoid a
      // backtracking render error that began throwing in Ember 2.10. We should
      // really try to remove this kind of munging of the outer parent by the
      // child, probably by just explicitly putting CSS class names in the
      // templates rather than detecting.
      // Note that we only set up observers if the property is not passed directly

      /* eslint-disable ember/no-observers, no-prototype-builtins */


      if (!this.hasOwnProperty('isDisabled')) {
        this.addObserver('managedFields.@each.isDisabled', this, function () {
          Ember.run.scheduleOnce('afterRender', _this, 'updateAggregateIsDisabled');
        });
      }

      if (!this.hasOwnProperty('isFocused')) {
        this.addObserver('managedFields.@each.isFocused', this, function () {
          Ember.run.scheduleOnce('afterRender', _this, 'updateAggregateIsFocused');
        });
      }

      if (!this.hasOwnProperty('isInvalid')) {
        this.addObserver('managedFields.@each.isInvalid', this, function () {
          Ember.run.scheduleOnce('afterRender', _this, 'updateAggregateIsInvalid');
        });
      }

      if (!this.hasOwnProperty('isReadOnly')) {
        this.addObserver('managedFields.@each.isReadOnly', this, function () {
          Ember.run.scheduleOnce('afterRender', _this, 'updateAggregateIsReadOnly');
        });
      }
      /* eslint-enable ember/no-observers, no-prototype-builtins */

    },
    destroy: function destroy() {
      Ember.run.cancel(this._focusMessageTask);

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (false && !(!Ember.isBlank(this.get('label')) || this.get('noLabel') || this.get('emptyLabel')) && Ember.assert('Form fields require either a label or the "noLabel" setting turned on.', !Ember.isBlank(this.get('label')) || this.get('noLabel') || this.get('emptyLabel'))); // Set modifiers if we detect checkboxes or switches inside the form field block element, affect spacing/padding

      var childViews = this.get('childViews');

      var _iterator = _createForOfIteratorHelper(childViews),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var childView = _step.value;

          if (childView instanceof _sqFieldCheckboxes.default) {
            this.set('withCheckboxes', true);
          }

          if (childView instanceof _sqOptionSwitch.default) {
            this.set('withSwitches', true);
          }

          if (childView instanceof _sqFieldRadioSet.default) {
            this.set('withRadioSet', true);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      if (!this.get('flashContext')) {
        return;
      }

      var errorMessage = this.get('errorMessage');

      if (this.get('isFocused') && errorMessage) {
        Ember.run.cancel(this._focusMessageTask); // We have to schedule these or Ember will throw a backtracking
        // render error due to `flash.currentMessage` already being set

        this._focusMessageTask = Ember.run.once(this, this._setFocusedMessage, errorMessage);
      } else if (this._didSetFocusedMessage && !errorMessage) {
        Ember.run.cancel(this._focusMessageTask);
        this._focusMessageTask = Ember.run.once(this, this._clearFocusedMessage);
      }
    },
    responsiveColumnClass: Ember.computed('useTopLabelVariant', 'topLabelResponsiveColumnSpan', function () {
      // only applied for top label variant
      return this.useTopLabelVariant ? "responsive-column responsive-column--".concat(this.topLabelResponsiveColumnSpan) : false;
    }),
    focusIn: function focusIn() {
      if (!this.get('flashContext')) {
        return;
      }

      var errorMessage = this.get('errorMessage');

      if (errorMessage) {
        Ember.run.once(this, this._setFocusedMessage, errorMessage);
      }
    },
    focusOut: function focusOut() {
      if (!this.get('flashContext')) {
        return;
      }

      if (this._didSetFocusedMessage) {
        Ember.run.once(this, this._clearFocusedMessage);
      }
    },
    _setFocusedMessage: function _setFocusedMessage(errorMessage) {
      this.get('flashContext').setFocusedMessage(errorMessage);
      this._didSetFocusedMessage = true;
    },
    _clearFocusedMessage: function _clearFocusedMessage() {
      this.get('flashContext').clearFocusedMessage();
      this._didSetFocusedMessage = false;
    },
    // These are all separate functions so we can pass them to
    // `Ember.run.scheduleOnce` and have them only be called once per runloop.
    updateAggregateIsDisabled: function updateAggregateIsDisabled() {
      this.updateAggregateProperty('isDisabled');
    },
    updateAggregateIsFocused: function updateAggregateIsFocused() {
      this.updateAggregateProperty('isFocused');
    },
    updateAggregateIsInvalid: function updateAggregateIsInvalid() {
      this.updateAggregateProperty('isInvalid');
    },
    updateAggregateIsReadOnly: function updateAggregateIsReadOnly() {
      this.updateAggregateProperty('isReadOnly');
    },
    updateAggregateProperty: function updateAggregateProperty(property) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.set(property, this.get('managedFields').isAny(property));
    },
    addManagedField: function addManagedField(field) {
      this.get('managedFields').addObject(field);
    },
    removeManagedField: function removeManagedField(field) {
      this.get('managedFields').removeObject(field);
    }
  });

  _exports.default = _default;
});