define("@square/glass-ui/services/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isActionableElement = isActionableElement;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var ACTIONABLE_TAG_NAMES = ['a', 'button', 'input', 'select', 'textarea'];
  var ACTIONABLE_SELECTOR = [].concat(_toConsumableArray(ACTIONABLE_TAG_NAMES.map(function (tag) {
    return "".concat(tag, ":not([disabled])");
  })), ['[data-ember-action]']).join(','); // If the element exists, it tries to focus on it.
  // It will assert if the element does not exist or is not actionable.

  function focusElement(element) {
    (false && !(element) && Ember.assert('Element must exist to be focused', element));

    if (element) {
      element.focus();
      (false && !(document.activeElement === element) && Ember.assert('Attempted to focus on element that was not focusable', document.activeElement === element));
    }
  }

  function isActionableElement(el) {
    return el.matches(ACTIONABLE_SELECTOR);
  } // Returns all element siblings between the two elements.


  function findElementsBetween(firstElement, lastElement) {
    var currentElement = firstElement.nextElementSibling;
    var between = [];

    while (currentElement !== lastElement) {
      between.push(currentElement);
      currentElement = currentElement.nextElementSibling;
    }

    return between;
  } // Gets all elements that match the given selector that are either
  // in the elements list or are descendents of an element in the list.
  // If an element in the list matches the selector, its descendents are not searched.


  function inclusiveDescendantsWithSelector(elements, selector) {
    return elements.flatMap(function (element) {
      if (element.matches(selector)) {
        return [element];
      } else {
        return _toConsumableArray(element.querySelectorAll(selector));
      }
    });
  }
  /*
  
  ## Focus Service:
  
  A central authority for handling focus for dialogs. Works in conjunction with the
  `{{focus-manager}}` component.
  
  To use, wrap your component template in a `{{focus-manager}}`. When the element is inserted,
  it will register itself with the focus service and focus on itself.
  
  If one focus manager is destroyed, the previous focus manager that was created will be given the focus back.
  
  */


  var _default = Ember.Service.extend({
    focusManagers: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.focusManagers = [];
    },
    addFocusManager: function addFocusManager(focusId, autofocusSelector) {
      this.focusManagers = [].concat(_toConsumableArray(this.focusManagers), [{
        focusId: focusId,
        autofocusSelector: autofocusSelector
      }]);
      this.focusOn(focusId, autofocusSelector);
    },
    removeFocusManager: function removeFocusManager(focusIdToRemove) {
      var _this = this;

      this.focusManagers = this.focusManagers.filter(function (_ref) {
        var focusId = _ref.focusId;
        return focusId !== focusIdToRemove;
      }); // Attempting to focus on a hidden element doesn't work, so we give
      // the other focus manager a chance to become visible

      Ember.run.schedule('afterRender', function () {
        if (_this.focusManagers.length > 0) {
          var _this$focusManagers = _this.focusManagers[_this.focusManagers.length - 1],
              focusId = _this$focusManagers.focusId,
              autofocusSelector = _this$focusManagers.autofocusSelector;

          _this.focusOn(focusId, autofocusSelector);
        }
      });
    },
    // Gets elements in this component, excluding the focus-capturing divs
    getElements: function getElements(focusId) {
      var _document$querySelect = document.querySelectorAll("[data-focus-manager-capture-focus=\"".concat(focusId, "\"]")),
          _document$querySelect2 = _slicedToArray(_document$querySelect, 2),
          firstElement = _document$querySelect2[0],
          lastElement = _document$querySelect2[1];

      return findElementsBetween(firstElement, lastElement);
    },
    focusOn: function focusOn(focusId, autofocusSelector) {
      // Attempt to focus on the passed-in selector, if it exists,
      // or the first actionable element.
      if (autofocusSelector) {
        var autofocusElements = inclusiveDescendantsWithSelector(this.getElements(focusId), autofocusSelector);
        focusElement(autofocusElements[0]);
      } else {
        this.captureFocus(focusId, 'first');
      }
    },
    captureFocus: function captureFocus(focusId, which) {
      // if the user tabs outside of the modal (hitting either hidden div),
      // move focus back to the first or last actionable element
      var focusableElements = inclusiveDescendantsWithSelector(this.getElements(focusId), ACTIONABLE_SELECTOR);

      if (focusableElements.length > 0) {
        if (which === 'first') {
          focusableElements[0].focus();
        } else {
          focusableElements[focusableElements.length - 1].focus();
        }
      } else {
        // Do not leave the focus on the focus capture div
        document.activeElement.blur();
      }
    }
  });

  _exports.default = _default;
});