define("@square/glass-ui/components/sq-card/attributes-table/row", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-table/row"], function (_exports, _row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _row.default,
    tagName: 'tr',
    classNames: ['card__attributes-table__row']
  });

  _exports.default = _default;
});