define("@square/glass-ui/components/dialog-sheet", ["exports", "@square/glass-ui/templates/components/dialog-sheet", "@square/glass-ui/utils/window"], function (_exports, _dialogSheet, _window) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This class is necessary for CSS animation of the sheet opening
  var START_POSITION_CLASS = 'sheet-layout--start-position'; // The associated scss media query is $small-media-query
  // and 599px comes from $medium-breakpoint - 1

  var SHEET_MOBILE_BREAKPOINT = 'screen and (max-width: 599px)';

  var _default = Ember.Component.extend({
    layout: _dialogSheet.default,
    ariaRole: 'dialog',
    attributeBindings: ['data-test-sheet', 'isTransitioning:data-sheet-is-transitioning'],
    'data-test-sheet': true,
    classNames: ['dialog', 'dialog--sheet', 'sheet-layout'],
    classNameBindings: ['sheetBodyIsFullWidth:dialog--sheet__content--full-width'],
    animateSlide: true,
    flash: Ember.inject.service(),
    scroll: Ember.inject.service(),
    title: null,
    close: null,
    flashContext: null,
    // This param powers the data-sheet-is-transitioning attribute, which
    // can be used by test utilities to ensure sheet animation is completed
    // before contining with tests
    isTransitioning: false,
    // only used internally by the component
    isMobile: true,
    sheetBodyIsFullWidth: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // Set up a matchMedia query to set the isMobile
      // property on page resize


      this.mobileQuery = (0, _window.matchMedia)(SHEET_MOBILE_BREAKPOINT);

      this.updateIsMobile = function (e) {
        _this.set('isMobile', e.matches);
      };

      this.mobileQuery.addListener(this.updateIsMobile); // Fire the function manually once so the isMobile
      // param will be set correctly on first page load

      this.updateIsMobile(this.mobileQuery);
      this.get('scroll').disableWindowScrolling();
    },
    setupSlideAnimation: function setupSlideAnimation() {
      var _this2 = this;

      this._onTransitionEnd = Ember.run.bind(this, function () {
        if (this.isDestroyed) {
          return;
        }

        this.set('isTransitioning', false);
      });
      this.element.addEventListener('transitionend', this._onTransitionEnd);
      this.element.classList.add(START_POSITION_CLASS); // Remove START_POSITION_CLASS so sheet slides up

      this.animationTask = Ember.run.next(function () {
        _this2.set('isTransitioning', true);

        _this2.element.classList.remove(START_POSITION_CLASS);
      });
    },
    teardownSlideAnimation: function teardownSlideAnimation() {
      Ember.run.cancel(this.animationTask);
      this.element.removeEventListener('transitionend', this._onTransitionEnd);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.animateSlide) {
        this.setupSlideAnimation();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.mobileQuery.removeListener(this.updateIsMobile);

      if (this.animateSlide) {
        this.teardownSlideAnimation();
      }

      this.get('scroll').enableWindowScrolling();

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});