define("@square/glass-ui/components/sq-button", ["exports", "@square/glass-ui/templates/components/sq-button"], function (_exports, _sqButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BUTTON_VARIANT = _exports.BUTTON_RANK = void 0;
  var BUTTON_RANK = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary'
  };
  _exports.BUTTON_RANK = BUTTON_RANK;
  var BUTTON_VARIANT = {
    DESTRUCTIVE: 'destructive',
    ALTERNATIVE: 'alternative'
  };
  _exports.BUTTON_VARIANT = BUTTON_VARIANT;

  var _default = Ember.Component.extend({
    layout: _sqButton.default,
    tagName: 'button',
    attributeBindings: ['disabled', 'testSelector:data-test-button', 'isShowingSpinner:data-test-button-loading', 'type'],
    classNames: ['button'],
    classNameBindings: ['rankClass', 'variantClass', 'hasCaret:caret-down', 'isShowingSpinner:button--loading'],
    rank: BUTTON_RANK.SECONDARY,
    variant: null,
    disabled: false,
    label: null,
    spinnerColor: 'blue-medium',
    spinnerTrackColor: 'base',
    isShowingSpinner: false,
    hasCaret: false,
    type: Ember.computed('rank', function () {
      if (this.get('rank') === BUTTON_RANK.PRIMARY) {
        return 'submit';
      } else {
        return 'button';
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if ( // eslint-disable-next-line no-prototype-builtins
      !this.hasOwnProperty('spinnerColor') && this.get('rank') === 'primary') {
        this.set('spinnerColor', 'white');
        this.set('spinnerTrackColor', 'white');
      }
    },
    rankClass: Ember.computed('rank', function () {
      if (this.rank) {
        return "button--".concat(this.rank);
      } else {
        return '';
      }
    }),
    variantClass: Ember.computed('variant', function () {
      if (this.variant) {
        return "button--".concat(this.variant);
      } else {
        return '';
      }
    }),
    testSelector: Ember.computed('rank', function () {
      if (this.get('rank')) {
        return this.get('rank');
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});