import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Auth from 'bridge-dashboard/app/services/auth';
import Config from '../../../../services/config';
export default class AuthorizedRouteIntegrationSettingsSyncType extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  // normal class body definition here
  @service auth!: Auth;
  @service config!: Config;

  // results of 
  model() {
    return this.config.fetchSyncTypeConfig((this.paramsFor('integration.settings.object_type') as { object_type_id: string })).object_type_id;
  }
}

