define("@square/glass-ui/templates/components/svg/icon-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p5CjGlIR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"circle\"],[14,\"r\",\"3\"],[14,\"cx\",\"8\"],[14,\"cy\",\"8\"],[14,0,\"svg-icon__fill\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-radio.hbs"
    }
  });

  _exports.default = _default;
});