define("@square/glass-ui/components/sq-option", ["exports", "@square/glass-ui/templates/components/sq-option"], function (_exports, _sqOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqOption.default,
    classNames: ['option'],
    classNameBindings: ['_activeClass', 'isDisabled:option-is-disabled'],
    activeClass: 'option-is-active',
    // Applies customizable `activeClass` if `isActive`` is true
    _activeClass: Ember.computed.and('isActive', 'activeClass'),
    isActive: false,
    isDisabled: false,
    click: function click() {
      if (!this.get('isDisabled') && this.optionClickedAction) {
        this.optionClickedAction(this.get('value'), this.get('isActive'));
      }
    }
  });

  _exports.default = _default;
});