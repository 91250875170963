
import { CategoryAggregationRow } from "./category-aggregation-row"

export interface CategoryAggregationMapping {
    square_id: string
    external_id: string
    enabled: boolean
    id?: number
}

export function toCategoryAggregationMapping(categoryAggregationRow: CategoryAggregationRow): CategoryAggregationMapping {
    const { external_id, square_id, enabled} = categoryAggregationRow
    const categoryAggregationMapping: CategoryAggregationMapping = {
        enabled,
        external_id,
        square_id        
    }

    if('category_aggregation_mapping_id' in categoryAggregationRow) {
        categoryAggregationMapping.id = categoryAggregationRow.category_aggregation_mapping_id
    }

    return categoryAggregationMapping
}