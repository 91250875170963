define("@square/glass-ui/components/selector-control/navigation/item/step", ["exports", "@square/glass-ui/templates/components/selector-control/navigation/item/step"], function (_exports, _step) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _step.default,
    tagName: 'span',
    classNames: ['breadcrumbs__crumb'],
    attributeBindings: ['data-test-selector-control-step'],
    'data-test-selector-control-step': Ember.computed('isActive', function () {
      if (this.get('isActive')) {
        return 'active';
      } else {
        return 'inactive';
      }
    }),
    isActive: false,
    actions: {
      select: function select() {
        if (this.onSelect) {
          this.onSelect(this.get('stepIndex'));
        }
      }
    }
  });

  _exports.default = _default;
});