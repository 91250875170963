define("@square/glass-ui/templates/components/modals/modal-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8fJW1TlO",
    "block": "{\"symbols\":[\"modalObject\",\"index\"],\"statements\":[[6,[37,3],[[35,13]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"modal-layout \",[30,[36,10],[[35,9],\"modal-layout--inactive\"],null],\" \",[34,8],\" \",[34,7]]]],[12],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[30,[36,11],[[35,0,[\"stack\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"modal-layout__modal-container \",[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,2]],null],[35,0,[\"stack\",\"length\"]]],null],\"modal-layout__modal-container--hidden\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,6],[[32,1,[\"componentName\"]]],[[\"modalOptions\",\"close\",\"setCloseHandler\",\"lockModalHeight\",\"unlockModalHeight\"],[[32,1,[\"options\"]],[30,[36,4],[[32,0],\"close\"],null],[30,[36,4],[[32,0],[30,[36,5],[[32,1,[\"closeHandler\"]]],null]],null],[30,[36,4],[[32,0],\"lockModalHeight\"],null],[30,[36,4],[[32,0],\"unlockModalHeight\"],null]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modal\",\"inc\",\"not-eq\",\"if\",\"action\",\"mut\",\"component\",\"positionClassName\",\"zIndexClassName\",\"modalIsOpen\",\"unless\",\"-track-array\",\"each\",\"hasActiveModal\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/modals/modal-manager.hbs"
    }
  });

  _exports.default = _default;
});