define("@square/glass-ui/templates/components/sq-card/attributes-table/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bZ3o9o7H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"cell\"],[[30,[36,0],[\"sq-card/attributes-table/cell\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-card/attributes-table/row.hbs"
    }
  });

  _exports.default = _default;
});