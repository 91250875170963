define("@square/glass-ui/components/selector-control/navigation/button", ["exports", "@square/glass-ui/components/sq-button", "@square/glass-ui/templates/components/sq-button"], function (_exports, _sqButton, _sqButton2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SELECTOR_NAV_ACTION_TYPE = void 0;
  var SELECTOR_NAV_ACTION_TYPE = {
    NEXT: 'NEXT',
    PREVIOUS: 'PREVIOUS'
  };
  _exports.SELECTOR_NAV_ACTION_TYPE = SELECTOR_NAV_ACTION_TYPE;

  var _default = _sqButton.default.extend({
    layout: _sqButton2.default,
    classNames: ['selector-control__navigation__button'],
    classNameBindings: ['actionTypeClass'],
    attributeBindings: ['data-test-selector-control-button'],
    'data-test-selector-control-button': Ember.computed.reads('actionType'),
    actionType: SELECTOR_NAV_ACTION_TYPE.NEXT,
    actionTypeClass: Ember.computed('actionType', function () {
      if (this.get('actionType') === SELECTOR_NAV_ACTION_TYPE.NEXT) {
        return 'selector-control__navigation__button--next';
      } else {
        return 'selector-control__navigation__button--previous';
      }
    }),
    click: function click() {
      if (this.onClick) {
        this.onClick();
      }

      if (!this.get('preventNavigation') && this.navigate) {
        this.navigate(this.get('actionType'));
      }
    }
  });

  _exports.default = _default;
});