import buildRoutes from 'ember-engines/routes';

export default buildRoutes(function () {
  // route map
  this.route('authorized-route', { path: '/' }, function () {
    this.route('accept-terms');
    this.route('new-integration');
    this.route('no-integrations');
    this.route('setup-integration');
    this.route('integration', function () {
      this.route('audit');
      this.route('audit', function () {
        this.route('show', { path: '/:sync_job_id' });
      });
      this.route('overview');
      this.route('settings', function () {
        this.route('integration');
        this.route('locations', function () {
          this.route('location', { path: '/:location_id' });
        });
        this.route('category-aggregations');
        this.route('source');
        this.route('sync-schedules');
        this.route('tax');
        this.route('measurement-unit-mappings');
        this.route('discount-mappings');
        this.route('item-modifier-mappings');
      });
      this.route('daily-invoices');
      this.route('daily-invoices', function () {
        this.route('daily-invoice-detail', { path: '/:groupId' })
      });
      this.route('sync-data', function () {
        // use parent-child routes, not of sibling routes, so blades open and close
        this.route('bank-transfers');
        this.route('bank-transfers', function () {
          this.route('show', { path: '/:pair_id' });
        });
        this.route('catalog');
        this.route('catalog', function () {
          this.route('show', { path: '/:pair_id' });
        });
        this.route('customers');
        this.route('customers', function () {
          this.route('show', { path: '/:pair_id' });
        });
        this.route('inventory-changes');
        this.route('inventory-changes', function () {
          this.route('show', { path: '/:pair_id' });
        });
        this.route('inventory-levels');
        this.route('inventory-levels', function () {
          this.route('show', { path: '/:pair_id' });
        });
        this.route('orders');
        this.route('orders', function () {
          this.route('show', { path: '/:pair_id' });
        });
      });
    });
    this.route('plugins', function () {
      this.route('plugin', { path: '/:plugin_id' });
      this.route('new');
    });
  });
});
