define("@square/glass-ui/utils/keyboard-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KEY_CODES = void 0;
  var KEY_CODES = {
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    LEFT_ARROW: 37,
    UP: 38,
    RIGHT_ARROW: 39,
    DOWN: 40
  };
  _exports.KEY_CODES = KEY_CODES;
});