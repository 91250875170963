import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import DailyInvoicesService from '../services/daily-invoices-service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import DailyInvoiceDetailRouteModel from '../routes/authorized-route/integration/daily-invoices/daily-invoice-detail';
import DailyInvoiceDetailBladeService, { ShowDailyInvoiceDetailBladeArgs } from '../services/daily-invoice-detail-blade-service';

export interface DailyInvoiceDetailBladeArgs {
  model: DailyInvoiceDetailRouteModel;
}

export default class DailyInvoiceDetailBlade extends Component<DailyInvoiceDetailBladeArgs> {
  @service dailyInvoicesService!: DailyInvoicesService;
  @service dailyInvoiceDetailBladeService!: DailyInvoiceDetailBladeService;
  @service currentUser!: CurrentUser;
  @service router!: any;

  @tracked data: ShowDailyInvoiceDetailBladeArgs | undefined;

  constructor(owner: unknown, args: DailyInvoiceDetailBladeArgs) {
    super(owner, args);
  }

  @action
  close() {
    this.router.transitionTo('authorized-route.integration.daily-invoices');
  }
}
