define("@square/glass-ui/templates/components/svg/icon-mute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NLuGYHcc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"d\",\"M7 19L12.8333 19L17 23L17 9L12.8333 13L7 13L7 19Z\"],[14,\"fill-rule\",\"evenodd\"],[14,0,\"svg-icon__fill\"],[12],[13],[2,\"\\n\"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,0,\"svg-icon__fill\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M22.9412 15.3698C22.7312 14.2426 21.9906 13.2883 21.3638 12.7715L22.6362 11.2285C23.5187 11.9561 24.5891 13.2952 24.9074 15.0035C25.2406 16.7919 24.7165 18.8142 22.6858 20.7278L21.3142 19.2722C22.9057 17.7725 23.1363 16.417 22.9412 15.3698Z\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-mute.hbs"
    }
  });

  _exports.default = _default;
});