define("@square/glass-ui/templates/components/video-player/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPagDEda",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"duration\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/video-player/duration.hbs"
    }
  });

  _exports.default = _default;
});