/**
## Input Search

SqInputSearch is a text field that sends an event when the search text changes.

```example
@title Basic Usage

@hbs
{{sq-input-search onChange=(action (mut value))}}

Value: {{value}}
```

```example
@title Usage with fluid width

@hbs
{{sq-input-search onChange=(action (mut value))
                  isFluid=true}}

Value: {{value}}
```

### Parameters
 * @param {action} [onChange] [An action that gets triggered when the text changes]
 * @param {String} [searchText] [The text in the component that is being searched for]
 * @param {String} [placeholderText] [The placeholder text]
 * @param {Number} [liveTypingDelay=150] [Time (in ms) component waits for additional input before firing onChange]
 * @param {Boolean} [isFluid] [The width of the search box should be maximized]

*/

import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { debounce } from '@ember/runloop';

export const DEFAULT_SEARCH_KEYPRESS_DELAY = 150; // ms

export default Component.extend({
  'data-test-input-search': readOnly('searchText'),
  classNames: ['input-icon', 'input-search', 'justify-opposite-item'],
  classNameBindings: ['isFluid:input-search--fluid'],
  searchText: null,
  prevSearchText: null,
  placeholderText: null,
  isFocused: false,
  isFluid: false,
  disabled: false,
  liveTypingDelay: DEFAULT_SEARCH_KEYPRESS_DELAY,
  
  init(...args: any[]) {
    this._super(...args);

    this.set('prevSearchText', this.searchText);
  },

  focusIn(...args: any[]) {
    this.set('isFocused', true);

    // @ts-ignore TODO: fixme
    if (this.onFocusIn) {

      // @ts-ignore TODO: fixme
      this.onFocusIn(...args);
    }
  },

  focusOut(...args: any[]) {
    this.set('isFocused', false);

    // @ts-ignore TODO: fixme
    if (this.onFocusOut) {

      // @ts-ignore TODO: fixme
      this.onFocusOut(...args);
    }
  },

  // debounce while typing
  input() {
    debounce(
      this,
      this.debouncedOnSearchTextChanged,
      this.liveTypingDelay,
      false
    );
  },

  // fire immediately on blur (should be no-op most of the time because
  // the action already fired on input)
  change() {
    this.onSearchTextChanged();
  },

  debouncedOnSearchTextChanged() {
    this.onSearchTextChanged();
  },

  // send the event only if the text actually
  // changes to account for the two event types
  onSearchTextChanged() {
    const newText = this.searchText || '';
    const oldText = this.prevSearchText || '';
    if (!this.isDestroyed) {
      if (newText !== oldText) {
        this.sendAction('onChange', newText); // eslint-disable-line ember/closure-actions
      }
      this.set('prevSearchText', newText);
    }
  },

  actions: {
    clearSearch() {
      this.set('searchText', null);
      this.onSearchTextChanged();
    },
  },
});
