define("@square/glass-ui/components/sq-option-checkbox", ["exports", "@square/glass-ui/templates/components/sq-option-checkbox", "@square/glass-ui/components/sq-checkbox"], function (_exports, _sqOptionCheckbox, _sqCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    layout: _sqOptionCheckbox.default,
    tagName: 'label',
    classNames: ['option', 'option--checkbox'],
    classNameBindings: ['checked:option--is-selected', 'checked:checkbox-option--is-checked', 'disabled:option--is-disabled'],
    attributeBindings: ['data-test-option-checkbox', 'data-test-option-checkbox-checked'],
    'data-test-option-checkbox': Ember.computed('label', 'value', function () {
      return this.get('label') || this.get('value') || true;
    }),
    'data-test-option-checkbox-checked': Ember.computed.alias('checked'),
    state: _sqCheckbox.default.UNCHECKED,
    testSelectorValue: Ember.computed('value', 'label', function () {
      var _this$value;

      return _typeof(this.value) === 'object' ? this.label : (_this$value = this.value) !== null && _this$value !== void 0 ? _this$value : true;
    })
  });

  _exports.default = _default;
});