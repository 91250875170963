define("@square/glass-ui/templates/components/focus-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H2wJkN7b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,\"data-focus-manager-capture-focus\",[34,0]],[14,0,\"focus-manager__capture-focus\"],[14,\"tabindex\",\"0\"],[15,\"onFocus\",[30,[36,1],[[32,0],[30,[36,1],[[32,0],\"captureFocus\",\"last\"],null]],null]],[12],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\\n\"],[10,\"div\"],[15,\"data-focus-manager-capture-focus\",[34,0]],[14,0,\"focus-manager__capture-focus\"],[14,\"tabindex\",\"0\"],[15,\"onFocus\",[30,[36,1],[[32,0],[30,[36,1],[[32,0],\"captureFocus\",\"first\"],null]],null]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"focusId\",\"action\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/focus-manager.hbs"
    }
  });

  _exports.default = _default;
});