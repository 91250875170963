/**
## SupportFooter

A footer with a link to a support site (TBD).


```example
<SupportFooter />
```

### Parameters

*/

import Component from '@glimmer/component';

interface SupportFooterArgs {}

export default class SupportFooter extends Component<SupportFooterArgs> {}
