import { helper } from '@ember/component/helper';
/* @ts-ignore TSLint can't find lodash, but it's here! */
import _ from 'lodash';

interface UppercaseifyArgumentObject {
  transformString: string;
  snakeify: boolean;
}

// (De)snakeifies a string
export function uppercaseify(
  _params: any,
  { transformString, snakeify }: UppercaseifyArgumentObject
): string {
  if (snakeify) {
    return _.snakeCase(transformString).toUpperCase();
  } else {
    return transformString
      .split('_')
      .join(' ')
      .toUpperCase();
  }
}

export default helper(uppercaseify);
