import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';
import formNamesObjectParser from '../../utils/form-names-object-parser';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import Integration from '../../models/services/integration/integration';
import { FormMap } from '../../models/interface/form-map';
import FlashService from '@square/glass-ui/addon/services/flash';

import { PostIntegrationSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-integration-settings'

interface SettingsDiscountContainerArgs { }

/**
 * Integration Settings wrapper for SettingsContainer.
 */
export default class SettingsDiscountContainer extends Component<SettingsDiscountContainerArgs> {

    @service currentUser!: CurrentUser;
    @service data!: Data;
    @service flash!: FlashService;

    @tracked isLoading: boolean = true;
    @tracked isSaveDisabled: boolean = true;
    @tracked isSaveRequesting: boolean = false;

    integrationSettingsFormMap: FormMap = {};

    @action
    toggleLoadingState(loading: boolean) {
        this.isLoading = loading;
    }

    @action
    handleFormMapUpdate(formMap: FormMap) {
        this.integrationSettingsFormMap = formMap;
    }

    @action
    async handleSave() {
        const requestBody = formNamesObjectParser(this.integrationSettingsFormMap) as PostIntegrationSettingsRequest;
        this.isSaveDisabled = true;
        this.isSaveRequesting = true;
        let requestResponse: Integration;
        try {
            requestResponse = await this.data.postIntegrationSettings(requestBody);
            this.currentUser.updateIntegration(requestResponse);
            this.flash.globalSuccess('Discount settings successfully updated!');
            this.isSaveRequesting = false;
        } catch (error) {
            this.flash.globalError(`Sync settings update failed. ${error.message}`, {
                dismiss: () => {
                    this.flash.clearGlobalMessage();
                },
            });
            this.isSaveRequesting = false;
            if (error.message.includes('401')) throw error;
        }
    }

    @action
    setSaveButtonDisabled(state: boolean) {
        this.isSaveDisabled = state;
    }
}