import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';

export default class AuthorizedRouteIntegrationSettingsSource extends Route {
  @service currentUser!: CurrentUser;

  model() {
    if (!this.currentUser.currentIntegration) return this.currentUser.loadIntegration();
    return
  }

  afterModel() {
    this.transitionTo({ queryParams: { source_state: null } });
  }
}
