define("@square/glass-ui/components/sq-table", ["exports", "@square/glass-ui/templates/components/sq-table"], function (_exports, _sqTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqTable.default,
    classNames: ['sq-table__container'],
    classNameBindings: ['isScrollableRight:sq-table__container--is-scrollable-right', 'isScrollableLeft:sq-table__container--is-scrollable-left'],
    // Applies `.sq-table__table--fixed-layout` to the inner table
    useFixedLayout: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.boundUpdateScrollableStatus = function () {
        return _this.updateScrollableStatus();
      };

      window.addEventListener('resize', this.boundUpdateScrollableStatus);
      this.element.addEventListener('scroll', this.boundUpdateScrollableStatus);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.updateScrollableStatus();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this.boundUpdateScrollableStatus);
      this.element.removeEventListener('scroll', this.boundUpdateScrollableStatus);
    },
    updateScrollableStatus: function updateScrollableStatus() {
      var _this$element = this.element,
          scrollWidth = _this$element.scrollWidth,
          clientWidth = _this$element.clientWidth,
          scrollLeft = _this$element.scrollLeft;
      var scrollRight = scrollWidth - clientWidth - scrollLeft;
      this.setProperties({
        isScrollableRight: scrollRight > 0,
        isScrollableLeft: scrollLeft > 0
      });
    }
  });

  _exports.default = _default;
});