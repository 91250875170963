define("@square/glass-ui/templates/components/sq-option-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vYiTHdno",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,13],null,[[\"fieldName\",\"value\",\"selected\",\"optionName\",\"disabled\",\"action\"],[[35,12],[35,11],[30,[36,10],[[35,9],true,null],null],[35,8],[35,7],[35,6]]]]],[2,\"\\n\\n\"],[10,\"div\"],[15,\"data-test-radio-label\",[30,[36,14],[[35,11],true],null]],[14,0,\"option__label radio__label\"],[12],[2,\"\\n\\n\"],[6,[37,10],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"text\"],[[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"text\"],[[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,1],null,[[\"label\",\"label-secondary\"],[[30,[36,0],[\"sq-option/label/primary\"],null],[30,[36,0],[\"sq-option/label/secondary\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"labelSecondary\",\"sq-option/label/secondary\",\"label\",\"sq-option/label/primary\",\"action\",\"disabled\",\"_optionName\",\"selected\",\"if\",\"value\",\"fieldName\",\"sq-radio\",\"or\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-option-radio.hbs"
    }
  });

  _exports.default = _default;
});