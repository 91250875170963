import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Data from 'bridge-dashboard/app/services/data';
import { hash } from 'rsvp';

import { PluginApplication } from '../../../models/services/plugin-application/plugin-application';
import { Gateway, Source } from '../../../models/services/source/source';
import FlashService from '@square/glass-ui/addon/services/flash';
interface PluginsNewModel {
  squareSources: Source[];
  plugins: PluginApplication[];
}

export default class AuthorizedRoutePluginsNew extends Route {
  @service data!: Data;
  @service flash!: FlashService;

  model(): Promise<PluginsNewModel> {
    return hash({
      squareSources: this.data.getAccountSources({ gateway: Gateway.SQUARE }),
      plugins: this.data.getPluginApplications(),
    });
  }

  afterModel(_model: Promise<PluginsNewModel>, transition: Transition): void {
    if (transition.to.queryParams.error_message) {
      this.flash.sheetError(transition.to.queryParams.error_message, {
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
    }
  }
}
