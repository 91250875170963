import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';

export default class AuthorizedRouteIndex extends Route {
  @service currentUser!: CurrentUser;

  beforeModel() {
    if (this.currentUser.hasIntegration()) {
      this.transitionTo('authorized-route.integration.overview');
    }
  }
}
