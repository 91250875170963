define("@square/glass-ui/templates/components/select-all-checkbox-manager/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkFLd4fr",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,3],null,[[\"checkbox\"],[[30,[36,2],[\"sq-option-checkbox\"],[[\"checked\",\"value\",\"label\",\"labelSecondary\",\"disabled\",\"action\"],[[32,1,[\"checked\"]],[32,1],[32,1,[\"label\"]],[32,1,[\"labelSecondary\"]],[32,1,[\"disabled\"]],[30,[36,1],[[32,0],[35,0]],null]]]]]]],[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"optionDidChange\",\"action\",\"component\",\"hash\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/select-all-checkbox-manager/list.hbs"
    }
  });

  _exports.default = _default;
});