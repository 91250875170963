define("@square/glass-ui/components/sq-radio", ["exports", "@square/glass-ui/templates/components/sq-radio"], function (_exports, _sqRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqRadio.default,
    tagName: 'span',
    classNames: ['option-toggle', 'radio'],
    classNameBindings: ['selected:option-toggle--is-selected', 'selected:radio--is-selected', 'disabled:option-toggle--is-disabled', 'disabled:radio--is-disabled'],
    attributeBindings: ['data-test-radio', 'data-test-radio-selected'],
    'data-test-radio': Ember.computed('value', function () {
      return this.get('value') || true;
    }),
    'data-test-radio-selected': Ember.computed.readOnly('selected'),
    selected: false,
    // The input element is invisible and positioned over top of
    // this parent element, so it should receive all click events
    // and will call this function in it's onChange event
    actions: {
      onChange: function onChange(selected) {
        if (this.action) {
          this.action(this.get('value'), selected, this.get('optionName'));
        }

        this.set('selected', selected);
      }
    }
  });

  _exports.default = _default;
});