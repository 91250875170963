define("@square/glass-ui/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CLOSE_X = _exports.CLOSE_NAVIGATION = _exports.CLOSE_ESC = _exports.CLOSE_CLICK = _exports.ANIMATION_DELAY = void 0;
  var ANIMATION_DELAY = 150; // Keep in sync with $modal-blade-animate-duration
  // Constants for understanding what caused the modal to close.

  _exports.ANIMATION_DELAY = ANIMATION_DELAY;
  var CLOSE_CLICK = 'close click';
  _exports.CLOSE_CLICK = CLOSE_CLICK;
  var CLOSE_ESC = 'close escape';
  _exports.CLOSE_ESC = CLOSE_ESC;
  var CLOSE_NAVIGATION = 'close navigation';
  _exports.CLOSE_NAVIGATION = CLOSE_NAVIGATION;
  var CLOSE_X = 'close x click';
  /*
  
  ## Modal Service:
  
  A central authority for presenting modals. Works in conjunction with the
  {{modals/modal-manager}} component.
  
  To use:
  
  1. Create a component for your modal (most likely using `{{dialog-modal}}` in its template)
  2. Call `open(componentName, options)` on the modal service.
  3. The modal-manager will initialize your component and display it. Your component will
     receive two properties:
  
      * `close`: a function to close the modal.
      * `modalOptions`: the `options` argument in `open()`.
  
  ### Advanced usage:
  
  #### Disable close on <esc> or clicking on the veil:
  
  In your modal component's `didInsertElement`, call the `setCloseHandler` actions
  with a function that returns false:
  
  ```js
  didInsertElement(...args) {
    this._super(...args);
    this.get('setCloseHandler')(() => {
      return false;
    });
  }
  ```
  
  #### Lock and unlock the vertical height of the modal
  
  Before and after making changes that might dramatically affect the height of the modal
  and create a jarring user experience (e.g. hiding or showing a large element, filtering
  a list), call `lockModalHeight`. Call `unlockModalHeight` to resume default behavior.
  
  ```js
  startFiltering() {
    this.get('lockModalHeight')();
    // filter elements
  },
  
  doneFiltering() {
    // show all elements
    this.get('unlockModalHeight')();
  }
  ```
  
  */

  _exports.CLOSE_X = CLOSE_X;

  var _default = Ember.Service.extend(Ember.Evented, {
    flash: Ember.inject.service(),
    scroll: Ember.inject.service(),
    isOpen: false,
    stack: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('stack', Ember.A());
    },

    /**
     * Opens a modal.
     *
     * @param componentName path to the modal component
     * @param options passed to the component as `modalOptions`
     * @param metaOptions options which are not passed to the component
     * @returns {*}
     */
    open: function open(componentName, options) {
      var _this = this;

      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          position = _ref.position;

      // allow consuming apps (*cough*dashboard*cough*) to listen for this event
      // and do any cleanup they might need to do
      this.trigger('onOpen'); // Cancel any scheduled close animation

      if (this.delayedCloser) {
        Ember.run.cancel(this.delayedCloser);
        this.get('stack').popObject();
        this.delayedCloser = null;
      } // Disable scrolling while leaving the scroll bar visible


      this.get('scroll').disableWindowScrolling();
      return new Ember.RSVP.Promise(function (resolver) {
        _this.get('stack').pushObject({
          componentName: componentName,
          options: options,
          resolver: resolver,
          closeHandler: null,
          metaOptions: {
            position: position
          }
        });

        Ember.run.next(function () {
          return _this.set('isOpen', true);
        });
      });
    },
    close: function close() {
      var _this2 = this;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (false && !(this.get('isOpen')) && Ember.assert('Called close on modal service when it is not open', this.get('isOpen')));

      if (this.get('stack.length') === 1) {
        this.get('flash').clearModalMessage();
        this.get('flash').clearModalValidation();
        this.set('isOpen', false);
        this.delayedCloser = Ember.run.later(function () {
          _this2.cleanUpClosedModal.apply(_this2, args);

          _this2.delayedCloser = null;
        }, ANIMATION_DELAY);
      } else {
        this.cleanUpClosedModal.apply(this, args);
      }
    },
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.delayedCloser);
    },
    cleanUpClosedModal: function cleanUpClosedModal() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      } // Restore scrolling and the original scroll position


      this.get('scroll').enableWindowScrolling();
      this.get('stack.lastObject.resolver').apply(void 0, arguments);
      this.get('stack').popObject();
    },
    closeIfOpen: function closeIfOpen() {
      if (this.get('isOpen')) {
        this.close.apply(this, arguments);
      }
    },
    closeX: function closeX() {
      this.maybeCloseOnEvent(CLOSE_X);
    },
    // If the close handler is missing (the default case), close the modal with no arguments
    // If the close handler exists and returns exactly true (somewhat common), close the modal with no arguments
    // If the close handler exists and returns something besides true or false, close the modal with the return value as an argument
    // (We don't want to pass `true` as an argument because many uses of open().then() expect an object or undefined.)
    maybeCloseOnEvent: function maybeCloseOnEvent(closeReason) {
      if (this.get('stack.length') === 0) {
        // If there's no modal on the stack, there is nothing to close.
        return;
      }

      var _this$get = this.get('stack.lastObject'),
          closeHandler = _this$get.closeHandler;

      if (typeof closeHandler !== 'function') {
        this.close();
        return;
      }

      var closeValue = closeHandler(closeReason);

      if (closeValue === true) {
        this.close();
      } else if (closeValue !== false) {
        this.close(closeValue);
      }
    }
  });

  _exports.default = _default;
});