import visibleInViewport from './visible-in-viewport';

/**
 * On an interval, attempts to scroll an element into view until it is visible
 * in the viewport.
 * @param selector Valid element selector.
 * @param interval
 */
function scrollIntoView(selector: string, interval = 100): void {
  const scrollInterval = window.setInterval(() => {
    const element = document.querySelector(selector);
    if (visibleInViewport(element)) {
      window.clearInterval(scrollInterval);
      return;
    }

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
      window.clearInterval(scrollInterval);
    }
  }, interval);
}

/**
 * On an interval, attempts to scroll an element into view until it is visible
 * within its parent element.
 * @param selector Valid element selector.
 * @param selector Valid element selector of the parent container.
 * @param interval
 */
function scrollIntoViewWithinParent(
  selector: string,
  parentSelector: string,
  interval = 100
): void {
  const scrollInterval = window.setInterval(() => {
    const element = document.querySelector(selector);
    const parent = document.querySelector(parentSelector);

    if (element && parent) {
      const rect = element.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();
      if (rect.top >= parentRect.top && rect.bottom <= parentRect.bottom) {
        window.clearInterval(scrollInterval);
        return;
      }
    }

    if (element) {
      element.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
      window.clearInterval(scrollInterval);
    }
  }, interval);
}

export { scrollIntoViewWithinParent, scrollIntoView };
