define("@square/glass-ui/components/sq-sorter/sorter-button", ["exports", "@square/glass-ui/templates/components/sq-sorter/sorter-button", "@square/glass-ui/utils/json-equality", "@square/glass-ui/components/sq-sorter"], function (_exports, _sorterButton, _jsonEquality, _sqSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  ### Overview
  
  This component is yielded by `sq-sorter`. Clicking on it will trigger sorting by the sortKey given to this component. See `sq-sorter` for example usage.
  
  ### Parameters
  @param {Action} [sort] Action that will be triggered on click. Will be assigned by `sq-sorter`.
  @param {(String|String[])} [sortKey] The sortKey by which the content will be sorted when this component is clicked. It should be provided after this component is yielded.
  @param {(String|String[])} [currentSortKey] The key currently being sorted by. It will be assigned by `sq-sorter`. If it matches `sortKey`, this component will have a caret.
  @param {Boolean} [sortDefault] If the caret is shown, its direction will be determined by whether it is being sorted in the default order.
  @param {String} [label] If no block is provided, the label will be displayed instead.
  */
  var _default = Ember.Component.extend({
    layout: _sorterButton.default,
    classNames: ['sorter-button'],
    classNameBindings: ['caret'],
    attributeBindings: ['colspan', 'data-test-sorter-button'],
    'data-test-sorter-button': true,
    click: function click() {
      if (this.sort) {
        this.sort(this.get('sortKey'));
      }
    },
    caret: Ember.computed('sortKey.0', 'currentSortKey', 'sortDefault', function () {
      if ((0, _jsonEquality.deepEquals)(this.get('sortKey'), this.get('currentSortKey'))) {
        var firstKey = Ember.isArray(this.get('sortKey')) ? this.get('sortKey')[0] : this.get('sortKey');
        var isSortedAscending = (0, _sqSorter.isAscending)(firstKey);

        if (!this.get('sortDefault')) {
          isSortedAscending = !isSortedAscending;
        }

        return isSortedAscending ? 'caret-up' : 'caret-down';
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});