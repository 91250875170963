define("@square/glass-ui/templates/components/dialog-actions/button-back-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YvXJ4z5W",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"color\"],[\"medium\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg/icon-arrow-previous\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/dialog-actions/button-back-arrow.hbs"
    }
  });

  _exports.default = _default;
});