define("@square/glass-ui/templates/components/selector-control/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "piXhQOCb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"section\"],[[30,[36,1],[\"selector-control/content/section\"],[[\"currentItem\"],[[35,0]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"currentItem\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/selector-control/content.hbs"
    }
  });

  _exports.default = _default;
});