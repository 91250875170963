define("@square/glass-ui/components/dialog-actions/button-close-x", ["exports", "@square/glass-ui/templates/components/dialog-actions/button-close-x"], function (_exports, _buttonCloseX) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _buttonCloseX.default,
    classNames: ['dialog__header__close', 'button--tertiary'],
    tagName: 'button',
    // Prevent from submitting forms via default `submit` type
    type: 'button',
    attributeBindings: ['type', 'data-test-dialog-close-icon', 'aria-label'],
    'data-test-dialog-close-icon': true,
    'aria-label': 'Close'
  });

  _exports.default = _default;
});