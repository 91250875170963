import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import Integration from '../../models/services/integration/integration';
import SquareAuth from '../../services/square-auth';
import { EngineRouterService } from 'ember-engines-router-service';
interface WizardSquareAuthArgs {
  currentIntegration: Integration;
  dropdownDisabled?: boolean
  setContinueDisabled: (continueDisabled: boolean) => void 
}

export default class WizardSquareAuth extends Component<WizardSquareAuthArgs> {
  constructor(owner: unknown, args: WizardSquareAuthArgs) {
    super(owner, args);
    if (this.args.dropdownDisabled) {
      this.disabled = true;
    }
  }

  @service router!: EngineRouterService;
  @service squareAuth!: SquareAuth;

  @tracked
  disabled = false;

  @action
  triggerSquareOAuthFlow() {
    this.disabled = true;
    this.args.setContinueDisabled(true);
  }
}
