define("@square/glass-ui/components/sq-form-field-with-link", ["exports", "@square/glass-ui/components/sq-form-field", "@square/glass-ui/templates/components/sq-form-field-with-link"], function (_exports, _sqFormField, _sqFormFieldWithLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  ## SqFormFieldComponent
  `sq-form-field-with-link` extends from `sq-form-field` to implement a pretty specific layout that is not directly supported by `sq-form-field`.
  
  Desktop:  [label] | [input          ] [link]
  
  Mobile 1: [label]                     [link]
            [input                           ]
  
  Mobile 2: [label                           ]
            [link-with-a-lot-of-text         ]
            [input                           ]
  
  The link can exist conditionally, but for mobile clients the label and input
  must be present. This means that support for noLabel from `sq-form-field` must
  be rethought if the component is ever mobilized.
  
  ```example
  @title Example
  
  @hbs
  {{#sq-form-field-with-link label=(t "business.devices.headers.deviceCode")
                             shouldShowLink=shouldShowLink
                             linkAction=(action "resetPassword")
                             linkLabel=(t "business.devices.edit.resetPassword")}}
  
    {{sq-field-input ...}}
  {{/sq-form-field-with-link}}
  
  @controller-properties
  {
    shouldShowLink: true,
    actions: {
      resetPassword() {
      }
    }
  }
  ```
  
  ### Parameters
  @param {String} [label] The text in the label.
  @param {String} [innerElementId] Populates the "for" attribute of the label.
  @param {Boolean} [shouldShowLink=true] Whether to display the link or not.
  @param {String} [linkAction] The action that should be forwarded to the controller when the link is clicked.
  @param {String} [linkLabel] Link text.
  
  Sass dependencies at: `app/assets/stylesheets/bridgeport/components/form-elements/formfield_formfield-with-link.sass`
   */
  var _default = _sqFormField.default.extend({
    layout: _sqFormFieldWithLink.default,
    classNames: 'form-field-with-link',
    shouldShowLink: true,
    actions: {
      linkAction: function linkAction() {
        if (this.linkAction) {
          this.linkAction();
        }
      }
    }
  });

  _exports.default = _default;
});