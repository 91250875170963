define("@square/glass-ui/components/svg/icon-arrow-previous", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-arrow-previous"], function (_exports, _svgIcon, _iconArrowPrevious) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconArrowPrevious.default,
    width: 18,
    height: 18,
    viewBox: '0 0 16 16',
    classNames: ['svg-icon--arrow-previous']
  });

  _exports.default = _default;
});