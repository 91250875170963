define("@square/glass-ui/components/sq-table/row", ["exports", "@square/glass-ui/templates/components/sq-table/row"], function (_exports, _row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _row.default,
    tagName: 'tr',
    classNameBindings: ['isSelectable:table-row--selectable', 'isSelected:table-row--selectable-is-selected'],
    isSelected: false,
    selectAction: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      this.set('isSelectable', typeof this.selectAction === 'function');
    },
    click: function click() {
      if (this.isSelectable) {
        this.selectAction();
      }
    }
  });

  _exports.default = _default;
});