define("@square/glass-ui/templates/components/ring-progress-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/nMu0XOC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ring-progress-indicator\"],[15,5,[34,3]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ring-progress-indicator__progress\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ring-progress-indicator__inner-bg\"],[12],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"ring-progress-indicator__center-image\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[35,1]],[[\"width\",\"height\"],[90,90]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"ring-progress-indicator__text\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"centerText\",\"centerImage\",\"component\",\"ringStyle\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/ring-progress-indicator.hbs"
    }
  });

  _exports.default = _default;
});