define("@square/glass-ui/components/dialog-actions/button", ["exports", "@square/glass-ui/components/dialog-actions", "@square/glass-ui/components/sq-button", "@square/glass-ui/templates/components/sq-button"], function (_exports, _dialogActions, _sqButton, _sqButton2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sqButton.default.extend({
    layout: _sqButton2.default,
    tagName: 'button',
    rank: null,
    classNames: ['dialog__button'],
    context: _dialogActions.CONTEXT.MODAL,
    classNameBindings: ['dialogRankClass', 'contextClasses'],
    attributeBindings: ['isBlade:data-test-blade-button', 'modalButtonTestSelectorValue:data-test-modal-button', 'sheetButtonTestSelectorValue:data-test-sheet-button'],
    contextClasses: Ember.computed('context', 'rank', function () {
      switch (this.get('context')) {
        case _dialogActions.CONTEXT.MODAL:
          if (this.get('rank') === _sqButton.BUTTON_RANK.PRIMARY) {
            return 'dialog--modal__button dialog__footer__button dialog--modal__button--primary';
          } else {
            return 'dialog--modal__button dialog__footer__button dialog--modal__button--secondary';
          }

        case _dialogActions.CONTEXT.BLADE:
          return 'dialog__footer__button dialog--blade__footer__button';

        case _dialogActions.CONTEXT.SHEET:
          if (this.get('rank') === _sqButton.BUTTON_RANK.PRIMARY) {
            return 'dialog--sheet__button dialog--sheet__button--primary';
          } else {
            return 'dialog--sheet__button dialog--sheet__button--secondary';
          }

        default:
          return '';
      }
    }),
    dialogRankClass: Ember.computed('rank', 'context', function () {
      if (this.get('rank') === _sqButton.BUTTON_RANK.PRIMARY) {
        return 'dialog__button--primary';
      } else if (this.get('rank') === _sqButton.BUTTON_RANK.SECONDARY) {
        return 'dialog__button--secondary';
      } else {
        return '';
      }
    }),
    // Test Selector Setup
    // Allows tests to target a data-test-selector according to
    // this button's context (e.g. [data-test-modal-button="primary"])
    isBlade: Ember.computed.equal('context', _dialogActions.CONTEXT.BLADE),
    modalButtonTestSelectorValue: Ember.computed('context', 'rank', function () {
      if (this.get('context') === _dialogActions.CONTEXT.MODAL) {
        return this.get('rank');
      } else {
        return null;
      }
    }),
    sheetButtonTestSelectorValue: Ember.computed('context', 'rank', function () {
      if (this.get('context') === _dialogActions.CONTEXT.SHEET) {
        return this.get('rank');
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});