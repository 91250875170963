define("@square/glass-ui/templates/components/banner-static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hAGDuXAK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"banner-static__icon\"],[12],[2,\"\\n  \"],[1,[30,[36,5],[[35,4]],[[\"color\"],[[35,3]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"banner-static__body\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,\"data-test-banner-static-close\",\"\"],[24,0,\"banner-static__close\"],[4,[38,1],[[32,0],[35,0]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"size\",\"width\",\"height\"],[\"small\",\"10\",\"10\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dismiss\",\"action\",\"svg/icon-close-x\",\"iconColor\",\"iconComponentName\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/banner-static.hbs"
    }
  });

  _exports.default = _default;
});