define("@square/glass-ui/templates/components/selector-control/navigation/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYoe9k6w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,7],[[35,6]],[[\"isActive\",\"class\",\"onSelect\"],[[35,5],[30,[36,4],[\"selector-control__navigation__item \",[35,3]],null],[30,[36,2],[[32,0],\"onSelect\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"action\",\"class\",\"concat\",\"isActive\",\"itemComponent\",\"component\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/selector-control/navigation/item.hbs"
    }
  });

  _exports.default = _default;
});