export default function generateQueryParameters(
  params: Record<string, any>
): string {
  let paramsString: string = '';
  let paramStrings: Array<string> = [];

  const paramSet = Array.from(new Set(Object.values(params)));

  if (paramSet.length === 1 && paramSet[0] === undefined) {
    paramsString = '';
  } else {
    paramStrings = Object.keys(params)
      .map((key) => {
        const paramValue = params[key];
        if (paramValue !== undefined && !Array.isArray(paramValue)) {
          return `${key}=${paramValue}`;
        } else if (paramValue !== undefined && Array.isArray(paramValue)) {
          return `${key}=${paramValue.join(',')}`;
        } else {
          return '';
        }
      })
      .filter((string) => string !== '');
  }

  if (paramStrings.length > 0) {
    paramsString = `?${paramStrings.join('&')}`;
  }

  return paramsString;
}
