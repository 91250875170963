import { helper } from '@ember/component/helper';

// format css class for given element containing string
// used for formatting "Successful" and "Failed" statuses of paired entities
export function formatSuccessTextClass(params: Array<string>): string | undefined {
  let [statusString] = params;

  if (!statusString || typeof statusString != 'string') {
    return;
  }

  statusString = statusString.toLowerCase();
  const successStrings = ['configured', 'successful', 'operational', 'connected', 'enabled', 'completed'];
  const failStrings = ['action required', 'failed'];

  if (
    !statusString.includes('not') &&
    successStrings.find((successString) => statusString.includes(successString))
  ) {
    return 'successful-text';
  } else if (
    failStrings.find((failString) => statusString.includes(failString))
  ) {
    return 'failed-text';
  } else {
    return;
  }
}

export default helper(formatSuccessTextClass);
