define("@square/glass-ui/components/dialog-header/actions-container", ["exports", "@square/glass-ui/templates/components/dialog-header/actions-container", "@square/glass-ui/components/dialog-actions"], function (_exports, _actionsContainer, _dialogActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actionsContainer.default,
    classNames: ['dialog__header__actions-container'],
    classNameBindings: ['contextClass', 'hasButtons:dialog__header__actions-container--with-buttons'],
    contextClass: Ember.computed('context', 'hasButtons', function () {
      switch (this.get('context')) {
        case _dialogActions.CONTEXT.SHEET:
          return this.get('hasButtons') ? 'dialog--sheet__header__actions-container dialog--sheet__header__actions-container--with-buttons' : 'dialog--sheet__header__actions-container';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});