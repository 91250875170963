import { modifier } from 'ember-modifier';

/**
 * Triggers a callback when the element has <= 1 scrollheight remaining (i.e.
 * cannot be scrolled any further).
 */
export default modifier(function onScrollToBottom(element, [callback]) {
  function onScroll() {
    const visibleHeight = element.clientHeight;
    const remainingScrollHeight = element.scrollHeight - element.scrollTop;

    if (remainingScrollHeight - visibleHeight <= 1) {
      if (callback) {
        callback();
      }
    }
  }

  element.addEventListener('scroll', onScroll);

  return () => {
    element.removeEventListener('scroll', onScroll);
  };
});
