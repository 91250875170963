/**
 * Creates a listener function that should be used to listen to click events in order
 * to trigger some behaviour whenever a click occurs outside of the target element
 * (e.g. user clicking outside of popover closes popover).
 *
 * @export
 * @param {Node} componentElement
 * @param {() => void} onOutsideClick
 * @param {String[]} ignoredClassNames If an element with any of these classes is found
 * in the path of the event, the click will also be ignored.
 * @returns {(event: Event) => void}
 */
export default function makeOutsideClickListener(
  onOutsideClick: () => void,
  ignoredElements: Node[],
  ignoredClassNames?: string[],
  onInsideClick?: () => void
): (event: Event) => void {
  return function onClose(event: Event): void {
    if (!event.target) {
      return;
    }

    const element = event.target as HTMLInputElement;
    const clickIsWithinElement = ignoredElements.some((ignoredElement) =>
      ignoredElement.contains(element)
    );

    const eventPath = (event as MouseEvent).composedPath();
    const clickIsFromIgnoredElement =
      ignoredClassNames &&
      eventPath.some((eventTarget) => {
        const element = eventTarget as Element;
        return ignoredClassNames.includes(element.className);
      });

    if (!clickIsWithinElement && !clickIsFromIgnoredElement) {
      onOutsideClick();
    } else if (onInsideClick) {
      onInsideClick();
    }
  };
}
