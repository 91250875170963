import { GatewaySystemType } from '@bridge/home-engine/addon/models/services/source/gateway-system-type';
import ExternalSource from '@bridge/home-engine/addon/models/services/integration/external-source';

export enum SquareRefreshStatus {
  FAILED = 'FAILED',
  SUCCESSFUL = 'SUCCESSFUL',
}

export enum Gateway {
  CLOUD_ELEMENTS = 'CLOUD_ELEMENTS',
  SQUARE = 'SQUARE',
}

export interface SquareAccount {
  id: number;
  merchant_id: string;
  email: string;
  business_name: string;
  refresh_status?: SquareRefreshStatus;
  expires_at: string;
}

export enum SquareAuthStatus {
  AUTHORIZED = 'Authorized',
  AUTHORIZED_REFRESH_FAIL = 'Authorized - Automatic refresh failed',
  UNAUTHORIZED = 'Unauthorized',
}

interface BusinessCentralConfiguration {
  [k: string]: string;
}

export interface LocationMapping {
  square_location_id: string;
  erp_location_id: string;
}

export interface CloudElementsLocationMapping extends LocationMapping {
  account_mappings: Array<object>;
  always_use_default_customer: boolean;
  erp_price_list_id: string;
  erp_tax_ids: Array<string>;
}

export interface InstanceVariables {
  currency_mappings: Array<object>;
  decompose_taxes: boolean;
  is_advanced_taxes_enabled?: boolean;
  exempt_tax_id?: string;
  item_mappings: Array<object>;
  location_mappings: Array<CloudElementsLocationMapping>;
  measurement_mappings: Array<object>;
  object_version?: string;
  rounding_item_id?: string;
  subsidiary?: string;
  unknown_item_id?: string;
}

export interface CloudElementsInstance {
  id?: string;
  instance_id?: string;
  gateway_system_type: GatewaySystemType;
  instance_variables?: InstanceVariables;
  sapb1_configuration?: object;
  netsuite_configuration?: object;
  business_central_configuration?: BusinessCentralConfiguration;
  name: string;
}

export interface Source {
  id?: string;
  bridge_account_id: string;
  gateway: Gateway;
  square_account?: SquareAccount;
  cloud_elements_instance?: CloudElementsInstance;
  external_source?: ExternalSource;
}

export const friendlies: { [key: string]: string } = {
  SAP_BUSINESS_ONE: 'SAP Business One',
  NETSUITE: 'NetSuite',
  BUSINESS_CENTRAL: 'Business Central',
  FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT: 'Finance & Supply Chain Management',
};
