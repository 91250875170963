define("@square/glass-ui/components/dialog-header", ["exports", "@square/glass-ui/components/dialog-actions", "@square/glass-ui/templates/components/dialog-header"], function (_exports, _dialogActions, _dialogHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    layout: _dialogHeader.default,
    title: null,
    tagName: 'header',
    classNames: ['dialog__header'],
    classNameBindings: ['contextClass'],
    contextClass: Ember.computed('context', function () {
      switch (this.get('context')) {
        case _dialogActions.CONTEXT.BLADE:
          return 'dialog--blade__header';

        case _dialogActions.CONTEXT.MODAL:
          return 'dialog--modal__header';

        case _dialogActions.CONTEXT.SHEET:
          return 'dialog--sheet__header';

        default:
          return '';
      }
    }),
    isCloseComponent: Ember.computed('close', function () {
      // For detecting a link-to component which can handle the route transition for closing the blade.
      return _typeof(this.get('close')) === 'object';
    })
  });

  _exports.default = _default;
});