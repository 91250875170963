define("@square/glass-ui/templates/components/media-block/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+YeVOTzf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,5],null,[[\"headline\",\"headline-primary\",\"headline-secondary\",\"area\",\"button-primary\",\"button-secondary\"],[[30,[36,3],[\"media-block/headline\",[35,4]],null],[30,[36,3],[\"media-block/headline\"],[[\"data-test-media-block-headline\",\"rank\"],[\"primary\",\"primary\"]]],[30,[36,3],[\"media-block/headline\"],[[\"data-test-media-block-headline\",\"rank\"],[\"secondary\",\"secondary\"]]],[30,[36,3],[\"media-block/section\"],[[\"class\"],[\"media-block__content__area\"]]],[30,[36,3],[\"media-block/button\"],[[\"data-test-media-block-cta\",\"rank\",\"variant\"],[\"primary\",\"primary\",[30,[36,2],[[30,[36,1],[[35,0],\"light\"],null],\"alternative\"],null]]]],[30,[36,3],[\"media-block/button\"],[[\"data-test-media-block-cta\",\"rank\",\"variant\"],[\"secondary\",\"secondary\",[30,[36,2],[[30,[36,1],[[35,0],\"light\"],null],\"alternative\"],null]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"theme\",\"eq\",\"if\",\"component\",\"data-test-media-block-headline\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/media-block/content.hbs"
    }
  });

  _exports.default = _default;
});