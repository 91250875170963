define("@square/glass-ui/components/segmented-control", ["exports", "@square/glass-ui/templates/components/segmented-control"], function (_exports, _segmentedControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
  A horizontal control consists of clickable segments used to toggle between one or more modes
  
  ```example
  @title Basic Example
  
  @controller-properties
  {
    options: ['%', '$'],
  
    selectedOptionValue: '%',
  
    actions: {
      segmentSelected(segment) {
        this.set('selectedOptionValue', segment);
        // Do other stuff
      }
    }
  }
  
  @hbs
  {{segmented-control options=options
                      value=selectedOptionValue
                      action=(action 'segmentSelected')}}
  ```
  
  ```example
  @title More than 2 options
  
  @controller-properties
  {
    options: ['Email Receipt', 'Print Receipt', 'No Receipt'],
  
    selectedOptionValue: 'No Receipt',
  
    actions: {
      segmentSelected(segment) {
        this.set('selectedOptionValue', segment);
        // Do other stuff
      }
    }
  }
  
  @hbs
  {{segmented-control options=options
                      value=selectedOptionValue
                      action=(action 'segmentSelected')}}
  ```
  
  @param {Object} options - Can be an array of objects or array of simple values.
  @param {Object} value - The selected option
  @param {String} labelPath - An optional path specifying the displaying content retrieved by `options.get(labelPath)`.
  @param {String} segmentClass - Optional custom class to apply to segments.
  */
  var _default = Ember.Component.extend({
    layout: _segmentedControl.default,
    classNames: ['segmented-control'],
    // arguments to pass in
    options: null,
    value: null,
    segmentClass: null,
    // supply a key to the label if your options consist of dictionaries, not simple values
    labelPath: null,
    labelsAndValues: Ember.computed('options.[]', 'labelPath', function () {
      var labelPath = this.get('labelPath');
      return this.get('options').map(function (option) {
        var label = labelPath ? Ember.get(option, labelPath) : option;
        return {
          value: option,
          label: label
        };
      });
    })
  });

  _exports.default = _default;
});