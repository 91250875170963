import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { EngineRouterService } from 'ember-engines-router-service';
import ENV from '@bridge/home-engine/config/environment';
import CurrentUser from 'bridge-dashboard/app/services/current-user';

export default class FscAuth extends Service {
  @service currentUser!: CurrentUser;
  @service router!: EngineRouterService;

  fnoAuthorizationLink: string = `${ENV.frontEndServiceUrl}/api/v1/oauth/fno/authorize`;

  generateAuthorizationLink(resourceUrl: string): string {
    return (
      this.fnoAuthorizationLink +
      `?client_path=${this.router.currentURL}` +
      `&fno_resource=${resourceUrl}` +
      `&bridge_account_id=${this.currentUser!.currentAccount!.id}`
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'fno-auth': FscAuth;
  }
}
