define("@square/glass-ui/components/dialog-blade", ["exports", "@square/glass-ui/templates/components/dialog-blade"], function (_exports, _dialogBlade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    flash: Ember.inject.service(),
    layout: _dialogBlade.default,
    title: null,
    attributeBindings: ['data-test-blade'],
    'data-test-blade': Ember.computed.readOnly('title'),
    classNames: ['dialog', 'dialog--blade', 'dashboard-header__fixed-top-element', 'ecosystem-header-top-element'],
    classNameBindings: ['showAtStartingPosition:dialog--blade--starting-position'],
    ariaRole: 'dialog',
    showAtStartingPosition: true,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(function () {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }

        _this.set('showAtStartingPosition', false);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      if (this.get('close')) {
        (false && !(typeof this.get('close') === 'function' || _typeof(this.get('close')) === 'object') && Ember.assert('Optional `close` parameter should be an action or link-to component in `{{dialog-blade}}.`', typeof this.get('close') === 'function' || _typeof(this.get('close')) === 'object'));
      }
    }
  });

  _exports.default = _default;
});