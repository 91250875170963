import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import NavigationServiceObject from 'bridge-dashboard/app/services/navigation';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import AbridgedIntegration from 'bridge-dashboard/app/types/abridged-integration';

export default class AuthorizedRouteIntegration extends Controller {
  @service currentUser!: CurrentUser;
  @service navigation!: NavigationServiceObject;

  @action
  handleIntegrationChange(integration: AbridgedIntegration): void {
    localStorage.setItem("selectedIntegrationId", integration.id);
    this.send('changeIntegration', integration.id);
  }

  get hasIntegration(): boolean {
    return this.currentUser.hasIntegration();
  }

  get pageName(): string {
    return this.navigation.currentPageLabel();
  }

  get currentIntegrationName(): string {
    return this.currentUser.currentIntegrationName;
  }

  get integrationSetupComplete(): boolean {
    return this.currentUser.isCompletelyConfigured();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration': AuthorizedRouteIntegration;
  }
}
