define("@square/glass-ui/templates/components/flash-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gCq7WrV1",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[30,[36,1],[[35,0,[\"flashComponent\"]],[35,0,[\"defaultFlashComponent\"]]],null]],[[\"isActive\",\"message\",\"type\",\"dismiss\",\"options\",\"class\"],[[35,0,[\"shouldShowFlash\"]],[35,0,[\"currentFlashObject\",\"message\"]],[35,0,[\"currentFlashObject\",\"type\"]],[35,0,[\"dismiss\"]],[35,0,[\"componentOptions\"]],[35,0,[\"componentClass\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"context\",\"or\",\"component\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/flash-portal.hbs"
    }
  });

  _exports.default = _default;
});