import { helper } from '@ember/component/helper';

// checks to see if a string contains template-specified strings
// this method handles edge cases needed to programmatically generated the settings-form component
export function containsStringValues(params: [string | string[], string]): boolean {
  let [string, templateSpecifiedStrings] = params;

  // if the string is null or the template-specified strings aren't really specified
  // return true
  if (
    (string === null || string === undefined) &&
    (templateSpecifiedStrings === null || templateSpecifiedStrings === '')
  )
    return true;
  if (
    (string === null || string === undefined) &&
    (templateSpecifiedStrings === null || templateSpecifiedStrings !== '')
  )
    return true;

  // if the string is not null and the templateSpecifiedStrings are trully not specified
  // return false
  if (
    string !== null &&
    (templateSpecifiedStrings === null || templateSpecifiedStrings === '')
  )
    return false;

    // handle the edge case of an array of strings being passed in
  const str: string = Array.isArray(string) ? string.join('') : string

  // find whether one of the comma-delimited template-specified strings is in the
  // original string
  const stringArray = templateSpecifiedStrings.split(',');
  const matchingStringArray = stringArray.find((element) =>
    str.toLowerCase().includes(element.toLowerCase())
  );
  return matchingStringArray !== undefined;
}

export default helper(containsStringValues);
