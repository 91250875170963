import { action } from '@ember/object';
import Component from '@glimmer/component';

interface MarketSearchableListArgs {
  onInputFocus?(): void;
  emptyStateText?: string;
  errorStateText?: string;
}

/**
 * Renders a text input component, and a list of Market rows below
 *
 * @export
 * @class MarketSearchableList
 * @extends {MarketSearchableComponent}
 */
export default class MarketSearchableList extends Component<MarketSearchableListArgs> {
  @action
  onMarketInputClick(): void {
    if (this.args.onInputFocus) {
      this.args.onInputFocus();
    }
  }

  @action
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  noop(): void {}
}
