define("@square/glass-ui/templates/components/sq-billboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a2rmRF6K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"billboard__info\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h4\"],[14,\"data-test-billboard-detail\",\"\"],[14,0,\"billboard__detail\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h3\"],[14,\"data-test-billboard-message\",\"\"],[14,0,\"billboard__message\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"billboard__actions\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,4],null,[[\"button-secondary\",\"button-primary\",\"actions\"],[[30,[36,3],[\"sq-button\"],[[\"rank\",\"class\"],[\"secondary\",\"billboard__button billboard__button--secondary\"]]],[30,[36,3],[\"sq-button\"],[[\"rank\",\"class\"],[\"primary\",\"billboard__button billboard__button--primary\"]]],[30,[36,3],[\"sq-billboard/actions\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,[[30,[36,4],null,[[\"svg\",\"image\"],[[30,[36,3],[\"sq-billboard/media\"],[[\"type\"],[\"SVG\"]]],[30,[36,3],[\"sq-billboard/media\"],[[\"type\"],[\"IMAGE\"]]]]]]]],[2,\"\\n\\n\"],[11,\"a\"],[24,\"data-test-billboard-close-icon\",\"\"],[24,0,\"billboard__close-icon\"],[24,\"role\",\"button\"],[4,[38,5],[[32,0],\"dismiss\"],null],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"size\"],[\"medium\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"message\",\"detail\",\"if\",\"component\",\"hash\",\"action\",\"svg/icon-close-x\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-billboard.hbs"
    }
  });

  _exports.default = _default;
});