/**
 ## SyncEntityGroupStatusesTable
 
 A table to display the different items (+ statuses and attributes) that have been synced for one sync type (e.g. customers).
 
 ```example
 <SyncEntityGroupStatusesTable
 @cursor={{ this.model.syncEntityGroupStatusesResponse.cursor }}
 @entity_id={{ this.entity_id }}
 @handleModelRefresh={{ this.refreshModel }}
 @rows={{ this.model.syncEntityGroupStatusesResponse.sync_entity_group_statuses }}
 @state={{ this.state }}
 @syncJobs={{ this.model.syncJobs.jobs }}
 @errorHeader="Your Bank Transfers"
 @errorHint="No Bank Transfers have been synced yet, start by running a manual sync."
 @iconClassName="icon-null-state-business-bank-account"
 />
 ```
 
 ### Parameters
 * @param {String} [cursor] [The pagination cursor for the data/rows passed to the component.]
 * @param {String} [entity_id] [The id by which to search/filter the list of items]
 * @param {Function} [handleModelRefresh] [Refreshes current model, e.g. if query parameters change (aka a user filters the list).]
 * @param {Array<SyncEntityGroupStatusesModel>} [rows] [The data to be displayed in the table.]
 * @param {String} [state] [The current state for filtering sync jobs.]
 * @param {Array<SyncJob>} [syncJobs] [Array of syncjobs ]
 * @param {String} [errorHeader] [The header of the notification shown when no entity pairs are available.]
 * @param {String} [errorHint] [The text of the notification shown when no entity pairs are available.]
 * @param {String} [iconClassName] [The name of the icon to show when no entity pairs are available.]
 */

import Component from '@glimmer/component';
import Ember from 'ember';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
// @ts-ignore ember-engines has no exported types
import { EngineRouterService } from 'ember-engines-router-service';
import SyncEntityGroupStatusesModel from '../models/services/sync-entity-group-status/sync-entity-group-status';
import SyncService from '../services/sync';
import Data from 'bridge-dashboard/app/services/data';
import { SyncJob, SyncJobState } from '../models/services/sync/sync-job';
import CurrentUser from 'bridge-dashboard/app/services/current-user';

import { DropdownFilterUnit } from '../models/interface/dropdown-filter-unit';
import FlashService from '@square/glass-ui/addon/services/flash';

import { capitalize } from '../helpers/capitalize';
import { SECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND } from '../utils/time';
import ModalService from '@square/glass-ui/addon/services/modal';
import { ManualSyncModalArgs, RunManualSyncResult } from './manual-sync-modal';
import { debounce } from '@ember/runloop';

interface QueryParams {
  state?: string | null;
  entity_type?: string | null;
}

interface SyncEntityGroupStatusesTableArgs {
  cursor: string;
  entity_id: string;
  handleModelRefresh: () => void;
  isLoading: boolean;
  rows: Array<SyncEntityGroupStatusesModel>;
  state: string;
  sync_type: string;
  entityType: string;
  syncJobs: Array<SyncJob>;
  entityTypes: Array<DropdownFilterUnit>;
}

const FIFTEEN_MINUTES = 15 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;

export default class SyncEntityGroupStatusesTable extends Component<SyncEntityGroupStatusesTableArgs> {
  constructor(owner: unknown, args: SyncEntityGroupStatusesTableArgs) {
    super(owner, args);

    if (this.args.isLoading) return;
    const recentManualSyncJobs: SyncJob[] = this.args.syncJobs.filter(
      (pastJob) => {
        const syncJobFireTime: number = new Date(pastJob.started_at).getTime();
        const presentTime: number = Date.now();

        return (
          [SyncJobState.QUEUED, SyncJobState.RUNNING].includes(
            pastJob.state.toUpperCase() as SyncJobState
          ) && presentTime - syncJobFireTime < FIFTEEN_MINUTES
        );
      }
    );
    if (recentManualSyncJobs.length > 0) {
      this.isManualSyncDisabled = true;
      this.isSyncJobRequestProcessing = true;
      const recentManualSyncJobIds: string[] = recentManualSyncJobs.map(
        (job) => job.id
      );
      this.beginSyncJobPolling(recentManualSyncJobIds);
    }
  }

  syncJobType: string = this.determineSyncJobType();
  rawSyncJobType: string = this.syncJobType.toUpperCase().replace('-', '_');
  formattedSyncJobType: string = capitalize(
    [this.syncJobType.split('-').join(' ')],
    { eachWord: true }
  );
  emberRoutePath = `authorized-route.integration.sync-data.${this.syncJobType}`;

  states: Array<DropdownFilterUnit> = [
    { label: 'All States', value: null },
    { label: 'Successful', value: 'SUCCESSFUL' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Mapped', value: 'MAPPED' },
    { label: 'Unmapped', value: 'UNMAPPED' },
  ];

  pollInterval = Ember.testing ? 0.5 * 1000 : 10 * 1000;

  @service data!: Data;
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;
  @service router!: EngineRouterService;
  @service sync!: SyncService;
  @service declare modal: ModalService;

  @tracked anyChecked = false;
  @tracked allChecked = false;
  @tracked cursor: string = this.args.cursor;
  @tracked entityId: string = this.args.entity_id;
  @tracked isSyncJobRequestProcessing = false;
  @tracked isManualSyncDisabled = this.args.isLoading;
  // controls display of loading indicator in UI
  @tracked nextPageIsLoading = false;
  @tracked pairsToSync = [];
  @tracked state: DropdownFilterUnit = this.states.find(
    (state) => state.value === this.args.state
  )!;
  @tracked entityType: DropdownFilterUnit = this.args.entityTypes?.find(
    (entityType) => entityType.value == this.args.entityType
  )!;

  @tracked rows: Array<SyncEntityGroupStatusesModel> = this.args.rows;
  @tracked syncJobs: Array<SyncJob> = this.args.syncJobs;

  private determineSyncJobType(): string {
    const deconstructedRouteName: Array<string> =
      this.router.currentRouteName.split('.');
    const syncDataIndex: number = deconstructedRouteName.indexOf('sync-data');
    return deconstructedRouteName[syncDataIndex + 1];
  }

  @action
  filterStateHandler(filterObject: DropdownFilterUnit) {
    this.state = filterObject;

    let stateParam;

    if (!filterObject.label.includes('All')) {
      stateParam = filterObject.value;
    } else {
      stateParam = null;
    }

    const queryParams: QueryParams = {
      state: stateParam,
    };

    this.router.transitionTo(this.emberRoutePath, {
      queryParams,
    });

    this.args.handleModelRefresh();
  }

  @action
  filterEntityTypeHandler(filterObject: DropdownFilterUnit) {
    this.entityType = filterObject;

    let entityTypeParam;

    if (!filterObject.label.includes('All')) {
      entityTypeParam = filterObject.value;
    } else {
      entityTypeParam = null;
    }

    const queryParams: QueryParams = {
      entity_type: entityTypeParam,
    };

    this.router.transitionTo(this.emberRoutePath, {
      queryParams,
    });

    this.args.handleModelRefresh();
  }

  // handles a change in value of a dropdown filter, triggering a route change
  // with the corresponding query parameter

  @action
  public searchByEntityID(chars: string): void {
    // control flow so that query param cleared if search box empty
    if (chars) {
      this.router.transitionTo(this.emberRoutePath, {
        queryParams: { entity_id: chars },
      });
    } else {
      this.router.transitionTo(this.emberRoutePath, {
        queryParams: { entity_id: null },
      });
    }
    this.args.handleModelRefresh();
  }

  @action debouncedSearchByEntityIDMarket(customEvent: any): void {
    debounce(this, this.searchByEntityIDMarket, customEvent, 1000);
  }

  searchByEntityIDMarket(customEvent: any): void {
    // control flow so that query param cleared if search box empty
    if (customEvent.detail.value) {
      this.router.transitionTo(this.emberRoutePath, {
        queryParams: { entity_id: customEvent.detail.value },
      });
    } else {
      this.router.transitionTo(this.emberRoutePath, {
        queryParams: { entity_id: null },
      });
    }
    this.args.handleModelRefresh();
  }

  @action
  clearSearch() {
    if (this.entityId) {
      this.router.transitionTo(this.emberRoutePath, {
        queryParams: { entity_id: null },
      });
      this.args.handleModelRefresh();
    }
  }

  @action
  openModal() {
    this.modal
      .open('manual-sync-modal', <ManualSyncModalArgs>{
        syncJobType: this.rawSyncJobType,
      })
      .then((result: RunManualSyncResult) => {
        if (result) {
          this.runSync(result);
        }
      });
  }

  public runSync(runSyncResult: RunManualSyncResult): void {
    if (runSyncResult) {
      this.isManualSyncDisabled = true;
      this.isSyncJobRequestProcessing = true;
      this.sync
        .runSync(this.rawSyncJobType, runSyncResult)
        .then((response: SyncJob[]) => {
          this.beginSyncJobPolling(response.map((job) => job.id));
        })
        .catch(() => {
          this.flash.globalError(
            `Bridge failed to start a ${this.formattedSyncJobType} sync. Please try again later.`,
            {
              dismiss: () => {
                this.flash.clearGlobalMessage();
              },
            }
          );
          this.isManualSyncDisabled = false;
          this.isSyncJobRequestProcessing = false;
        });
    }
  }

  private beginSyncJobPolling(syncJobIds: string[]): void {
    const startTime = new Date();
    const expireTime = new Date(startTime.getTime() + FIFTEEN_MINUTES);
    this.flash.globalWarning(
      `A ${this.formattedSyncJobType} sync is running in the background. You may close this window.`,
      {
        dismiss: () => {
          this.flash.clearGlobalMessage();
        },
        persistent: true,
      }
    );
    later(this.poll(expireTime, syncJobIds), this.pollInterval);
  }

  private poll(expireTime: Date, syncJobIds: string[]): () => Promise<void> {
    return async () => {
      // stop polling if past the expiration time
      if (Date.now() > expireTime.getTime()) {
        this.isManualSyncDisabled = false;
        return;
      }
      const syncJobs = await this.sync.batchRetrieve(syncJobIds);
      const syncJobStates: string[] = [
        ...new Set(syncJobs.map((job) => job.state)),
      ];
      const everyStateCompletedOrFailed: boolean = syncJobStates.every(
        (state: string) =>
          [SyncJobState.COMPLETED, SyncJobState.FAILED].includes(
            state as SyncJobState
          )
      );
      if (everyStateCompletedOrFailed) {
        if (
          syncJobStates.every(
            (state: string) => SyncJobState.COMPLETED === state
          )
        ) {
          this.handleSuccessfulJobsPollResponse(syncJobs);
        } else {
          this.handleFailedJobsPollResponse(syncJobs);
        }
      } else {
        later(this.poll(expireTime, syncJobIds), this.pollInterval);
      }
    };
  }

  private handleSuccessfulJobsPollResponse(syncJobs: SyncJob[]): void {
    const successfulJob: SyncJob = syncJobs.find(
      (job) => job.state === SyncJobState.COMPLETED
    )!;
    let successMessage = '';
    if (!successfulJob.location_id) {
      successMessage = `${this.formattedSyncJobType} sync successful!`;
    } else {
      successMessage = `${this.formattedSyncJobType} sync successful for all locations!}`;
    }
    this.flash.globalSuccess(successMessage);
    this.isManualSyncDisabled = false;
    this.isSyncJobRequestProcessing = false;
    // prevent refresh of page when testing (interferes with Mirage request interception)
    if (Ember.testing) return;
    this.args.handleModelRefresh();
  }

  private handleFailedJobsPollResponse(syncJobs: SyncJob[]): void {
    const failedJobs: SyncJob[] = syncJobs.filter(
      (job) => job.state === SyncJobState.FAILED
    );
    const failedJobLocations: string[] = failedJobs.map(
      (job) => `${job.location_name} (${job.location_id})`
    );
    let failureMessage = '';
    if (!failedJobs[0].location_id) {
      failureMessage = `${this.formattedSyncJobType} sync failed.`;
    } else {
      failureMessage = `${this.formattedSyncJobType
        } sync failed for ${failedJobLocations.join(', ')}`;
    }
    this.flash.globalError(failureMessage, {
      dismiss: () => {
        this.flash.clearGlobalMessage();
      },
    });
    this.isManualSyncDisabled = false;
    this.isSyncJobRequestProcessing = false;
  }

  @action
  public assignClickToAction(pairedEntity: any, event: Event): void {
    if ((<HTMLElement>event.target).localName !== 'input') {
      this.router.transitionTo(`${this.emberRoutePath}.show`, pairedEntity);
    }
  }

  // updates cursor based pm response
  // manages loading state for UI row spinner
  @action
  public loadMore(): void {
    if (this.cursor && !this.nextPageIsLoading) {
      this.nextPageIsLoading = true;
      const requestEntityType = this.entityType ? this.entityType.value : null;
      const requestState = this.state ? this.state.value : null;
      this.data
        .getSyncEntityGroupStatuses({
          cursor: this.cursor,
          entity_id: this.entityId,
          state: requestState!,
          sync_type: this.rawSyncJobType,
          entity_type: requestEntityType!,
        })
        .then((responseJson): void => {
          this.rows = [
            ...this.rows,
            ...responseJson.sync_entity_group_statuses,
          ];
          this.cursor = responseJson.cursor!;
          this.nextPageIsLoading = false;
        });
    }
  }
}
