define("@square/glass-ui/templates/components/selector-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sLm8732/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,9],null,[[\"navigation\",\"content\",\"currentItem\",\"isFirstItem\",\"isLastItem\",\"previousAction\",\"nextAction\"],[[30,[36,6],[\"selector-control/navigation\"],[[\"onSelectItem\",\"onNavigate\",\"activeItems\",\"isFirstItem\",\"isLastItem\",\"type\"],[[30,[36,1],[[32,0],\"selectItem\"],null],[30,[36,1],[[32,0],\"goToStep\"],null],[35,8],[35,4],[35,3],[35,7]]]],[30,[36,6],[\"selector-control/content\"],[[\"currentItem\"],[[35,5]]]],[35,5],[35,4],[35,3],[30,[36,1],[[32,0],[35,2]],null],[30,[36,1],[[32,0],[35,0]],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"setNextItem\",\"action\",\"setPreviousItem\",\"isLastItem\",\"isFirstItem\",\"currentItem\",\"component\",\"type\",\"activeItems\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/selector-control.hbs"
    }
  });

  _exports.default = _default;
});