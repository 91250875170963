define("@square/glass-ui/templates/components/sq-form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dK2vpkXF",
    "block": "{\"symbols\":[\"dropdown\",\"&default\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,\"data-test-sq-form-field-label\",[34,5]],[14,0,\"form-field__label\"],[15,\"for\",[34,6]],[12],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n\\n\"],[6,[37,7],[[35,0]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"class\"],[\"form-field__label__tooltip\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('@square/glass-ui/templates/components/sq-form-field.hbs' @ L7:C11) \"],null]],[[\"class\"],[\"form-field__label__popover-trigger\"]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,1],null,[[\"color\",\"height\",\"width\"],[\"light\",18,18]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[30,[36,2],[[32,1,[\"popover\"]],\"expected `dropdown.popover` to be a contextual component but found a string. Did you mean `(component dropdown.popover)`? ('@square/glass-ui/templates/components/sq-form-field.hbs' @ L12:C11) \"],null]],[[\"class\",\"constrainInParent\"],[\"form-field__label__popover\",false]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"form-field__content\"],[12],[2,\"\\n  \"],[18,2,[[32,0]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"svg/icon-info-circle\",\"-assert-implicit-component-helper-argument\",\"component\",\"sq-dropdown\",\"label\",\"innerElementId\",\"if\",\"noLabel\",\"unless\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-form-field.hbs"
    }
  });

  _exports.default = _default;
});