import { ConfigFormBlueprint } from '@bridge/home-engine/services/config';
/**
## SettingsContainer

Contains the settings form manager by handling the retrieval and completion of forms and
decoupling them from a page or sheet for drier code.

```example
<Settings::SettingsContainer
  @formMap={{this.integrationSettingsFormMap}}
  @handleFormMapUpdate={{this.handleFormMapUpdate}}
  @isLoading={{this.isLoading}}
  @loadingStateToggleHandler={{this.toggleLoadingState}}
  @loadingText="Populating integration settings..."
  @objectId={{this.currentUser.currentIntegration.id}}
  @onStandalonePage={{true}}
  @setSaveButtonDisabled={{this.setSaveButtonDisabled}}
  @settingsTarget="INTEGRATION"
/>
```

### Parameters
 * @param {Object} [formMap] [A single-level object with: property names describing the structure of the settings request, and values of the form fields.]
 * @param {Function} [handleFormMapUpdate] [Callback for updating form map.]
 * @param {boolean} [isLoading] [Loading state.]
 * @param {action} [loadingStateToggleHandler] [Event handler for when form finishes loading.]
 * @param {string} [objectId] [ID of the object being configured for use in making the form request.]
 * @param {boolean} [onStandalonePage] [True for forms that are on a page and not a sheet. Determines styling (mostly justification).]
 * @param {action} [setSaveButtonDisabled] [Controls "Save" or "Continue" state of button in parent component.]
 * @param {setSaveButtonDisabled} [setSaveButtonDisabled] [Callback for setting save button state.]
 * @param {SettingsFormType} [settingsTarget] [String describing object type being configured for use in making the form request.]
*/

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// @ts-ignore ember engines has no exported types
import { EngineRouterService } from 'ember-engines-router-service';
// @ts-ignore lodash may have no exported types (TODO: find/install if so)
import _ from 'lodash';
import Config from '../../services/config';
import Data from 'bridge-dashboard/app/services/data';
import { SettingsFormType } from '../../models/interface/settings-form-type';
import { FormMap } from '../../models/interface/form-map';
import BridgeApiException from 'bridge-dashboard/app/types/bridge-api-exception';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import FlashService from '@square/glass-ui/addon/services/flash';
import FscAuth from '@bridge/home-engine/services/fsc-auth'



interface SettingsSettingsContainerArgs {
  handleFormMapUpdate: Function;
  formMap: Object;
  isLoading: boolean;
  loadingStateToggleHandler: Function;
  objectId: string;
  onStandalonePage: boolean;
  setSaveButtonDisabled: Function;
  settingsTarget: SettingsFormType;
  showFscReauthorization: boolean;
}

export default class SettingsSettingsContainer extends Component<
  SettingsSettingsContainerArgs
> {
  constructor(owner: unknown, args: SettingsSettingsContainerArgs) {
    super(owner, args);
    this.fetchAndDisplaySettingsForm(this.args.objectId);
  }

  @service config!: Config;
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;
  @service fscAuth!: FscAuth;
  @service data!: Data;
  @service router!: EngineRouterService;

  @tracked configBlueprint?: ConfigFormBlueprint;
  @tracked fscReauthorizationLink = '';
  @tracked taxSettings: Object = {};

  supplementalObject: Object = {};

  fetchAndDisplaySettingsForm(objectId: string) {
    let configBlueprintResponsePromise;
    switch (SettingsFormType[this.args.settingsTarget]) {
      case SettingsFormType.SOURCE:
        configBlueprintResponsePromise = this.config.fetchExistingSourceConfig(
          objectId
        );
        break;
      case SettingsFormType.INTEGRATION:
        configBlueprintResponsePromise = this.config.fetchIntegrationConfig(
          objectId
        );
        break;
      case SettingsFormType.LOCATION:
        configBlueprintResponsePromise = this.config.fetchLocationConfig(
          objectId
        );
        break;
      case SettingsFormType.TAX:
        configBlueprintResponsePromise = this.config.fetchTaxConfig(objectId);
        break;
      case SettingsFormType.MEASUREMENT_UNIT:
        configBlueprintResponsePromise = this.config.fetchMeasurementUnitConfig(
          objectId
        );
        break;
      case SettingsFormType.ITEM_MODIFIER:
        configBlueprintResponsePromise = this.config.fetchItemModifierConfig(
          objectId
        );
        break;
      case SettingsFormType.DISCOUNT:
          configBlueprintResponsePromise = this.config.fetchDiscountConfig(
            objectId
          );
          break;
      default:
        throw new Error(
          `${this.args.settingsTarget} is not a valid settings target.`
        );
    }
    configBlueprintResponsePromise.then(
      (json) => {
        this.configBlueprint = json.configuration_blueprint;
        this.args.loadingStateToggleHandler(false);
      },
      (error: BridgeApiException) => {
        this.args.loadingStateToggleHandler(false);
        this.flash.globalError(
          `Settings form failed to load. ${error}`,
          {
            dismiss: () => {
              this.flash.clearGlobalMessage();
            },
          }
        );
      }
    );
  }

  @action
  handleCompletedForm(newFormMap: FormMap, isSaveDisabled: boolean): void {
    if (_.isEqual(this.args.formMap, newFormMap)) return; // If the newFormMap is the same as the old one, return

    this.args.setSaveButtonDisabled(isSaveDisabled);
    this.args.handleFormMapUpdate(newFormMap);

    if (this.args.showFscReauthorization) {
      this.fscReauthorizationLink = this.fscAuth.generateAuthorizationLink(
        newFormMap.resource_url
      );
    }
  }
}
