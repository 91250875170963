define("@square/glass-ui/components/sq-checkbox", ["exports", "@square/glass-ui/templates/components/sq-checkbox"], function (_exports, _sqCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHECKBOX_STATE = void 0;
  var CHECKBOX_STATE = {
    UNCHECKED: false,
    CHECKED: true,
    INDETERMINATE: 'indeterminate'
  };
  /*
  
  Probably don't mess with this. Checkboxes are deceivingly complicated.
  -jbiggs
  
  ---Native HTML behavior of checkbox inputs----
  - Browsers treat 'checked' and 'indeterminate' as two separate concerns
  when it comes to checkboxes
  - Checkboxes should have the 'checked' property when they are actually
  checked *and* when they are 'indeterminate'
  - HTML provides a 'checked' attribute for <input> elements, that you
  can pass an attribute to in the component template, but the 'indeterminate'
  property can only be set throught javascript
  
  ---
  
  This component uses a faux checkbox in order to provide a custom style
  for checkboxes, but also includes a valid checkbox input that is kept
  in-sync with the state of the matching faux checkbox
  
  */

  _exports.CHECKBOX_STATE = CHECKBOX_STATE;

  var _default = Ember.Component.extend({
    layout: _sqCheckbox.default,
    tagName: 'span',
    classNames: ['option-toggle', 'checkbox'],
    classNameBindings: ['checked:checkbox--is-checked', 'checked:option-toggle--is-selected', 'indeterminate:checkbox--is-indeterminate', 'disabled:option-toggle--is-disabled', 'disabled:checkbox--is-disabled'],
    attributeBindings: ['data-test-checkbox', 'data-test-checkbox-checked'],
    'data-test-checkbox': Ember.computed('value', 'testSelectorValue', function () {
      return this.testSelectorValue || this.value || true;
    }),
    'data-test-checkbox-checked': Ember.computed.readOnly('checked'),
    // passed in, true, false, or 'indeterminate'
    // It is passed to the 'checked' attribute on the
    // checkbox input and will evalute to 'true' if
    // either 'true' or 'indeterminate' is passed
    checked: false,
    indeterminate: Ember.computed.equal('checked', CHECKBOX_STATE.INDETERMINATE),
    // The svg icon serving as either a checkmark or an indeterminate line
    // We use svgs even for the simpler undetermined line in order to
    // ensure line-weight consistency and porportion of the marks always match
    checkmarkSvg: Ember.computed('checked', function () {
      switch (this.get('checked')) {
        case CHECKBOX_STATE.CHECKED:
          return 'svg/icon-checkmark';

        case CHECKBOX_STATE.INDETERMINATE:
          return 'svg/icon-undetermined';

        default:
          return null;
      }
    }),
    actions: {
      onChange: function onChange(checked) {
        if (this.action) {
          this.action(checked, this.get('value'));
        } // Ember uses the `change` action internally to 2-way bind
        // the value passed to the `checked` param on the {{input}}
        // helper, but because we're using a custom action, this
        // gets overridden, so we still need to set `checked` here


        this.set('checked', checked);
      }
    }
  });

  _exports.default = _default;
});