define("@square/glass-ui/components/sq-field-date", ["exports", "@square/glass-ui/templates/components/sq-field-date", "@square/glass-ui/utils/field-element-support"], function (_exports, _sqFieldDate, _fieldElementSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldElementSupport.default, {
    layout: _sqFieldDate.default,
    // passed in
    disabledRange: null,
    placeholder: null,
    value: null,
    valueFormatted: null,
    constrainCalendarInParent: true,
    elementHasFocus: false,
    enablePastDates: false,
    enableFutureDates: true,
    shouldShowCalendar: false,
    isFocused: Ember.computed.or('elementHasFocus', 'shouldShowCalendar'),
    boundClickToggle: null,
    // saved ref to event handler for easy removal
    // Make a mutable copy of the date for the calendar input to manipulate,
    // but so that we can make this component DDAU.
    mutableDateCopy: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.boundClickToggle = this.clickToggle.bind(this);
      window.addEventListener('click', this.boundClickToggle);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('click', this.boundClickToggle);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      this.set('mutableDateCopy', this.get('value') ? new Date(this.get('value')) : new Date());
    },
    actions: {
      toggleCalendar: function toggleCalendar() {
        this.toggleProperty('shouldShowCalendar');

        if (this.onToggleCalendar) {
          this.onToggleCalendar(this.get('shouldShowCalendar'));
        }
      },
      selectDate: function selectDate(date) {
        if (this.onChange) {
          this.onChange(date);
        }

        this.send('toggleCalendar');
      }
    },
    clickToggle: function clickToggle(e) {
      var _this = this;

      Ember.run(function () {
        if (_this.element.contains(e.target) || !_this.get('shouldShowCalendar')) {
          return;
        }

        _this.send('toggleCalendar');
      });
    },
    focusIn: function focusIn() {
      this.set('elementHasFocus', true);
    },
    focusOut: function focusOut() {
      this.set('elementHasFocus', false);
    }
  });

  _exports.default = _default;
});