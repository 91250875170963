define("@square/glass-ui/components/svg/icon-radio", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-radio"], function (_exports, _svgIcon, _iconRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconRadio.default,
    classNames: ['svg-icon--radio'],
    viewBox: '0 0 16 16',
    width: 16,
    height: 16
  });

  _exports.default = _default;
});