define("@square/glass-ui/components/video-player", ["exports", "moment", "@square/glass-ui/utils/video", "@square/glass-ui/templates/components/video-player"], function (_exports, _moment, _video, _videoPlayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
  The video player component can embed a responsive video component in the page with
  a `<video>` tag
  
  **NOTE** If you're loading the video from your localhost, there will be a bug in Chrome where the
  playback slider will reset to the beginning of the video when attempting to skip to a specific point
  by clicking or dragging the playback slider. This behavior does not occur if you load the video
  properly from a source like AWS or a CDN.
  
  ```example
  
  @title Basic Usage
  @description The `video` element fills in the width of its container and the height
  auto-adjusts. You can pass a `class` and give the video a static width with CSS if needed.
  By default, the video type is `video/mp4`, but you can pass a different type for better
  optimization. Don't forget to add accessible `alt` text!
  
  @hbs
  {{video-player src="/assets/videos/video-example.mp4"
                 type="video/mp4"
                 alt="My Example Video"
                 poster="/assets/images/video-example-poster.png"}}
  
  ```
  Renders:
  
  ```
  <div class="video-player">
    <video class="video-player" src="assets/videos/video-example.mp4" type="video/mp4">
      <p>Your browser does not support HTML5 video. assets/videos/video-example.mp4</p>
    </video>
    <div class="video-player-controls">
      ...
    </div>
  </div>
  ```
  
  ```example
  
  @title Custom Overlay
  @description You can use the component as a block to pass custom text or template to display
  as the video overlay with the play icon. Note that at <= small viewports, anything that is not
  an <h2> will be hidden.
  
  @hbs
  {{#video-player src="/assets/videos/video-example.mp4"
                 type="video/mp4"
                 alt="My Example Video"
                 poster="/assets/images/video-example-poster.png"
                 as |video|}}
    <h2>Custom Overlay Text</h2>
    <p>Maybe a description of the video and a duration: {{video.duration}}</p>
  
  {{/video-player}}
  
  ```
  
  ```example
  @title Custom Theming
  @description You can use the CSS mixin `VideoPlayerTheme` and pass a custom color to
  theme the video player controls with a color of your choice. (If your video is in a container
  that uses the `TieDye` mixin, you don't need to do this! The TieDie mixin will theme any videos
  inside of it.)
  
  @hbs
  {{video-player src="/assets/videos/video-example.mp4"
                 poster="/assets/images/video-example-poster.png"
                 class="my-custom-themed-video-player"}}
  ```
  
  ```
  // You can pass a color variable or a regular color value (hex/rgba) here
  .my-custom-themed-video-player {
    @include VideoPlayerTheme(#924cc4)
  }
  
  ```
  
  ```example
  
  @title Autoplay
  @description Pass `autoplay=true` to enable autoplay. Due to Chrome restrictions, video will be muted. For mobile
  devices, video will play inline.
  
  @hbs
  {{video-player src="/assets/videos/video-example.mp4"
                 type="video/mp4"
                 alt="My Example Video"
                 poster="/assets/images/video-example-poster.png"
                 autoplay=true}}
  ```
  
  ```example
  @title Background Videos (Hiding player UI)
  @description You can hide the controls bar and the overlay icon if you need something akin to
  a background video (where you don't want the user to interact with it) by passing `canInteract=false`
  which will hide the overlay and the controls. You probably also want to pass `autoplay=true`,
  `loop=true` and `playsinline=true`
  
  @hbs
  {{video-player src="/assets/videos/video-example.mp4"
                 poster="/assets/images/video-example-poster.png"
                 class="my-custom-themed-video-player"
                 canInteract=false
                 autoplay=true
                 loop=true
                 playinline=true}}
  ```
  
  ### Parameters
  @param {String} [src] The source of the video. Both external link or video placed in public/ works.
  @param {String} [type="video/mp4"] Declare the type of the source for the optimization (optional)
  @param {Boolean} [autoplay=false] Autoplay video (optional)
  @param {Boolean} [loop=false] Loop video (optional)
  @param {Boolean} [playsinline=false] Play video inline in mobile browsers (optional)
  @param {Boolean} [canInteract=true] Set this to false if you need something akin to a background video where you don't want the user to be able to interact with it
  @param {String} [description] Video alt text
  @param {String} [poster] Poster image src. Shows while the video is loading
  @param {Action} [onPlay] Send this action when the video plays
  @param {Action} [onPause] Send this action when the video pauses
  @param {Action} [onEnd] Send this action when the video ends
  @param {Action} [onTimeUpdate] Send this action on video time update.
  */
  function convertDurationToMinuteSeconds(duration) {
    var momentDuration = _moment.default.duration(duration, 'seconds');

    var minutes = momentDuration.minutes();
    var seconds = momentDuration.seconds();
    seconds = "0".concat(seconds).slice(-2); // prepend 0 to seconds if it's single digit

    return "".concat(minutes, ":").concat(seconds);
  }

  var _default = Ember.Component.extend({
    layout: _videoPlayer.default,
    classNames: ['video-player'],
    classNameBindings: ['isPlaying:video-player--is-playing', 'autoplayAtFirst:video-player--initial-autoplay', 'showControlsOnHover:video-player--show-controls-on-hover', 'hasPlayed:video-player--active'],
    attributeBindings: ['style'],
    isPlaying: false,
    isMuted: false,
    type: 'video/mp4',
    autoplay: false,
    loop: false,
    playsinline: false,
    videoProgress: 0,
    videoVolume: 1,
    canInteract: true,
    isTouchStartSupported: false,
    togglePlayOnTouchEnd: false,
    isAtBeginning: true,
    hasPlayed: false,
    hasEnded: false,
    showControlsOnHover: Ember.computed.not('isAtBeginning'),
    // We call `video.load()` because we want to show the poster
    // when the video finishes playing. If the `autoplay` attribute is
    // set when `video.load()` is called, the video would start playing
    // rather than showing the poster. This allows us to remove the
    // autoplay attribute from the video tag once it has played.
    autoplayAtFirst: Ember.computed('autoplay', 'hasPlayed', function () {
      return this.get('autoplay') && !this.get('hasPlayed');
    }),
    // Prevent the poster from flashing during loading the video when autoplaying
    autoplayAwarePoster: Ember.computed('autoplay', 'hasEnded', 'poster', function () {
      if (this.get('autoplay') && !this.get('hasEnded')) {
        return null;
      }

      return this.get('poster');
    }),
    style: Ember.computed('poster', function () {
      return "background-image: url(".concat(this.poster, ")");
    }),
    overlayIcon: Ember.computed('isPlaying', function () {
      return this.isPlaying ? 'svg/icon-pause' : 'svg/icon-play';
    }),
    currentVideoTime: Ember.computed('videoProgress', 'duration', function () {
      return this.get('videoProgress') * this.duration;
    }),
    currentDisplayTime: Ember.computed('currentVideoTime', function () {
      return convertDurationToMinuteSeconds(this.get('currentVideoTime'));
    }),
    video: Ember.computed('element', function () {
      return this.element.getElementsByClassName('video-player__video')[0];
    }),
    lastVolume: Ember.computed('video', function () {
      return this.get('video').volume;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setVideoEventHandlers();
      this.get('video').addEventListener('loadedmetadata', Ember.run.bind(this, this.onloadedmetadata));
      this.get('video').addEventListener('timeupdate', Ember.run.bind(this, this.ontimeupdate));
      this.get('video').addEventListener('ended', Ember.run.bind(this, this.onend));
      this.get('video').addEventListener('play', Ember.run.bind(this, this.onplay));
      this.get('video').addEventListener('pause', Ember.run.bind(this, this.onpause));

      if (this.get('autoplay')) {
        // if user passes in playsinline, then we mute the video (mobile)
        if (this.get('playsinline')) {
          // chrome requires autoplay video to be muted
          this.mute();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments); // pause video before teardown to avoid timeupdate race conditions


      this.pause();
      this.get('video').removeEventListener('loadedmetadata', this.onloadedmetadata);
      this.get('video').removeEventListener('timeupdate', this.ontimeupdate);
      this.get('video').removeEventListener('ended ', this.onend);
      this.get('video').removeEventListener('play', this.onplay);
      this.get('video').removeEventListener('pause', this.onpause);
    },
    setVideoEventHandlers: function setVideoEventHandlers() {
      var _this = this;

      // Set the video duration after video metadata has loaded
      this.onloadedmetadata = function () {
        if (_this.isDestroyed) {
          return;
        } // this prevents a weird flash of the placeholder image
        // after the video is loaded.


        _this.set('style', null);

        var duration = _this.get('video').duration;

        _this.set('duration', duration);

        var displayedDuration = convertDurationToMinuteSeconds(duration);

        _this.set('displayedDuration', displayedDuration);
      }; // Update the slider bar as the video plays


      this.ontimeupdate = function () {
        if (!_this.isPlaying || _this.isDestroyed) {
          return;
        }

        var previousProgress = _this.get('videoProgress');

        var currentProgress = _this.get('video').currentTime / _this.get('video').duration;

        if (_this.onTimeUpdate) {
          _this.onTimeUpdate(previousProgress, currentProgress);
        }

        _this.set('videoProgress', currentProgress);
      }; // Set video to isPlaying false to update the pause/play icon


      this.onend = function () {
        if (_this.isDestroyed) {
          return;
        }

        _this.set('isPlaying', false);

        _this.set('hasEnded', true);

        _this.resetVideoToBeginning(); // shows poster again


        if (_this.onEnd) {
          _this.onEnd();
        }
      };

      this.onplay = function () {
        if (_this.isDestroyed) {
          return;
        }

        _this.set('hasPlayed', true);

        _this.set('isPlaying', true);

        _this.set('isAtBeginning', false);

        if (_this.onPlay) {
          _this.onPlay();
        }
      };

      this.onpause = function () {
        if (_this.isDestroyed) {
          return;
        }

        _this.set('isPlaying', false);

        if (_this.onPause) {
          _this.onPause();
        }
      };
    },
    resetVideoToBeginning: function resetVideoToBeginning() {
      this.get('video').load();
      this.set('videoProgress', 0);
      this.set('isAtBeginning', true);
    },
    // Updates currentTime of video
    updatetime: function updatetime(e, progress) {
      this.set('videoProgress', progress);
      this.get('video').currentTime = this.get('currentVideoTime');
    },
    // May return promise; older browsers may return nothing
    play: function play() {
      return this.get('video').play();
    },
    pause: function pause() {
      this.get('video').pause();
    },
    // Setup play flag and events for cancelling play on touchmove & touchcancel
    allowPlayMobile: function allowPlayMobile() {
      this.set('isTouchStartSupported', true);
      this.set('togglePlayOnTouchEnd', true);
      this.cancelPlayMobileListener = this.cancelPlayMobile.bind(this);
      this.get('video').addEventListener('touchmove', Ember.run.bind(this, this.cancelPlayMobileListener), {
        once: true
      });
      this.get('video').addEventListener('touchcancel', Ember.run.bind(this, this.cancelPlayMobileListener), {
        once: true
      });
    },
    cancelPlayMobile: function cancelPlayMobile() {
      if (this.isDestroyed) {
        return;
      }

      this.set('togglePlayOnTouchEnd', false);
    },
    togglePlayMobile: function togglePlayMobile() {
      if (this.get('togglePlayOnTouchEnd')) {
        var playAction = this.togglePlay();

        if (playAction) {
          playAction.catch(function () {}); // iOS may return permission error on touch and hold; we can't recover from it
        }
      }

      this.get('video').removeEventListener('touchmove', this.cancelPlayMobileListener);
      this.get('video').removeEventListener('touchcancel', this.cancelPlayMobileListener);
    },
    togglePlayDesktop: function togglePlayDesktop() {
      if (!this.get('isTouchStartSupported')) {
        this.togglePlay();
      }
    },
    togglePlay: function togglePlay() {
      // Only toggle play/pause on click if this video is set
      // to be interactive (e.g. not a background/layout video)
      if (this.canInteract) {
        return this.isPlaying ? this.pause() : this.play();
      }

      return null;
    },
    mute: function mute() {
      this.set('lastVolume', this.get('video').volume);
      this.set('videoVolume', 0);
      this.get('video').muted = true;
      this.set('isMuted', true);
    },
    unmute: function unmute() {
      this.set('videoVolume', this.lastVolume);
      this.get('video').muted = false;
      this.set('isMuted', false);
    },
    fullScreen: function fullScreen() {
      var video = this.get('video');
      (0, _video.requestFullscreen)(video);
    },
    // volume is a value from 0 to 1
    changeVolume: function changeVolume(e, value) {
      // ensures the steps for volume are to the tenths
      var videoVolume = Number(value.toFixed(1));
      this.get('video').volume = videoVolume;
      this.set('videoVolume', videoVolume);
      this.set('isMuted', videoVolume === 0);
    }
  });

  _exports.default = _default;
});