import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// import { captureMessage } from '@sentry/browser';
import { AsyncFetchState } from '../../utils/custom-objects/async-fetch-state';
import { restartableTask, TaskGenerator, timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

const DEFAULT_DEBOUNCE_INTERVAL = 500;

interface QueryProviderArgs<DataType, TransformedData> {
  fetchFn: (params: {
    query: string | null;
    signal?: AbortSignal;
  }) => Promise<DataType>;
  hasNextPage?: () => boolean;
  transformData?: (data: DataType) => TransformedData;
  debounceInterval?: number;
  query?: string;
}

export default class QueryProvider<Data, TData> extends Component<
  QueryProviderArgs<Data, TData>
> {
  @tracked data: (Data | TData)[] | Data | TData | undefined;
  @tracked status = AsyncFetchState.NotRequested;

  debounceInterval = this.args.debounceInterval ?? DEFAULT_DEBOUNCE_INTERVAL;

  constructor(owner: unknown, args: QueryProviderArgs<Data, TData>) {
    super(owner, args);
    this.fetchData();
  }

  /**
   * Uses ember-concurrency to debounce `fetchData` requests and passes an
   * AbortSignal through that can be used by consumers to proactively cancel
   * outdated requests.
   */
  @restartableTask
  *_debouncedFetchData(args = {}): TaskGenerator<void> {
    const controller = new AbortController();
    const signal = controller.signal;

    yield timeout(this.debounceInterval);
    try {
      yield this.fetchData({ ...args, signal });
    } finally {
      controller.abort();
    }
  }

  fetchData = async ({
    isFetchingNextPage = false,
    signal,
  }: {
    isFetchingNextPage?: boolean;
    signal?: AbortSignal;
  } = {}): Promise<void> => {
    this.status = AsyncFetchState.Loading;
    const query = this.args.query ?? null;

    try {
      const res = await this.args.fetchFn({ query, signal });
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      // Ignore responses from queries that are no longer the latest query
      if (typeof query === 'string' && query !== this.args.query) {
        return;
      }

      this.status = AsyncFetchState.Success;
      const parsedData = this.args.transformData?.(res) ?? res;

      this.data =
        Array.isArray(this.data) && isFetchingNextPage
          ? // eslint-disable-next-line unicorn/prefer-spread
            this.data.concat(parsedData)
          : parsedData;
    } catch (e: unknown) {
      // Ignore abort controller aborted errors
      if (e instanceof DOMException && e.name === 'AbortError') {
        return;
      }

      // Ignore errors from queries that are no longer the latest query
      if (typeof query === 'string' && query !== this.args.query) {
        // captureMessage('[query-provider] outdated query errored');
        return;
      }

      this.status = AsyncFetchState.Error;
    }
  };

  debouncedFetchData = (): void => {
    taskFor(this._debouncedFetchData).perform();
  };

  fetchNextPage = async (): Promise<void> => {
    if (this.status === AsyncFetchState.Loading || !this.args.hasNextPage?.()) {
      return;
    }

    await this.fetchData({ isFetchingNextPage: true });
  };

  get hasError(): boolean {
    return this.status === AsyncFetchState.Error;
  }

  get isLoading(): boolean {
    return this.status === AsyncFetchState.Loading;
  }
}
