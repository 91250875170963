import Route from '@ember/routing/route';

import { MerchantApplication } from '../../../models/services/merchant-application/merchant-application';

export default class AuthorizedRoutePluginsPlugin extends Route {
  model(params: { plugin_id: string}): MerchantApplication {
    const plugins: Array<MerchantApplication> = this.modelFor(
      'authorized-route.plugins'
    );
    return plugins.find((plugin) => plugin.id === Number(params.plugin_id))!;
  }
}
