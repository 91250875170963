import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthorizedRouteIntegrationSettingsLocationsLocation extends Route {
  @action
  willTransition() {
    return true;
  }
  
  model(params: any) {
    return params.location_id;
  }
}
