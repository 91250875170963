import FlashService from '@square/glass-ui/addon/services/flash';
import { VendorEntity } from '../../models/services/vendor-entity/vendor-entity';
import Data from 'bridge-dashboard/app/services/data';
import SyncEntityGroupStatus from '@bridge/home-engine/addon/models/services/sync-entity-group-status/sync-entity-group-status';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

interface UnmappedSyncEntityBladeArgs {
  syncObject: SyncEntityGroupStatus;
  update: () => void;
}

export default class UnmappedSyncEntityBlade extends Component<UnmappedSyncEntityBladeArgs> {
  @tracked externalId = '';

  @tracked externalName = '';

  @tracked externalIdToSave = '';

  @tracked isRetrieveBtnLoading = false;

  @tracked isSaveMappingBtnLoading = false;

  @tracked currentState = 'UNMAPPED';

  @service data!: Data;

  @service flash!: FlashService;

  get isSaveDisabled() {
    return (
      this.externalIdToSave !== this.externalId || this.externalIdToSave === ''
    );
  }

  get isRetrieveDisabled() {
    return this.externalIdToSave === this.externalId;
  }

  get externalNameDisplay() {
    return this.externalName !== '' ? this.externalName : '-';
  }

  @action handleChange(e: CustomEvent) {
    this.externalId = e.detail.value;
  }

  @action saveMapping() {
    this.isSaveMappingBtnLoading = true;
    this.data
      .updateExternalMapping(this.args.syncObject.integration_id, {
        sync_entity_group_status_id: this.args.syncObject.id,
        external_id: this.externalIdToSave,
        external_source_id: this.args.syncObject.external_source_id,
      })
      .then(() => {
        this.currentState = 'MAPPED';
        this.flash.globalSuccess('Sync entity successfully mapped.');
        // setTimeout(() => {
        //   this.args.update();
        // }, 2000);
      })
      .finally(() => {
        this.isSaveMappingBtnLoading = false;
      });
  }

  @action getExternalName() {
    this.isRetrieveBtnLoading = true;

    this.data
      .getVendorEntity(
        this.args.syncObject.external_source_id,
        this.externalId,
        this.args.syncObject.entity_type
      )
      .then((entity: VendorEntity) => {
        this.externalName = entity.name;
        this.externalIdToSave = this.externalId;
      })
      .catch(() => {
        this.externalName = 'Entity not found';
      })
      .finally(() => {
        this.isRetrieveBtnLoading = false;
      });
  }
}
