define("@square/glass-ui/utils/field-checkbox-focus-support", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    mouseTarget: '.ember-checkbox',
    mouseDown: false,
    // If any child checkbox encounters a mousedown/up event, set a single variable accordingly
    setMouseEvents: Ember.on('didInsertElement', function () {
      var _this = this;

      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(this.element).find(this.get('mouseTarget')).on({
        mousedown: function mousedown() {
          _this.set('mouseDown', true);
        },
        mouseup: function mouseup() {
          _this.set('mouseDown', false);
        }
      });
    }),
    removeMouseEvents: Ember.on('willDestroyElement', function () {
      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(this.element).find(this.get('mouseTarget')).off('mousedown mouseup');
    }),
    // Key logic of the mixin. If change focus from inner checkbox A to inner checkbox B
    // we never allow isFocused to be set to false at any time. Visually it avoids any split second "flash")
    // of our of focus state; this really shouldn't happen considering we stayed "in focus" full time
    // in the master checkbox set.
    focusOut: Ember.on('focusOut', function () {
      if (this.get('mouseDown') === false) {
        this.set('isFocused', false);
      }
    })
  });

  _exports.default = _default;
});