define("@square/glass-ui/services/scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // We maintain a count since multiple sources could try to disable scrolling at the same time.
    lockCount: 0,
    scrollCoordinates: null,
    disableWindowScrolling: function disableWindowScrolling() {
      this.incrementProperty('lockCount');

      if (this.get('lockCount') > 1) {
        return;
      } // Store the original scroll position, so we can use it to move the app
      // content up and to the left.
      // NOTE: Using `pageYOffset` instead of `scrollY` since IE11 only has the former


      var scrollY = window.pageYOffset;
      var scrollX = window.pageXOffset; // Disable window scrolling while leaving the scroll bar visible

      document.body.classList.add('no-scroll'); // FIXME(DASHEX-1399): We should fallback correctly when #sq-app-container is
      // not present. As it is now, scrolling is disabled but the content jumps to
      // the top since we're not shifting it here.

      var appElement = document.querySelector('#sq-app-container');

      if (appElement) {
        appElement.style.top = "-".concat(scrollY, "px");
        appElement.style.left = "-".concat(scrollX, "px");
      }

      this.set('scrollCoordinates', {
        scrollX: scrollX,
        scrollY: scrollY
      });
    },
    enableWindowScrolling: function enableWindowScrolling() {
      if (this.get('lockCount') === 0) {
        (false && !(false) && Ember.assert('Scrolling should not be enabled if it was not previously disabled'));
        return;
      }

      this.decrementProperty('lockCount');

      if (this.get('lockCount') > 0) {
        return;
      } // Restore window scrolling


      document.body.classList.remove('no-scroll'); // Simulate the original scroll position by moving the app content up and to the left

      var appElement = document.querySelector('#sq-app-container');

      if (appElement) {
        appElement.style.top = 'auto';
        appElement.style.left = 'auto';
      }

      var _this$get = this.get('scrollCoordinates'),
          scrollX = _this$get.scrollX,
          scrollY = _this$get.scrollY;

      this.set('scrollCoordinates', null);
      window.scrollTo(scrollX, scrollY);
    }
  });

  _exports.default = _default;
});