import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { Router } from '@ember/routing';

// Gatekeeper route to all of its child routes
export default class AuthorizedRoute extends Route {
  @service currentUser!: CurrentUser;
  @service router!: Router;
  @service flash!: any;

  @action
  willTransition(transition: any) {
    if (
      transition.from.name.includes('authorized-route.new-integration') &&
      transition.to.name.includes('authorized-route.index')
    ) {
      this.refresh();
    }
  }

  model() {
    return this.currentUser.loadUser();
  }

  afterModel() {
    if (!this.currentUser.user?.terms_accepted) {
      this.transitionTo('authorized-route.accept-terms');
    }
  }

  // see: https://guides.emberjs.com/release/routing/loading-and-error-substates/#toc_error-substates
  // handles all errors originated within authorized-route
  /* NOTE: value of rejected Promises or thrown objects must be Error object,
   * else error will not bubble as model to error substate route
   */
  @action
  error(error: any, _transition: any) {
    this.flash.globalError(error.message, {
      dismiss: () => {
        this.flash.clearGlobalMessage();
      },
    });
  }
}
