define("@square/glass-ui/components/media-block/media", ["exports", "@square/glass-ui/templates/components/media-block/media"], function (_exports, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_BLOCK_OVERLAY_GRADIENT_DIRECTION = _exports.MEDIA_BLOCK_OVERLAY_COLOR = _exports.MEDIA_BLOCK_IMAGE_CROP = void 0;
  var MEDIA_BLOCK_IMAGE_CROP = {
    FIT: 'fit',
    FILL: 'fill'
  };
  _exports.MEDIA_BLOCK_IMAGE_CROP = MEDIA_BLOCK_IMAGE_CROP;
  var MEDIA_BLOCK_OVERLAY_GRADIENT_DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    BOTTOM: 'bottom'
  };
  _exports.MEDIA_BLOCK_OVERLAY_GRADIENT_DIRECTION = MEDIA_BLOCK_OVERLAY_GRADIENT_DIRECTION;
  var MEDIA_BLOCK_OVERLAY_COLOR = {
    DARK: 'dark',
    LIGHT: 'light'
  };
  _exports.MEDIA_BLOCK_OVERLAY_COLOR = MEDIA_BLOCK_OVERLAY_COLOR;

  var _default = Ember.Component.extend({
    layout: _media.default,
    classNames: ['media-block__media'],
    classNameBindings: ['cropClass', 'overlayClasses'],
    attributeBindings: ['data-test-media-block-media'],
    'data-test-media-block-media': true,
    crop: MEDIA_BLOCK_IMAGE_CROP.FIT,
    cropClass: Ember.computed('crop', function () {
      if (this.crop === MEDIA_BLOCK_IMAGE_CROP.FILL) {
        return 'media-block__media--crop-fill';
      } else {
        return 'media-block__media--crop-fit';
      }
    }),
    overlayClasses: Ember.computed('overlay.{color,direction}', function () {
      if (this.overlay) {
        var overlayClasses = "media-block__media--overlay media-block__media--overlay--".concat(this.overlay.color);

        if (this.overlay.direction) {
          overlayClasses = "".concat(overlayClasses, " media-block__media--overlay-gradient media-block__media--overlay-gradient--").concat(this.overlay.direction);
        }

        return overlayClasses;
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});