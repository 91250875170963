define("@square/glass-ui/templates/components/sq-field-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pmoydCC7",
    "block": "{\"symbols\":[\"option\",\"index\",\"checkboxComponent\",\"&default\"],\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,0],[\"sq-option-checkbox\"],[[\"class\",\"name\",\"label\",\"labelSecondary\",\"value\",\"checked\",\"disabled\",\"action\"],[\"option-set__option\",[32,1,[\"name\"]],[32,1,[\"label\"]],[32,1,[\"labelSecondary\"]],[32,1,[\"value\"]],[32,1,[\"checked\"]],[30,[36,5],[[35,4],[32,1,[\"disabled\"]]],null],[30,[36,3],[[32,0],\"handleChange\",[32,2]],null]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,4,[[30,[36,1],null,[[\"option\",\"props\",\"index\"],[[32,3],[32,1],[32,2]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\",\"action\",\"disabled\",\"or\",\"let\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-field-checkboxes.hbs"
    }
  });

  _exports.default = _default;
});