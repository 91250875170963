import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthorizedRouteIntegrationIndex extends Route {
  @action
  refreshCurrentRoute() {
    this.refresh();
  }

  // makes sure anyone landing at /home is redirected to overview page
  beforeModel() {
    this.transitionTo('authorized-route.integration.overview'); 
  };
}
