define("@square/glass-ui/components/dialog-actions/button-back-arrow", ["exports", "@square/glass-ui/templates/components/dialog-actions/button-back-arrow"], function (_exports, _buttonBackArrow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _buttonBackArrow.default,
    classNames: ['dialog__header__previous', 'button--tertiary'],
    tagName: 'button',
    // Prevent from submitting forms via default `submit` type
    type: 'button',
    attributeBindings: ['type', 'data-test-dialog-header-back-button'],
    'data-test-dialog-header-back-button': true
  });

  _exports.default = _default;
});