define("@square/glass-ui/components/sq-card/attributes-list", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-list"], function (_exports, _attributesList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _attributesList.default,
    tagName: 'dl',
    classNames: ['card__attributes-list'],
    attributeBindings: ['data-test-card-attributes-list'],
    'data-test-card-attributes-list': true
  });

  _exports.default = _default;
});