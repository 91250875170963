define("@square/glass-ui/components/sq-draggable-list/handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['draggable__handle', 'draggable__handle--inline', 'draggable-list__handle'],
    classNameBindings: ['disabled:draggable__handle--disabled']
  });

  _exports.default = _default;
});