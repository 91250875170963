import { helper } from '@ember/component/helper';

interface ParseJsonStringArgumentObject {
  jsonString: string;
  key: string;
}

function extractStringFromObject(object: { [k: string]: any}, key: string): string {
  let string = '';
  if (key) {
    string = object[key];
  } else {
    Object.keys(object).forEach(key => {
      string += object[key] + '. ';
    })
  }
  return string;
}

export function parseJsonString(_params: any, {jsonString, key}: ParseJsonStringArgumentObject): string {
  const jsonObject = JSON.parse(jsonString);
  let parsedString = '';

  if (Array.isArray(jsonObject)) {
    jsonObject.forEach(object => {
      parsedString += extractStringFromObject(object, key);
    })
  } else {
    parsedString = extractStringFromObject(jsonObject, key);
  };

  return parsedString;
}

export default helper(parseJsonString);
