define("@square/glass-ui/templates/components/svg/icon-fullscreen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j3NDV/Mw",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M14 8H11H9V10V13H11V10H14V8ZM19 8H22H24V10V13H22V10H19V8ZM22 23H19V21H22V18H24V21V23H22ZM14 23H11H9V21V18H11V21H14V23Z\"],[14,\"fill-rule\",\"evenodd\"],[14,0,\"svg-icon__fill\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-fullscreen.hbs"
    }
  });

  _exports.default = _default;
});