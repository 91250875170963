import SyncEntityGroupStatus from '@bridge/home-engine/addon/models/services/sync-entity-group-status/sync-entity-group-status';
import Component from '@glimmer/component';

interface SyncDataBladeArgs {
  syncObject: SyncEntityGroupStatus;
}
type SyncState = 'SUCCESSFUL' | 'FAILED' | 'UNMAPPED' | 'MAPPED';

const SYNC_OBJECT_STATE_LABEL_MAP = {
  SUCCESSFUL: 'Successfully Synced',
  FAILED: 'Failed to Sync',
  UNMAPPED: 'Unmapped entity',
  MAPPED: 'Mapping Successful, awaiting sync',
  DELETED: 'Entity was deleted in target system.',
};

export default class SyncDataBlade extends Component<SyncDataBladeArgs> {
  get stateLabel() {
    return (
      SYNC_OBJECT_STATE_LABEL_MAP[this.args.syncObject.state as SyncState] ||
      'Invalid state'
    );
  }
}
