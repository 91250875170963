import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
/* @ts-ignore TSLint claims to not find service, but actually is. */
import { EngineRouterService } from 'ember-engines-router-service';

export default class Audit extends Controller {
  @service router!: EngineRouterService;

  @tracked state!: string;
  @tracked sync_type!: string;

  queryParams: Array<string> = ['sync_type', 'state'];

  @action
  refreshModel(): void {
    this.send('refreshCurrentRoute');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    audit: Audit;
  }
}
