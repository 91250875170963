import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { ExecutionType } from '../models/services/sync/sync-job';
import * as moment from 'moment';
import 'moment-timezone'
import { SyncWindow } from '../models/services/sync/sync-window';
import ModalService, { CloseHandler } from '@square/glass-ui/addon/services/modal';
import Data from 'bridge-dashboard/app/services/data';
import Location from '../models/services/location/location';
import SyncType from '../models/services/sync-type-status/sync-job-type';
import { MultiSelectOption } from './glass-multi-select';

export interface RunManualSyncResult {
  begin_date: string;
  end_date: string;
  timezone: string;
  execution_type: string;
  location_ids: string[];
}

export interface ManualSyncModalArgs extends Record<string, unknown> {
  syncJobType: string;
}

interface SyncTypeOption {
  label: string;
  value: ExecutionType;
  description: string;
}

const MANUAL_DATE_WINDOW: SyncTypeOption = {
  label: 'Custom Window',
  value: ExecutionType.BATCH_MANUAL_DATE_WINDOW,
  description: 'Includes data that has changed within the date window.',
};

const BATCH_INCREMENTAL_SYNC_TYPE: SyncTypeOption = {
  label: 'Latest Changes',
  value: ExecutionType.BATCH_INCREMENTAL,
  description: 'Includes objects that have changed since the last successful sync and retries the last 30 days of failures.',
};

const BATCH_FULL_SYNC_TYPE: SyncTypeOption = {
  label: 'Full Sync',
  value: ExecutionType.BATCH_ALL,
  description: 'All objects are resynced. This may take a long time. Use this option if your catalog is out of date after updating locations, price lists or tax relationships.',
};

const ALL_SYNC_TYPES = [
  BATCH_INCREMENTAL_SYNC_TYPE,
  BATCH_FULL_SYNC_TYPE,
  MANUAL_DATE_WINDOW
];

const ORDER_SYNC_TYPES = [
  BATCH_INCREMENTAL_SYNC_TYPE,
  MANUAL_DATE_WINDOW
];

const LOCATION_BASED_SYNCS = [
  SyncType.ORDERS,
  SyncType.INVENTORY_LEVELS,
  SyncType.INVENTORY_CHANGES,
  SyncType.BANK_TRANSFERS
]

type Args = {
  modalOptions: ManualSyncModalArgs;
  close: (value: RunManualSyncResult | false) => void;
  setCloseHandler: (closeHandler: CloseHandler<RunManualSyncResult | false>) => void;
}

export default class ManualSyncModal extends Component<Args> {
  @service declare modal: ModalService;
  @service declare data: Data;
  @service declare flash: any;

  @tracked selectedSyncType: SyncTypeOption = this.availableSyncTypes[0];
  @tracked isRunSyncDisabled = false;
  @tracked isLoadingLocations;

  timezone = moment.tz.guess();
  timezoneAbbr = moment.tz(moment(), this.timezone).zoneAbbr();
  window: SyncWindow = {
    begin: moment().startOf('day').subtract(5, 'days').startOf('day'),
    end: moment().endOf('day')
  };
  locations: MultiSelectOption[] = [];
  selectedLocationIds: string[] = [];

  constructor(owner:unknown, args: Args) {
    super(owner, args);
    this.isLoadingLocations = this.isLocationBasedSync;

    if (this.isLoadingLocations) {
      this.fetchLocations();
    }
  }

  get isLocationBasedSync() {
    return LOCATION_BASED_SYNCS.includes(this.args.modalOptions.syncJobType as SyncType);
  }

  get isFullSyncSelected() {
    return this.selectedSyncType === BATCH_FULL_SYNC_TYPE;
  }

  get availableSyncTypes(): SyncTypeOption[] {
    if (this.args.modalOptions.syncJobType === SyncType.ORDERS) {
      return ORDER_SYNC_TYPES;
    } else {
      return ALL_SYNC_TYPES;
    }
  }

  get manualSyncTypes(): SyncTypeOption[] {
    return this.availableSyncTypes.filter(
      (type) => type.value !== this.selectedSyncType.value
    );
  }

  get isCalendarSyncType() {
    return this.selectedSyncType.value === ExecutionType.BATCH_MANUAL_DATE_WINDOW;
  }

  @action
  selectSyncType(syncType: SyncTypeOption) {
    this.selectedSyncType = syncType;
  }

  @action
  onLocationChange(locationIds: string[]) {
    this.selectedLocationIds = locationIds;
    if (this.isLocationBasedSync && this.selectedLocationIds.length === 0) {
      this.isRunSyncDisabled = true;
    } else {
      this.isRunSyncDisabled = false;
    }
  }

  @action
  close() {
    this.args.close(false);
  }

  @action
  runSync() {
    this.args.close({
      begin_date: moment(this.window.begin).local().format("YYYY-MM-DD"),
      end_date: moment(this.window.end).local().format("YYYY-MM-DD"),
      timezone: this.timezone,
      execution_type: this.selectedSyncType.value,
      location_ids: this.selectedLocationIds
    });
  }

  private fetchLocations() {
    this.isRunSyncDisabled = true;
    this.data.getLocationsBySyncType(this.args.modalOptions.syncJobType)
      .then((locations: Location[]) => {
        this.locations = locations.map((location) => this.toMultiSelectOption(location));
        this.onLocationChange(locations.map((location: Location) => location.square_id));
        this.isLoadingLocations = false;
        this.isRunSyncDisabled = false;
      })
      .catch(() => {
        this.flash.globalError(
          'Unable to load locations.  Please try again later',
          {
            dismiss: () => {
              this.flash.clearGlobalMessage();
            },
          }
        );
        this.args.close(false);
      })
  }

  private toMultiSelectOption(location: Location): MultiSelectOption {
    return {
      value: location.square_id,
      name: location.square_name
    }
  }
}

declare module '@square/glass-ui/addon/services/modal' {
  interface Registry {
    ['manual-sync-modal']: ManualSyncModal;
  }
}
