define("@square/glass-ui/components/sq-card", ["exports", "@square/glass-ui/templates/components/sq-card", "@square/glass-ui/components/dialog-actions"], function (_exports, _sqCard, _dialogActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqCard.default,
    classNames: ['card'],
    classNameBindings: ['contextClass', 'isDisabled:card--is-disabled'],
    // Passed in:
    context: null,
    isDisabled: false,
    contextClass: Ember.computed('context', function () {
      switch (this.get('context')) {
        case _dialogActions.CONTEXT.BLADE:
          return 'card--in-blade';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});