import Component from '@ember/component';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { computed } from '@ember/object';

export default Component.extend({
  router: service(),
  item: null,
  children: [],

  isOpen: false,

  childContainerStyle: computed('isOpen', 'childHeightTotal', function() {
    const isOpen = this.isOpen;
    const childHeightTotal = this.childHeightTotal;
    return htmlSafe(`max-height: ${isOpen ? childHeightTotal : 0}px`);
  }),

  didInsertElement(...args: any[]) {
    this._super(...args);

    // @ts-ignore TODO: fixme
    this.router.on('routeDidChange', this, this.syncOpenState);
    scheduleOnce('afterRender', this, this.calculateChildHeight);
    this.syncOpenState();
  },

  willDestroyElement(...args: any[]) {
    this._super(...args);

    // @ts-ignore TODO: fixme
    this.router.off('routeDidChange', this, this.syncOpenState);
  },

  // after each route transition, ensure that this section is open or closed
  // based on the currently active route.
  syncOpenState() {
    // contextual learning: .mapBy is an Ember array method, and can trip up
    // typing and tests when a component is in isolation.
    // best to check the presence of an element first when performing array method
    // const childRoutes = this.children.mapBy('routeName');
    // const childRoutes = this.children.map((child) => child && child.routeName);
    const childRoutes = this.children.map((child: any) => child && child.route);
    const anyChildIsActive = childRoutes.some((route: { name: string}) => {
      if (route.name === 'catch_all') {
        return false;
      }

      // @ts-ignore TODO: fixme
      return this.router.isActive(route.name);
    });

    // @ts-ignore TODO: fixme
    this.set('anyChildIsActive', anyChildIsActive);
    this.set('isOpen', anyChildIsActive);
  },

  calculateChildHeight() {
    
    this.set(
      // @ts-ignore TODO: fixme
      'childHeightTotal',
      this.element.querySelector('.app-layout__sidenav-parent__children')!.scrollHeight
    );
  },

  actions: {
    toggle() {
      this.toggleProperty('isOpen');
    },
  },
});
