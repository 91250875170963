define("@square/glass-ui/components/focus-manager", ["exports", "@square/glass-ui/templates/components/focus-manager"], function (_exports, _focusManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _focusManager.default,
    focus: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('focusId', Ember.guidFor(this));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('focus').addFocusManager(this.get('focusId'), this.get('autofocusSelector'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('focus').removeFocusManager(this.get('focusId'));

      this._super.apply(this, arguments);
    },
    actions: {
      captureFocus: function captureFocus(which) {
        this.get('focus').captureFocus(this.get('focusId'), which);
      }
    }
  });

  _exports.default = _default;
});