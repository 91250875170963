define("@square/glass-ui/components/svg/icon-triple-dot", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-triple-dot"], function (_exports, _svgIcon, _iconTripleDot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconTripleDot.default,
    classNames: ['svg-icon--triple-dot'],
    width: 16,
    height: 16,
    viewBox: '0 0 16 16'
  });

  _exports.default = _default;
});