define("@square/glass-ui/components/sq-field-textarea", ["exports", "@square/glass-ui/utils/field-element-support"], function (_exports, _fieldElementSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MINIMUM_TEXTAREA_HEIGHT = 47;

  var _default = Ember.TextArea.extend(_fieldElementSupport.default, {
    autofocus: false,
    autoSize: false,
    classNames: 'form-field__textarea',
    classNameBindings: ['autoSize:form-field__textarea--autosize'],
    disabled: false,
    minHeight: MINIMUM_TEXTAREA_HEIGHT,
    // eslint-disable-next-line ember/no-on-calls-in-components
    runCaretAlignment: Ember.on('init', // eslint-disable-next-line ember/no-observers
    Ember.observer('value', function () {
      if (this.get('autoSize')) {
        Ember.run.scheduleOnce('afterRender', this, this.resizeTextAreaToContent);
      }
    })),
    resizeTextAreaToContent: function resizeTextAreaToContent() {
      if (this.get('state') === 'preRender' || this.get('state') === 'destroying') {
        return;
      }

      var minHeight = this.get('minHeight');
      this.get('element').style.height = "".concat(minHeight, "px");
      var contentHeight = this.get('element').scrollHeight;

      if (contentHeight > minHeight) {
        this.get('element').style.height = "".concat(contentHeight, "px");
      }
    }
  });

  _exports.default = _default;
});