define("@square/glass-ui/templates/components/more-actions-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iKQBrfno",
    "block": "{\"symbols\":[\"dropdown\",\"&default\"],\"statements\":[[6,[37,7],null,[[\"class\"],[[30,[36,6],[\"more-actions-menu \",[35,5]],null]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('@square/glass-ui/templates/components/more-actions-menu.hbs' @ L2:C5) \"],null]],[[\"class\"],[\"more-actions-menu__trigger\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"title\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,2,[[30,[36,4],null,[[\"popover\",\"close\"],[[32,1,[\"popover\"]],[32,1,[\"close\"]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"iconTitle\",\"svg/icon-triple-dot\",\"-assert-implicit-component-helper-argument\",\"component\",\"hash\",\"dropdownClass\",\"concat\",\"sq-dropdown\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/more-actions-menu.hbs"
    }
  });

  _exports.default = _default;
});