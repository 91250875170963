define("@square/glass-ui/components/select-all-checkbox-manager/list", ["exports", "@square/glass-ui/templates/components/select-all-checkbox-manager/list"], function (_exports, _list) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _list.default
  });

  _exports.default = _default;
});