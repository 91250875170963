import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { EngineRouterService } from 'ember-engines-router-service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
export default class AuthorizedRouteAcceptTerms extends Controller {
  @service router!: EngineRouterService;
  @service currentUser!: CurrentUser;

  @tracked termsAccepted: boolean = false;
  @tracked isSaveRequesting: boolean = false;

  @action
  handleTermsOfServiceChange(checkboxEvent: Event) {
    if ((<HTMLInputElement>checkboxEvent.target).checked) {
      this.termsAccepted = true;
    } else {
      this.termsAccepted = false;
    }
  }

  @action
  async handleAcceptTerms() {
    this.isSaveRequesting = true;
    const updatedUser = await this.currentUser.acceptTerms();

    if (updatedUser?.terms_accepted) {
      this.router.transitionTo('authorized-route.integration.overview');
    }
    this.isSaveRequesting = false;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/accept-terms': AuthorizedRouteAcceptTerms;
  }
}
