define("@square/glass-ui/components/sq-form", ["exports", "@square/glass-ui/templates/components/sq-form"], function (_exports, _sqForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqForm.default,
    tagName: 'form',
    classNameBindings: ['useTopLabelVariant:sq-form--top-label', 'useTopLabelVariant:responsive-columns'],
    // Passed in
    flashContext: null,
    useTopLabelVariant: false,
    submit: function submit(e) {
      // Prevent page refresh
      e.preventDefault();
      this.onSubmit();
    }
  });

  _exports.default = _default;
});