define("@square/glass-ui/components/sq-field-input-searchable", ["exports", "@square/glass-ui/templates/components/sq-field-input-searchable", "@square/glass-ui/utils/field-element-support"], function (_exports, _sqFieldInputSearchable, _fieldElementSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var KEY_CODE_TAB = 9;
  var KEY_CODE_ENTER = 13;
  var KEY_CODE_ESC = 27;
  var KEY_CODE_UP = 38;
  var KEY_CODE_DOWN = 40; // Adapted from Ember.Array.uniq

  function uniq(array) {
    var ret = [];
    var seen = new Set();

    var _iterator = _createForOfIteratorHelper(array),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var item = _step.value;

        if (!seen.has(item)) {
          seen.add(item);
          ret.push(item);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return ret;
  }

  var _default = Ember.Component.extend(_fieldElementSupport.default, {
    layout: _sqFieldInputSearchable.default,
    // saved refs to event handlers for easy removal
    boundHandleOutsideClick: null,
    boundHandleScroll: null,
    // passed in (required)
    options: null,
    value: null,
    // passed in (optional)
    standaloneStyling: false,
    isLoading: false,
    labelPath: 'label',
    placeholder: null,
    nullStateText: null,
    defaultOption: null,
    isAdditive: false,
    groupOptionsBy: null,
    formatter: null,
    syncFilterOnClose: true,
    alwaysOpenIfActive: false,
    clearFilterOnClose: false,
    // actions passed in (required)
    valueSelected: null,
    // actions passed in (optional)
    inputChanged: null,
    inputCancelled: null,
    dropdownOpened: null,
    dropdownClosed: null,
    dropdownScrolledToBottom: null,
    classNames: ['form-field-input-searchable'],
    classNameBindings: ['isActive:form-field-input-searchable--is-active', 'isOpen:form-field-input-searchable--is-open', 'standaloneStyling:form-field-input-searchable--standalone', 'hasEnteredAdditiveOption:form-field-input-searchable--has-entered-custom-value'],
    willDestroyElement: function willDestroyElement() {
      this._super(); // to clean up state / event handlers if the component is being removed by parent component logic


      document.removeEventListener('click', this.boundHandleOutsideClick);

      if (this.popover) {
        this.popover.removeEventListener('scroll', this.boundHandleScroll);
      }

      this.element.removeEventListener('mouseleave', this._onMouseLeave);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();

      var value = this.get('value');
      this.boundHandleOutsideClick = this.handleOutsideClick.bind(this);
      this.boundHandleScroll = this.onScroll.bind(this);

      if (value) {
        this.set('filter', Ember.get(value, this.get('labelPath')));
        this.set('highlightedOption', value);
      }

      this.set('input', this.element.querySelector('.form-field-input-searchable__input'));
      this.set('popover', this.element.querySelector('.form-field-input-searchable__popover'));

      if (this.popover) {
        this.popover.addEventListener('scroll', this.boundHandleScroll);
      }

      this._onMouseLeave = function () {
        return _this.set('highlightedOption', null);
      };

      this.element.addEventListener('mouseleave', this._onMouseLeave);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      var _this$getProperties = this.getProperties('value', 'previousValue'),
          value = _this$getProperties.value,
          previousValue = _this$getProperties.previousValue;

      if (value !== previousValue) {
        if (value) {
          this.set('filter', Ember.get(value, this.get('labelPath')));
        } else {
          this.set('filter', '');
        }
      }

      this.set('previousValue', value);

      this._setInitialHighlightedOption();
    },
    _setInitialHighlightedOption: function _setInitialHighlightedOption() {
      var highlightedOption;

      if (this.get('highlightDefaultOptionEnabled')) {
        highlightedOption = this.get('defaultOption');
      } else if (this.get('highlightFirstOptionEnabled')) {
        highlightedOption = this.get('optionsList')[0];
      } else {
        return;
      }

      this.set('highlightedOption', highlightedOption);
    },
    highlightDefaultOptionEnabled: Ember.computed('defaultOption', 'shouldHighlightDefaultOption', 'optionsList.[]', function () {
      var defaultOption = this.get('defaultOption');
      var shouldHighlightDefaultOption = defaultOption && this.get('shouldHighlightDefaultOption');

      if (shouldHighlightDefaultOption) {
        var renderedOptions = this.get('optionsList');

        if (!(renderedOptions && renderedOptions.includes(defaultOption)) && window.console) {
          // We need to use console.warn() because Ember.Logger.warn is deprecated
          // as of Ember 3.2.
          window.console.warn('Cannot highlight defaultOption; does not exist in the optionsList');
        }
      }

      return shouldHighlightDefaultOption;
    }),
    highlightFirstOptionEnabled: Ember.computed('optionsList.[]', 'shouldHighlightFirstOption', function () {
      return !Ember.isEmpty(this.get('optionsList')) && this.get('shouldHighlightFirstOption');
    }),
    // internal state
    filter: null,
    isOpen: Ember.computed('alwaysOpenIfActive', 'filter.length', 'isActive', 'isLoading', 'optionsList.length', function () {
      return this.get('isActive') && (this.get('filter.length') > 0 || this.get('optionsList.length') > 0 || this.get('isLoading') || this.alwaysOpenIfActive);
    }),
    isActive: false,
    previousValue: Ember.computed.reads('value'),
    shouldShowAdditiveOption: function shouldShowAdditiveOption() {
      /*
         NB: This is intentionally not a computed property.  'optionsList' is a caller,
         which encourages the adding of 'shouldShowAdditiveOption' as a dependency to
         'optionsList'.  It then becomes easy to create a dependency chain on something
         like 'filter' which would invalidate 'optionsList' on every keypress.
          This leads to performance difficulties on lists of options of size, say, 1000.
          Further, 'shouldShowAdditiveOption' doesn't need to be a computed property
         because the properties it could depend on are: 'filter', 'options', 'isAdditive',
         'labelPath'.
          Since changes to 'filter' cause changes to 'options' (newly allocated memory,
         not necessarily different contents), a dependency on 'filter' is redundant.
         'filter' invalidates on every keypress anyways, so this would never return a
         cached result.
          Similarly, since 'optionsList' is the only thing that checks this and 'optionsList'
         already depends on 'options', there's no need for that here either.
          Finally, we only care about changes to 'isAdditive' and 'labelPath' when
         we want to process changes to the list of options.
          This can simply be a function.
      */
      if (!this.get('isAdditive')) {
        return false;
      }

      var filter = this.get('filter');

      if (Ember.isBlank(filter)) {
        return false;
      }

      var lowerCaseFilter = filter.toLowerCase();
      var label = this.get('labelPath'); // don't include additive option if filter has match found in options

      var filterMatchesOption = (this.get('options') || []).filter(Ember.isPresent).find(function (item) {
        var itemName = Ember.get(item, label);

        if (Ember.isBlank(itemName)) {
          return false;
        }

        return itemName.toLowerCase() === lowerCaseFilter;
      });
      return !filterMatchesOption;
    },
    shouldShowCaret: Ember.computed('filter', 'isOpen', 'hasEnteredAdditiveOption', 'isReadOnly', function () {
      return !Ember.isBlank(this.get('filter')) && !this.get('isOpen') && !this.get('hasEnteredAdditiveOption') && !this.get('isReadOnly');
    }),
    optionsList: Ember.computed('options.[]', '_internallyFilteredOptions.[]', function () {
      var optionsToRender = [];
      var options = this.get('options'); // If we've filtered internally using defaultFilter to narrow things down, render that list

      if (this.get('_internallyFilteredOptions')) {
        optionsToRender = this.get('_internallyFilteredOptions'); // If the component has options passed in, use that list
      } else if (options) {
        optionsToRender = options;
      }

      if (this.shouldShowAdditiveOption() && !options.some(function (option) {
        return option.isAdditive;
      })) {
        return [{
          isAdditive: true
        }].concat(_toConsumableArray(optionsToRender));
      } else {
        return optionsToRender;
      }
    }),
    formattedOptions: Ember.computed('optionsList.[]', 'groupOptionsBy', function () {
      var groupOptionsBy = this.get('groupOptionsBy');
      var options = this.get('optionsList'); // If no "groupOptionsBy" header prop is specified
      // If there are no options, make sure to return a single empty group.

      if (Ember.isEmpty(groupOptionsBy) || options.length === 0) {
        return [{
          options: options
        }];
      } // Map options to groups by specified property


      var headersMap = uniq(options.map(function (option) {
        return Ember.get(option, groupOptionsBy);
      })); // Reduce the subsequent "headers" array into an array of groups with
      // headers and options. Sort options with no header to the top of the
      // options list.

      return headersMap.reduce(function (groups, header) {
        groups.push({
          header: header,
          options: options.filter(function (option) {
            return Ember.get(option, groupOptionsBy) === header;
          })
        });
        return groups;
      }, []).sort(function (groupA, groupB) {
        if (!groupA.header) {
          return -1;
        }

        if (!groupB.header) {
          return 1;
        }

        return 0;
      });
    }),
    shouldHighlightFirstOption: Ember.computed.not('defaultOption'),
    highlightedOption: null,
    // event handlers
    keyDown: function keyDown(e) {
      switch (e.keyCode) {
        case KEY_CODE_DOWN:
          this.highlightNext(e);
          break;

        case KEY_CODE_UP:
          this.highlightPrevious(e);
          break;

        case KEY_CODE_ENTER:
          this.enterPressed(e);
          break;

        case KEY_CODE_TAB:
          this.closeDropdown();

          if (this.componentBlurred) {
            this.componentBlurred();
          }

          if (this.get('syncFilterOnClose')) {
            this.syncFilter();
          }

          break;

        case KEY_CODE_ESC:
          if (this.get('isActive')) {
            if (this.get('inputCancelled')) {
              this.applyFilter('');

              if (this.inputCancelled) {
                this.inputCancelled();
              }
            } else {
              if (this.componentBlurred) {
                this.componentBlurred();
              }

              if (this.get('syncFilterOnClose')) {
                this.syncFilter();
              }
            }

            this.closeDropdown();
            e.stopPropagation();
          }

          break;

        default:
          this.openDropdown();
      }
    },
    // dropdown management
    openDropdown: function openDropdown() {
      if (this.get('isActive')) {
        return;
      }

      if (this.dropdownOpened) {
        this.dropdownOpened();
      }

      this.set('isActive', true);
      Ember.run.scheduleOnce('afterRender', this, this.selectSearchableInput);
      document.addEventListener('click', this.boundHandleOutsideClick);
    },
    selectSearchableInput: function selectSearchableInput() {
      this.element.querySelector('.form-field-input-searchable__input').select();
    },
    handleOutsideClick: function handleOutsideClick(event) {
      // Handle clicks outside the dropdown by committing the selection and then closing the dropdown
      if (this.element !== event.target && !this.element.contains(event.target)) {
        this.closeDropdown();

        if (this.componentBlurred) {
          this.componentBlurred();
        }

        if (this.get('syncFilterOnClose')) {
          this.syncFilter();
        }
      }
    },
    closeDropdown: function closeDropdown() {
      if (this.dropdownClosed) {
        this.dropdownClosed();
      }

      document.removeEventListener('click', this.boundHandleOutsideClick); // This isn't likely to happen, but it's here as a safeguard incase the parent component
      // destroys this component after a selection has been made.

      if (this.isDestroyed) {
        return;
      }

      if (this.clearFilterOnClose) {
        this.set('filter', '');
      }

      this.set('isActive', false);
    },
    // selection management
    commitSelection: function commitSelection(option) {
      var _this2 = this;

      if (!option) {
        if (this.get('value')) {
          this.set('filter', Ember.get(this.get('value'), this.get('labelPath')));
        } else {
          this.set('filter', '');
        }

        return;
      }

      var selectedAdditiveOption = Ember.get(option, 'isAdditive');
      this.set('hasEnteredAdditiveOption', selectedAdditiveOption);

      if (this.get('allowDisabled') && !selectedAdditiveOption && Ember.get(option, 'isDisabled')) {
        return;
      }

      if (this.get('isAdditive') && selectedAdditiveOption) {
        // `createNewOption` can either return Promises or values directly
        var newFilterPromise = Ember.RSVP.resolve(this.get('createNewOption')(this.get('filter')));
        newFilterPromise.then(function (newFilter) {
          if (!Ember.isNone(newFilter)) {
            _this2.set('filter', newFilter);
          }
        });
        return;
      } // NOTE: in theory this shouldn't be necessary. We already have a hook to set filter on value changes in didReceiveAttrs
      // For some reason it doesn't seem to call the method every time it should, and it doesn't work.
      // Need to come back and do more debugging here to be able to remove these lines


      var newValue = Ember.get(option, this.get('labelPath'));
      this.set('filter', newValue);

      if (this.valueSelected) {
        this.valueSelected(option);
      }
    },
    enterPressed: function enterPressed(e) {
      if (this.get('isOpen')) {
        /*
         * It is possible that the current "highlightedOption" is invalid, eg. the user types and then
         * presses enter before loading finishes. In this case the previous highlighted option will
         * still be stored in the property, and will be selected. We actually want to invalidate the
         * previous highlight option in this case (eg. no longer in the optionsList array) and
         * fall back to the defaultOption (if present).
         */
        var highlightedOption = this.get('highlightedIndex') >= 0 ? this.get('highlightedOption') : null;
        var option = highlightedOption || this.get('defaultOption');
        this.commitSelection(option);
        this.closeDropdown();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    // selection management
    highlightedIndex: Ember.computed('optionsList.[]', 'highlightedOption', function () {
      return this.get('optionsList').indexOf(this.get('highlightedOption'));
    }),
    highlightIndex: function highlightIndex(index) {
      var option = this.get('optionsList')[index];
      this.set('highlightedOption', option);
    },
    highlightNext: function highlightNext(e) {
      this.openDropdown();

      if (this.get('highlightedIndex') < 0) {
        this.highlightIndex(0);
        e.stopPropagation();
        e.preventDefault();
        return;
      }

      var nextIndex = this.get('highlightedIndex') + 1;

      if (nextIndex < this.get('optionsList.length')) {
        this.highlightIndex(nextIndex);
        e.stopPropagation();
        e.preventDefault();
        Ember.run.scheduleOnce('afterRender', this, this.trackHighlighted);
      }
    },
    highlightPrevious: function highlightPrevious(e) {
      var previousIndex = this.get('highlightedIndex') - 1;

      if (previousIndex > -1) {
        this.highlightIndex(previousIndex);
        e.stopPropagation();
        e.preventDefault();
        Ember.run.scheduleOnce('afterRender', this, this.trackHighlighted);
      }
    },
    // Sync the filter to the current value.
    // If possible, add the text as a new option.
    // Otherwise, reset the text to the previous value.
    syncFilter: function syncFilter() {
      var currentLabel = this.get('value') ? Ember.get(this.get('value'), this.get('labelPath')) : '';
      var hasNewValue = !Ember.isEmpty(this.get('filter')) && this.get('filter') !== currentLabel;

      if (hasNewValue && this.get('optionsList.length') > 0) {
        this.commitSelection(this.get('optionsList')[0]);
      } else if (this.get('inputChanged')) {
        this.set('filter', currentLabel);
      } else {
        this.applyFilter(currentLabel);
      } // When the filter is next opened, all the options should be visible, even if something was
      // already selected.


      this.set('_internallyFilteredOptions', null);
    },
    trackHighlighted: function trackHighlighted() {
      var highlighted = document.querySelector('.form-field-input-searchable__option--is-highlighted');

      if (!this.get('isActive') || !highlighted) {
        return;
      }

      var pane = this.element.querySelector('.form-field-input-searchable__popover');
      var paneHeight = pane.offsetHeight;
      var highlightedOptionHeight = highlighted.offsetHeight;
      var highlightedTop = highlighted.offsetTop;
      var highlightedBottom = highlightedTop + highlightedOptionHeight;

      if (highlightedBottom > paneHeight || highlightedTop < 0) {
        highlighted.scrollIntoView();
      }
    },
    // search/filtering logic
    applyFilter: function applyFilter(filter) {
      var _this3 = this;

      var options = this.get('options');
      var labelPath = this.get('labelPath');

      var format = function format(text) {
        var lowerCased = text.toLowerCase();

        if (_this3.get('formatter')) {
          return _this3.get('formatter').apply(lowerCased);
        } else {
          return lowerCased;
        }
      };

      var formattedInput = format(filter);
      var filterLength = formattedInput.length;
      var filtered = options.filter(function (option) {
        var value = format(Ember.get(option, labelPath));
        return value.slice(0, filterLength) === formattedInput;
      });
      this.setProperties({
        _internallyFilteredOptions: filtered,
        filter: filter
      });

      this._setInitialHighlightedOption();
    },
    onScroll: function onScroll() {
      if (this.get('isLoading')) {
        return;
      }

      if (!this.get('dropdownScrolledToBottom')) {
        return;
      }

      var popover = this.get('popover');
      var remainingScrollHeight = popover.scrollHeight - popover.scrollTop;
      var popoverHeight = popover.clientHeight; // When certain browsers are zoomed, subpixels need to be accounted for, so we need to provide a 1 pixel buffer
      // https://bugs.chromium.org/p/chromium/issues/detail?id=890345

      var scrollBarAtBottom = Math.abs(remainingScrollHeight - popoverHeight) <= 1;

      if (scrollBarAtBottom && this.dropdownScrolledToBottom) {
        this.dropdownScrolledToBottom();
      }
    },
    actions: {
      open: function open() {
        this.openDropdown();
      },
      filterChanged: function filterChanged(value) {
        if (this.inputChanged) {
          this.inputChanged(value);
        } else {
          this.applyFilter(value);
        }

        if (this.get('popper')) {
          // Update Popper instance  so it can reposition based on
          // new filtered list height. This is especially important
          // if the list is currently flipped on top of the input.
          this.get('popper').scheduleUpdate();
        }
      },
      optionClicked: function optionClicked(option) {
        this.commitSelection(option);
        this.closeDropdown();
      }
    }
  });

  _exports.default = _default;
});