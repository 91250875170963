define("@square/glass-ui/utils/field-element-support", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Mixin.create({
    isDisabled: Ember.computed.alias('disabled'),
    isFocused: false,
    isInvalid: Ember.computed.alias('invalid'),
    isReadOnly: Ember.computed.alias('readOnly'),
    parentLabel: Ember.computed('element', function () {
      // eslint-disable-next-line ember/no-jquery
      return (0, _jquery.default)(this.element).parents('.form-field__content').siblings('.form-field__label');
    }),
    parentField: null,
    // `{{sq-form-field}}` applies the invalid CSS class at the outer field level,
    // so we don't want to apply the default `input-is-invalid` class here.
    invalidClass: null,
    init: function init() {
      this._super.apply(this, arguments); // managedFields is only a property when the parent view is a Form Field component. While 95% of the time we expect that
      // this is the case, we still want components that use this mixin to work independently and not break.
      // The parent field can also be passed in explicitly


      var parentField = this.get('parentField');

      if (!parentField && _typeof(this.get('parentView.managedFields')) === 'object') {
        parentField = this.get('parentView');
        this.set('parentField', parentField);
      }

      if (parentField) {
        parentField.addManagedField(this);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // If there is no parent field, don't try to interact with it at all


      if (!this.get('parentField')) {
        return;
      } // If this is already set then there are multiple inputs, so we just return and
      // allow the first input to continue receiving focus


      if (this.get('parentField.innerElementId')) {
        return;
      } // eslint-disable-next-line ember/no-jquery


      var childId = (0, _jquery.default)(this.element).children().filter(':input').attr('id');

      if (childId) {
        this.set('parentField.innerElementId', childId);
      } else if (['input', 'textarea', 'select'].includes(this.get('tagName'))) {
        this.set('parentField.innerElementId', this.get('elementId')); // TODO (nschaden): is traversing DOM with jQuery to find a label and add a click event efficient?
      } else {
        var inputs = (0, _jquery.default)(this.element).find('button,input,textarea,select'); // eslint-disable-line ember/no-jquery

        if (inputs.length > 0 && this.get('parentLabel.length')) {
          var firstInput = inputs.first(); // [UI-28] This check prevents clicking label from toggling first checkbox in a form field

          if (!firstInput.is('input[type=checkbox]')) {
            this.set('parentField.innerElementId', firstInput.attr('id'));
          }

          this.get('parentLabel').on('click.labelOverride', function () {
            return firstInput.focus();
          });
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('parentLabel.length')) {
        this.get('parentLabel').off('click.labelOverride');
      }

      var parentField = this.get('parentField');

      if (parentField) {
        parentField.removeManagedField(this);
      }
    },
    focusIn: function focusIn() {
      this.set('isFocused', true);

      if (this.onFocusIn) {
        this.onFocusIn.apply(this, arguments);
      }
    },
    focusOut: function focusOut() {
      this.set('isFocused', false);

      if (this.onFocusOut) {
        this.onFocusOut.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});