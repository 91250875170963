define("@square/glass-ui/components/sq-input-calendar", ["exports", "@square/glass-ui/templates/components/sq-input-calendar", "moment"], function (_exports, _sqInputCalendar, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALL_TIME = void 0;
  var MODE = {
    RANGE: 'RANGE',
    SINGLE: 'SINGLE'
  };
  var ALL_TIME = {
    ALL_TIME: true
  };
  _exports.ALL_TIME = ALL_TIME;

  var _default = Ember.Component.extend({
    layout: _sqInputCalendar.default,
    classNames: ['input-calendar', 'grid-row'],
    classNameBindings: ['_isInvalid', 'inputClassNames'],
    count: 1,
    mode: MODE.RANGE,
    // These need to default to undefined instead of null because they can be passed to moment() and...
    // moment(null) -> invalid date
    // moment(undefined) -> A valid date
    beginDate: undefined,
    endDate: undefined,
    selectedDate: Ember.computed.alias('beginDate'),
    disabledRange: null,
    // bind anything to isToggled to reset the click counter, e.g. when a calendar popover is dismissed
    isToggled: null,
    // not a computed property because we use to navigate months
    visibleMonth: null,
    // for setting ranges
    _firstClick: true,
    // for suppressing month navigation on click
    _lockVisibleMonth: false,
    // eslint-disable-next-line ember/no-on-calls-in-components
    beginDateDidChange: Ember.on('init', // eslint-disable-next-line ember/no-observers
    Ember.observer('beginDate', function () {
      // don't jump around when we're clicking
      if (this._lockVisibleMonth) {
        return;
      }

      if (this.get('beginDate') === ALL_TIME) {
        return;
      }

      this.set('visibleMonth', (0, _moment.default)(this.get('beginDate')).startOf('month').toDate());
    })),
    // eslint-disable-next-line ember/no-observers
    isToggledDidChange: Ember.observer('isToggled', function () {
      this._firstClick = true;

      if (this.get('beginDate') === ALL_TIME) {
        // set to current month if ALL_TIME
        this.set('visibleMonth', (0, _moment.default)().startOf('month').toDate());
      } else {
        this.set('visibleMonth', (0, _moment.default)(this.get('beginDate')).startOf('month').toDate());
      }
    }),
    months: Ember.computed('_months.length', 'canPageBack', 'canPageForward', 'count', 'visibleMonth', function () {
      // we'll cache these for more efficient views and stable DOM elements
      if (!this._months) {
        Ember.set(this, '_months', []); // eslint-disable-line ember/no-side-effects
      }

      var _this$getProperties = this.getProperties('count', 'visibleMonth'),
          count = _this$getProperties.count,
          visibleMonth = _this$getProperties.visibleMonth;

      if (!(count && visibleMonth)) {
        return this._months;
      }

      this.set('_months.length', count); // eslint-disable-line ember/no-side-effects

      var calendarWidth = count === 1 ? 'grid-col-24-24' : "grid-col-".concat(Math.floor(24 / count), "-24");

      for (var monthIndex = 0; monthIndex < count; monthIndex++) {
        if (!this._months[monthIndex]) {
          this._months[monthIndex] = Ember.Object.create(); // eslint-disable-line ember/no-side-effects
        }

        var beginDate = (0, _moment.default)(visibleMonth).add(monthIndex, 'months').toDate();
        var endDate = (0, _moment.default)(beginDate).endOf('month').startOf('day').toDate(); // eslint-disable-next-line ember/no-side-effects

        this._months[monthIndex].setProperties({
          month: beginDate,
          range: {
            beginDate: beginDate,
            endDate: endDate
          },
          isFirstObject: monthIndex === 0,
          isLastObject: monthIndex === count - 1,
          calendarWidth: calendarWidth,
          canPageBack: monthIndex === 0 && this.get('canPageBack'),
          canPageForward: monthIndex === count - 1 && this.get('canPageForward')
        });
      }

      return this._months;
    }),
    _moveVisible: function _moveVisible(dir) {
      var visibleMonth = (0, _moment.default)(this.get('visibleMonth')).add(dir, 'months').toDate();
      this.set('visibleMonth', visibleMonth);
    },
    _withLockedVisibleMonth: function _withLockedVisibleMonth(cb) {
      this._lockVisibleMonth = true;
      var ret = cb();
      this._lockVisibleMonth = false;
      return ret;
    },
    _selectDate: function _selectDate(date) {
      if ((0, _moment.default)().isBefore(date) && !this.get('allowFutureSelections')) {
        return false;
      }

      if (!this.get('allowPastSelections') && (0, _moment.default)().startOf('day').isAfter(date)) {
        return false;
      }

      var disabledRange = this.get('disabledRange');

      if (disabledRange && disabledRange.beginDate <= date && date <= disabledRange.endDate) {
        return false;
      }

      if (this.get('mode') === MODE.SINGLE) {
        this.set('selectedDate', date);

        if (this.action) {
          this.action(date);
        }
      } else {
        if (this._firstClick) {
          this.setProperties({
            beginDate: date,
            endDate: date
          });

          if (this.action) {
            this.action({
              beginDate: date,
              endDate: date
            });
          }
        } else {
          var times = [this.get('beginDate').getTime(), date.getTime()];
          var begin = Math.min(times[0], times[1]);
          var end = Math.max(times[0], times[1]);
          this.setProperties({
            beginDate: new Date(begin),
            endDate: new Date(end)
          });

          if (this.action) {
            this.action({
              beginDate: new Date(begin),
              endDate: new Date(end)
            });
          }
        }

        this._firstClick = !this._firstClick; // TODO: time out clicks so single day selections "stick"? (maybe only on mouseout)
        // TODO: highlight the range that will be selected if clicked?
      }

      return false;
    },
    // bind to this to show/hide the < date nav
    canPageBack: Ember.computed('allowPastPageBack', 'visibleMonth', function () {
      return this.get('allowPastPageBack') || (0, _moment.default)(this.get('visibleMonth')).isAfter((0, _moment.default)());
    }),
    // bind to this to show/hide the > date nav; by default no paging forward
    canPageForward: Ember.computed('allowFuturePageForward', 'visibleMonth', function () {
      return this.get('allowFuturePageForward') || (0, _moment.default)(this.get('visibleMonth')).add(1, 'months').isBefore((0, _moment.default)());
    }),
    // bind to this to override default "yes past" config
    allowPastSelections: true,
    allowPastPageBack: true,
    // bind to this to override default "no future" config
    allowFutureSelections: false,
    allowFuturePageForward: false,
    actions: {
      selectRange: function selectRange(range) {
        var _this = this;

        this._withLockedVisibleMonth(function () {
          if (_this.get('mode') === MODE.SINGLE) {
            _this._selectDate(range.beginDate);
          } else {
            _this.setProperties(range);

            if (_this.action) {
              _this.action(range);
            }
          }
        });
      },
      selectDate: function selectDate(date) {
        var _this2 = this;

        this._withLockedVisibleMonth(function () {
          _this2._selectDate(date);
        });
      },
      pageBack: function pageBack() {
        if (this.get('canPageBack')) {
          this._moveVisible(-1);
        }

        return false;
      },
      pageForward: function pageForward() {
        if (this.get('canPageForward')) {
          this._moveVisible(1);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});