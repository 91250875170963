define("@square/glass-ui/components/sq-card/header/title", ["exports", "@square/glass-ui/templates/components/sq-card/header/title"], function (_exports, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _title.default,
    tagName: 'h3',
    classNames: ['card__header__title'],
    title: null
  });

  _exports.default = _default;
});