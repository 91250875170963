import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import KeyboardEventCodes from '../../utils/constants/keyboard-event-codes';
import makeKeyDownListenerMap from '../../utils/make-key-down-listener-map';
import { scrollIntoViewWithinParent } from '../../utils/scroll-into-view';

export interface ListSelectionsDidChangeEvent {
  // newSelection: HTMLMarketRowElement;
  // selections: HTMLMarketRowElement[];
  newDeselection: Element;
  newSelection: any;
  selections: any[];
}

export const LIST_OPTION_HIGHLIGHTED_CLASS =
  'market-searchable-dropdown-row--highlighted';

interface MarketSearchableComponentArgs {
  isLoading: boolean;
  hasError: boolean;
  label: string;
  query: string;
  value: string;
  listOptions: [];
  listOptionValuePath: string;
  listCreateEnabled: boolean;
  listCreateLabel: string;
  leadingCreateRow: boolean;
  listContainerClass: string;
  onInputChange(value: string): void;
  onInputFocus(): void;
  onInputBlur(): void;
  onArrowDownKey(): void;
  onArrowUpKey(): void;
  onEnterKey(success: boolean): void;
  onEscapeKey(): void;
  onListChange(value: string): void;
  onListCreate(value: string): void;
  onTabKey(event: KeyboardEvent): void;
  useInputKeyboardShortcuts?: boolean;
}

/**
 * Represents a component with a searchable input and keyboard navigation.
 *
 * @export
 * @class MarketSearchableComponent
 * @extends {Component<MarketSearchableComponentArgs>}
 */
export default class MarketSearchableComponent extends Component<MarketSearchableComponentArgs> {
  @tracked highlightedIndex = 0;
  keydownListener?: (this: Window, event: KeyboardEvent) => void;

  inputElement!: Element;
  listOptionHighlightedClass = LIST_OPTION_HIGHLIGHTED_CLASS;

  get options(): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    highlighted: boolean;
    isCreateRow: boolean;
  }[] {
    if (!this.args.listOptions) {
      return [];
    }

    let options = this.args.listOptions.map(
      (listOption: Record<string, string> | null) => {
        return {
          value: listOption,
          highlighted: false,
          isCreateRow: false,
        };
      }
    );

    if (this.args.listCreateEnabled && this.args.listCreateLabel) {
      const createRow = {
        value: null,
        highlighted: false,
        isCreateRow: true,
      };
      options = this.args.leadingCreateRow
        ? [createRow, ...options]
        : [...options, createRow];
    }

    if (options[this.highlightedIndex]) {
      options[this.highlightedIndex].highlighted = true;
    }

    return options;
  }

  willDestroy(): void {
    super.willDestroy();
    this.removeListeners();
  }

  removeListeners(): void {
    if (this.keydownListener) {
      window.removeEventListener('keydown', this.keydownListener);
    }
  }

  @action
  resetHighlightedIndex(): void {
    this.highlightedIndex = 0;
  }

  scrollHighlightedRowIntoView(): void {
    scrollIntoViewWithinParent(
      `.${LIST_OPTION_HIGHLIGHTED_CLASS}`,
      `.${this.args.listContainerClass}`
    );
  }

  selectPrevious(): void {
    if (this.shouldIgnoreInput()) {
      return;
    }

    // Decrement the index, stopping at 0
    this.highlightedIndex = Math.max(0, this.highlightedIndex - 1);
    this.scrollHighlightedRowIntoView();
  }

  selectNext(): void {
    if (this.shouldIgnoreInput()) {
      return;
    }

    // Increment the index, stopping at the last item in the list (no
    // circular wrapping).
    this.highlightedIndex = Math.min(
      this.options.length === 0 ? 0 : this.options.length - 1,
      this.highlightedIndex + 1
    );
    this.scrollHighlightedRowIntoView();
  }

  onTabKey(event: KeyboardEvent): void {
    this?.args?.onTabKey?.(event);
    if (event.shiftKey) {
      this.selectPrevious();
    } else {
      this.selectNext();
    }
  }

  onEnterKey(): void {
    if (this.shouldIgnoreInput()) {
      this?.args?.onEnterKey?.(false);
    }

    // If 'Enter' is clicked with no options rendered, and the option to create
    // is displayed, we treat this as clicking it.
    const selectedOption = this.options[this.highlightedIndex];
    if (!selectedOption) {
      this?.args?.onEnterKey(false);
      return;
    }

    if (selectedOption.isCreateRow) {
      this.args?.onListCreate?.(this.args.query);
      this.args?.onEnterKey?.(true);
    } else {
      // Trigger the `onListChange` handler for the currently highlighted row.
      const newValue = selectedOption.value[this.args.listOptionValuePath];

      this.args?.onListChange?.(newValue);
      this.args?.onInputBlur?.();
      this.args?.onEnterKey?.(true);
    }
  }

  shouldIgnoreInput(): boolean {
    return this.isDestroyed || this.isDestroying;
  }

  @action
  onMarketInputBlur(event: FocusEvent): void {
    const element = event.relatedTarget as HTMLInputElement;
    // Ignore events triggered by clicks on popover rows as those
    // will be handled by the the change/create handlers.
    if (element?.localName !== 'market-row') {
      this.args?.onInputBlur?.();
      this.highlightedIndex = -1;
    }
  }

  @action
  onMarketInputChange(event: CustomEvent): void {
    this.args?.onInputChange?.(event.detail.value.trim());
    this.resetHighlightedIndex();
  }

  @action
  onMarketInputKeydown(event: KeyboardEvent): void {
    if (!this.args.useInputKeyboardShortcuts) {
      return;
    }

    const { key } = event;
    switch (key) {
      case KeyboardEventCodes.ArrowDown:
        this.onArrowDownKey();
        break;
      case KeyboardEventCodes.ArrowUp:
        this.onArrowUpKey();
        break;
      case KeyboardEventCodes.Enter:
        this.onEnterKey();
        break;
      case KeyboardEventCodes.Escape:
        this.onEscapeKey();
        break;
      default:
        break;
    }
  }

  @action
  onMarketListSelectionsDidChange(
    event: CustomEvent<ListSelectionsDidChangeEvent>
  ): void {
    if (event.detail.newDeselection) {
      return;
    }

    const newValue = event.detail.newSelection.value;
    if (!newValue) {
      this.args?.onListCreate?.(this.args.query);
      return;
    }
    this.args?.onListChange?.(newValue);
  }

  onArrowDownKey(): void {
    this.args?.onArrowDownKey?.();
    this.selectNext();
  }

  onArrowUpKey(): void {
    this.args?.onArrowUpKey?.();
    this.selectPrevious();
  }

  onEscapeKey(): void {
    this.args?.onEscapeKey?.();
  }

  @action
  attachKeyDownListeners(): void {
    if (this.args.useInputKeyboardShortcuts) {
      return;
    }

    this.keydownListener = makeKeyDownListenerMap({
      [KeyboardEventCodes.ArrowDown]: this.onArrowDownKey.bind(this),
      [KeyboardEventCodes.ArrowUp]: this.onArrowUpKey.bind(this),
      [KeyboardEventCodes.Tab]: this.onTabKey.bind(this),
      [KeyboardEventCodes.Enter]: this.onEnterKey.bind(this),
      [KeyboardEventCodes.Escape]: this.onEscapeKey.bind(this),
    });
    window.addEventListener('keydown', this.keydownListener);
  }
}
