define("@square/glass-ui/components/deprecated/sheet-layout", ["exports", "@square/glass-ui/templates/components/deprecated/sheet-layout"], function (_exports, _sheetLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var START_POSITION_CLASS = 'sheet-layout--start-position';

  var _default = Ember.Component.extend({
    layout: _sheetLayout.default,
    screen: Ember.inject.service(),
    flash: Ember.inject.service(),
    classNames: ['sheet-layout'],
    classNameBindings: ['isWidthNormal:sheet-layout--width-normal', 'isWidthWide:sheet-layout--width-wide'],
    attributeBindings: ['isTransitioning:data-sheet-is-transitioning'],
    confirmButton: null,
    contentDisabled: false,
    contentWidth: 'normal',
    isWidthNormal: Ember.computed.equal('contentWidth', 'normal'),
    isWidthWide: Ember.computed.equal('contentWidth', 'wide'),
    hasCustomizedActions: false,
    hasCustomizedFlash: false,
    hideMobileFooter: false,
    shouldShowMobileFooter: Ember.computed('hideMobileFooter', 'screen.isMobile', function () {
      return !this.get('hideMobileFooter') && this.get('screen.isMobile');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      (false && !(!this.get('confirm') || this.get('confirmButton')) && Ember.assert('You must pass a translated `confirmButton` string when passing a `confirm` action to `{{sheet-layout}}`', !this.get('confirm') || this.get('confirmButton')));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._onTransitionEnd = function () {
        return _this.set('isTransitioning', false);
      };

      this.element.addEventListener('transitionend', this._onTransitionEnd);
      this.element.classList.add(START_POSITION_CLASS); // Remove START_POSITION_CLASS so sheet slides up. We use "next" instead of "scheduleOnce" so there
      // is sufficient delay between adding and removing START_POSITION_CLASS to trigger a CSS transition.

      this.animationTask = Ember.run.next(function () {
        _this.set('isTransitioning', true);

        _this.element.classList.remove(START_POSITION_CLASS);
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.animationTask);
      this.element.removeEventListener('transitionend', this._onTransitionEnd);

      this._super.apply(this, arguments);
    },
    actions: {
      // action associated with top left header bar 'X'
      cancel: function cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },
      // action associated with default top right header bar 'Confirm' button
      confirm: function confirm() {
        if (this.confirm) {
          this.confirm();
        }
      },
      // action associated with secondary top right header bar button
      secondary: function secondary() {
        if (this.secondary) {
          this.secondary();
        }
      },
      // action associated with clicking the link on a flash message
      flashMessageLinkClick: function flashMessageLinkClick() {
        if (this.flashMessageLinkClick) {
          this.flashMessageLinkClick();
        }
      }
    }
  });

  _exports.default = _default;
});