import { capitalize } from '../../../helpers/capitalize';

class SyncJob {
  id: string;
  fire_time: string;
  sync_definition_id: string;
  location_id?: string;
  location_name?: string;
  sync_type: string;
  source_type: string;
  state: string;
  created_at: string;
  started_at: string;
  completed_at: string;
  presentable_error: string;
  execution_type: ExecutionType;

  constructor(job: SyncJob) {
    this.id = job.id;
    this.fire_time = job.fire_time;
    this.sync_definition_id = job.sync_definition_id;
    this.location_id = job.location_id;
    this.location_name = job.location_name;
    this.sync_type = job.sync_type;
    this.source_type = job.source_type;
    this.state = job.state;
    this.created_at = job.created_at;
    this.started_at = job.started_at;
    this.completed_at = job.completed_at;
    this.presentable_error = job.presentable_error;
    this.execution_type = job.execution_type;
  }
}

class SyncJobView extends SyncJob {
  syncTypeLabel: string;

  constructor(job: SyncJob) {
    super(job);
    this.syncTypeLabel = capitalize([job.sync_type], {
      eachWord: true,
      delimiter: '_',
    });
  }
}

enum SyncJobState {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  UNKNOWN = 'UNKNOWN'
}

enum ExecutionType {
  BATCH_INCREMENTAL = 'BATCH_INCREMENTAL',
  BATCH_ALL = 'BATCH_ALL',
  BATCH_MANUAL_DATE_WINDOW = 'BATCH_MANUAL_DATE_WINDOW',
}

export { SyncJob, SyncJobView, SyncJobState, ExecutionType };
