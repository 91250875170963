define("@square/glass-ui/templates/components/deprecated/sq-form-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YxzHya9q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"form-checkbox__content \",[30,[36,2],[[35,1],\"form-checkbox__content--is-disabled\"],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"type\",\"class\",\"disabled\",\"data-test-form-checkbox\",\"checked\",\"indeterminate\"],[\"checkbox\",\"form-checkbox__input\",[35,1],[35,5],[35,4],[35,3]]]]],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"form-checkbox__check \",[30,[36,2],[[35,3],\"form-checkbox__check--indeterminate\"],null]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-checkbox__label\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-checkbox__label__text\"],[12],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-checkbox__label__secondary-text\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"labelSecondary\",\"disabled\",\"if\",\"isIndeterminate\",\"checked\",\"label\",\"input\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/deprecated/sq-form-checkbox.hbs"
    }
  });

  _exports.default = _default;
});