import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { SyncJob } from '../../../../models/services/sync/sync-job';
import SyncService from '../../../../services/sync';

export default class AuthorizedRouteIntegrationAuditShow extends Route {
  @service sync!: SyncService;

  model(params: { sync_job_id: string; }): Promise<SyncJob> {
    return this.sync.getById(params.sync_job_id);
  }
}
