define("@square/glass-ui/components/sq-card/attributes-list/attribute/label", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-list/attribute/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'dt',
    layout: _label.default,
    classNames: ['card__attributes-list__attribute__label'],
    attributeBindings: ['data-test-card-attribute-label'],
    'data-test-card-attribute-label': true
  });

  _exports.default = _default;
});