define("@square/glass-ui/components/sq-loading", ["exports", "@square/glass-ui/templates/components/sq-loading"], function (_exports, _sqLoading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqLoading.default,
    classNames: ['loading'],
    classNameBindings: ['isActive:loading-is-active', 'useSmallSpinner:loading--small', 'useFixedPositioning:loading--fixed', 'useFixedPositioning:dashboard-header__fixed-top-element'],
    useFixedPositioning: false,
    useSmallSpinner: false,
    // Schedule new run loop instead of setting `useFixedPositioning` here, as per:
    // http://emberjs.com/deprecations/v1.x/#toc_modifying-a-property-within-code-didinsertelement-code
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.checkOffsetParentTask = Ember.run.schedule('afterRender', this, this.checkOffsetParent);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this.checkOffsetParentTask);
    },
    // If the element has a positioned offset parent (the element CSS uses
    // for absolute positioning) that's not `.l-viewport-app` or the top level
    // `<html>` element, use absolute positioning so the spinner can center
    // itself based on its context. Otherwise use fixed positioning to ensure
    // the spinner is centered vertically in the viewport even if the content has
    // no height.
    checkOffsetParent: function checkOffsetParent() {
      var offsetParent = this.get('element').offsetParent;
      var useFixedPositioning = !offsetParent || offsetParent.matches('.l-viewport-app, html');
      this.set('useFixedPositioning', useFixedPositioning);
    }
  });

  _exports.default = _default;
});