define("@square/glass-ui/templates/components/svg/icon-close-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0MHjnmSD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"d\",\"M3 3L13 13M13 3L3 13\"],[14,\"stroke\",\"black\"],[14,\"stroke-opacity\",\"0.9\"],[14,\"stroke-width\",\"2\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,0,\"svg-icon__stroke\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-close-x.hbs"
    }
  });

  _exports.default = _default;
});