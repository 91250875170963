import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { IntegrationState } from '../../models/services/integration/integration-state_enum';
import FlashService from '@square/glass-ui/addon/services/flash';
export default class AuthorizedRouteIntegration extends Route {
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;

  @action
  changeIntegration(integrationId: string): void {
    this.currentUser.changeCurrentIntegrationId(integrationId);
    this.refresh();
  }

  model() {
    return this.currentUser.loadIntegration();
  }

  afterModel() {
    if (!this.currentUser.hasIntegration()) {
      this.transitionTo('authorized-route');
    } else if (
      this.currentUser.currentIntegrationState === IntegrationState.SETUP
    ) {
      // this.transitionTo('authorized-route.setup-integration');
    }
  }
}
