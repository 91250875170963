/**
## Audit::FilterTable

A component for containing and managing a filter-table pair.

```example
<Audit::FilterTable 
  @objectTypes={{this.integrationObjectTypes}} 
  @cursor={{this.model.cursor}}
  @rows={{this.model.sync_jobs}} 
  @state={{this.state}}
  @syncType={{this.syncType}} 
/>
```

### Parameters
  * @param {Array<FilterTableColumnObject>} [columns] 
  * @param {String!} [cursor] [The pagination cursor for the data/rows passed to the component. ]
  * @param {Array<FilterCriteria>} [filterCriteria] [Objects with details of selection criteria.]
  * @param {Array<SyncJob>} [rows] [The data to be displayed in the table. ]
  * @param {String} [state] [The current state for filtering sync jobs. ]
  * @param {String} [sync_type] [The current type for filtering sync jobs. ]
*/

import Component from '@glimmer/component';

import { FilterCriterion } from '../../models/interface/filter-criteria';
import { FilterTableColumnObject } from '../../models/interface/filter-table-column-object';
import { SyncJob } from '../../models/services/sync/sync-job';

interface IntegrationAuditFilterTableArgs {
  columns: Array<FilterTableColumnObject>;
  cursor: string;
  filterCriteria: Array<FilterCriterion>;
  handleModelRefresh: () => void;
  loadMore: () => void;
  rows: Array<SyncJob>;
  uniqueIdentifierKey: string;
}

export default class IntegrationAuditFilterTable extends Component<IntegrationAuditFilterTableArgs> {}
