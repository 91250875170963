define("@square/glass-ui/templates/components/svg/icon-pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6qZzqzwo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M14 9H11V23H14V9ZM21 9H18V23H21V9Z\"],[14,\"fill-rule\",\"evenodd\"],[14,0,\"svg-icon__fill\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-pause.hbs"
    }
  });

  _exports.default = _default;
});