import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Instance as PopperInstance } from '@popperjs/core';
import { LIST_OPTION_HIGHLIGHTED_CLASS } from './searchable-component';
import KeyboardEventCodes from '../../utils/constants/keyboard-event-codes';
import makeKeyDownListenerMap from '../../utils/make-key-down-listener-map';
import makeOutsideClickListener from '../../utils/make-outside-click-listener';
import { createSameWidthPopper } from '../../utils/popper';
import { scrollIntoView } from '../../utils/scroll-into-view';

interface MarketSearchableDropdownArgs {
  onEmptySubmit?(): void;
  onPopoverOpen(): void;
  onPopoverClose(): void;
  onInputFocus(): void;
  onInputBlur(): void;
  onInputChange(value: string): void;
  onListChange(value: string): void;
  onListCreate(value: string): void;
  onListScrollToBottom(): void;
  registerClosePopover?(closePopover: () => void): void;
  openDropdownWithEmptyQuery?: boolean;
  query: string;
  listHeader: string;
}

/**
 * Renders a text input component, and a list of Market rows in a popover when that
 * input is clicked.
 *
 * @export
 * @class MarketSearchableDropdown
 * @extends {MarketSearchableComponent}
 */
export default class MarketSearchableDropdown extends Component<MarketSearchableDropdownArgs> {
  @tracked isPopoverVisible = false;

  private triggerElement!: HTMLElement;
  private popperElement!: HTMLElement;
  private popperInstance!: PopperInstance;
  private closeListener?: (this: Window, event: MouseEvent) => void;
  private inputKeydownListener?: (
    this: HTMLInputElement,
    event: KeyboardEvent
  ) => void;
  private resetHighlightedIndex!: () => void;

  private get shouldOpenDropdownWithEmptyQuery() {
    return this.args.openDropdownWithEmptyQuery ?? true;
  }

  @action
  registerResetHighlightedIndex(resetHighlightedIndex: () => void): void {
    this.resetHighlightedIndex = resetHighlightedIndex;
  }

  @action
  registerClosePopover(): void {
    if (this.args.registerClosePopover) {
      this.args.registerClosePopover(this.closePopover);
    }
  }

  removeListeners(): void {
    if (this.closeListener) {
      window.removeEventListener('click', this.closeListener);
    }
  }


  /*
    if key is Enter and input element is in focus... 
      try to save the value if its real

    otherwise dont do anything.

  */

  @action
  closePopover(): void {
    const shouldIgnoreInput = this.isDestroyed || this.isDestroying;
    if (shouldIgnoreInput || !this.isPopoverVisible) {
      return;
    }

    delete this.popperElement.dataset.show;
    this.isPopoverVisible = false;
    this.resetHighlightedIndex();
    this.args?.onPopoverClose?.();
  }

  @action
  onEnterKey(success: boolean): void {
    this.openDropdown();

    if (success) {
      this.closePopover();
    }

    // No item was selected and the query is empty
    if (!success && !this.args.query) {
      this.args.onEmptySubmit?.();
    }
  }

  @action
  openDropdown(): void {
    if (this.isPopoverVisible) {
      return;
    }

    this.args?.onInputFocus?.();
    this.openPopover(this.args.query);
    this.scrollHighlightedRowIntoView();
  }

  @action
  onMarketInputClick(): void {
    this.openDropdown();
  }

  @action
  onInputBlur(): void {
    this.closePopover();
    this.args?.onInputBlur?.();
  }

  @action
  onInputChange(value: string): void {
    this.openPopover(value);
    this.args?.onInputChange?.(value);

    if (!this.shouldOpenDropdownWithEmptyQuery && !value) {
      this.closePopover();
    }
  }

  @action
  onListChange(value: string): void {
    this.closePopover();
    this.args?.onListChange?.(value);
  }

  @action
  onListCreate(value: string): void {
    this.closePopover();
    this.args?.onListCreate?.(value);
  }

  @action
  attachInputListeners(element: HTMLInputElement): void {
    this.triggerElement = element;
    this.inputKeydownListener = makeKeyDownListenerMap(
      {
        // These calls will be no-ops if the popover is already open.
        [KeyboardEventCodes.ArrowDown]: this.openDropdown.bind(this),
        [KeyboardEventCodes.ArrowUp]: this.openDropdown.bind(this),
        [KeyboardEventCodes.Enter]: this.openDropdown.bind(this),
        // Allow for events to bubble up to the popover listener.
      },
      false
    );
    element.addEventListener('keydown', this.inputKeydownListener);
  }

  @action
  attachPopoverListeners(element: HTMLElement): void {
    this.popperElement = element;
    this.closeListener = makeOutsideClickListener(
      this.closePopover.bind(this),
      [this.popperElement, this.triggerElement],
      ['market-searchable-dropdown__popover']
    );
    window.addEventListener('click', this.closeListener);

    this.popperInstance = createSameWidthPopper(
      this.triggerElement,
      this.popperElement
    );
  }

  private openPopover(query: string): void {
    if (!query && !this.shouldOpenDropdownWithEmptyQuery) {
      return;
    }

    this.popperElement.dataset.show = '';
    this.isPopoverVisible = true;
    this.popperInstance.update();
    this.args?.onPopoverOpen?.();
  }

  private scrollHighlightedRowIntoView(): void {
    scrollIntoView(LIST_OPTION_HIGHLIGHTED_CLASS);
  }

  willDestroy(): void {
    super.willDestroy();
    this.removeListeners();
  }
}
