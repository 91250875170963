define("@square/glass-ui/components/sq-card/attributes-table/header-cell", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-table/header-cell"], function (_exports, _headerCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _headerCell.default,
    tagName: 'th',
    classNames: ['card__attributes-table__header-cell']
  });

  _exports.default = _default;
});