import SyncEntityGroupStatus from '@bridge/home-engine/addon/models/services/sync-entity-group-status/sync-entity-group-status';
import Component from '@glimmer/component';

interface BladeArgs {
  syncObject: SyncEntityGroupStatus;
}

export default class Blade extends Component<BladeArgs> {
  get isUnmapped() {
    return this.args.syncObject.state === 'UNMAPPED';
  }
}
