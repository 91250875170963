define("@square/glass-ui/templates/components/dialog-blade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ID33URz",
    "block": "{\"symbols\":[\"header\",\"&default\"],\"statements\":[[6,[37,5],null,[[\"title\",\"close\",\"context\"],[[35,4],[35,3],\"BLADE\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"title\"],[[32,1,[\"title\"]]]]]]],[2,\"\\n\\n\"],[6,[37,0],[[30,[36,2],[[32,1,[\"secondary-actions-container\"]],\"expected `header.secondary-actions-container` to be a contextual component but found a string. Did you mean `(component header.secondary-actions-container)`? ('@square/glass-ui/templates/components/dialog-blade.hbs' @ L7:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,[[30,[36,1],null,[[\"secondary-actions\"],[[30,[36,0],[\"dialog-actions\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,1,[\"primary-actions-container\"]],\"expected `header.primary-actions-container` to be a contextual component but found a string. Did you mean `(component header.primary-actions-container)`? ('@square/glass-ui/templates/components/dialog-blade.hbs' @ L11:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,[[30,[36,1],null,[[\"dropdown\"],[[30,[36,0],[\"sq-dropdown\"],[[\"class\",\"data-test-dialog-blade-dropdown\"],[\"dialog__header__dropdown dialog--blade__header__dropdown\",true]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[1,[30,[36,9],null,[[\"context\"],[[30,[36,8],[[35,7],[35,6,[\"contexts\",\"blade\"]]],null]]]]],[2,\"\\n\\n\"],[18,2,[[30,[36,1],null,[[\"body\",\"footer\"],[[30,[36,0],[\"dialog-body\"],[[\"context\"],[\"BLADE\"]]],[30,[36,0],[\"dialog-actions\"],[[\"context\",\"class\"],[\"BLADE\",\"dialog__footer dialog--blade__footer\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-assert-implicit-component-helper-argument\",\"close\",\"title\",\"dialog-header\",\"flash\",\"flashContext\",\"or\",\"flash-portal\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/dialog-blade.hbs"
    }
  });

  _exports.default = _default;
});