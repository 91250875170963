define("@square/glass-ui/components/sq-chart-popover", ["exports", "@square/glass-ui/components/sq-popover"], function (_exports, _sqPopover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sqPopover.default.extend({
    attributeBindings: ['style']
  });

  _exports.default = _default;
});