export enum SyncJobType {
  BANK_TRANSFERS = 'BANK_TRANSFERS',
  CATALOG = 'CATALOG',
  CUSTOMERS = 'CUSTOMERS',
  INVENTORY_CHANGES = 'INVENTORY_CHANGES',
  INVENTORY_LEVELS = 'INVENTORY_LEVELS',
  ORDERS = 'ORDERS',
}

// weird syntax, but TS doesn't yet support `export default enum` for some reason
export default SyncJobType;
