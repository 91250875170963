define("@square/glass-ui/components/ring-progress-indicator", ["exports", "@square/glass-ui/templates/components/ring-progress-indicator"], function (_exports, _ringProgressIndicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
  ## Overview
  
  A ring progress indicator with optional text at the center.
  
  ## Usage
  
  ```handlebars
  {{ring-progress-indicator value=value
                            centerText=centerText
                            centerImage=centerImage}}
  ```
  
  @param {Number} value - The current percentage value ranged from 0.0 to 1.0.
  @param {String} centerText - The text on the center of the ring.
  @param {String} centerImage - The path for the center image svg template. If `centerImage` is truthy it will replace the center text.
  */
  var _default = Ember.Component.extend({
    layout: _ringProgressIndicator.default,
    // Passed in:
    value: 0,
    centerText: null,
    centerImage: null,
    hasBeenInserted: false,
    ringStyle: Ember.computed('value', 'hasBeenInserted', function () {
      // When starting with 0% and the element is not yet inserted, the ring will not be rendered.
      // We trigger an update on `hasBeenInserted` property to force re-render the ring.
      if (!this.get('hasBeenInserted')) {
        return null;
      }

      var elem = document.querySelector('.ring-progress-indicator__progress');
      var styles = getComputedStyle(elem);
      var filled = styles.color;
      var unfilled = styles.backgroundColor;

      if (this.get('value') < 0.5) {
        var deg = 90 + this.get('value') * 360;
        return Ember.String.htmlSafe("background-image: linear-gradient(90deg, ".concat(unfilled, " 50%, transparent 50%, transparent),\n        linear-gradient(").concat(deg, "deg, ").concat(filled, " 50%, ").concat(unfilled, " 50%, ").concat(unfilled, ");"));
      } else {
        var _deg = -90 + this.get('value') * 360 - 180;

        return Ember.String.htmlSafe("background-image: linear-gradient(".concat(_deg, "deg, ").concat(filled, " 50%, transparent 50%, transparent),\n        linear-gradient(270deg, ").concat(filled, " 50%, ").concat(unfilled, " 50%, ").concat(unfilled, ");"));
      }
    }),
    cappedValue: Ember.computed('value', function () {
      return Math.max(0, Math.min(1, this.get('value')));
    }),
    progressClass: Ember.computed('cappedValue', function () {
      return "ring-progress-indicator__progress-".concat(Math.round(this.get('cappedValue') * 100));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('hasBeenInserted', true);
    }
  });

  _exports.default = _default;
});