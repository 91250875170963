define("ember-engines/components/link-to-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LinkTo;
  var LinkComponent;
  {
    LinkComponent = Ember.LinkComponent;
  }
  {
    LinkTo = LinkComponent.extend({
      _route: Ember.computed('route', '_mountPoint', '_currentRouteState', function () {
        var routeName = this._super.apply(this, arguments);

        var mountPoint = Ember.get(this, '_mountPoint');

        if (mountPoint && routeName !== Ember.get(this, '_currentRoute')) {
          return this._namespacePropertyValue(mountPoint, routeName);
        }

        return routeName;
      }),
      _mountPoint: Ember.computed(function () {
        return Ember.getOwner(this).mountPoint;
      }),
      didReceiveAttrs: function didReceiveAttrs() {
        this._super.apply(this, arguments);

        var owner = Ember.getOwner(this);
        (false && !(owner.mountPoint !== undefined) && Ember.assert("You attempted to use {{link-to}} within a routeless engine, this is not supported. Use {{link-to-external}} to construct links within a routeless engine. See http://ember-engines.com/guide/linking-and-external-links for more info.", owner.mountPoint !== undefined));

        if (owner.mountPoint) {
          // Prepend engine mount point to targetRouteName
          if ('targetRouteName' in this) {
            this._prefixProperty(owner.mountPoint, 'targetRouteName');
          } // Prepend engine mount point to current-when if set


          if (Ember.get(this, 'current-when') !== null) {
            this._prefixProperty(owner.mountPoint, 'current-when');
          }
        }
      },
      _prefixProperty: function _prefixProperty(prefix, prop) {
        var _this = this;

        var propValue = Ember.get(this, prop); // Sometimes `targetRouteName` will be a class

        if (Ember.typeOf(propValue) !== 'string') {
          return;
        }

        var namespacedPropValue;

        if (prop === 'current-when') {
          // `current-when` is a space-separated list of routes
          namespacedPropValue = propValue.split(' ');
          namespacedPropValue = namespacedPropValue.map(function (propValue) {
            return _this._namespacePropertyValue(prefix, propValue);
          });
          namespacedPropValue = namespacedPropValue.join(' ');
        } else {
          namespacedPropValue = this._namespacePropertyValue(prefix, propValue);
        }

        Ember.set(this, prop, namespacedPropValue);
      },
      _namespacePropertyValue: function _namespacePropertyValue(prefix, propValue) {
        if (propValue === 'application') {
          return prefix;
        } else {
          return prefix + '.' + propValue;
        }
      }
    });
  }
  var _default = LinkTo;
  _exports.default = _default;
});