import { ConfigFormBlueprint } from '@bridge/home-engine/services/config';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Config from '../../services/config';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import FlashService from '@square/glass-ui/addon/services/flash';
interface WizardTaxConfigArgs {
  setContinueDisabled: Function;
}

export default class WizardTaxConfig extends Component<
  WizardTaxConfigArgs
> {
  constructor(owner: unknown, args: WizardTaxConfigArgs) {
    super(owner, args);
    this.fetchAndDisplayTaxSettingsForm();
  }

  @service config!: Config;
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;

  @tracked
  settingsFormLoading = true;

  @tracked
  configBlueprint: ConfigFormBlueprint | null = null;

  

  @action
  fetchAndDisplayTaxSettingsForm() {
    this.settingsFormLoading = true;
    this.config
      .fetchTaxConfig(this.currentUser!.currentIntegration!.id!)
      .then(
        (goodResponse) => {
          this.configBlueprint = goodResponse.configuration_blueprint;
          this.settingsFormLoading = false;
          if (this.currentUser.isIntegrationConfigured()) {
            this.args.setContinueDisabled(false);
          }
        },
        (badResponse: Error) => {
          this.settingsFormLoading = false;
          this.flash.sheetError(badResponse, {
            dismiss: () => {
              this.flash.clearSheetMessage();
            },
          });
        }
      );
  }
}
