define("@square/glass-ui/templates/components/sq-file-select-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4eETWzfx",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"],[1,[30,[36,4],null,[[\"type\",\"class\",\"disabled\",\"accept\",\"change\"],[\"file\",\"input\",[35,3],[35,2],[30,[36,1],[[32,0],\"fileInputChanged\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"linkText\",\"action\",\"fileTypeWhitelist\",\"disabled\",\"input\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-file-select-link.hbs"
    }
  });

  _exports.default = _default;
});