define("@square/glass-ui/components/sq-option-radio", ["exports", "@square/glass-ui/templates/components/sq-option-radio"], function (_exports, _sqOptionRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqOptionRadio.default,
    tagName: 'label',
    classNames: ['option', 'option--radio'],
    classNameBindings: ['selected:option--is-selected', 'selected:radio-option--is-selected', 'disabled:option--is-disabled'],
    attributeBindings: ['data-test-option-radio', 'data-test-option-radio-selected'],
    'data-test-option-radio': Ember.computed('label', 'value', function () {
      return this.get('label') || this.get('value') || true;
    }),
    'data-test-option-radio-selected': Ember.computed.readOnly('selected'),
    optionName: null,
    selected: false
  });

  _exports.default = _default;
});