import Config, { ConsolidatedMappingResource } from '../services/config';
import { SettingsFormType } from '../models/interface/settings-form-type';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

interface TaxItemOption {
  value: string;
  label: string;
  name: string;
  checked: boolean;
}
interface TaxCodeWithLabel {
  value: string;
  label: string;
}
interface TaxCodeMultiselectArgs {
  taxCodes: string[];
  isLoading: boolean;
  isListCreateEnabled: boolean;
  listCreateLabel: string;
  handleChange: (taxCodes: string[]) => void;
  options: TaxItemOption[];
  searchOptionResource: ConsolidatedMappingResource;
  settingsTarget: SettingsFormType;
}

export default class TaxCodeMultiselect extends Component<TaxCodeMultiselectArgs> {
  @service config!: Config;
  constructor(owner: unknown, args: TaxCodeMultiselectArgs) {
    super(owner, args);
    this.taxCodes = args.taxCodes || [];
    this.handleChange = args.handleChange;
  }

  handleChange!: (newTaxCodes: string[]) => void;

  hasNextPage = (): boolean => Boolean(this.cursor[this.currentValue]);

  // Store cursor as an object to ensure we never send an invalid cursor/query combo
  @tracked cursor: Record<string, string | null> = {};

  @tracked options: TaxItemOption[] = [];
  @tracked taxCodes: string[] = [];
  @tracked taxCodeLabelDictionary = new Map<string, string>();
  @tracked currentValue = '';
  @tracked inputLabel: string = '';
  @tracked isOptionloading: boolean = false;

  get isListCreateEnabled(): boolean {
    return (
      (!this.options || this.args.isListCreateEnabled) &&
      this.currentValue.length > 0 &&
      (!this.options ||
        !this.options.some((option) => option.value === this.currentValue))
    );
  }

  get listCreateLabel(): string {
    return `${this.args.listCreateLabel}: ${this.currentValue}`;
  }

  // TODO: We might need to optimize with a debounce or speeding up the filter in the future
  get currentOptions(): TaxItemOption[] {
    return this.options;
  }
  get taxCodesWithLabels(): TaxCodeWithLabel[] {
    return this.taxCodes.map((taxCode) => {
      return {
        value: taxCode,
        label: taxCode,
      };
    });
    /*
      Current implementation only supports having the value to show to the user, in the future we would like to fetch the labels to display.
      See https://github.com/squareup/se-bridge/pull/1409 
    */
  }

  @action
  async fetchTaxCodes({
    query,
    signal: _signal,
  }: {
    query: string | null;
    signal: AbortSignal;
  }) {
    const searchQuery = query ?? '';

    try {
      this.isOptionloading = true;
      const res = await this.config.fetchSearchableFieldOptions({
        settings_form_type: this.args.settingsTarget,
        option_resource: this.args.searchOptionResource,
        query: searchQuery,
        cursor: this.cursor[searchQuery],
      });

      this.isOptionloading = false;

      this.cursor[searchQuery] = res.cursor;

      this.options = [...res.data.field_value];

      return this.options;
    } catch (e) {
      this.isOptionloading = false;
      throw new Error(e.message);
    }
  }

  @action
  handleInput(fetchDataDebounced: () => Promise<void>, value: string): any {
    this.cursor = {};
    this.currentValue = value;
    fetchDataDebounced();
  }

  @action
  onListChange(value: string) {
    this.taxCodes = [...this.taxCodes, value];
    this.handleChange(this.taxCodes);
    this.currentValue = '';
  }

  @action
  onListCreate() {
    this.isOptionloading = false;
    this.taxCodes = [...this.taxCodes, this.currentValue];
    this.handleChange(this.taxCodes);
    this.currentValue = '';
  }

  @action
  onDeleteTaxCode(taxCode: string, _e: UIEvent): any {
    this.taxCodes = this.taxCodes.filter((t) => t !== taxCode);
    this.handleChange(this.taxCodes);
  }

  @action
  handleEnter() {
    this.taxCodes = [...this.taxCodes, this.currentValue];
    this.handleChange(this.taxCodes);
    this.currentValue = '';
  }
}
