define("ember-slugify/index", ["exports", "simple-pinyin", "speakingurl", "unorm"], function (_exports, _simplePinyin, _speakingurl, _unorm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slugify = _exports.default = slugify;
  _exports.removeDiacritics = removeDiacritics;

  function removeDiacritics(str) {
    // eslint-disable-next-line
    return _unorm.default.nfd(str).replace(/[\u0300-\u036f]/g, '');
  }

  function slugify(str) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _speakingurl.default)((0, _simplePinyin.default)(removeDiacritics(str.split('_').join('-')), {
      pinyinOnly: false
    }).join(' '), options);
  }
});