import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import _ from 'lodash';
import { SyncTypeStatus } from '../../../models/services/sync-type-status/sync-type-status';

export default class AuthorizedRouteIntegrationOverview extends Controller {
  @service currentUser!: CurrentUser;

  get alphabeticallyOrderedSyncs() {
    return _.sortBy(
      this.currentUser.activeSyncTypes,
      (syncTypeStatus: SyncTypeStatus): String => syncTypeStatus.sync_type
    );
  }

  get areAnySyncsActive() {
    return this.currentUser.activeSyncTypes.length > 0;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/overview': AuthorizedRouteIntegrationOverview;
  }
}
