define("@square/glass-ui/components/sq-content-overflow-tooltip-manager", ["exports", "jquery", "@square/glass-ui/utils/input"], function (_exports, _jquery, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var THROTTLE_PERIOD = 50;
  var THROTTLE_PERIOD_RESIZE = 1000; // because we're referencing pseudo elements, we can't grab this dynamically, it's part of the DOM.
  // also a performance boost when we don't make the extra reference. But we have to update this accordingly
  // when the tooltip styling is updated.
  // horizontal offset = tooltip side padding + borders - left offset

  var TOOLTIP_HORIZONTAL_OFFSET = 34; // vertical offset = tooltip top/bottom padding + (font size * line height) + borders - top offset

  var TOOLTIP_VERTICAL_OFFSET = 31;

  var _default = Ember.Component.extend({
    classNames: ['content-overflow-tooltip-manager'],
    // passed in
    criteria: null,
    useTruncationCache: true,
    // lifecycle
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var criteria = this.get('criteria');
      (false && !(criteria) && Ember.assert('criteria required', criteria));

      if (criteria) {
        var self = this; // eslint-disable-line unicorn/no-this-assignment
        // eslint-disable-next-line ember/no-jquery

        (0, _jquery.default)(this.element).on("mouseenter.contentOverflowTooltipManager".concat(this.get('elementId')), criteria, function () {
          // In tests, we want to test for multiple lines with overflow content.
          // This requires us to always call checkElementForTruncation and ignore throttling
          // since tests execute way faster than the throttling limit we set for real world use cases.
          // If we throttle, after first `mouseenter`, any following event would be ignored.
          if (Ember.testing) {
            // eslint-disable-next-line ember/no-jquery
            self.checkElementForTruncation((0, _jquery.default)(this));
          } else {
            Ember.run.throttle(self, self.checkElementForTruncation, (0, _jquery.default)(this), // eslint-disable-line ember/no-jquery
            THROTTLE_PERIOD);
          }
        }); // eslint-disable-next-line ember/no-jquery

        (0, _jquery.default)(window).on("resize.contentOverflowTooltipManager".concat(this.get('elementId')), this.windowDidResize.bind(this));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('criteria')) {
        // eslint-disable-next-line ember/no-jquery
        (0, _jquery.default)(this.element).off("mouseenter.contentOverflowTooltipManager".concat(this.get('elementId'))); // eslint-disable-next-line ember/no-jquery

        (0, _jquery.default)(window).off("resize.contentOverflowTooltipManager".concat(this.get('elementId')));
      }
    },
    windowDidResize: function windowDidResize() {
      Ember.run.throttle(this, 'clearTruncationCache', THROTTLE_PERIOD_RESIZE, false);
    },
    clearTruncationCache: function clearTruncationCache() {
      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(this.element).find(this.get('criteria')).data('contentOverflowTooltipChecked', false).removeClass('content-overflow-tooltip-manager__truncated content-overflow-tooltip-manager__truncated--position-top-left content-overflow-tooltip-manager__truncated--position-top-right content-overflow-tooltip-manager__truncated--position-bottom-left content-overflow-tooltip-manager__truncated--position-bottom-right');
    },
    // TODO (nschaden): for now criteria passed into this element only works if the criteria targets the *immediate*
    // element wrapping the content we want to truncate. Examples so far are just a type of simple .table-cell, but
    // can this transfer to other more complex objects?
    checkElementForTruncation: function checkElementForTruncation(element) {
      // drop out if the element has already been checked for truncation, or the element
      // doesn't exactly match our criteria (generally this should only happen after we've added
      // tooltip content and you are getting a mouse move event where the target is the inner wrapper div work)
      if (element.data('contentOverflowTooltipChecked') && this.useTruncationCache) {
        return;
      }

      element.data('contentOverflowTooltipChecked', true);
      var fullContentWidth = this.contentIsTruncated(element);

      if (fullContentWidth) {
        // determine if tooltip is hitting any window boundaries
        var contentOverflowClass = 'content-overflow-tooltip-manager__truncated';
        var $window = (0, _jquery.default)(window); // eslint-disable-line ember/no-jquery

        var $elementOffset = element.offset();
        var crossingRight = $window.width() < $elementOffset.left + fullContentWidth + TOOLTIP_HORIZONTAL_OFFSET;
        var crossingTop = $window.scrollTop() >= $elementOffset.top - TOOLTIP_VERTICAL_OFFSET;

        if (crossingRight && !crossingTop) {
          contentOverflowClass += " ".concat(contentOverflowClass, "--position-top-left");
        } else if (crossingRight && crossingTop) {
          contentOverflowClass += " ".concat(contentOverflowClass, "--position-bottom-left");
        } else if (!crossingRight && crossingTop) {
          contentOverflowClass += " ".concat(contentOverflowClass, "--position-bottom-right");
        } // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions


        Ember.run.scheduleOnce('afterRender', function () {
          element.addClass(contentOverflowClass).attr('data-tooltip', element.text());

          if (!element.children().hasClass('content-overflow-tooltip-manager__original-content')) {
            element.wrapInner('<div class="content-overflow-tooltip-manager__original-content"></div>');
          }
        });
      }
    },
    // helper function to check for truncated content, return full width if we should truncate
    contentIsTruncated: function contentIsTruncated(domElement) {
      if (!domElement) {
        return false;
      }

      var elementWidth = domElement.width();
      var nonTruncatedWidth = (0, _input.measureTextWidth)(domElement.text());
      return nonTruncatedWidth > elementWidth ? nonTruncatedWidth : false;
    }
  });

  _exports.default = _default;
});