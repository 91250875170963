import { helper } from '@ember/component/helper';
import { format } from 'timeago.js';

// return the amount of time since the time given in a string
// slightly modified from https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
export function timeSince(params: Array<string>): string {
  const [statusDateString] = params;

  return format(statusDateString);
}

export default helper(timeSince);
