define("@square/glass-ui/components/sq-card/header", ["exports", "@square/glass-ui/templates/components/sq-card/header"], function (_exports, _header) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _header.default,
    classNames: ['card__header'],
    title: null,
    titleSecondary: null,
    link: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      var isLinkTextPresent = Ember.isPresent(this.get('link.text'));
      var isLinkHrefPresent = Ember.isPresent(this.get('link.href'));
      var isLinkActionPresent = Ember.isPresent(this.get('link.action'));
      var isLinkComponentPresent = Ember.isPresent(this.get('link.component'));
      (false && !(isLinkHrefPresent + isLinkActionPresent + isLinkComponentPresent <= 1) && Ember.assert('Only pass one or none of `link.href`, `link.action`, `link.component` to `{{card/header}}`', isLinkHrefPresent + isLinkActionPresent + isLinkComponentPresent <= 1));

      if (isLinkHrefPresent || isLinkActionPresent || isLinkComponentPresent) {
        (false && !(isLinkTextPresent) && Ember.assert('`link.text` must be passed when using `link.href`, `link.action`, or `link.component` in `{{card/header}}`', isLinkTextPresent));
      }
    }
  });

  _exports.default = _default;
});