define("@square/glass-ui/components/media-block/headline", ["exports", "@square/glass-ui/templates/components/media-block/headline"], function (_exports, _headline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _headline.default,
    tagName: 'h2',
    classNames: ['media-block__headline'],
    classNameBindings: ['rankClass'],
    attributeBindings: ['data-test-media-block-headline'],
    'data-test-media-block-headline': Ember.computed('rank', function () {
      if (this.rank) {
        return this.rank;
      } else {
        return true;
      }
    }),
    rankClass: Ember.computed('rank', function () {
      if (this.rank) {
        return "media-block__headline--".concat(this.rank);
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});