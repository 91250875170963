define("@square/glass-ui/components/selector-control/navigation/item/tab", ["exports", "@square/glass-ui/templates/components/selector-control/navigation/item/tab"], function (_exports, _tab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _tab.default,
    tagName: 'a',
    classNames: ['tab-set__tab'],
    classNameBindings: ['isActive:tab-set__tab--is-active'],
    attributeBindings: ['data-test-selector-control-tab'],
    'data-test-selector-control-tab': Ember.computed('isActive', function () {
      if (this.get('isActive')) {
        return 'active';
      } else {
        return 'inactive';
      }
    }),
    isActive: false,
    click: function click() {
      if (this.onSelect) {
        this.onSelect();
      }
    }
  });

  _exports.default = _default;
});