define("@square/glass-ui/components/deprecated/sheet-layout/action-buttons", ["exports", "@square/glass-ui/templates/components/deprecated/sheet-layout/action-buttons"], function (_exports, _actionButtons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actionButtons.default,
    screen: Ember.inject.service(),
    tagName: 'ul',
    classNameBindings: ['screen.isMobile::sheet-layout__headerbar-navright'],
    // passed in
    hasCustomizedActions: false,
    actionsPartial: null,
    confirm: null,
    confirmButton: null,
    confirmButtonDisabled: false,
    confirmAction: null,
    secondaryButton: null,
    secondaryButtonIsWarning: false,
    secondaryButtonDisabled: false,
    secondaryAction: null
  });

  _exports.default = _default;
});