import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DropdownFilterUnit } from '../models/interface/dropdown-filter-unit';
import { FilterCriterion } from '../models/interface/filter-criteria';
import {
  FILTER_CRITERION_TYPE,
  syncTypeUnits,
} from '../models/interface/filter-criterion-type';
import { FilterTableColumnObject } from '../models/interface/filter-table-column-object';
import { SearchSyncJobsResponse } from '../models/services/sync/search-sync-jobs-response';
import { SyncJob, SyncJobView } from '../models/services/sync/sync-job';
import SyncService, { AuditRouterParams } from '../services/sync';

interface AuditLogArgs {
  cursor: string;
  handleModelRefresh: () => void;
  rows: Array<SyncJobView>;
  state: string;
  syncType: string;
}

export default class AuditLog extends Component<AuditLogArgs> {
  constructor(owner: unknown, args: AuditLogArgs) {
    super(owner, args);
    this.sync.search().then((responseJson: SearchSyncJobsResponse): void => {
      this.updateRows(responseJson);
      this.isTableLoading = false;
    });
  }

  @service sync!: SyncService;

  loadingText = 'Loading sync jobs...';

  // arrays of objects that describe what attributes of each model type should be displayed,
  // as well as what the heading of each column should read
  columns: Array<FilterTableColumnObject> = [
    {
      label: 'Sync Type',
      key: 'syncTypeLabel',
    },
    {
      label: 'Created',
      key: 'created_at',
    },
    {
      label: 'Started',
      key: 'started_at',
    },
    {
      label: 'Completed',
      key: 'completed_at',
    },
    { label: 'State', key: 'state', formatSuccess: true },
    {
      label: 'Location',
      key: 'location_name',
    },
  ];

  rowRoute = 'authorized-route.integration.audit.show';

  state = this.args.state;
  syncType = this.args.syncType;

  states: Array<DropdownFilterUnit> = [
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Created',
      value: 'CREATED',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Queued',
      value: 'QUEUED',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Running',
      value: 'RUNNING',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Failed',
      value: 'FAILED',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Completed',
      value: 'COMPLETED',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Expired',
      value: 'EXPIRED',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Stopped',
      value: 'STOPPED',
      unitActive: true,
    },
    {
      type: FILTER_CRITERION_TYPE.STATE,
      label: 'Cancelled',
      value: 'CANCELLED',
      unitActive: true,
    },
  ];

  syncTypes: Array<DropdownFilterUnit> = [...syncTypeUnits];

  selectedStates = this.states.filter((state) => {
    if (!this.state) {
      return [];
    } else {
      return this.state.includes(state.value!);
    }
  });

  selectedSyncTypes: Array<DropdownFilterUnit> = this.syncTypes.filter(
    (syncType) => {
      if (!this.syncType) {
        return [];
      } else {
        return this.syncType.includes(syncType.value!);
      }
    }
  );

  stateFilterCriterion: FilterCriterion = {
    key: 'state',
    label: 'State',
    multiple: true,
    selectedUnits: this.selectedStates,
    units: this.states,
  };

  syncJobFilterCriterion: FilterCriterion = {
    key: 'sync_type',
    label: 'Sync Type',
    multiple: true,
    selectedUnits: this.selectedSyncTypes,
    units: this.syncTypes,
  };

  filterCriteria: Array<FilterCriterion> = [
    this.stateFilterCriterion,
    this.syncJobFilterCriterion,
  ];

  cursor: string = this.args.cursor;

  @tracked
  rows: Array<SyncJob> = [];

  @tracked
  isTableLoading = true;

  // controls display of loading indicator in UI
  @tracked
  nextPageIsLoading = false;

  filterCheckDelay = 1000;

  @action
  handleFilterChange(
    selectedUnits: Array<DropdownFilterUnit>,
    type: string
  ): void {
    this.isTableLoading = true;
    this.rows = [];
    if (selectedUnits.length === 0) {
      this.isTableLoading = false;
      return;
    }
    if (type === FILTER_CRITERION_TYPE.STATE) {
      this.stateFilterCriterion.selectedUnits = selectedUnits;
    } else if (type === FILTER_CRITERION_TYPE.SYNC_TYPE) {
      this.syncJobFilterCriterion.selectedUnits = selectedUnits;
    }
    const selectedStateValues = this.stateFilterCriterion.selectedUnits.map(
      (unit) => unit.value!
    );
    const selectedSyncTypeValues = this.syncJobFilterCriterion.selectedUnits.map(
      (unit) => unit.value!
    );
    const params = {
      states: selectedStateValues,
      sync_types: selectedSyncTypeValues,
    };
    this.debouncedSyncSearch(params);
  }

  debouncedSyncSearch(params: AuditRouterParams): void {
    debounce(this, this.searchSyncJobs, params, this.filterCheckDelay, false);
  }

  searchSyncJobs(params: AuditRouterParams): void {
    this.sync
      .search(params)
      .then((responseJson: SearchSyncJobsResponse): void => {
        this.rows = responseJson.jobs.map((jobs) => new SyncJobView(jobs));
        this.cursor = responseJson.cursor;
        this.isTableLoading = false;
      });
  }

  // updates content with cursor-based paginated response
  // manages loading state for UI row spinner
  @action
  loadMore(): void {
    if (this.cursor && !this.nextPageIsLoading) {
      this.nextPageIsLoading = true;
      const selectedStateValues = this.stateFilterCriterion.selectedUnits.map(
        (unit) => unit.value!
      );
      const selectedSyncTypeValues = this.syncJobFilterCriterion.selectedUnits.map(
        (unit) => unit.value!
      );
      const params: AuditRouterParams = {
        cursor: this.cursor,
        states: selectedStateValues,
        sync_types: selectedSyncTypeValues,
      };
      this.sync
        .search(params)
        .then((responseJson: SearchSyncJobsResponse): void => {
          this.updateRows(responseJson);
          this.nextPageIsLoading = false;
        });
    }
  }

  updateRows(responseJson: SearchSyncJobsResponse): void {
    const jobViews: Array<SyncJobView> = responseJson.jobs.map(
      (job) => new SyncJobView(job)
    );
    this.rows = [...this.rows, ...jobViews];
    this.cursor = responseJson.cursor;
  }
}
