import { ConfigFormBlueprint } from '@bridge/home-engine/services/config';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Config from '../../services/config';
import { Source, friendlies } from '../../models/services/source/source';
import ENV from '@bridge/home-engine/config/environment';
import { GatewaySystemType } from '@bridge/home-engine/models/services/source/gateway-system-type';

interface WalkthroughCheckbox {
  nameValue: string;
  walkthroughLink: string;
  walkthroughLinkLabel: string;
  changeHandler: (checkboxEvent: Event) => void;
  checked: boolean;
}
interface WizardExternalConfigArgs {
  externalSource: Source;
  externalGatewaySystemTypes: GatewaySystemType[];
  handleCompletedForm: Function;
  handleExternalSourceDropdownSelection: Function;
  handleSubmit: Function;
  selectedGatewaySystemType: string;
  setContinueDisabled: Function;
  code: string;
}

export default class WizardExternalConfig extends Component<
  WizardExternalConfigArgs
> {
  constructor(owner: unknown, args: WizardExternalConfigArgs) {
    super(owner, args);
    if (
      this.args.selectedGatewaySystemType ===
        GatewaySystemType.SAP_BUSINESS_ONE &&
      this.args.externalSource
    ) {
      this.sapB1WalkthroughCheckboxes.map((checkbox) => {
        checkbox.checked = true;
      });

      this.squareWalkthroughCheckbox.checked = true;
      this.toggleSAPB1Checklist(true);
    }

    if (this.args.selectedGatewaySystemType && this.args.code) {
      this.fetchAndDisplaySourceSettingsForm();
    }

    if (
      this.args.selectedGatewaySystemType ===
      GatewaySystemType.FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT
    ) {
      this.toggleLastChecklist(true);
    }
  }

  sapConnectorSetupUrl = `${ENV.frontEndServiceUrl}/api/v1/signed-url/sap-connector-setup`;

  businessCentralAuthenticationLink: string = `${ENV.frontEndServiceUrl}/api/v1/oauth/business-central/authorize`;

  installingG2cDownloadUrl: string = `${ENV.frontEndServiceUrl}/api/v1/signed-url/installing-g2c`;

  friendlies = friendlies;

  @service config!: Config;

  @tracked
  configBlueprint: ConfigFormBlueprint | null = null;

  @tracked
  showSapB1Checklist = false;

  @tracked
  showSquareModiferUnitSetupChecklist = false;

  @tracked
  showBusinessCentralOauthButton = false;

  @tracked
  displaySourceSettingsForm = false;

  @tracked
  settingsFormLoading = false;

  @tracked
  sapB1WalkthroughCheckboxes: WalkthroughCheckbox[] = [
    {
      nameValue: 'sapb1-udf_confirm',
      walkthroughLink:
        'https://developer.squareup.com/docs/squarebridge/sapb1/create-udf',
      walkthroughLinkLabel: 'Create UDFs in SAPB1',
      changeHandler: this.handleSapB1ChecklistChange,
      checked: false,
    },
    {
      nameValue: 'sapb1-stored-procedures_confirm',
      walkthroughLink:
        'https://developer.squareup.com/docs/squarebridge/sapb1/deploy-stored-procedures',
      walkthroughLinkLabel: 'Deploy stored procedures in SAPB1 SQL Server',
      changeHandler: this.handleSapB1ChecklistChange,
      checked: false,
    },
    {
      nameValue: 'g2c_download',
      walkthroughLink: this.sapConnectorSetupUrl,
      walkthroughLinkLabel: 'Download Ground2Cloud',
      changeHandler: this.handleSapB1ChecklistChange,
      checked: false,
    },
    {
      nameValue: 'g2c_confirm',
      walkthroughLink: this.installingG2cDownloadUrl,
      walkthroughLinkLabel: 'Install Ground2Cloud',
      changeHandler: this.handleSapB1ChecklistChange,
      checked: false,
    },
  ];

  @tracked
  squareWalkthroughCheckbox: WalkthroughCheckbox = {
    nameValue: 'square-unit-modifier_confirm',
    walkthroughLink:
      'https://developer.squareup.com/docs/squarebridge/units-and-modifiers',
    walkthroughLinkLabel:
      'Create all corresponding units and modifiers in Square',
    changeHandler: this.handleLastChecklistChange,
    checked: !!sessionStorage.getItem('square-unit-modifier_confirm'),
  };

  @action
  setExternalSource(gatewaySystemType: GatewaySystemType): void {
    this.resetForm();
    this.args.handleExternalSourceDropdownSelection(gatewaySystemType);
    switch (gatewaySystemType) {
      case GatewaySystemType.BUSINESS_CENTRAL:
        this.showBusinessCentralOauthButton = true;
        break;
      case GatewaySystemType.SAP_BUSINESS_ONE:
        this.toggleSAPB1Checklist(true);
        break;
      case GatewaySystemType.NETSUITE:
        this.fetchAndDisplaySourceSettingsForm();
        break;
      case GatewaySystemType.FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT:
      default:
        this.toggleLastChecklist(true);
        break;
    }
  }

  @action
  handleLastChecklistChange(checkboxEvent: Event) {
    if ((<HTMLInputElement>checkboxEvent.target).checked) {
      this.fetchAndDisplaySourceSettingsForm();
      sessionStorage.setItem(
        (<HTMLInputElement>checkboxEvent.target).name,
        'checked'
      );
    } else {
      this.displaySourceSettingsForm = false;
      this.args.setContinueDisabled(true);
      sessionStorage.setItem(
        (<HTMLInputElement>checkboxEvent.target).name,
        'checked'
      );
    }
  }

  @action
  handleSapB1ChecklistChange(checkboxEvent: Event) {
    this.handleChecklistChange(this.sapB1WalkthroughCheckboxes, checkboxEvent);
  }

  handleChecklistChange(
    elementCheckboxes: WalkthroughCheckbox[],
    checkboxEvent: Event
  ) {
    const eventTarget = <HTMLInputElement>checkboxEvent.target;
    const checkbox = elementCheckboxes.find(
      (checkbox) => checkbox.nameValue === eventTarget.name
    );

    if (!checkbox) return;

    if (eventTarget.checked) {
      checkbox.checked = true;
      if (
        elementCheckboxes.filter((checkbox) => checkbox.checked).length ===
        elementCheckboxes.length
      ) {
        this.toggleLastChecklist(true);
      }
      sessionStorage.setItem(checkbox.nameValue, 'checked');
    } else {
      checkbox.checked = false;
      this.toggleLastChecklist(false);
      this.displaySourceSettingsForm = false;
      sessionStorage.removeItem(checkbox.nameValue);
    }
  }

  @action
  fetchAndDisplaySourceSettingsForm() {
    this.settingsFormLoading = true;
    if (this.args.externalSource) {
      this.config
        .fetchExistingSourceConfig(this.args.externalSource.id)
        .then((json) => {
          this.configBlueprint = json.configuration_blueprint;
          this.displaySourceSettingsForm = true;
          this.settingsFormLoading = false;
          this.args.setContinueDisabled(false);
        });
    } else {
      this.config
        .fetchNewSourceConfig(this.args.selectedGatewaySystemType)
        .then((json) => {
          this.configBlueprint = json.configuration_blueprint;
          this.displaySourceSettingsForm = true;
          this.settingsFormLoading = false;
        });
    }
  }

  @action
  toggleSAPB1Checklist(doShow: boolean): void {
    this.showSapB1Checklist = doShow;
    if (
      this.sapB1WalkthroughCheckboxes.filter((checkbox) => checkbox.checked)
        .length === this.sapB1WalkthroughCheckboxes.length
    ) {
      this.toggleLastChecklist(true);
    } else {
      this.toggleLastChecklist(false);
    }
  }

  @action
  toggleLastChecklist(doShow: boolean): void {
    this.showSquareModiferUnitSetupChecklist = doShow;
    if (
      this.showSquareModiferUnitSetupChecklist &&
      this.squareWalkthroughCheckbox.checked
    ) {
      this.fetchAndDisplaySourceSettingsForm();
    }
  }

  resetForm() {
    this.displaySourceSettingsForm = false;
    this.showSapB1Checklist = false;
    this.showBusinessCentralOauthButton = false;
  }
}
