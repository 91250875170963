import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { fuzzySearch } from '../utils/fuzzy-search';
import { SettingsOption } from '@bridge/home-engine/models/interface/settings-option';

interface SearchableDropdownSelectArgs {
  selection: SettingsOption | string;
  isLoading: boolean;
  isListCreateEnabled: boolean;
  listCreateLabel: string;
  handleChange: (value: SettingsOption) => void;
  options: SettingsOption[];
}

export default class SearchableDropdownSelect extends Component<SearchableDropdownSelectArgs> {
  @tracked input = '';

  get isListCreateEnabled(): boolean {
    return (
      (!this.args.options || this.args.isListCreateEnabled) &&
      this.input.length > 0 &&
      (!this.args.options ||
        !this.args.options.some((option) => option.value === this.input))
    );
  }

  // TODO: We might need to optimize with a debounce or speeding up the filter in the future
  get currentOptions(): SettingsOption[] {
    if (this.args.options) {
      return this.args.options.filter((option) =>
        this.isValidSearchOption(option)
      );
    } else {
      return [];
    }
  }

  get label(): string {
    const selectedValue = this.getSelectedValue();
    if(selectedValue === null || selectedValue === undefined) {
      return '';
    }
    return this.findLabel(selectedValue);
  }

  findLabel(selectedValue: string) {
    if (this.args.options) {
      const selectedOption = this.args.options.find(
        (option) => option.value === selectedValue
      );
      return (selectedOption && selectedOption.label) ?? selectedValue;
    } else if(this.isSelectionOption(this.args.selection)) {
      console.debug(`Falling back on selected option: ${JSON.stringify(this.args.selection)}`);
      return (this.args.selection as SettingsOption).label;
    } else {
      console.debug(`Falling back on selected value: ${selectedValue} from ${JSON.stringify(this.args.selection)}`);
      return selectedValue;
    }
  }

  get listCreateLabel(): string {
    return `${this.args.listCreateLabel}: ${this.input}`;
  }

  @action
  handleInput(val: string): any {
    this.input = val;
  }

  @action
  onListChange(value: string) {
    this.args.handleChange({
      label: this.findLabel(value),
      value: value
    });
    this.input = '';
  }

  @action
  onListCreate() {
    this.args.handleChange({
      label: this.input,
      value: this.input
    });
    this.input = '';
  }

  @action
  handleEnter() {
    this.onListCreate();
  }

  private isValidSearchOption(option: SettingsOption) {
    return (
      fuzzySearch(this.input, option.value) ||
      fuzzySearch(this.input, option.label)
    );
  }

  isSelectionOption(selection: SettingsOption | string): selection is SettingsOption {
    return typeof selection === "object" && 'value' in selection
  }

  private getSelectedValue() : string {
    if(this.isSelectionOption(this.args.selection)) {
      return this.args.selection.value;
    } else {
      return this.args.selection;
    }
  }

}
