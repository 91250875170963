define("@square/glass-ui/templates/components/sq-draggable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a5RrwGP4",
    "block": "{\"symbols\":[\"rowComponent\",\"item\",\"index\",\"&default\"],\"statements\":[[6,[37,9],[[30,[36,0],[\"sq-draggable-list/row\"],[[\"dragStart\",\"dragMove\",\"dragEnd\",\"dragOnlyOnHandle\"],[[30,[36,8],[[32,0],\"dragStart\"],null],[30,[36,8],[[32,0],\"dragMove\"],null],[30,[36,8],[[32,0],\"dragEnd\"],null],[35,7]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,4,[[30,[36,1],null,[[\"row\",\"handle\"],[[30,[36,0],[[32,1]],[[\"model\"],[[32,2]]]],[30,[36,0],[\"sq-draggable-list/handle\"],null]]]],[32,2],[32,3]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,4,[[30,[36,1],null,[[\"row\",\"handle\"],[[30,[36,0],[[32,1]],null],[30,[36,0],[\"sq-draggable-list/handle\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"orderedItems\",\"-track-array\",\"each\",\"shouldRenderList\",\"if\",\"dragOnlyOnHandle\",\"action\",\"with\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-draggable-list.hbs"
    }
  });

  _exports.default = _default;
});