define("@square/glass-ui/templates/components/sq-card/attributes-table/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zK93o/sw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"tr\"],[14,0,\"card__attributes-table__row\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"cell\"],[[30,[36,0],[\"sq-card/attributes-table/header-cell\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-card/attributes-table/header.hbs"
    }
  });

  _exports.default = _default;
});