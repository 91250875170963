define("@square/glass-ui/templates/components/selector-control/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pLlc3MM/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,6],null,[[\"item\",\"next\",\"previous\"],[[30,[36,2],[\"selector-control/navigation/item\"],[[\"type\",\"onSelect\",\"activeItems\"],[[35,5],[30,[36,0],[[32,0],\"onSelectItem\"],null],[35,4]]]],[30,[36,2],[\"selector-control/navigation/button\"],[[\"disabled\",\"actionType\",\"navigate\"],[[35,3],\"NEXT\",[30,[36,0],[[32,0],\"navigate\",\"NEXT\"],null]]]],[30,[36,2],[\"selector-control/navigation/button\"],[[\"disabled\",\"actionType\",\"navigate\"],[[35,1],\"PREVIOUS\",[30,[36,0],[[32,0],\"navigate\",\"PREVIOUS\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isFirstItem\",\"component\",\"isLastItem\",\"activeItems\",\"type\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/selector-control/navigation.hbs"
    }
  });

  _exports.default = _default;
});