define("@square/glass-ui/components/selector-control/navigation", ["exports", "@square/glass-ui/components/selector-control/navigation/item", "@square/glass-ui/templates/components/selector-control/navigation"], function (_exports, _item, _navigation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _navigation.default,
    tagName: 'nav',
    classNames: ['selector-control__navigation'],
    classNameBindings: ['navTypeClass'],
    attributeBindings: ['data-test-selector-control-navigation'],
    'data-test-selector-control-navigation': true,
    type: _item.SELECTOR_TYPE.TAB,
    navTypeClass: Ember.computed('type', function () {
      switch (this.get('type')) {
        case _item.SELECTOR_TYPE.STEP:
          return 'breadcrumbs';

        case _item.SELECTOR_TYPE.TAB:
          return 'tab-set';

        case _item.SELECTOR_TYPE.SEGMENT:
          return 'segmented-control';

        default:
          return '';
      }
    }),
    actions: {
      onSelectItem: function onSelectItem(item) {
        this.onSelectItem(item);
      },
      navigate: function navigate(direction) {
        this.onNavigate(direction);
      }
    }
  });

  _exports.default = _default;
});