define("@square/glass-ui/templates/components/media-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jh9M9rW1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"content\",\"media\"],[[30,[36,2],[\"media-block/content\"],null],[30,[36,2],[\"media-block/media\"],[[\"shouldPreventMediaInteract\"],[[30,[36,1],[[35,0],\"stacked\"],null]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"mediaLayout\",\"eq\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/media-block.hbs"
    }
  });

  _exports.default = _default;
});