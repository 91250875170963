import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AuthorizedRouteIntegrationSyncDataCustomersShow extends Controller {

  @action
  close() {
    this.transitionToRoute('authorized-route.integration.sync-data.customers');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/customers/show': AuthorizedRouteIntegrationSyncDataCustomersShow;
  }
}
