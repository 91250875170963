define("@square/glass-ui/components/sq-option/label/primary", ["exports", "@square/glass-ui/templates/components/sq-option/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _label.default,
    tagName: 'p',
    classNames: ['option__label--primary'],
    attributeBindings: ['data-test-option-label-text'],
    'data-test-option-label-text': 'primary'
  });

  _exports.default = _default;
});