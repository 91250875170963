import DailyInvoiceDetailBladeService from "@bridge/home-engine/services/daily-invoice-detail-blade-service";
import Route from "@ember/routing/route";
import {inject as service } from '@ember/service';

export default class DailyInvoiceDetailRoute extends Route {
  @service dailyInvoiceDetailBladeService!: DailyInvoiceDetailBladeService;

  model(params: {groupId: string}) {
    this.dailyInvoiceDetailBladeService.fetch(params.groupId);
    return params.groupId;
  }
}