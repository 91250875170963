import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import SourceState from '../models/services/source/source-state';
import FlashService from '@square/glass-ui/addon/services/flash';
interface SourceStateIndicatorArgs {
  sourceState: string;
}

export default class SourceStateIndicator extends Component<SourceStateIndicatorArgs> {
  @service flash!: FlashService;

  constructor(owner: unknown, args: SourceStateIndicatorArgs) {
    super(owner, args);
    if (this.args.sourceState) {
      try {
        const decodedSourceState = this.decodeSourceState(this.args.sourceState);
        if (decodedSourceState && decodedSourceState.status === 200) {
          this.flash.globalSuccess('Successfully reauthorized with Finance & Supply Chain Management', {
            persistent: true,
            dismiss: () => {
              this.flash.clearSheetMessage();
            },
          });
        } else if (decodedSourceState) {
          let errorMessage = decodedSourceState.message
            ? decodedSourceState.message
            : 'There was a problem connecting to Finance & Supply Chain Management';
          this.flash.globalError(errorMessage, {
            persistent: true,
            dismiss: () => {
              this.flash.clearSheetMessage();
            },
          });
        }
      } catch (error: any) {
        console.log('Error decoding source_state query parameter')
      }
    }
  }

  decodeSourceState(sourceState: string): SourceState | null {
    if (sourceState) {
      try {
        return JSON.parse(atob(sourceState));
      } catch (error: any) {
        return null;
      }
    } else {
      return null;
    }
  }
}
