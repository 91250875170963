
/**
  ## Category Aggregation Settings Table

  Table for managing order category aggregations. 

  ```hbs
  <CategoryAggregationSettingsTable />
  ```

*/

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';
import Location from '../models/services/location/location';
import FlashService from '@square/glass-ui/addon/services/flash';
import { CategoryAggregationRow } from '../models/services/category-aggregations/category-aggregation-row';
import {
  CategoryAggregationMapping,
  toCategoryAggregationMapping,
} from '../models/services/category-aggregations/category-aggregation-mapping';
import { toUpsertCategoryAggregationMappingsRequest } from '../models/services/category-aggregations/upsert-category-aggregation-mappings-request';


interface CategoryAggregationSettingsTableArgs {}
export default class CategoryAggregationSettingsTable extends Component<
  CategoryAggregationSettingsTableArgs
> {
  constructor(owner: unknown, args: CategoryAggregationSettingsTableArgs) {
    super(owner, args);

    this.fetchOrderLocations().then(() => {
      if (this.locations.length) {
        this.locationId = this.locations[0].square_id;
        return this.getCategoryAggregationMappings(this.locationId);
      } else {
        this.showEmptyState = true;
      }
    });
  }

  @service data!: Data;
  @service flash!: FlashService;

  @tracked
  showEmptyState = false;

  @tracked
  locationId = '';

  @tracked
  locationFieldMap = new Map();

  @tracked
  isLocationsLoading = true;

  @tracked
  isTableLoading = true;

  loadingText = 'Loading sync schedule settings...';

  locations: Array<Location> = [];

  @tracked
  categoryAggregationRows: Array<CategoryAggregationRow> = [];

  uniqueIdentifierKey = 'sync_definition_id';

  @tracked
  isButtonsDisabled = true;

  @tracked
  isSaveButtonLoading = false;

  @tracked
  hasChanges = false;

  searchOptionResourcePayload = {
    "gateway_type": "NETSUITE",
    "request_path": "NONINVENTORY_ITEMS",
    "request_method": "GET",
    "loading_strategy": "SEARCH",
    "option_resource_metadata": {
      "field_name": "orders_sync_configuration[category_mapping]",
      "field_target": "options",
      "value_response_json_path": "$.[?(@)].id",
      "label_response_json_path": "$.[?(@)].name",
      "label_excludes_value": false
    }
  };

  @action async init() {}

  @action async fetchOrderLocations() {
    try {
      const orderLocations = await this.data.getLocationsBySyncType('ORDERS');
      this.locations = orderLocations;
      this.locationFieldMap = this.locations.reduce((map, location) => {
        map.set(location.square_id, {
          value: location.square_id,
          name: location.square_name,
          label: location.square_name,
        });
        return map;
      }, new Map());
    } catch (error) {
      this.flash.globalError(`Square Order locations failed to load. ${error}`, {
        dismiss: () => {
          this.flash.clearGlobalMessage();
        },
      });
    }

    this.isLocationsLoading = false;
  }

  @action
  save(): void {
    this.isSaveButtonLoading = true;
    this.saveCategoryAggregationMappings();
  }

  @action
  onLocationChange(locationId: string) {
    this.locationId = locationId;
    this.getCategoryAggregationMappings(locationId);
  }

  @action
  reset() {
    this.categoryAggregationRows = [];
  }

  @action
  getCategoryAggregationMappings(locationId: string) {
    this.isTableLoading = true;

    this.reset();

    this.data
      .getCategoryAggregationMappings(locationId)
      .then((categoryAggregationMappingRows) => {
        this.categoryAggregationRows = categoryAggregationMappingRows;
        this.sortRowsByCategoryName()
        this.isTableLoading = false;
      })
      .catch((error) => {
        this.isTableLoading = false;
        this.flash.globalError(`Category aggregation mappings failed to load. ${error}`, {
          dismiss: () => {
            this.flash.clearGlobalMessage();
          },
        });
      });
  }



  @action
  switchChangeHandler(categoryAggregationRow: CategoryAggregationRow) {
    const newCategoryAggregationRow = {
      ...this.findRow(categoryAggregationRow.square_id),
      enabled: !categoryAggregationRow.enabled,
    };

    this.update(newCategoryAggregationRow);
    this.refreshRows();
  }

  get currentLocationField() {
    return this.locationFieldMap.get(this.locationId);
  }

  get locationFieldOptions() {
    return Array.from(this.locationFieldMap.values());
  }

  get rows() {
    return this.categoryAggregationRows;
  }

  findRow(squareId: string) {
    return this.categoryAggregationRows.find(
      (row) => row.square_id === squareId
    )!;
  }

  findRowIndex(squareId: string) {
    return this.categoryAggregationRows.findIndex(
      (row) => row.square_id === squareId
    )!;
  }

  get categoryAggregationMappings() {
    return this.categoryAggregationRows.map(toCategoryAggregationMapping);
  }

  @action refreshRows() {
    this.categoryAggregationRows = [...this.categoryAggregationRows];
  }

  @action
  update(categoryAggregationRow: CategoryAggregationRow) {
    const rowIdx = this.findRowIndex(categoryAggregationRow.square_id);
    this.categoryAggregationRows.splice(rowIdx, 1, categoryAggregationRow);
    this.hasChanges = true;
  }

  @action
  inputHandler(categoryAggregationRow: CategoryAggregationRow, e: any): void {
    const newCategoryAggregationRow = {
      ...this.findRow(categoryAggregationRow.square_id),
      external_id: e.detail.value,
    };
    this.update(newCategoryAggregationRow);
  }

  @action
  inputHandlerMarket(categoryAggregationRow: CategoryAggregationRow, value: string): void {
    const newCategoryAggregationRow = {
      ...this.findRow(categoryAggregationRow.square_id),
      external_id: value,
    };
    this.update(newCategoryAggregationRow);
  }

  @action
  sortRowsByCategoryName() {
    this.categoryAggregationRows.sort((a, b) => a.category_name && b.category_name ? a.category_name.localeCompare(b.category_name) : 0)
    this.refreshRows()
  }

  mergeCategoryAggregationMappingIds(
    categoryAggregationMappings: CategoryAggregationMapping[]
  ) {
    const categoryAggregationMappingIdMap = categoryAggregationMappings.reduce(
      (map, categoryAggregationMapping) => {
        map.set(
          categoryAggregationMapping.square_id,
          categoryAggregationMapping.id
        );
        return map;
      },
      new Map()
    );

    this.categoryAggregationRows = this.categoryAggregationRows.map((row) => ({
      ...row,
      category_aggregation_mapping_id: categoryAggregationMappingIdMap.get(
        row.square_id
      ),
    }));
  }

  saveCategoryAggregationMappings(): void {
    this.data
      .upsertCategoryAggregationMappings(
        this.locationId,
        toUpsertCategoryAggregationMappingsRequest(
          this.categoryAggregationMappings
        )
      )
      .then((categoryAggregationMappings) => {
        this.mergeCategoryAggregationMappingIds(categoryAggregationMappings);
        this.isSaveButtonLoading = false;
        this.flash.globalSuccess(
          'Category aggregation settings successfully saved!'
        );

        this.hasChanges = false;
      })
      .catch((error) => {
        this.flash.globalError(error, {
          dismiss: () => {
            this.flash.clearGlobalMessage();
          },
        });
        this.isSaveButtonLoading = false;
        this.isButtonsDisabled = false;
      });
  }
}
