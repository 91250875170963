import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { DropdownFilterUnit } from './../../../../models/interface/dropdown-filter-unit';


import { EngineRouterService } from 'ember-engines-router-service';
export default class AuthorizedRouteIntegrationSyncDataCatalog extends Controller {
  @service router!: EngineRouterService;

  queryParams: Array<string> = ['state', 'entity_id', 'entity_type'];

  entity_id: string | null = null;
  state: string | null = null;
  entity_type: string | null = null;

  entityTypes: Array<DropdownFilterUnit> = [
    { label: 'All Types', value: undefined },
    { label: 'Catalog Category', value: 'CATALOG_CATEGORY' },
    { label: 'Catalog Tax', value: 'CATALOG_TAX' },
    { label: 'Catalog Item', value: 'CATALOG_ITEM' },
    { label: 'Catalog Item Variation', value: 'CATALOG_ITEM_VARIATION' },
    { label: 'Catalog Discount', value: 'CATALOG_DISCOUNT' },
  ];

  @action
  refreshModel() {
    this.send('refreshCurrentRoute');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/catalog': AuthorizedRouteIntegrationSyncDataCatalog;
  }
}
