import Service, { inject as service } from '@ember/service';
/* @ts-ignore TSLint doesn't identify, but still uses the following module. */
import { EngineRouterService } from 'ember-engines-router-service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import Auth from 'bridge-dashboard/app/services/auth';
import FetchHandler from 'bridge-dashboard/app/services/fetch-handler';
import ENV from '@bridge/home-engine/config/environment';
import { SettingsFormType } from '@bridge/home-engine/models/interface/settings-form-type';

enum FormElements {
  hidden = 'hidden',
  input = 'input',
  select = 'select',
  select_searchable = 'select-searchable',
}

enum FormElementTypes {
  checkbox = 'checkbox',
  checkbox_set = 'checkbox-set',
  password = 'password',
  switch = 'switch',
  text = 'text',
  url = 'url',
}

export interface GetSettingsFormResponse {
  configuration_blueprint: ConfigFormBlueprint;
  configuration_data: Array<ConfigFormDataItem>;
}

export interface GetSettingsOptionsResponse {
  data: Array<ConfigFormDataItem>;
}

interface RetrieveSettingsOptionsRequest {
  settingsFormType: SettingsFormType;
  optionResource: ConsolidatedMappingResource;
  valueResources: Array<ConsolidatedFieldResource>;
  configObjectId?: string;
}

export interface ConfigFormBlueprint {
  system_name: string;
  type: string;
  label: string;
  sections: Array<FormSection>;
  mapping_field_sources: Array<MappingFieldSource>;
  mapping_field_option_resources: Array<ConsolidatedMappingResource>;
  resources: Array<ConsolidatedFieldResource>;
}

interface SettingsFormResource {
  gateway_type: string;
  request_path: string;
  request_method: string;
  request_parameters: Array<RequestParameter>;
  metadata: Array<FormResourceMetadata>;
  loading_strategy: LoadingStrategy;
}

export interface ConsolidatedFieldResource extends SettingsFormResource {
  field_resource_metadata: Array<FieldResourceMetadata>;
}

export interface FieldResourceMetadata {
  response_json_path: string;
  field_name: string;
  field_target: string;
  property_value_on_match: string;
}

export interface ConsolidatedMappingResource extends SettingsFormResource {
  option_resource_metadata: OptionResourceMetadata;
}

export interface RequestParameter {
  name: string;
  value: string;
}

export interface FormResourceMetadata {
  field_name: string;
  field_target: string;
}

export enum LoadingStrategy {
  FRONT = 'FRONT',
  LAZY = 'LAZY',
  REFRESH = 'REFRESH',
  SEARCH = 'SEARCH',
}

export interface OptionResourceMetadata extends FormResourceMetadata {
  value_response_json_path: string;
  label_response_json_path: string;
}

export interface MappingFieldSource {
  element: string;
  field_name_template: string;
  gateway_type: string;
  hidden_field_name_template: string;
  placeholder: string;
  request_method: string;
  request_path: string;
  section_label: string;
  loading_strategy: string;
  options: Array<FormFieldOptionObject>;
}

export interface ConfigFormDataItem {
  field_name: string;
  target_property: string;
  field_value: any;
}

export interface FormSection {
  label: string;
  required_for: Array<string>;
  intro_text?: string;
  error_text?: string;
  service?: FormServiceObject;
  fields: Array<FormFieldObject>;
  two_column?: boolean;
}

export interface FormServiceObject {
  name: string;
  uri: string;
}

export interface FormFieldOptionObject {
  label: string;
  name?: string;
  value: string;
  checked?: boolean;
}

export interface FormFieldObject {
  label: string;
  control_field?: boolean;
  is_custom_value_allowed?: boolean;
  required_for?: Array<string>;
  disabled: boolean;
  searchable: boolean;
  service: FormServiceObject;
  element: FormElements;
  type?: FormElementTypes;
  placeholder?: string;
  tip?: string;
  doc?: string;
  options?: Array<FormFieldOptionObject>;
  name: string;
  checked?: boolean;
  value: any;
  state: string;
  yup_validations?: Array<Array<string | boolean | number>>;
}

export interface SearchSettingsOptionRequest {
  settings_form_type: SettingsFormType;
  option_resource: ConsolidatedMappingResource;
  query: string;
  cursor: string | null;
}

export interface SearchSettingsOptionResponse {
  data: ConfigFormDataItem;
  cursor: string | null;
}

export default class Config extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  @service auth!: Auth;
  @service currentUser!: CurrentUser;
  @service fetchHandler!: FetchHandler;
  @service router!: EngineRouterService;
  sourceConfig = {};

  resourcePrefix = '/api/v1';

  // normal class body definition here
  fetchNewSourceConfig(elementType: string) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/settings/sources/elements/${elementType}`
    );
  }

  fetchExistingSourceConfig(
    sourceId: string = this.currentUser!.currentExternalSource!.id!
  ) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${
        this.currentUser!.currentIntegration!.id
      }/settings/sources/${sourceId}`
    );
  }

  fetchLocationConfig(locationId: string) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${
        this.currentUser!.currentIntegration!.id
      }/settings/locations/${locationId}`
    );
  }

  fetchFieldOptions(
    settingsTarget: SettingsFormType,
    optionResource: ConsolidatedMappingResource,
    valueResources: ConsolidatedFieldResource[],
    configObjectId?: string
  ) {
    const url = `${this.resourcePrefix}/integrations/${
      this.currentUser!.currentIntegration!.id
    }/settings/options`;
    const request: RetrieveSettingsOptionsRequest = {
      settingsFormType: settingsTarget,
      optionResource,
      valueResources,
      configObjectId,
    };
    return this.fetchHandler.doPostAndHandleResponse<GetSettingsOptionsResponse>(
      url,
      request
    );
  }

  fetchSearchableFieldOptions(request: SearchSettingsOptionRequest) {
    const url = `${this.resourcePrefix}/integrations/${
      this.currentUser!.currentIntegration!.id
    }/settings/options/search`;
    return this.fetchHandler.doPostAndHandleResponse<SearchSettingsOptionResponse>(
      url,
      request
    );
  }

  fetchIntegrationConfig(integrationId: string) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${integrationId}/settings/integration`
    );
  }

  fetchMeasurementUnitConfig(integrationId: string) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${integrationId}/settings/measurement-unit`
    );
  }

  fetchItemModifierConfig(integrationId: string) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${integrationId}/settings/item-modifier`
    );
  }

  fetchDiscountConfig(integrationId: string) {
    return this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${integrationId}/settings/discount`
    );
  }


  async fetchTaxConfig(integrationId: string) {
    return await this.fetchHandler.doGetAndHandleResponse<GetSettingsFormResponse>(
      `${this.resourcePrefix}/integrations/${integrationId}/settings/tax`
    );
  }

  fetchObjectTypeConfig(objectTypeId: string) {
    return fetch(`${this.baseUrl()}/object_types/${objectTypeId}`, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json());
  }

  private baseUrl(): string {
    const integrationId: string = this.currentUser!.currentIntegration!.id!;
    return `${ENV.frontEndServiceUrl}/api/v1/integrations/${integrationId}/settings`;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    config: Config;
  }
}
