define("@square/glass-ui/components/sq-unavailable", ["exports", "@square/glass-ui/templates/components/sq-unavailable"], function (_exports, _sqUnavailable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqUnavailable.default,
    classNames: ['unavailable'],
    iconClassName: 'icon-null-beta',
    shouldShowIcon: Ember.computed.bool('iconClassName'),
    shouldShowHint: Ember.computed.notEmpty('hint'),
    svgClassName: '',
    shouldShowSvg: Ember.computed.bool('svg')
  });

  _exports.default = _default;
});