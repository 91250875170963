define("@square/glass-ui/templates/components/sq-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B83tY23n",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"data-test-switch-input\",\"type\",\"class\",\"name\",\"disabled\",\"checked\",\"tabindex\",\"change\"],[[30,[36,6],[[35,5],true],null],\"checkbox\",\"option-toggle__input switch__input\",[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onChange\"],[[\"value\"],[\"target.checked\"]]]]]]],[2,\"\\n\"],[10,\"span\"],[14,0,\"switch__paddle\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"tabindex\",\"checked\",\"disabled\",\"name\",\"value\",\"or\",\"input\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-switch.hbs"
    }
  });

  _exports.default = _default;
});