define("@square/glass-ui/components/svg/icon-close-x", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-close-x"], function (_exports, _svgIcon, _iconCloseX) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconCloseX.default,
    classNames: ['svg-icon--close-x'],
    viewBox: '1.5 1.5 13 13',
    width: 12,
    height: 12
  });

  _exports.default = _default;
});