define("@square/glass-ui/templates/components/sq-card/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOL7Ld7l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-card/body.hbs"
    }
  });

  _exports.default = _default;
});