define("@square/glass-ui/templates/components/sq-sorter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WPgFoSf2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"sortedContent\",\"sort\",\"sortDefault\",\"sortKey\",\"button\"],[[35,6],[30,[36,0],[[32,0],\"toggleSort\"],null],[35,3],[35,4],[30,[36,5],[\"sq-sorter/sorter-button\"],[[\"currentSortKey\",\"sortDefault\",\"tagName\",\"class\",\"sort\"],[[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"toggleSort\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"sorterButtonClass\",\"sorterButtonTagName\",\"_sortDefault\",\"_sortKey\",\"component\",\"sortedContent\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-sorter.hbs"
    }
  });

  _exports.default = _default;
});