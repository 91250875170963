import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AuthorizedRouteIntegrationAuditShow extends Controller {
  @action
  close(): void {
    this.transitionToRoute('authorized-route.integration.audit');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/audit/show': AuthorizedRouteIntegrationAuditShow;
  }
}
