import { helper } from '@ember/component/helper';

const regex = /_+|-+/g;
const replacement = ' ';

// The substituted value will be contained in the result variable
export function humanize(params: string[]) {
  let str = params[0];

  if (params === undefined || params === null) {
    return '';
  }

  let result = str.toLowerCase().replace(regex, replacement);
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default helper(humanize);