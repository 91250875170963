define("@square/glass-ui/templates/components/sq-unavailable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQG4wqdX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,11],[[35,10]],[[\"width\",\"height\",\"originalSvgWidth\",\"originalSvgHeight\",\"class\"],[[35,9],[35,8],[35,7],[35,6],[30,[36,5],[\"unavailable--icon \",[35,4]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[\"unavailable--icon \",[34,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"unavailable--header\"],[12],[2,\"\\n  \"],[1,[34,13]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"unavailable--hint\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hint\",\"iconClassName\",\"shouldShowIcon\",\"if\",\"svgClassName\",\"concat\",\"originalSvgHeight\",\"originalSvgWidth\",\"svgHeight\",\"svgWidth\",\"svg\",\"component\",\"shouldShowSvg\",\"header\",\"shouldShowHint\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-unavailable.hbs"
    }
  });

  _exports.default = _default;
});