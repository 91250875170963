export enum GatewaySystemType {
  SAP_BUSINESS_ONE = "SAP_BUSINESS_ONE",
  NETSUITE = "NETSUITE",
  BUSINESS_CENTRAL = "BUSINESS_CENTRAL",
  SQUARE = "SQUARE",
  FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT = "FINANCE_AND_SUPPLY_CHAIN_MANAGEMENT"
}

export function getExternalTypes(): GatewaySystemType[] {
  return Object.values(GatewaySystemType).filter(isExternal);
}

export function isExternal(type: GatewaySystemType) {
  return type !== GatewaySystemType.SQUARE;
}
