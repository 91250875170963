define("@square/glass-ui/templates/components/svg/icon-alert-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bMq53vLk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M0.40911 13.7584L7.56222 0.79348C7.75236 0.448865 8.24767 0.448865 8.4378 0.793481L15.5909 13.7585C15.7747 14.0917 15.5337 14.5 15.1531 14.5H0.846898C0.466303 14.5 0.225253 14.0917 0.40911 13.7584ZM7.99999 9.00145C7.44771 9.00145 6.99999 8.55373 6.99999 8.00145V6.00187C6.99999 5.44958 7.44771 5.00187 7.99999 5.00187C8.55228 5.00187 8.99999 5.44958 8.99999 6.00187V8.00145C8.99999 8.55373 8.55228 9.00145 7.99999 9.00145ZM7.99999 10.001C7.44771 10.001 6.99999 10.4486 6.99999 11.0008C6.99999 11.553 7.44771 12.0006 7.99999 12.0006C8.55228 12.0006 8.99999 11.553 8.99999 11.0008C8.99999 10.4486 8.55228 10.001 7.99999 10.001Z\"],[14,\"fill\",\"black\"],[14,\"fill-opacity\",\"0.9\"],[14,0,\"svg-icon__fill\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-alert-triangle.hbs"
    }
  });

  _exports.default = _default;
});