define("@square/glass-ui/components/sq-field-checkboxes", ["exports", "@square/glass-ui/templates/components/sq-field-checkboxes", "@square/glass-ui/utils/field-element-support", "@square/glass-ui/utils/field-checkbox-focus-support"], function (_exports, _sqFieldCheckboxes, _fieldElementSupport, _fieldCheckboxFocusSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldElementSupport.default, _fieldCheckboxFocusSupport.default, {
    layout: _sqFieldCheckboxes.default,
    tagName: 'fieldset',
    disabled: false,
    classNames: ['option-set', 'checkbox-set'],

    /* You may notice that the parameters passed into this function are in a
    different order from those that are sent out in the onChange action.
    This is because the first param `index`, is passed in through this component's
    template, while the following 2 come directly from the `sq-checkbox` component.
    We pass `checked` as the first param to the onChange action so that consuming
    components can use the (mut) template helper instead of needing to write a
    separate action to consume a second or third param */
    actions: {
      handleChange: function handleChange(index, checked, value) {
        Ember.set(this.get('options')[index], 'checked', checked);

        if (this.get('onChange')) {
          this.get('onChange')(checked, this.get('options'), index, value, this.get('options')[index]);
        }
      }
    }
  });

  _exports.default = _default;
});