import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import DailyInvoicesService from '../services/daily-invoices-service';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import BridgeSquareLocation from '../models/services/location/square_location';
import { ListDailyInvoiceLocationsResponse } from '../models/services/daily-invoices/list-daily-invoice-locations-response';
import { action } from '@ember/object';
import { MultiSelectOption } from './glass-multi-select';
import { tracked } from '@glimmer/tracking';
import { EntityType } from '../models/services/entity-types';
import { SearchDailyInvoiceResponse } from '../models/services/daily-invoices/search-daily-invoices-response';
import { DailyInvoiceState } from '../models/services/daily-invoices/daily-invoice-state';
import * as moment from 'moment';
import { Router } from '@ember/routing';
import DailyInvoiceDetailBladeService from '../services/daily-invoice-detail-blade-service';

interface MarketDatePickerRangeChangedEventDetail {
  startDate: string;
  endDate: string;
}

export const ALL_DAILY_INVOICE_TYPES = <const>[
  EntityType.ORDER,
  EntityType.CREDIT_MEMO,
  EntityType.PAYMENT,
  EntityType.REFUND,
];

export interface DailyInvoiceStateRow {
  groupId: string;
  date: string;
  type: string;
  location: string;
  state: string;
  cssClass: string;
  details: string;
}

export default class DailyInvoices extends Component<Record<string, never>> {
  @service dailyInvoicesService!: DailyInvoicesService;
  @service dailyInvoiceDetailBladeService!: DailyInvoiceDetailBladeService;
  @service currentUser!: CurrentUser;
  @service router!: Router;

  cursor?: string;
  dailyInvoiceLocations: MultiSelectOption[] = [];

  entityTypeOptions: MultiSelectOption[] = [
    {
      value: EntityType.ORDER,
      name: 'Orders',
    },
    {
      value: EntityType.CREDIT_MEMO,
      name: 'Returns',
    },
    {
      value: EntityType.PAYMENT,
      name: 'Payments',
    },
    {
      value: EntityType.REFUND,
      name: 'Refunds',
    },
  ];

  selectedLocationIds: string[] = [];
  selectedTypes: EntityType[] = [...ALL_DAILY_INVOICE_TYPES];

  @tracked selectedStartDate: string;
  @tracked selectedEndDate: string;
  @tracked isLoadingLocations = true;
  @tracked isLoadingDailyInvoiceRows = true;
  @tracked dailyInvoiceRows: DailyInvoiceStateRow[] = [];
  @tracked locationLabel = '';
  @tracked typeLabel = '';

  get showEmptyState(): boolean {
    return (
      !this.isLoadingLocations &&
      !this.isLoadingDailyInvoiceRows &&
      this.dailyInvoiceRows.length === 0
    );
  }

  get showDailyInvoicesTable(): boolean {
    return (!this.isLoadingLocations && !this.isLoadingDailyInvoiceRows) || this.dailyInvoiceRows.length > 0;
  }

  get dateButtonTrigger(): string {
    if (this.selectedStartDate && this.selectedEndDate) {
      if (this.isDateSelectionEqual()) {
        return new Date(this.selectedStartDate).toLocaleDateString();
      }
      return `${new Date(
        this.selectedStartDate
      ).toLocaleDateString()} - ${new Date(
        this.selectedEndDate
      ).toLocaleDateString()}`;
    }
    if (this.selectedStartDate && !this.selectedEndDate) {
      return new Date(this.selectedStartDate).toLocaleDateString();
    }
    if (this.selectedEndDate) {
      return new Date(this.selectedEndDate).toLocaleDateString();
    }
    return '';
  }

  constructor(owner: unknown, args: Record<string, never>) {
    super(owner, args);

    const now: moment.Moment = moment();
    const then: moment.Moment = now.clone().subtract(1, 'month');

    this.selectedEndDate = now.toISOString();
    this.selectedStartDate = then.toISOString();

    this.dailyInvoicesService
      .listDailyInvoiceLocations(this.currentUser?.currentIntegrationId)
      .then((resp) => this.handleListDailyInvoiceLocations(resp));

    this.typeLabel = this.determineLabel(
      this.selectedTypes,
      this.entityTypeOptions.length,
      'Types'
    );
  }

  handleSearchDailyInvoices(resp: SearchDailyInvoiceResponse) {
    const resultRows: DailyInvoiceStateRow[] = resp.states.map((state) =>
      this.toDailyInvoiceStateRow(state)
    );
    this.dailyInvoiceRows = [...this.dailyInvoiceRows, ...resultRows];
    this.cursor = resp.cursor;
    this.isLoadingDailyInvoiceRows = false;
  }

  toDailyInvoiceStateRow(state: DailyInvoiceState): DailyInvoiceStateRow {
    return {
      groupId: state.group_id,
      date: this.getDate(state),
      type: this.getEntityTypeDisplayName(state),
      location: this.getLocationName(state),
      state: this.getState(state),
      cssClass: this.getCssClass(state),
      details: 'Details...',
    };
  }
  getCssClass(state: DailyInvoiceState) {
    switch (state.state) {
      case 'SUCCESSFUL':
        return 'successful-text';
      case 'FAILED':
        return 'failed-text';
      default:
        return '';
    }
  }

  handleListDailyInvoiceLocations(resp: ListDailyInvoiceLocationsResponse) {
    this.dailyInvoiceLocations = resp.locations.map((location) =>
      this.toLocationMultiSelectOption(location)
    );
    this.selectedLocationIds = resp.locations.map(
      (location) => location.location_id
    );
    this.isLoadingLocations = false;
    this.locationLabel = this.determineLabel(
      this.selectedLocationIds,
      this.dailyInvoiceLocations.length,
      'Locations'
    );
    this.searchDailyInvoices();
  }

  determineLabel(
    selectedValues: Array<string>,
    allValuesLength: number,
    pluralLabel: string
  ): string {
    const numberOfValuesSelected = selectedValues.length;
    if (numberOfValuesSelected === 0) {
      return `No ${pluralLabel}`;
    } else if (
      (numberOfValuesSelected === 1 &&
        numberOfValuesSelected !== allValuesLength) ||
      (numberOfValuesSelected > 0 && numberOfValuesSelected < allValuesLength)
    ) {
      return `${numberOfValuesSelected} ${pluralLabel}`;
    } else {
      return `All ${pluralLabel}`;
    }
  }

  toLocationMultiSelectOption(
    location: BridgeSquareLocation
  ): MultiSelectOption {
    return {
      value: location.location_id,
      name: location.location_name,
    };
  }

  @action
  onShowDetail(row: DailyInvoiceStateRow) {
    this.router.transitionTo(
      `authorized-route.integration.daily-invoices.daily-invoice-detail`,
      row.groupId
    );
  }

  private resetRows() {
    this.dailyInvoiceRows = [];
    this.cursor = undefined;
  }

  @action
  onMarketDateRangeChanged(
    event: CustomEvent<MarketDatePickerRangeChangedEventDetail>
  ) {
    this.selectedStartDate = event.detail.startDate;
    this.selectedEndDate = event.detail.endDate;
    this.resetRows();
    this.searchDailyInvoices();
  }

  @action
  onLocationsChange(locationIds: string[]) {
    this.selectedLocationIds = locationIds;
    this.resetRows();
    this.searchDailyInvoices();
  }

  @action
  onLocationsChangeMarket(event: CustomEvent) {
    if (event) {
      if (event.detail.value) {
        this.selectedLocationIds = event.detail.value.split(',');
      } else {
        this.selectedLocationIds = [];
      }
      this.locationLabel = this.determineLabel(
        this.selectedLocationIds,
        this.dailyInvoiceLocations.length,
        'Locations'
      );
      this.resetRows();
      this.searchDailyInvoices();
    }
  }

  @action
  onTypesChanged(types: EntityType[]) {
    this.selectedTypes = types;
    this.resetRows();
    this.searchDailyInvoices();
  }

  @action
  onTypesChangedMarket(event: CustomEvent) {
    if (event) {
      if (event.detail.value) {
        this.selectedTypes = event.detail.value.split(',');
      } else {
        this.selectedTypes = [];
      }
      this.typeLabel = this.determineLabel(
        this.selectedTypes,
        this.entityTypeOptions.length,
        'Types'
      );
      this.resetRows();
      this.searchDailyInvoices();
    }
  }

  searchDailyInvoices() {
    this.isLoadingDailyInvoiceRows = true;
    this.dailyInvoicesService
      .searchDailyInvoices(this.currentUser?.currentIntegrationId, {
        location_ids: this.selectedLocationIds,
        types: this.selectedTypes,
        begin_date: this.selectedStartDate,
        end_date: this.selectedEndDate || this.selectedStartDate,
        cursor: this.cursor,
      })
      .then((resp) => this.handleSearchDailyInvoices(resp));
  }

  isDateSelectionEqual() {
    return this.selectedStartDate === this.selectedEndDate;
  }

  getDate(row: DailyInvoiceState): string {
    return row.date;
  }

  getEntityTypeDisplayName(row: DailyInvoiceState): string {
    switch (row.entity_type) {
      case EntityType.ORDER:
        return 'Order';
      case EntityType.CREDIT_MEMO:
        return 'Credit Memo';
      case EntityType.PAYMENT:
        return 'Payment';
      case EntityType.REFUND:
        return 'Refund';
      default:
        return '';
    }
  }

  getLocationName(row: DailyInvoiceState): string {
    let locationName = '';
    for (const location of this.dailyInvoiceLocations) {
      if (location.value === row.location_id) {
        locationName = location.name;
      }
    }
    return locationName;
  }

  getState(row: DailyInvoiceState): string {
    switch (row.state) {
      case 'FAILED':
        return 'Failed';
      case 'SUCCESSFUL':
        return 'Succeeded';
      default:
        return '';
    }
  }

  @action loadMore(): void {
    if (this.cursor && !this.isLoadingDailyInvoiceRows) {
      this.searchDailyInvoices();
    }
  }
}
