import SyncJobType from '../sync-type-status/sync-job-type';
import { CategoryAggregationMapping } from '../category-aggregations/category-aggregation-mapping'
enum SyncDirection {
  DIRECTIONAL = 'DIRECTIONAL',
  BIDIRECTIONAL = 'BIDIRECTIONAL',
}

//Stubbed
export interface ServiceWindow {}

//Stubbed
export interface OrderAdhocTaxMatchRule {}

export interface OrdersSyncConfiguration {
  id: number;
  integration_id: string;
  is_aggregated: boolean;
  gift_card_item_id: string;
  gift_card_discount_item_id: string;
  service_window: ServiceWindow;
  tip_item_id: string;
  is_use_default_customer: string;
  default_customer_id: string;
  category_aggregation_mappings: CategoryAggregationMapping[];
  custom_amount_item_id: string;
  default_service_charge_id: string;
  adhoc_default_item_id: string;
  order_adhoc_tax_match_rules: OrderAdhocTaxMatchRule;
  require_category_aggregation: boolean;
  uncategorized_item_id: string;
}

export default interface SyncDefinition {
  id: string;
  integration_id: string;
  sync_type: SyncJobType;
  origin_source_id: string;
  target_source_id: string;
  sync_direction: SyncDirection;
  enabled: boolean;
  orders_sync_configuration?: OrdersSyncConfiguration;
  location_id?: string;
}
