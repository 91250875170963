import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { DropdownFilterUnit } from './../../../../models/interface/dropdown-filter-unit';


import { EngineRouterService } from 'ember-engines-router-service';
export default class AuthorizedRouteIntegrationSyncDataInventoryChanges extends Controller {

  @service router!: EngineRouterService;

  queryParams: Array<string> = ['state', 'entity_id', 'entity_type'];

  entity_id: string | null = null;
  state: string | null = null;
  entity_type: string | null = null;

  entityTypes: Array<DropdownFilterUnit> = [
    { label: 'All Types', value: undefined },
    { label: 'Inventory Adjustment Upward', value: 'INVENTORY_ADJUSTMENT_UPWARD' },
    { label: 'Inventory Adjustment Downward', value: 'INVENTORY_ADJUSTMENT_DOWNWARD' },
    { label: 'Inventory Physical Count', value: 'INVENTORY_PHYSICAL_COUNT' },
    { label: 'Inventory Transfer', value: 'INVENTORY_TRANSFER' },
  ];

  @action
  refreshModel() {
    this.send('refreshCurrentRoute');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/inventory-changes': AuthorizedRouteIntegrationSyncDataInventoryChanges;
  }
}
