export default function makeKeyDownListenerMap(
  listenerMap: Record<string, (event: KeyboardEvent) => void>,
  stopPropagation = true
): (event: KeyboardEvent) => void {
  return function onKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    if (listenerMap[key] === undefined) {
      return;
    }

    if (stopPropagation) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }

    listenerMap[key](event);
  };
}
