define("@square/glass-ui/components/sq-progress-bar", ["exports", "@square/glass-ui/templates/components/sq-progress-bar"], function (_exports, _sqProgressBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqProgressBar.default,
    classNames: ['sq-progress-bar'],
    // Passed in:
    // Should be within [0.0, 1.0]
    progress: 0,
    statusText: null,
    showPercentage: true,
    percent: Ember.computed('progress', function () {
      var progress = this.get('progress');

      if (!progress || progress < 0) {
        return 0;
      } else if (progress > 1) {
        progress = 1;
      }

      return progress * 100;
    }),
    percentStyle: Ember.computed('percent', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('percent'), "%"));
    })
  });

  _exports.default = _default;
});