define("@square/glass-ui/components/sq-card/footer", ["exports", "@square/glass-ui/templates/components/sq-card/footer"], function (_exports, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _footer.default,
    classNames: ['card__footer']
  });

  _exports.default = _default;
});