define("@square/glass-ui/templates/components/sq-option-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pvSYXckC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,12],null,[[\"name\",\"checked\",\"disabled\",\"value\",\"accessibleText\",\"action\"],[[35,11],[35,10],[35,9],[35,8],[35,7],[35,6]]]]],[2,\"\\n\"],[10,\"div\"],[15,\"data-test-switch-label\",[30,[36,13],[[35,8],true],null]],[14,0,\"option__label switch__label\"],[12],[2,\"\\n\\n\"],[6,[37,14],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"class\",\"text\"],[\"switch-label--primary\",[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,14],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"class\",\"text\"],[\"switch-label--secondary\",[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,14],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,1],null,[[\"label\",\"label-secondary\"],[[30,[36,0],[\"sq-option/label/primary\"],[[\"class\"],[\"switch-label--primary\"]]],[30,[36,0],[\"sq-option/label/secondary\"],[[\"class\"],[\"switch-label--secondary\"]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"labelSecondary\",\"sq-option/label/secondary\",\"label\",\"sq-option/label/primary\",\"action\",\"textScreenReader\",\"value\",\"disabled\",\"checked\",\"name\",\"sq-switch\",\"or\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-option-switch.hbs"
    }
  });

  _exports.default = _default;
});