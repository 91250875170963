define("@square/glass-ui/templates/components/sq-card/header/subtitle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7tJ7VJD1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"strong\"],[12],[18,1,null],[13]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"strong\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"subtitle\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-card/header/subtitle.hbs"
    }
  });

  _exports.default = _default;
});