define("@square/glass-ui/templates/components/dialog-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eNLCkdsq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,\"data-test-dialog-content\",\"\"],[15,0,[34,0]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"contentClass\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/dialog-body.hbs"
    }
  });

  _exports.default = _default;
});