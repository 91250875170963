define("@square/glass-ui/components/sq-calendar-month", ["exports", "@square/glass-ui/templates/components/sq-calendar-month", "moment", "@square/glass-ui/components/sq-input-calendar"], function (_exports, _sqCalendarMonth, _moment, _sqInputCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DESIRED_WEEKS_INDICES = [0, 1, 2, 3, 4, 5];
  var DESIRED_WEEKS = 6;
  var DAYS_IN_A_WEEK_INDICES = [0, 1, 2, 3, 4, 5, 6];
  var DAYS_IN_A_WEEK = 7;
  var Day = Ember.Object.extend({
    // Date instance
    value: null
  });
  var Week = Ember.Object.extend({
    days: null,
    init: function init() {
      this._super.apply(this, arguments);

      return this.set('days', DAYS_IN_A_WEEK_INDICES.map(function () {
        return Day.create();
      }));
    }
  });

  var _default = Ember.Component.extend({
    layout: _sqCalendarMonth.default,
    classNames: ['calendar--month'],
    month: null,
    beginDate: null,
    endDate: null,
    selectedDate: Ember.computed.alias('beginDate'),
    weekdaysMin: Ember.computed(function () {
      return _moment.default.weekdaysMin(true);
    }),
    // single cached property for the normalized highlighted range
    selectionRange: Ember.computed('beginDate', 'endDate', function () {
      var _this$getProperties = this.getProperties('beginDate', 'endDate'),
          beginDate = _this$getProperties.beginDate,
          endDate = _this$getProperties.endDate; // No selection range if no begin date or ALL_TIME


      if (!beginDate || beginDate === _sqInputCalendar.ALL_TIME && endDate === _sqInputCalendar.ALL_TIME) {
        return undefined;
      }

      var selectionBegin = (0, _moment.default)(beginDate).clone().startOf('day');
      var selectionEnd = (0, _moment.default)(endDate || beginDate).clone().startOf('day');
      return {
        selectionBegin: selectionBegin,
        selectionEnd: selectionEnd
      };
    }),
    // first and last day of given month:
    visibleRange: Ember.computed('month', function () {
      var month = this.get('month');

      if (!month) {
        return undefined;
      }

      var visibleBegin = (0, _moment.default)(month).startOf('month');
      var visibleEnd = (0, _moment.default)(month).endOf('month').startOf('day');
      return {
        visibleBegin: visibleBegin,
        visibleEnd: visibleEnd
      };
    }),
    // firstDay and lastDay of the weeks we want to show:
    paddedWeeks: Ember.computed('visibleRange', function () {
      var visibleRange = this.get('visibleRange');

      if (!visibleRange) {
        return undefined;
      }

      var visibleBegin = visibleRange.visibleBegin,
          visibleEnd = visibleRange.visibleEnd;
      var firstDay = visibleBegin.clone().startOf('week');
      var lastDay = visibleEnd.clone().endOf('week').startOf('day');

      while (Math.ceil(lastDay.diff(firstDay, 'days') / DAYS_IN_A_WEEK) < DESIRED_WEEKS) {
        var daysToFirst = visibleBegin.diff(firstDay, 'days');
        var daysFromLast = lastDay.diff(visibleEnd, 'days');

        if (daysToFirst <= daysFromLast) {
          firstDay.subtract(1, 'weeks');
        } else {
          lastDay.add(1, 'weeks');
        }
      }

      return {
        firstDay: firstDay,
        lastDay: lastDay
      };
    }),
    weeks: Ember.computed('_weeks', 'additionalRanges', 'allowFutureSelections', 'allowPastSelections', 'disabledRange', 'paddedWeeks', 'selectionRange', 'visibleRange', function () {
      // we'll cache these for more efficient views and stable action targets
      if (!this._weeks) {
        // eslint-disable-next-line ember/no-side-effects
        this.set('_weeks', DESIRED_WEEKS_INDICES.map(function () {
          return Week.create();
        }));
      }

      var _this$getProperties2 = this.getProperties('selectionRange', 'visibleRange', 'paddedWeeks'),
          selectionRange = _this$getProperties2.selectionRange,
          visibleRange = _this$getProperties2.visibleRange,
          paddedWeeks = _this$getProperties2.paddedWeeks;

      if (!visibleRange || !paddedWeeks) {
        return this._weeks;
      }

      var _ref = selectionRange || {},
          selectionBegin = _ref.selectionBegin,
          selectionEnd = _ref.selectionEnd;

      var visibleBegin = visibleRange.visibleBegin,
          visibleEnd = visibleRange.visibleEnd;
      var firstDay = paddedWeeks.firstDay;
      var today = (0, _moment.default)().startOf('day');
      var currentDay = firstDay.clone();
      var disallowPastSelections = !this.get('allowPastSelections');
      var disallowFutureSelections = !this.get('allowFutureSelections');

      var _iterator = _createForOfIteratorHelper(this._weeks),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var week = _step.value;

          var _iterator2 = _createForOfIteratorHelper(week.get('days')),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var day = _step2.value;
              this.beginPropertyChanges();
              day.setProperties({
                value: currentDay.clone().toDate(),
                date: currentDay.date(),
                // NOTE: (carden) calendar__day gets dasherized into calendar--day, yay :)
                calendar__dayIsToday: Number(currentDay) === Number(today),
                calendar__dayIsInCurrentMonth: visibleBegin <= currentDay && currentDay <= visibleEnd,
                calendar__dayIsDisallowed: disallowFutureSelections && Number(currentDay) > Number(today) || disallowPastSelections && Number(currentDay) < Number(today)
              });

              this._setRange(day, currentDay, selectionRange, selectionBegin, selectionEnd, 'calendar__dayIsSelected', 'calendar__dayIsSelectionBegin', 'calendar__dayIsSelectionEnd');

              var additionalRanges = this.get('additionalRanges');

              if (additionalRanges) {
                if (additionalRanges['primary']) {
                  var range = additionalRanges['primary'];

                  this._setRange(day, currentDay, range, range.beginDate, range.endDate, 'calendar__dayInPrimaryRange', 'calendar__dayIsPrimaryRangeBegin', 'calendar__dayIsPrimaryRangeEnd');
                }

                if (additionalRanges['secondary']) {
                  var _range = additionalRanges['secondary'];

                  this._setRange(day, currentDay, _range, _range.beginDate, _range.endDate, 'calendar__dayInSecondaryRange', 'calendar__dayIsSecondaryRangeBegin', 'calendar__dayIsSecondaryRangeEnd');
                }
              }

              var disabledRange = this.get('disabledRange');

              if (disabledRange) {
                this._setRange(day, currentDay, disabledRange, disabledRange.beginDate, disabledRange.endDate, 'calendar__dayInDisabledRange', 'calendar__dayIsDisabledRangeBegin', 'calendar__dayIsDisabledRangeEnd');
              }

              this.endPropertyChanges();
              currentDay.add(1, 'day');
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return this._weeks;
    }),
    _setRange: function _setRange(day, currentDay, range, begin, end, inRangeClass, isBeginClass, isEndClass) {
      day.set(inRangeClass, range && begin <= currentDay && currentDay <= end);
      day.set(isBeginClass, range && Number(currentDay) === Number(begin));
      day.set(isEndClass, range && Number(currentDay) === Number(end));
    },
    // bind to this to override default "yes past" config
    allowPastSelections: true,
    // bind to this to override default "no future" config
    allowFutureSelections: false,
    actions: {
      selectDate: function selectDate(date) {
        if (this.action) {
          this.action(date);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});