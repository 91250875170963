define("@square/glass-ui/utils/input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.measureTextWidth = measureTextWidth;

  // helper method to visually check width of text in question by measuring width in a hidden,
  // offscreen text container
  function measureTextWidth(text) {
    var testcontainer = (0, _jquery.default)('.form-field-measure-container'); // eslint-disable-line ember/no-jquery

    if (testcontainer.length === 0) {
      testcontainer = (0, _jquery.default)('<p class="form-field-measure-container"></p>'); // eslint-disable-line ember/no-jquery

      (0, _jquery.default)('body').append(testcontainer); // eslint-disable-line ember/no-jquery
    }

    testcontainer.text(text);
    return testcontainer.width();
  }
});