import { helper } from '@ember/component/helper';

interface isStringValueArgumentObject {
  caseSensitive: boolean;
}

// checks to see if two strings (really two of anything) are strictly equivalent
export function isStringValue(params: Array<string>, {caseSensitive}: isStringValueArgumentObject): boolean {
  const [objectInputType, templateSpecifiedType] = params;

  if (!objectInputType || !templateSpecifiedType) {
    return false;
  };

  if (caseSensitive) {
    return objectInputType === templateSpecifiedType;
  } else {
    return objectInputType.toLowerCase() === templateSpecifiedType.toLowerCase();
  }
}

export default helper(isStringValue);
