define("@square/glass-ui/components/media-block", ["exports", "@square/glass-ui/templates/components/media-block"], function (_exports, _mediaBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_BLOCK_LAYOUT = void 0;
  var MEDIA_BLOCK_LAYOUT = {
    LINEAR: 'linear',
    HORIZONTAL: 'horizontal',
    STACKED: 'stacked'
  };
  _exports.MEDIA_BLOCK_LAYOUT = MEDIA_BLOCK_LAYOUT;

  var _default = Ember.Component.extend({
    layout: _mediaBlock.default,
    classNames: ['media-block'],
    classNameBindings: ['layoutClass'],
    attributeBindings: ['data-test-media-block'],
    'data-test-media-block': true,
    mediaLayout: MEDIA_BLOCK_LAYOUT.LINEAR,
    layoutClass: Ember.computed('mediaLayout', function () {
      if (this.mediaLayout !== MEDIA_BLOCK_LAYOUT.LINEAR) {
        return "media-block--".concat(this.mediaLayout);
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});