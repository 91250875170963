import { helper } from '@ember/component/helper';

const BASE_64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

const isStringBase64Encoded = (encodedString: string) => encodedString && BASE_64_REGEX.test(encodedString);

const entityDeatilsMapping = {
  "tax_rate": { heading: "TAX RATE", suffix: "%"},
  "tax_area": { heading: "TAX AREA", suffix: ""},
  "Item_No": { heading: "ITEM NUMBER", suffix: ""},
  "Variant_code": {heading: "VARIANT CODE", suffix: ""},
  "docType": {heading: "DOCUMENT TYPE", suffix: ""},
  "docId": {heading: "DOCUMENT ID", suffix: ""},
  "itemId": {heading: "ITEM ID", suffix: ""},
  "lineNum": {heading: "LINE NUMBER", suffix: ""},
  "locationId": {heading: "LOCATION ID", suffix: ""}
}

export function decodeEntityObject(params: string[]) {
  const encodedEntityObject = params[0];
  const decodedEntityObjectArray = [];
  
  if(isStringBase64Encoded(encodedEntityObject)) {
    try {
      const decodedEntityObject = JSON.parse(atob(encodedEntityObject));
      
      for (const [key, value] of Object.entries(decodedEntityObject)){
        const entityDetailObject = entityDeatilsMapping[key as keyof typeof entityDeatilsMapping];
        if(entityDetailObject) {
          decodedEntityObjectArray.push({isBase64Object: true, entityName: entityDetailObject.heading, entityValue: value + entityDetailObject.suffix})
        }
      }  
    } catch(e) {
      decodedEntityObjectArray.push({isBase64Object: false, value: encodedEntityObject});
    }
  } else {
    decodedEntityObjectArray.push({isBase64Object: false, value: encodedEntityObject});
  }
  return decodedEntityObjectArray;
}

export default helper(decodeEntityObject);
