define("@square/glass-ui/components/banner-flash-global", ["exports", "@square/glass-ui/components/banner-flash"], function (_exports, _bannerFlash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bannerFlash.default.extend({
    classNames: ['banner-flash--global', 'dashboard-header__fixed-top-element']
  });

  _exports.default = _default;
});