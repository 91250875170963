define("@square/glass-ui/templates/components/sq-field-radio-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98EyPFhO",
    "block": "{\"symbols\":[\"option\",\"radioComponent\",\"&default\"],\"statements\":[[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,11]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,10],[[30,[36,0],[\"sq-option-radio\"],[[\"fieldName\",\"optionName\",\"value\",\"disabled\",\"label\",\"labelSecondary\",\"selected\",\"class\",\"action\"],[[30,[36,7],[[35,9],[35,8]],null],[32,1,[\"name\"]],[32,1,[\"value\"]],[30,[36,7],[[35,6],[32,1,[\"disabled\"]]],null],[30,[36,2],[[32,1,[\"label\"]],[32,1,[\"label\"]]],null],[30,[36,2],[[32,1,[\"labelSecondary\"]],[32,1,[\"labelSecondary\"]]],null],[30,[36,5],[[32,1,[\"value\"]],[35,4]],null],\"option-set__option\",[30,[36,3],[[32,0],\"optionClicked\",[32,1]],null]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[30,[36,1],null,[[\"option\",\"props\"],[[32,2],[32,1]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\",\"action\",\"value\",\"eq\",\"disabled\",\"or\",\"fieldName\",\"name\",\"let\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-field-radio-set.hbs"
    }
  });

  _exports.default = _default;
});