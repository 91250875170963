define("@square/glass-ui/components/modals/modal-manager", ["exports", "@square/glass-ui/templates/components/modals/modal-manager", "@square/glass-ui/services/modal"], function (_exports, _modalManager, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MODAL_POSITION = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var KEY_ESC = 27;
  var MODAL_POSITION = {
    TOP: 'TOP'
  };
  _exports.MODAL_POSITION = MODAL_POSITION;

  var positionClassNameMap = _defineProperty({}, MODAL_POSITION.TOP, 'modal-layout--position-top');
  /**
  
  ## Overview
  
  **Do not use this component directly!** It is intended to exist only in `application.hbs`.
  
  Modal Manager controls the display of modal windows over the application. It manages the
  veil/scrim behind the modal window, as well as click, ESC key, navigation, and focus events.
  
  It works in conjunction with the modal service, which actually holds the state.
  
  ## Usage
  
  ```handlebars
  {{! Again, don't do this anywhere but application.hbs! }}
  {{modals/modal-manager}}
  ```
  
  ## How to show a modal
  
  1. Create a component, i.e. `{{my-modal}}`. 99.9% percent of the time, you will include
     the `{{dialog-modal}}` component inside of it.
  2. In response to some user action, call `open('my-modal')` on the modal service.
  
      ```javascript
      // some other route or component
      modal: Ember.inject.service(),
  
      actions: {
        openModal() {
          this.get('modal').open('my-modal', { someData: 108 })
            .then(() =>
              // do something when the modal closes
            );
        }
      }
      ```
  
  3. Your `my-modal` component will receive five properties:
      1. A `modalOptions` property, which is the second argument to `open()`.
         So you can access `someData` with `this.get('modalOptions.someData')`.
      2. A `close` action, which closes the modal.
      3. A `setCloseHandler` action, which allows the component to disallow closing
         the modal when clicking on the veil, pressing ESC, or navigating away.
      4. A `lockModalHeight` action, which allows the component to fix its vertical
         height before making changes to its contents, to avoid vertical bouncing.
      5. An `unlockModalHeight` action, which returns to the default vertical sizing.
  
  */


  var _default = Ember.Component.extend({
    layout: _modalManager.default,
    modal: Ember.inject.service(),
    hasActiveModal: Ember.computed.notEmpty('modal.stack'),
    modalIsOpen: Ember.computed.readOnly('modal.isOpen'),
    lastModal: Ember.computed.readOnly('modal.stack.lastObject'),

    /*
      In dashboard-header, the highest z-index of the side-drawer veil is 804. When the inactivity logout
      modal renders, the modal is shown behind the veil instead of being in the front. This makes it
      difficult to interact with the modal if its hidden behind this veil. We override the z-index value
      by adding a CSS class to the component if the modal rendered is the inactivity-modal.
    */
    zIndexClassName: Ember.computed('hasActiveModal', 'lastModal', function () {
      if (this.get('hasActiveModal')) {
        var lastModal = this.get('lastModal');

        if (lastModal.componentName === 'inactivity-modal') {
          return 'modal-layout--z-index-override';
        }
      }

      return '';
    }),
    positionClassName: Ember.computed('hasActiveModal', 'lastModal', function () {
      if (this.get('hasActiveModal')) {
        var lastModal = this.get('lastModal');

        if (lastModal.metaOptions.position) {
          return positionClassNameMap[lastModal.metaOptions.position] || '';
        }
      }

      return '';
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._windowKeydownListener = function (e) {
        return _this.handleEscKey(e);
      };

      this._mousewheelListener = function (e) {
        return _this.handleScrolling(e);
      };

      window.addEventListener('keydown', this._windowKeydownListener);
      this.element.addEventListener('mousewheel', this._mousewheelListener);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('keydown', this._windowKeydownListener);
      this.element.removeEventListener('mousewheel', this._mousewheelListener);
    },
    click: function click(e) {
      if (e.target.classList.contains('modal-layout') || e.target.classList.contains('modal-layout__modal-container')) {
        this.get('modal').maybeCloseOnEvent(_modal.CLOSE_CLICK);
      }
    },
    handleEscKey: function handleEscKey(e) {
      if (this.get('modal.isOpen') && e.keyCode === KEY_ESC) {
        this.get('modal').maybeCloseOnEvent(_modal.CLOSE_ESC);
      }
    },
    // disallow scrolling behind the modal
    handleScrolling: function handleScrolling(e) {
      if (e.target.classList.contains('modal-layout')) {
        e.preventDefault();
      }
    },
    _veilTouchStarted: false,
    touchStart: function touchStart(e) {
      if (e.target.classList.contains('modal-layout')) {
        this._veilTouchStarted = true;
      }
    },
    touchMove: function touchMove(e) {
      // Disable touch scrolling if we started the touch on the veil
      if (this._veilTouchStarted && e.target.classList.contains('modal-layout')) {
        e.preventDefault();
      }
    },
    touchEnd: function touchEnd() {
      this._veilTouchStarted = false;
    },
    actions: {
      close: function close() {
        var _this$get;

        (_this$get = this.get('modal')).close.apply(_this$get, arguments);
      },

      /*
         Modals are vertically centered on the screen, but the height of the modal content can change
         in certain cases in response to user behavior, which causes jarring vertical movement. These
         helper functions allow modal implementations to lock and unlock the height of the modal prior
         to updating their contents, to avoid that vertical movement.
      */
      lockModalHeight: function lockModalHeight() {
        var modalWrappers = this.element.querySelectorAll('.dialog--modal');
        var modalWrapper = modalWrappers[modalWrappers.length - 1];
        modalWrapper.style.height = "".concat(modalWrapper.offsetHeight, "px");
      },
      unlockModalHeight: function unlockModalHeight() {
        var modalWrappers = this.element.querySelectorAll('.dialog--modal');
        var modalWrapper = modalWrappers[modalWrappers.length - 1];
        modalWrapper.style.height = 'auto';
      }
    }
  });

  _exports.default = _default;
});