define("@square/glass-ui/components/progress-slider", ["exports", "@square/glass-ui/templates/components/progress-slider"], function (_exports, _progressSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  ### ProgressSlider component
  
  The progress slider component contains the logic for the video player slider and volume bar slider.
  This component is currently only used with video-player, though it is extracted to be video-player agnostic.
  
  Usage:
  
  @hbs
  {{progress-slider dragContainer=element
                    onClick=(action onClick)
                    onDrag=(action onDrag)
                    progress=(readonly progress)
                    mouseDown=(action pause)
                    mouseUp=(action play)
                    role="slider"
                    valueMin="0"
                    currentValue=sliderValue
                    valueMax=duration}}
  
  ### Parameters
   * @param {Action} [onClick] Action fired after clicking on the slider. Sends the action with the event and progress of track (where the track was clicked. see calculateProgress)
   * @param {Action} [onDrag] Action fired after dragging the sliderThumb. Sends the action with the event and progress of track (where the track was clicked. see calculateProgress)
   * @param {Element} [dragContainer] Element object to add the drag event handlers too. This allows holding the drag for the slider thumb to anywhere in the dragContainer.
   * @param {Number} [progress] (readonly) A fraction that represents how far into the slider the thumb is (see progressStyle)
   * @param {Number} [minValue=0] minimum value for the progress slider
   * @param {Number} [maxValue] maximum value for the progress slider
   * @param {number} [currentValue] starting value for the progress slider
  */
  var _default = Ember.Component.extend({
    layout: _progressSlider.default,
    attributeBindings: ['role', 'aria-valuenow', 'aria-valuemax', 'aria-valuemin'],
    classNames: ['progress-slider'],
    role: 'slider',
    sliderTrack: Ember.computed('element', function () {
      return this.element.getElementsByClassName('progress-slider__track')[0];
    }),
    // DO NOT set within this component. It should only be updated from the parent component through action handlers.
    progress: 0,
    minValue: 0,
    maxValue: 1,
    currentValue: 0,
    'aria-valuemin': Ember.computed.alias('minValue'),
    'aria-value-max': Ember.computed.alias('maxValue'),
    'aria-valuenow': Ember.computed.alias('currentValue'),
    progressStyle: Ember.computed('progress', function () {
      var percent = this.progress * 100;
      return Ember.String.htmlSafe("width: ".concat(percent, "%"));
    }),
    // Calculates how much the thumb has moved from the left end of the slider track at the end of a click / drag event
    calculateProgress: function calculateProgress(e) {
      var domRect = this.get('sliderTrack').getBoundingClientRect();
      var trackWidth = domRect.width;
      var distanceFromStartOfTrack = e.pageX - domRect.x; // clicked/dragged before the start of the track

      if (distanceFromStartOfTrack < 0) {
        distanceFromStartOfTrack = 0; // clicked/dragged past the end of the track
      } else if (distanceFromStartOfTrack > trackWidth) {
        distanceFromStartOfTrack = trackWidth;
      }

      return distanceFromStartOfTrack / trackWidth;
    },
    click: function click(e) {
      var progress = this.calculateProgress(e);
      this.onClick(e, progress);
    },
    // Boolean for whether the slider thumb is being dragged
    isDragging: false,
    sliderThumb: Ember.computed('element', function () {
      return this.element.getElementsByClassName('progress-slider__thumb')[0];
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Set the handlers so they can be referenced later for teardown


      this.setDragHandlers();
      this.dragContainer.addEventListener('touchstart', this.dragStart, false);
      this.dragContainer.addEventListener('touchend', this.dragEnd, false);
      this.dragContainer.addEventListener('touchmove', this.drag, false);
      this.dragContainer.addEventListener('mousedown', this.dragStart, false);
      this.dragContainer.addEventListener('mouseup', this.dragEnd, false);
      this.dragContainer.addEventListener('mousemove', this.drag, false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.dragContainer.removeEventListener('touchstart', this.dragStart);
      this.dragContainer.removeEventListener('touchend', this.dragEnd);
      this.dragContainer.removeEventListener('touchmove', this.drag);
      this.dragContainer.removeEventListener('mousedown', this.dragStart);
      this.dragContainer.removeEventListener('mouseup', this.dragEnd);
      this.dragContainer.removeEventListener('mousemove', this.drag);
    },
    // Handlers for dragging the slider thumb
    setDragHandlers: function setDragHandlers() {
      var _this = this;

      this.dragStart = function (e) {
        if (e.target === _this.sliderThumb) {
          _this.set('isDragging', true);
        }
      };

      this.dragEnd = function () {
        _this.set('isDragging', false);
      };

      this.drag = function (e) {
        if (_this.isDragging) {
          e.preventDefault();

          var progress = _this.calculateProgress(e);

          _this.onDrag(e, progress);
        }
      };
    }
  });

  _exports.default = _default;
});