define("@square/glass-ui/templates/components/sq-drop-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJUkpjAA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"drop-target__content\"],[12],[2,\"\\n\"],[6,[37,8],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"drop-target__label\"],[12],[1,[35,6,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"drop-target__icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"drop-target__helper\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"drop-target__placeholder\"],[12],[1,[34,2]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"field-hint drop-target__hint\"],[12],[2,\"\\n        \"],[1,[34,3]],[2,\"\\n        \"],[1,[30,[36,7],null,[[\"value\",\"fileTypeWhitelist\",\"linkText\",\"fileChanged\"],[[35,6],[35,5],[35,4],[30,[36,0],[[32,0],\"fileChanged\"],null]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"],[6,[37,8],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"drop-target__dismiss\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],[30,[36,0],[[32,0],\"fileDismissed\"],null]],null],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"size\"],[\"large\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"svg/icon-close-x\",\"placeholderText\",\"fileSelectLinkOr\",\"fileSelectLinkText\",\"fileTypeWhitelist\",\"file\",\"sq-file-select-link\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-drop-target.hbs"
    }
  });

  _exports.default = _default;
});