import Component from '@glimmer/component';
import { Source } from '@bridge/home-engine/models/services/source/source';
import getNameFromIntegrationSource from '@bridge/home-engine/utils/integration-source-util';

interface WizardIntegrationConfirmArgs {
  squareSource: Source;
  externalSource: Source;
}
export default class WizardIntegrationConfirm extends Component<WizardIntegrationConfirmArgs> {

  externalSourceName: string;

  constructor(owner: unknown, args: WizardIntegrationConfirmArgs) {
    super(owner, args);
    const extIntegrationSource = args.externalSource;
    this.externalSourceName = getNameFromIntegrationSource(extIntegrationSource);
  }


}
