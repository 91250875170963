define("@square/glass-ui/components/selector-control/navigation/item", ["exports", "@square/glass-ui/templates/components/selector-control/navigation/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SELECTOR_TYPE = void 0;
  var SELECTOR_TYPE = {
    TAB: 'TAB',
    STEP: 'STEP',
    SEGMENT: 'SEGMENT'
  };
  _exports.SELECTOR_TYPE = SELECTOR_TYPE;

  var _default = Ember.Component.extend({
    layout: _item.default,
    tagName: '',
    type: SELECTOR_TYPE.TAB,
    key: null,
    isActive: Ember.computed('key', 'activeItems', function () {
      return this.get('activeItems').includes(this.get('key'));
    }),
    itemComponent: Ember.computed('type', function () {
      return "selector-control/navigation/item/".concat(this.get('type').toLowerCase());
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      (false && !(Ember.isPresent(this.get('label')) || Ember.isPresent(this.get('key'))) && Ember.assert('{{selector-control/navigation/item}} requires either a `key` or a `label` as a unique identifier. You passed neither.', Ember.isPresent(this.get('label')) || Ember.isPresent(this.get('key')))); // If we don't have a key, then use the label as the key. If we have neither
      // then assert that one must be passed

      if (!this.get('key') && this.get('label')) {
        this.set('key', this.get('label'));
      }
    },
    actions: {
      onSelect: function onSelect() {
        this.get('onSelect')(this.get('key'));
      }
    }
  });

  _exports.default = _default;
});