define("@square/glass-ui/components/list-keyboard-event-handler", ["exports", "@square/glass-ui/utils/keyboard-support", "@square/glass-ui/templates/components/list-keyboard-event-handler"], function (_exports, _keyboardSupport, _listKeyboardEventHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _listKeyboardEventHandler.default,
    itemFocusedClass: null,
    itemClass: null,
    items: null,
    highlightedIndex: -1,
    domItems: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.set('domItems', this.element.querySelectorAll(".".concat(this.itemClass)));
    },
    // event handlers
    keyDown: function keyDown(e) {
      switch (e.keyCode) {
        case _keyboardSupport.KEY_CODES.DOWN:
          this.highlightNext(e);
          break;

        case _keyboardSupport.KEY_CODES.UP:
          this.highlightPrevious(e);
          break;

        case _keyboardSupport.KEY_CODES.ENTER:
          if (this.enterPressed && this.highlightedIndex !== -1) {
            var selectedItem = this.get('domItems')[this.highlightedIndex];

            if (!Ember.isEmpty(this.items)) {
              selectedItem = this.items[this.highlightedIndex];
            }

            this.enterPressed(selectedItem, this.highlightedIndex, e);
          }

          break;

        default:
          break;
      }
    },
    highlightNext: function highlightNext(e) {
      var nextIndex = this.highlightedIndex + 1;

      if (nextIndex >= this.get('domItems.length')) {
        nextIndex = 0;
      }

      this.highlightIndex(nextIndex);
      e.stopPropagation();
      e.preventDefault();
    },
    highlightPrevious: function highlightPrevious(e) {
      var previousIndex = this.highlightedIndex - 1;

      if (previousIndex < 0) {
        previousIndex = this.get('domItems.length') - 1;
      }

      this.highlightIndex(previousIndex);
      e.stopPropagation();
      e.preventDefault();
    },
    highlightIndex: function highlightIndex(index) {
      this.set('highlightedIndex', index);
      var activeItem = this.element.querySelector(".".concat(this.itemFocusedClass));

      if (activeItem) {
        activeItem.classList.remove(this.itemFocusedClass);
      }

      var items = this.element.querySelectorAll(".".concat(this.itemClass));
      items.item(index).classList.add(this.itemFocusedClass);
    }
  });

  _exports.default = _default;
});