import { helper } from '@ember/component/helper';

// singularize string by simply stripping it of its terminating 's', if any
export function singularize(params: Array<string>): string {
  const [string] = params;
  if (string.slice(-1).toLowerCase() === 's') {
    return string.slice(0, -1);
  } else {
    return string;
  }
}

export default helper(singularize);
