import { Router } from '@ember/routing';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { DailyInvoiceDetail } from '../models/services/daily-invoices/retrieve-daily-invoice-details';
import DailyInvoicesService from './daily-invoices-service';

export interface ShowDailyInvoiceDetailBladeArgs {
  groupId: string;
  location: string;
  date: string;
  type: string;
}

export default class DailyInvoiceDetailBladeService extends Service {
  @tracked isLoading = true;
  @tracked data: DailyInvoiceDetail | undefined;

  @service dailyInvoicesService!: DailyInvoicesService;
  @service currentUser!: CurrentUser;
  @service router!: Router;

  fetch(groupId: string) {
    this.isLoading = true;
    this.dailyInvoicesService.retrieveDailyInvoiceDetails(this.currentUser.currentIntegrationId, 
      groupId)
      .then(resp => {
        this.data = resp.details;
        this.isLoading = false;
      })
      .catch(() => {
        this.close();
      });
  }

  close() {
    this.router.transitionTo('authorized-route.integration.daily-invoices');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'daily-invoice-detail-blade-service': DailyInvoiceDetailBladeService;
  }
}
