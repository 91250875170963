
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import BridgeSquareLocation from '@bridge/home-engine/addon/models/services/location/square_location';
import Data from 'bridge-dashboard/app/services/data';
import Config, { ConfigFormBlueprint } from '../../services/config';
import FlashService from '@square/glass-ui/addon/services/flash';
interface WizardLocationConfigArgs {
  selectedLocationOption: BridgeSquareLocation;
  handleLocationSelection: Function;
}

export default class WizardLocationConfig extends Component<
  WizardLocationConfigArgs
> {
  constructor(owner: unknown, args: WizardLocationConfigArgs) {
    super(owner, args);
    if (this.args.selectedLocationOption) {
      this.fetchAndDisplayLocationSettingsForm(
        this.args.selectedLocationOption.location_id
      );
    } else {
      this.fetchLocationOptions();
    }
  }

  @service config!: Config;
  @service data!: Data;
  @service flash!: FlashService;

  @tracked
  configBlueprint?: ConfigFormBlueprint;

  @tracked
  displayLocationSettingsForm: boolean = false;

  @tracked
  settingsFormLoading: boolean = false;

  @tracked
  optionsLoading: boolean = false;

  @tracked
  locationOptions: BridgeSquareLocation[] = [];

  @tracked
  configObjectId?: string;

  setLocation(location: BridgeSquareLocation) {
    this.args.handleLocationSelection(location);
    this.fetchAndDisplayLocationSettingsForm(location.location_id);
  }

  fetchAndDisplayLocationSettingsForm(locationId: string) {
    this.settingsFormLoading = true;
    this.config.fetchLocationConfig(locationId).then(
      (json) => {
        this.configBlueprint = json.configuration_blueprint;
        this.displayLocationSettingsForm = true;
        this.settingsFormLoading = false;
        this.configObjectId = locationId;
      },
      (badResponse: Error) => {
        this.settingsFormLoading = false;
        if (badResponse.message.includes('401')) {
          throw badResponse;
        } else {
          this.flash.sheetError(badResponse, {
            dismiss: () => {
              this.flash.clearSheetMessage();
            },
          });
        }
      }
    );
  }

  fetchLocationOptions() {
    this.optionsLoading = true;
    this.data.syncLocations().then(
      (square_locations) => {
        this.locationOptions = square_locations;
        this.optionsLoading = false;
      },
      (badResponse: Error) => {
        this.settingsFormLoading = false;
        if (badResponse.message.includes('401')) {
          throw badResponse;
        } else {
          this.flash.sheetError(badResponse, {
            dismiss: () => {
              this.flash.clearSheetMessage();
            },
          });
        }
      }
    );
  }
}
