define("@square/glass-ui/components/sq-field-input", ["exports", "@square/glass-ui/utils/field-element-support"], function (_exports, _fieldElementSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend(_fieldElementSupport.default, {
    autofocus: false,
    classNames: 'form-field__input',
    placeholder: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('autofocus')) {
        Ember.run.scheduleOnce('afterRender', this, this.focusElement);
      } // if a placeholder isn't explicitly set, match wrapping field label


      if (Ember.isNone(this.get('placeholder'))) {
        var label = this.get('parentView.label');

        if (Ember.isPresent(label)) {
          this.set('placeholder', label);
        }
      }
    },
    focusElement: function focusElement() {
      this.get('element').focus();
    }
  });

  _exports.default = _default;
});