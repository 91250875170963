/**
 * Determines whether or not an element is visible within the current viewport.
 * @param element
 * @returns true if visible, false otherwise
 */
export default function visibleInViewport(element: Element | null): boolean {
  if (!element) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
