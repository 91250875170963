define("@square/glass-ui/templates/components/select-all-checkbox-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OiAwiSdN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"selectAllCheckbox\",\"list\"],[[30,[36,3],[\"sq-option-checkbox\"],[[\"data-test-select-all-checkbox\",\"label\",\"checked\",\"action\"],[true,[35,6],[30,[36,5],[[35,4]],null],[30,[36,0],[[32,0],\"selectAllDidChange\"],null]]]],[30,[36,3],[[35,2]],[[\"options\",\"optionDidChange\"],[[35,1],[30,[36,0],[[32,0],\"optionDidChange\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"options\",\"listComponent\",\"component\",\"selectAllCheckedState\",\"readonly\",\"selectAllLabel\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/select-all-checkbox-manager.hbs"
    }
  });

  _exports.default = _default;
});