import Config, {
  ConsolidatedMappingResource,
  FormFieldOptionObject,
} from '../../services/config';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { SettingsFormType } from '@bridge/home-engine/models/interface/settings-form-type';

interface AsyncSelectOption {
  value: string;
  label: string;
  name: string;
  checked: boolean;
}

interface AsyncSearchableDropdownArgs {
  value?: string;
  isLoading: boolean;
  isListCreateEnabled: boolean;
  listCreateLabel: string;
  handleChange: (value: string) => void;
  options: AsyncSelectOption[];
  searchOptionResource: ConsolidatedMappingResource;
  settingsTarget: SettingsFormType;
}

export default class AsyncSearchableDropdown extends Component<AsyncSearchableDropdownArgs> {
  constructor(owner: unknown, args: AsyncSearchableDropdownArgs) {
    super(owner, args);
    this.inputLabel = args.value || '';
    this.value = args.value;
    this.options = args.options;
    this.handleChange = args.handleChange;
  }

  handleChange!: (value: string) => void;

  hasNextPage = (): boolean =>
    Boolean(this.currentValue ? this.cursor[this.currentValue] : false);

  @service config!: Config;

  // Store cursor as an object to ensure we never send an invalid cursor/query combo
  @tracked cursor: Record<string, string | null> = {};
  @tracked options: AsyncSelectOption[] = [];
  @tracked value?: string;
  @tracked currentValue = '';
  @tracked inputLabel: string;
  @tracked isOptionloading: boolean = false;

  get isListCreateEnabled(): boolean {
    return (
      (!this.options || this.args.isListCreateEnabled) &&
      this.currentValue.length > 0 &&
      (!this.options ||
        !this.options.some((option) => option.value === this.currentValue))
    );
  }

  get listCreateLabel(): string {
    return `${this.args.listCreateLabel}: ${this.currentValue}`;
  }

  @action
  async fetchData({
    query,
    signal: _signal,
  }: {
    query: string | null;
    signal: AbortSignal;
  }) {
    if (query === null || query === '') {
      return this.options;
    }

    try {
      this.isOptionloading = true;
      const res = await this.config.fetchSearchableFieldOptions({
        settings_form_type: this.args.settingsTarget,
        option_resource: this.args.searchOptionResource,
        query: query,
        cursor: this.cursor[query],
      });

      this.isOptionloading = false;

      this.cursor[query] = res.cursor;

      if (res.data && res.data.field_value) {
        this.options = [...res.data.field_value];
      } else {
        this.options = [];
      }

      return this.options;
    } catch (e) {
      this.isOptionloading = false;
      throw new Error(e.message);
    }
  }

  @action
  handleInput(fetchDataDebounced: () => Promise<void>, value: string): void {
    this.cursor = {};
    this.currentValue = value;
    fetchDataDebounced();
  }

  @action
  onListChange(option: FormFieldOptionObject) {
    this.inputLabel = option.label;
    this.value = option.value;
    this.handleChange(option.value);
    this.currentValue = '';
  }

  @action
  onListCreate() {
    this.isOptionloading = false;
    this.options = [];
    this.handleChange(this.currentValue);
    this.inputLabel = this.currentValue;
    this.currentValue = '';
  }

  // TODO: Fix implementation of onEnter we should prob have an option for manual entry and not as an arg
  @action
  handleEnter() {
    // this.taxCodes = [...this.taxCodes, this.currentValue];
    // this.handleChange(this.taxCodes);
    // this.currentValue = '';
  }
}
