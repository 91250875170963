import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ModalService, {
  CloseHandler,
} from '@square/glass-ui/addon/services/modal';

interface OptionsTableModalArgs {
  modalOptions: OptionsTableModalOptions;
  close: (value: string) => void;
  setCloseHandler: (closeHandler: CloseHandler<string>) => void;
}

export interface OptionsTableModalOptions extends Record<string, unknown> {
  placeholder: string;
  tip: string;
  title: string;
}

/*
 * see https://glass-docs.sqprod.co/glass/docs/development/adding-modals.html
 * for details on implementing GlassUI modals
 */
export default class OptionsTableModal extends Component<OptionsTableModalArgs> {
  @service modal!: ModalService;

  @tracked inputValue = '';

  @action
  handleInput(event: Event): void {
    /* @ts-ignore name and value exist on these event target properties */
    this.inputValue = event.target.value;
  }

  @action
  close(inputValue: string): void {
    this.args.close(inputValue);
  }
}

declare module '@square/glass-ui/addon/services/modal' {
  interface Registry {
    ['options-table-modal']: OptionsTableModal;
  }
}
