define("@square/glass-ui/components/deprecated/sq-flash-message", ["exports", "@square/glass-ui/templates/components/deprecated/sq-flash-message"], function (_exports, _sqFlashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE = void 0;
  var TYPE = {
    ERROR: 'ERROR',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING'
  };
  _exports.TYPE = TYPE;

  var _default = Ember.Component.extend({
    layout: _sqFlashMessage.default,
    classNames: ['banner-flash'],
    classNameBindings: ['isActive:banner-flash--is-visible', 'typeClass'],
    shouldShowIcon: true,
    typeClass: Ember.computed('type', function () {
      switch (this.type) {
        case TYPE.ERROR:
          return 'banner-flash--is-error';

        case TYPE.INFO:
          return 'banner-flash--is-info';

        case TYPE.SUCCESS:
          return 'banner-flash--is-successful';

        case TYPE.WARNING:
          return 'banner-flash--is-warning';

        default:
          return '';
      }
    }),
    iconComponentName: Ember.computed('type', function () {
      switch (this.type) {
        case TYPE.INFO:
          return 'svg/icon-info-circle';

        case TYPE.ERROR:
          return 'svg/icon-alert-triangle';

        case TYPE.SUCCESS:
          return 'svg/icon-checkmark-circle';

        case TYPE.WARNING:
          return 'svg/icon-alert-triangle';

        default:
          return 'svg/icon-info-circle';
      }
    }),
    iconColor: Ember.computed('type', function () {
      switch (this.type) {
        case TYPE.ERROR:
          return 'white';

        case TYPE.INFO:
          return 'blue-medium';

        case TYPE.SUCCESS:
          return 'white';

        case TYPE.WARNING:
          return 'base';

        default:
          return 'base';
      }
    }),
    actions: {
      dismiss: function dismiss() {
        if (this.dismiss) {
          this.dismiss();
        }
      }
    }
  });

  _exports.default = _default;
});