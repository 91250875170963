define("@square/glass-ui/components/sq-popover", ["exports", "@square/glass-ui/templates/components/sq-popover", "popper.js"], function (_exports, _sqPopover, _popper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ANIMATION_DELAY_MS = void 0;
  _exports.getElement = getElement;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ANIMATION_DELAY_MS = 300;
  _exports.ANIMATION_DELAY_MS = ANIMATION_DELAY_MS;
  var POPPER_PLACEMENTS_TOP = new Set(['top', 'top-start', 'top-end']);
  var POPPER_PLACEMENTS_LEFT = new Set(['left', 'left-start', 'left-end']);
  var POPPER_PLACEMENTS_RIGHT = new Set(['right', 'right-start', 'right-end']);
  var POPPER_PLACEMENTS_BOTTOM = new Set(['bottom', 'bottom-start', 'bottom-end']);

  function getElement(elementOrString) {
    return elementOrString instanceof HTMLElement ? elementOrString : document.querySelector(elementOrString);
  }

  var _default = Ember.Component.extend({
    layout: _sqPopover.default,
    classNames: ['popover'],
    classNameBindings: ['isActive:popover--is-active', 'isFlipped:popover--is-flipped', 'fixedPlacementStyleClass'],
    // Attributes
    isActive: false,
    isFlipped: false,
    closePopoverOnClick: false,
    constrainInParent: true,
    constrainInViewport: true,
    // These attributes only apply when passing constrainInParent=false
    placement: 'bottom',
    referenceElement: null,
    popper: null,
    extraPopperModifiers: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.get('constrainInParent') === false) {
        if (this.get('isActive')) {
          this.set('popoverIsVisible', true);
          var popper = this.get('popper');

          if (popper) {
            popper.scheduleUpdate();
          } else {
            popper = this.createPopper();
            this.set('popper', popper);

            if (this.get('onCreatePopper')) {
              this.get('onCreatePopper')(popper);
            }
          }
        }
      }
    },
    // Add margins to place the popover away from the referenceElement based on the given placement.
    // https://popper.js.org/popper-documentation.html#Popper.placements
    // sq-popover does not support the 'auto' placement string in PopperJS
    fixedPlacementStyleClass: Ember.computed('constrainInParent', 'placement', function () {
      if (this.get('constrainInParent') === false) {
        var placement = this.get('placement');

        if (POPPER_PLACEMENTS_TOP.has(placement)) {
          return 'popover--position-fixed-top';
        } else if (POPPER_PLACEMENTS_LEFT.has(placement)) {
          return 'popover--position-fixed-left';
        } else if (POPPER_PLACEMENTS_RIGHT.has(placement)) {
          return 'popover--position-fixed-right';
        } else if (POPPER_PLACEMENTS_BOTTOM.has(placement)) {
          return 'popover--position-fixed-bottom';
        } else {
          (false && !(false) && Ember.assert("A valid placement direction (top, left, right, bottom) must be specified. placement: ".concat(this.get('placement'))));
          return null;
        }
      } else {
        return '';
      }
    }),
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.destroyPopper();
    },
    destroyPopper: function destroyPopper() {
      var popper = this.get('popper');

      if (popper) {
        popper.destroy();
        this.set('popper', null);

        if (this.get('onCreatePopper')) {
          this.get('onCreatePopper')(null);
        }
      }
    },
    createPopper: function createPopper() {
      var _this = this;

      var popperReferenceElement = getElement(this.get('referenceElement')) || this.get('element').parentElement;
      var extraPopperModifiers = this.extraPopperModifiers || {};
      return new _popper.default(popperReferenceElement, this.get('element'), {
        placement: this.get('placement'),
        positionFixed: true,
        modifiers: _objectSpread({
          preventOverflow: {
            // Always constrain within the viewport
            enabled: this.constrainInViewport,
            boundariesElement: 'viewport'
          },
          hide: {
            // Hide should also be disabled if prevent overflow is disabled
            enabled: false
          },
          flip: {
            // Don't try to flip the popper if it will overflow the screen
            enabled: false
          },
          computeStyle: {
            // Use the top and left properties to position the popover.
            gpuAcceleration: false
          }
        }, extraPopperModifiers),
        onCreate: function onCreate(dataObject) {
          Ember.run(function () {
            return _this.set('isFlipped', dataObject.flipped);
          });
        },
        onUpdate: function onUpdate(dataObject) {
          Ember.run(function () {
            return _this.set('isFlipped', dataObject.flipped);
          });
        }
      });
    },
    click: function click() {
      if (this.get('closePopoverOnClick') && this.close) {
        this.close();
      }
    }
  });

  _exports.default = _default;
});