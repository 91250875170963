define("@square/glass-ui/templates/components/progress-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K9B5wxvF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"progress-slider__track\"],[12],[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"progress-slider__progress\"],[15,5,[34,0]],[12],[13],[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[15,0,[31,[\"progress-slider__thumb \",[30,[36,2],[[35,1],\"slider-bar__thumb--is-dragging\"],null]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"progressStyle\",\"isDragging\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/progress-slider.hbs"
    }
  });

  _exports.default = _default;
});