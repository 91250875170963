define("@square/glass-ui/components/sq-field-input-searchable/input-element", ["exports", "@square/glass-ui/templates/components/sq-field-input-searchable/input-element"], function (_exports, _inputElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _inputElement.default,
    type: 'text',
    selectPaddingBuffer: 0,
    open: function open() {// noop; passed in
    },
    filterChanged: function filterChanged() {// noop; passed in
    },
    actions: {
      focusOut: function focusOut() {// This is only here as a bugfix. See the description of
        // https://go.sqprod.co/sq-field-input-searchable-bugfix-pr
        // for more information.
      }
    }
  });

  _exports.default = _default;
});