define("@square/glass-ui/components/dialog-body", ["exports", "@square/glass-ui/components/dialog-actions", "@square/glass-ui/templates/components/dialog-body"], function (_exports, _dialogActions, _dialogBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BODY_LAYOUT = void 0;
  var BODY_LAYOUT = {
    DEFAULT: 'DEFAULT',
    CENTERED: 'CENTERED'
  };
  _exports.BODY_LAYOUT = BODY_LAYOUT;

  var _default = Ember.Component.extend({
    layout: _dialogBody.default,
    classNames: ['dialog__body'],
    classNameBindings: ['bodyClass'],
    attributeBindings: ['data-test-dialog-body', 'isBladeContext:data-test-blade-body', 'isModalContext:data-test-modal-body'],
    bodyLayout: BODY_LAYOUT.DEFAULT,
    isModalContext: Ember.computed('context', function () {
      return this.get('context') === _dialogActions.CONTEXT.MODAL;
    }),
    isBladeContext: Ember.computed('context', function () {
      return this.get('context') === _dialogActions.CONTEXT.BLADE;
    }),
    isBodyLayoutCentered: Ember.computed('isModalContext', 'bodyLayout', function () {
      // NB(jlintern): The CENTERED layout might work for blades and sheets, but the only place
      // we know it will be used is MODAL context, so let's restrict it to that for now.
      return this.get('isModalContext') && this.get('bodyLayout') === BODY_LAYOUT.CENTERED;
    }),
    bodyClass: Ember.computed('context', function () {
      switch (this.get('context')) {
        case _dialogActions.CONTEXT.MODAL:
          return 'dialog--modal__body';

        case _dialogActions.CONTEXT.BLADE:
          return 'dialog--blade__body';

        case _dialogActions.CONTEXT.SHEET:
          return 'dialog--sheet__body';

        default:
          return '';
      }
    }),
    // wraps the content passed to dialog-body
    contentClass: Ember.computed('context', 'isBodyLayoutCentered', function () {
      var classes = [];

      if (this.get('context') === _dialogActions.CONTEXT.MODAL) {
        classes.push('dialog--modal__content');

        if (this.get('isBodyLayoutCentered')) {
          classes.push('dialog--modal__content--centered');
        }
      } else if (this.get('context') === _dialogActions.CONTEXT.BLADE) {
        classes.push('dialog--blade__content');
      } else if (this.get('context') === _dialogActions.CONTEXT.SHEET) {
        classes.push('dialog--sheet__content');
      }

      return classes.join(' ');
    })
  });

  _exports.default = _default;
});