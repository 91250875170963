import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';
import Data from 'bridge-dashboard/app/services/data';
import Auth from 'bridge-dashboard/app/services/auth';
import SyncTypes from '../../../../models/services/sync-type-status/sync-job-type';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import SyncService from '../../../../services/sync';
import { EngineRouterService } from 'ember-engines-router-service';
export default class AuthorizedRouteSyncDataBankTransfers extends Route {
  @service auth!: Auth;
  @service currentUser!: CurrentUser;
  @service data!: Data;
  @service router!: EngineRouterService;
  @service sync!: SyncService;

  // disable query parameter caching; on return to route, query params are default
  resetController(controller: any) {
    const queryParams = controller.get('queryParams');
    queryParams.forEach(function (param: any) {
      controller.set(param, null);
    });
  }

  @action
  refreshCurrentRoute() {
    this.refresh();
  }

  syncType = SyncTypes.BANK_TRANSFERS;

  model(params: any) {
    return hash({
      syncEntityGroupStatusesResponse: this.data.getSyncEntityGroupStatuses({
        ...params,
        sync_type: this.syncType,
      }),
      syncJobs: this.sync.search({
        sync_types: [ this.syncType ]
      }),
    });
  }
}
