define("@square/glass-ui/templates/components/sq-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+H8Rjv/",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,10],null,[[\"data-test-checkbox-input\",\"type\",\"class\",\"id\",\"name\",\"disabled\",\"checked\",\"indeterminate\",\"tabindex\",\"change\"],[[30,[36,9],[[35,8],[35,7],true],null],\"checkbox\",\"option-toggle__input checkbox__input\",[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onChange\"],[[\"value\"],[\"target.checked\"]]]]]]],[2,\"\\n\"],[1,[30,[36,12],[[35,11]],[[\"class\",\"width\",\"height\"],[\"option-toggle__mark checkbox__checkmark\",16,16]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"tabindex\",\"indeterminate\",\"checked\",\"disabled\",\"name\",\"checkboxId\",\"value\",\"testSelectorValue\",\"or\",\"input\",\"checkmarkSvg\",\"component\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-checkbox.hbs"
    }
  });

  _exports.default = _default;
});