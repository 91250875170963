import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Data from 'bridge-dashboard/app/services/data';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import _ from 'lodash';
import { action } from '@ember/object';
import FlashService from '@square/glass-ui/addon/services/flash';
export default class AuthorizedRouteNewIntegration extends Route {
  @service currentUser!: CurrentUser;
  @service data!: Data;
  @service flash!: FlashService;

  @action
  willTransition() {
    return true;
  }

  beforeModel() {
    if (
      !_.isEmpty(this.currentUser.currentIntegration) &&
      this.currentUser.isCompletelyConfigured()
    ) {
      this.currentUser.clearCurrentIntegration();
    }
  }

  model(params: any) {
    if (params.error_message) {
      this.flash.sheetError(params.error_message, {
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
    }
    return this.data.getAccountSources();
  }

  afterModel() {
    this.transitionTo({ queryParams: { code: null, source_state: null } });
  }
}
