import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
// @ts-ignore ember-engines has no exported types
import { EngineRouterService } from 'ember-engines-router-service';

interface WizardTestSyncArgs {}

export default class WizardTestSync extends Component<WizardTestSyncArgs> {
  @service router!: EngineRouterService;

  get locationSettingsUrl(): string {
    return this.router.urlFor('authorized-route.integration.settings.locations');
  }
}
