define("@square/glass-ui/templates/components/selector-control/navigation/item/step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TnUUX1JI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"breadcrumbs__crumb__item breadcrumbs__crumb__item--link\"],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"select\"],null],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"breadcrumbs__crumb__item\"],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"action\",\"isActive\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/selector-control/navigation/item/step.hbs"
    }
  });

  _exports.default = _default;
});