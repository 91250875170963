import { IntegrationState } from './integration-state_enum';

export default class AbridgedIntegration {
  id: string;
  name: string;
  state: IntegrationState;

  constructor(id: string, name: string, state: IntegrationState) {
    this.id = id;
    this.name = name;
    this.state = state;
  }
}
