define("@square/glass-ui/components/svg/svg-icon", ["exports", "@square/glass-ui/templates/components/svg/svg-icon"], function (_exports, _svgIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _svgIcon.default,
    tagName: 'svg',
    attributeBindings: ['width', 'height', 'viewBox', 'fill', 'role'],
    classNames: ['svg-icon'],
    classNameBindings: ['colorClass'],
    color: 'base',
    height: 28,
    width: 28,
    viewBox: '0 0 28 28',
    title: null,
    role: 'img',
    colorClass: Ember.computed('color', function () {
      return "svg-icon--theme-color-".concat(this.get('color'));
    })
  });

  _exports.default = _default;
});