define("@square/glass-ui/components/sq-billboard", ["exports", "@square/glass-ui/templates/components/sq-billboard"], function (_exports, _sqBillboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqBillboard.default,
    classNames: ['billboard'],
    detail: null,
    message: null,
    actions: {
      dismiss: function dismiss() {
        if (this.onDismiss) {
          this.onDismiss();
        }
      }
    }
  });

  _exports.default = _default;
});