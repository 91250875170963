define("@square/glass-ui/components/sq-dropdown-trigger", ["exports", "@square/glass-ui/templates/components/sq-dropdown-trigger"], function (_exports, _sqDropdownTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   `sq-dropdown-trigger` is intended to be used in `sq-dropdown`, it should not be used elsewhere.
   */
  var _default = Ember.Component.extend({
    layout: _sqDropdownTrigger.default,
    classNames: ['dropdown__trigger'],
    tagName: 'button',
    classNameBindings: ['isActive:dropdown__trigger--is-active', 'isDisabled:dropdown__trigger--is-disabled'],
    attributeBindings: ['isDisabled:disabled', 'tabindex', 'style', 'type'],
    type: 'button',
    tabindex: 0
  });

  _exports.default = _default;
});