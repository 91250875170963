define("@square/glass-ui/components/sq-field-radio-set", ["exports", "@square/glass-ui/templates/components/sq-field-radio-set", "@square/glass-ui/utils/field-element-support"], function (_exports, _sqFieldRadioSet, _fieldElementSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fieldElementSupport.default, {
    layout: _sqFieldRadioSet.default,
    tagName: 'fieldset',
    classNames: ['option-set', 'radio-set'],
    options: Ember.computed(function () {
      return [];
    }),
    actions: {
      /* You may notice that the parameters passed into this function are in a
      different order from those that are sent out in the onChange action.
      This is because the first param `option`, is passed in through this component's
      template, while the following 2 come directly from the `sq-radio` component.
      We pass `value` as the first param to the onChange action so that consuming
      components can use the (mut) template helper instead of needing to write a
      separate action to consume a second or third param */
      optionClicked: function optionClicked(option, value, selected) {
        this.set('value', value);

        if (this.onChange) {
          this.onChange(value, option, selected);
        }
      }
    }
  });

  _exports.default = _default;
});