import { helper } from '@ember/component/helper';
/* @ts-ignore TSLint can't find lodash, but it's here! */
import _ from 'lodash';

interface SnakifyArgumentObject {
  transformString: string;
  snakeify: boolean;
}

// (De)snakeifies a string
export function snakeify(_params: any, { transformString, snakeify }: SnakifyArgumentObject): string {
  if (snakeify) {
    return _.snakeCase(transformString);
  } else {
    return transformString.split('_').join(' ')
  }
}

export default helper(snakeify);
