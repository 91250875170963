define("@square/glass-ui/components/sq-billboard/actions", ["exports", "@square/glass-ui/templates/components/sq-billboard/actions"], function (_exports, _actions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _actions.default,
    tagName: ''
  });

  _exports.default = _default;
});