/**
  ## MultipleSelectorFilter::Dropdown

  Glass-UI-powered dropdown that allows for selection of more than one option.
  Example:

  ```hbs
    <MultipleSelectorFilter::Dropdown 
      @alignRight={{@alignRight}}
      @class={{concat @class "-dropdown"}}
      @isDisabled={{@isDisabled}}
      @isSearchable={{@isSearchable}}
      @label={{@label}}
      @multiple={{@multiple}}
      @onChange={{@handleFilterChange}}
      @onOpen={{this.onOpen}}
      @selectedUnits={{@selectedUnits}}
      @units={{@units}}
    />
  ```

  ### Parameters
    - @param {(options: Array<DropdownFilterUnit>, type: string) => void} 
        [onChange] [Event handler for option checked status change.]
    - @param {string} [class] [CSS class.]
    - @param {boolean} [isSearchable] [TO-DO. Whether options are searchable.]
    - @param {string} [label] [Label of options type. 
        Used to generate dynamic label.]
    - @param {() => void} [onOpen] [TO-DO. Event handler for dropdown open.]
    - @param {Array<DropdownFilterUnit>} [units] [Options.]
    - @param {Array<DropdownFilterUnit>} [selectedUnits] [Selected options.]
    - @param {boolean} [isDisabled] [Whether dropdown is disabled or not.]
    - @param {boolean} [multiple] [TO-DO. Whether multiple options can be selected.]

 */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';

import { DropdownFilterUnit } from '../../models/interface/dropdown-filter-unit';

interface InternalSubunitOption {
  unit: DropdownFilterUnit;
  checked: boolean;
}

interface MultipleSelectorFilterDropdownArgs {
  onChange: (options: Array<DropdownFilterUnit>, type: string) => void;
  class: string;
  isSearchable: boolean;
  label: string;
  onOpen: () => void;
  units: Array<DropdownFilterUnit>;
  selectedUnits: Array<DropdownFilterUnit>;
  isDisabled: boolean;
  multiple: boolean;
  alignRight: boolean;
}

export default class MultipleSelectorFilterDropdown extends Component<MultipleSelectorFilterDropdownArgs> {
  internalSubunitOptions: Array<InternalSubunitOption> = this.args.units.map(
    (subunit) => ({
      unit: subunit,
      checked: this.args.selectedUnits.includes(subunit),
      disabled: !subunit.unitActive,
      label: subunit.label,
    })
  );

  allSelectedUnits = this.args.selectedUnits && this.args.selectedUnits.map(unit =>
    unit.value
  ).join();

  dropdownIdentifier = `[data-test-dropdown-div-for="${this.args.label}"`

  @tracked
  label = this.determineLabel(this.args.selectedUnits);

  get selectAllLabel(): string {
    const pluralizeUnitLabel = pluralize(this.args.label);
    return `All ${pluralizeUnitLabel}`;
  }

  determineLabel(selectedUnits: Array<DropdownFilterUnit>): string {
    const prettifiedUnitType = pluralize(this.args.label);
    const numberOfUnitsSelected = selectedUnits.length;
    if (numberOfUnitsSelected === 0 && !this.args.isDisabled) {
      return `No ${prettifiedUnitType}`;
    } else if (numberOfUnitsSelected === 1) {
      return selectedUnits[0].label;
    } else if (
      numberOfUnitsSelected > 0 &&
      numberOfUnitsSelected < this.args.units.length
    ) {
      return `${numberOfUnitsSelected} ${prettifiedUnitType}`;
    } else {
      return `All ${prettifiedUnitType}`;
    }
  }

  @action
  sendSelectedUnits(): void {
    const selectedUnits = this.internalSubunitOptions
      .filter((subunitOption) => subunitOption.checked)
      .map((checkedSubunitOption) => checkedSubunitOption.unit);
    this.label = this.determineLabel(selectedUnits);
    this.args.onChange(selectedUnits, this.internalSubunitOptions[0]!.unit!.type!);
  }

  @action
  sendSelectedUnitsMarket(event: CustomEvent): void {
    const selectedUnits = this.internalSubunitOptions
      .filter((subunitOption) => event.detail.value.includes(subunitOption.unit.value))
      .map((checkedSubunitOption) => checkedSubunitOption.unit);
    this.label = this.determineLabel(selectedUnits);
    this.args.onChange(selectedUnits, this.internalSubunitOptions[0]!.unit!.type!);
  }
}
