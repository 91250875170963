define("@square/glass-ui/components/sq-table/cell", ["exports", "@square/glass-ui/templates/components/sq-table/cell"], function (_exports, _cell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _cell.default,
    tagName: 'td',
    attributeBindings: ['colspan', 'rowspan', 'scope'],
    classNames: ['sq-table__cell'],
    classNameBindings: ['isFixedLeft:sq-table__cell--is-fixed-left'],
    isFixedLeft: false
  });

  _exports.default = _default;
});