import SyncJobType from '../sync-type-status/sync-job-type';

import BatchJobTriggerFrequencyType from './batch-job-trigger-frequenecy-type';

export class SyncSchedule {
  sync_definition_id: string;
  location_name: string;
  sync_type: SyncJobType;
  frequency: BatchJobTriggerFrequencyType;
  timezone: string;
  sync_definition_enabled: boolean;
  sync_schedule_enabled: boolean;

  constructor(syncSchedule: SyncSchedule) {
    this.sync_definition_id = syncSchedule.sync_definition_id;
    this.location_name = syncSchedule.location_name;
    this.sync_type = syncSchedule.sync_type;
    this.frequency = syncSchedule.frequency;
    this.timezone = syncSchedule.timezone;
    this.sync_definition_enabled = syncSchedule.sync_definition_enabled;
    this.sync_schedule_enabled = syncSchedule.sync_schedule_enabled;
  }
}
