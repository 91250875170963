define("@square/glass-ui/components/banner-flash", ["exports", "@square/glass-ui/templates/components/banner-flash", "@square/glass-ui/services/flash"], function (_exports, _bannerFlash, _flash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bannerFlash.default,
    classNames: ['banner-flash'],
    classNameBindings: ['isActive:banner-flash--is-visible', 'typeClass'],
    isActive: null,
    message: null,
    type: null,
    typeClass: Ember.computed('type', function () {
      switch (this.get('type')) {
        case _flash.FLASH_TYPES.ERROR:
          return 'banner-flash--is-error';

        case _flash.FLASH_TYPES.SUCCESS:
          return 'banner-flash--is-successful';

        case _flash.FLASH_TYPES.WARNING:
          return 'banner-flash--is-warning';

        default:
          return '';
      }
    }),
    iconComponentName: Ember.computed('type', function () {
      switch (this.get('type')) {
        case _flash.FLASH_TYPES.ERROR:
          return 'svg/icon-alert-triangle';

        case _flash.FLASH_TYPES.SUCCESS:
          return 'svg/icon-checkmark-circle';

        case _flash.FLASH_TYPES.WARNING:
          return 'svg/icon-alert-triangle';

        default:
          return 'svg/icon-info-circle';
      }
    }),
    iconColor: Ember.computed('type', function () {
      switch (this.get('type')) {
        case _flash.FLASH_TYPES.ERROR:
          return 'white';

        case _flash.FLASH_TYPES.SUCCESS:
          return 'white';

        case _flash.FLASH_TYPES.WARNING:
          return 'base';

        default:
          return 'base';
      }
    })
  });

  _exports.default = _default;
});