define("@square/glass-ui/templates/components/deprecated/sheet-layout/headerbar-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1BvidwrX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,6,[\"isMobile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[35,3]],[[\"class\"],[\"sheet-layout__headerbar-action--mobile sheet-layout__headerbar-action--mobile-close\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"size\",\"color\"],[\"large\",\"medium\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"sheet-layout__headerbar-action--mobile sheet-layout__headerbar-action--mobile-close\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],[35,0]],null],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"size\",\"color\"],[\"large\",\"medium\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"sheet-layout__headerbar-navleft\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"sheet-layout__headerbar-item\"],[12],[2,\"\\n\"],[6,[37,5],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[35,3]],[[\"class\"],[\"sheet-layout__headerbar-close\"]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,2],null,[[\"size\",\"color\"],[\"large\",\"medium\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"sheet-layout__headerbar-close\"],[4,[38,1],[[32,0],[35,0]],null],[12],[2,\"\\n          \"],[1,[30,[36,2],null,[[\"size\",\"color\"],[\"large\",\"medium\"]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cancelAction\",\"action\",\"svg/icon-close-x\",\"cancelComponent\",\"component\",\"if\",\"screen\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/deprecated/sheet-layout/headerbar-close.hbs"
    }
  });

  _exports.default = _default;
});