import {
  createPopper,
  Instance as PopperInstance,
  Modifier,
  State,
} from '@popperjs/core';

export type PopperOptions = {
  offset?: [number, number];
  placement?:
    | 'top-start'
    | 'bottom-start'
    | 'bottom-end'
    | 'top'
    | 'bottom'
    | 'right'
    | 'left';
  padding?: number;
};

const sameWidthPopperModifier: Modifier<
  'sameWidthModifier',
  { name: string; enabled: boolean; phase: string }
> = {
  name: 'sameWidthModifier',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: { state: State }) => {
    // eslint-disable-next-line no-param-reassign
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: { state: State }) => {
    // eslint-disable-next-line no-param-reassign
    state.elements.popper.style.width = `${
      (state.elements.reference as HTMLElement).offsetWidth
    }px`;
  },
};

export function createSameWidthPopper(
  triggerElement: HTMLElement,
  popperElement: HTMLElement
): PopperInstance {
  return createPopper(triggerElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
      sameWidthPopperModifier,
      { name: 'flip', options: { fallbackPlacements: ['top-start'] } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });
}
