define("@square/glass-ui/templates/components/svg/icon-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xi7zHjIJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M10.7176 1.69648L4.17652 8.4358L0.282471 4.42375L1.71763 3.0308L4.17652 5.5642L9.28247 0.303528L10.7176 1.69648Z\"],[14,\"fill\",\"black\"],[14,0,\"svg-icon__fill\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-checkmark.hbs"
    }
  });

  _exports.default = _default;
});