import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";

export interface MultiSelectOption {
  value: string;
  name: string;
}

export type MultiSelectArgs = {
  singularLabel: string;
  pluralLabel: string;
  options: MultiSelectOption[];
  onChange: (selected: string[]) => void;
}

class MultiSelectSelection {
  option: MultiSelectOption;
  @tracked selected: boolean;

  constructor(option: MultiSelectOption, select: boolean) {
    this.option = option;
    this.selected = select;
  }
}

/**
 * @param locations - A list of Square Locations to include in this dropdown
 * @param onChange - Event listener accepts a list of locations IDS selected
 */
export default class GlassMultiSelect extends Component<MultiSelectArgs> {
  @tracked options: MultiSelectSelection[];
  @tracked isAllSelected;

  allOptions: MultiSelectSelection[];

  pluralLabel: string = "Selections";
  singularLabel: string = "Selection";

  constructor(owner: unknown, args: MultiSelectArgs) {
    super(owner, args);

    this.options = this.selectAll(true);
    this.isAllSelected = true;
    this.allOptions = this.selectAll(true);

    if (this.args.pluralLabel) {
      this.pluralLabel = this.args.pluralLabel;
    }
    if (this.args.singularLabel) {
      this.singularLabel = this.args.singularLabel;
    }
  }

  get dropdownText(): string {
    const selected = this.getSelected();
    if (this.isAllSelected) {
      return "All " + this.pluralLabel;
    } 
    if (selected.length >= 1) {
      return selected.length + " " + this.pluralLabel;
    } else {
      return "No " + this.pluralLabel;
    }
  }

  get allCheckboxLabel(): string {
    return "All " + this.pluralLabel;
  }

  @action
  onSelectAll(checked: boolean) {
    this.isAllSelected = checked;
    this.options = this.selectAll(checked);
    this.args.onChange(this.getSelectedValues());
  }

  @action 
  onToggleSelect(selection: MultiSelectSelection, value: boolean) {
    if (value) {
      this.select(selection);
    } else {
      this.deselect(selection);
    }
    this.checkAllLocationsSelected();
  }

  select(selection: MultiSelectSelection) {
    if (!selection.selected) {
      selection.selected = true;
      this.args.onChange(this. getSelectedValues());
    }
  }

  deselect(selection: MultiSelectSelection) {
    if (selection.selected) {
      selection.selected = false;
      this.args.onChange(this. getSelectedValues());
    }
  }

  private checkAllLocationsSelected() {
    if (this.getSelected().length === this.args.options.length) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
  }

  private selectAll(selected: boolean): MultiSelectSelection[] {
    return this.args.options.map((option: MultiSelectOption) => {
      return new MultiSelectSelection(option, selected);
    });
  }

  private getSelected(): MultiSelectSelection[] {
    return this.options.filter(selection => selection.selected);
  }

  private getSelectedValues(): string[] {
    return this.options.filter(selection => selection.selected)
      .map(selection => selection.option.value);
  }
}