import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';
import { EngineRouterService } from 'ember-engines-router-service';
import { PluginApplication } from '../../models/services/plugin-application/plugin-application';
import FlashService from '@square/glass-ui/addon/services/flash';
interface PluginsSheetArgs {
  plugin: PluginApplication;
}

export default class PluginsSheet extends Component<PluginsSheetArgs> {
  @service data!: Data;
  @service flash!: FlashService;
  @service router!: EngineRouterService;

  @tracked isRequestingMerchantToken: boolean = false;
  @tracked freshMerchantToken: string | null = null;

  sheetTitle: string = `${this.args.plugin.application_name} Plugin`;

  @action
  handleClose(): void {
    this.flash.clearSheetMessage();
    this.router.transitionTo('authorized-route.plugins');
  }

  @action
  async refreshMerchantToken() {
    this.isRequestingMerchantToken = true;
    try {
      this.freshMerchantToken = await this.data.resetMerchantToken(
        this.args.plugin.id
      );
      this.flash.sheetSuccess('Merchant token successfully reset!');
      this.isRequestingMerchantToken = false;
    } catch (error) {
      this.isRequestingMerchantToken = false;
      this.flash.sheetError(error.message, {
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
    }
  }
}
