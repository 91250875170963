define("@square/glass-ui/templates/components/dialog-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z86iXSvK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"dialog__header__content\"],[12],[2,\"\\n\"],[6,[37,10],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,11],null,[[\"context\"],[[35,0]]],[[\"default\"],[{\"statements\":[[6,[37,10],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[35,5]],[[\"class\",\"data-test-dialog-close-icon\"],[\"dialog__header__close button\",\"true\"]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,8],null,[[\"size\",\"color\"],[\"small\",\"medium\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,7],null,[[\"class\",\"click\"],[\"dialog__header__close button\",[30,[36,6],[[32,0],[35,5]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"secondary-actions-container\"],[[30,[36,1],[\"dialog-header/actions-container\"],[[\"context\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"title\",\"context\"],[[35,3],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"title\"],[[30,[36,1],[\"dialog-header/title\"],[[\"context\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,1,[[30,[36,2],null,[[\"primary-actions-container\"],[[30,[36,1],[\"dialog-header/actions-container\"],[[\"context\"],[[35,0]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,[[30,[36,2],null,[[\"subheader\"],[[30,[36,1],[\"dialog-header/subheader\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"context\",\"component\",\"hash\",\"title\",\"dialog-header/title\",\"close\",\"action\",\"dialog-actions/button-close-x\",\"svg/icon-close-x\",\"isCloseComponent\",\"if\",\"dialog-header/actions-container\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/dialog-header.hbs"
    }
  });

  _exports.default = _default;
});