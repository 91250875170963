import Component from '@glimmer/component';

interface MarketSearchableComponentInputArgs {
  autofocus?: boolean;
}

export default class MarketSearchableComponentInput extends Component<MarketSearchableComponentInputArgs> {
  get shouldAutofocus(): boolean {
    if (typeof this.args.autofocus === 'undefined') {
      return false;
    }

    return this.args.autofocus;
  }
}
