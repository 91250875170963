define("@square/glass-ui/components/media-block/content", ["exports", "@square/glass-ui/templates/components/media-block/content"], function (_exports, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_BLOCK_CONTENT_THEME = void 0;
  var MEDIA_BLOCK_CONTENT_THEME = {
    LIGHT: 'light',
    DARK: 'dark'
  };
  _exports.MEDIA_BLOCK_CONTENT_THEME = MEDIA_BLOCK_CONTENT_THEME;

  var _default = Ember.Component.extend({
    layout: _content.default,
    classNames: ['media-block__content'],
    classNameBindings: ['contentThemeClass'],
    attributeBindings: ['data-test-media-block-content'],
    'data-test-media-block-content': true,
    contentThemeClass: Ember.computed('theme', function () {
      if (this.theme === MEDIA_BLOCK_CONTENT_THEME.LIGHT) {
        return 'media-block__content--theme-light';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});