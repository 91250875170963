define("@square/glass-ui/templates/components/svg/icon-undetermined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVZrAQMe",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M8 2H0V0H8V2Z\"],[14,\"fill\",\"black\"],[14,0,\"svg-icon__fill\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-undetermined.hbs"
    }
  });

  _exports.default = _default;
});