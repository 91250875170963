define("@square/glass-ui/components/sq-card/attributes-list/attribute", ["exports", "@square/glass-ui/templates/components/sq-card/attributes-list/attribute"], function (_exports, _attribute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _attribute.default,
    classNames: ['card__attributes-list__attribute'],
    classNameBindings: ['isFullWidth:card__attributes-list__attribute--is-full-width'],
    attributeBindings: ['data-test-card-attribute'],
    'data-test-card-attribute': true,
    // Passed in:
    isFullWidth: false,
    label: null,
    value: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      (false && !(Ember.isPresent(this.get('label'))) && Ember.assert('You must pass translated `label` string to `{{card/attribute-list/attribute}}`', Ember.isPresent(this.get('label'))));
    }
  });

  _exports.default = _default;
});