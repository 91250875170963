define("@square/glass-ui/templates/components/svg/icon-info-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P3+2zkyD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"title\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"clip-rule\",\"evenodd\"],[14,\"d\",\"M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 7C8.55229 7 9 7.44772 9 8V11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11V8C7 7.44772 7.44772 7 8 7ZM8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z\"],[14,\"fill\",\"black\"],[14,\"fill-opacity\",\"0.9\"],[14,0,\"svg-icon__fill\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/svg/icon-info-circle.hbs"
    }
  });

  _exports.default = _default;
});