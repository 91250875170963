define("@square/glass-ui/components/svg/icon-info-circle", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-info-circle"], function (_exports, _svgIcon, _iconInfoCircle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconInfoCircle.default,
    classNames: ['svg-icon--info-circle'],
    width: 18,
    height: 18,
    viewBox: '0 0 16 16'
  });

  _exports.default = _default;
});