define("@square/glass-ui/components/sq-draggable-list/row", ["exports", "jquery", "draggabilly"], function (_exports, _jquery, _draggabilly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  ## Draggable List Row
  
  This is a contextual component yielded by `sq-draggable-list`, and is not meant
  to be used on its own.
  
  ### Parameters
  @param {Action} staticClick - Optional - An action to trigger when the user's pointer (via touch or mouse click) is pressed and unpressed and has not moved enough to start dragging.
  
  Sass dependencies at: `app/assets/stylesheets/dashboard/components/_sq-draggable-list.sass`
  */
  var EVENT_NAME_MAPPINGS = {
    dragStart: 'draggabillyDragStart',
    dragMove: 'draggabillyDragMove',
    dragEnd: 'draggabillyDragEnd',
    staticClick: 'draggabillyStaticClick'
  };

  var _default = Ember.Component.extend({
    classNames: ['draggable-list__row'],
    draggabillyInstance: null,
    dragOnlyOnHandle: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Draggabilly setup can take a significant amount of time, resulting in jank (e.g. for `dialog-sheet` sliding into view)
      // Deferring this work unblocks the main thread so frames aren't dropped.


      Ember.run.schedule('afterRender', this, this.setupDraggabilly);
    },
    setupDraggabilly: function setupDraggabilly() {
      var _this = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var draggabilly = new _draggabilly.default(this.element, {
        axis: 'y',
        containment: true,
        handle: this.get('dragOnlyOnHandle') ? '.draggable-list__handle' : undefined
      }); // adapted from Dashboard's `utils/draggabilly`

      var _loop = function _loop() {
        var eventName = _Object$keys[_i];
        draggabilly.on(eventName, function () {
          var _Ember;

          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return (_Ember = Ember).run.apply(_Ember, [_this, EVENT_NAME_MAPPINGS[eventName], draggabilly].concat(args));
        });
      };

      for (var _i = 0, _Object$keys = Object.keys(EVENT_NAME_MAPPINGS); _i < _Object$keys.length; _i++) {
        _loop();
      }

      this.set('draggabillyInstance', draggabilly);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var draggabillyInstance = this.get('draggabillyInstance');

      if (draggabillyInstance) {
        draggabillyInstance.destroy();
      }
    },
    draggabillyStaticClick: function draggabillyStaticClick() {
      if (this.staticClick) {
        this.staticClick();
      }
    },
    draggabillyDragStart: function draggabillyDragStart() {
      var $el = (0, _jquery.default)(this.element); // eslint-disable-line ember/no-jquery

      var $tableCells = $el.find('td'); // Ensure element has width before we remove it from the document flow via
      // `position: fixed` below. Table rows require a different approach than
      // divs here.

      if ($tableCells.length > 0) {
        $tableCells.each(function (i, cell) {
          var $cell = (0, _jquery.default)(cell); // eslint-disable-line ember/no-jquery

          $cell.css({
            'max-width': $cell.outerWidth(),
            width: $cell.outerWidth()
          });
        });
      } else {
        $el.css({
          width: $el.outerWidth()
        });
      }

      $el.css({
        // prevent document.elementFromPoint from always finding the dragged item
        pointerEvents: 'none',
        // Remove from document flow to avoid shifting rows down, and fix start
        // position for draggabilly to transition based on
        position: 'fixed',
        top: this.element.getBoundingClientRect().top,
        left: this.element.getBoundingClientRect().left
      });

      if (this.dragStart) {
        this.dragStart(this.element);
      }
    },
    draggabillyDragMove: function draggabillyDragMove(draggabilly, event, pointer) {
      if (this.dragMove) {
        this.dragMove(draggabilly, event, pointer);
      }
    },
    draggabillyDragEnd: function draggabillyDragEnd() {
      // eslint-disable-next-line ember/no-jquery
      (0, _jquery.default)(this.element).css({
        pointerEvents: '',
        // draggabilly sets an inline `position: relative` style on all rows, so
        // we're just restoring that here.
        position: 'relative',
        top: '',
        left: '',
        width: ''
      }); // eslint-disable-next-line ember/no-jquery

      (0, _jquery.default)(this.element).find('td').css({
        'max-width': '',
        width: ''
      });

      if (this.dragEnd) {
        this.dragEnd(this.element, this.get('model'));
      }
    }
  });

  _exports.default = _default;
});