define("@square/glass-ui/components/dialog-modal", ["exports", "@square/glass-ui/templates/components/dialog-modal", "@square/glass-ui/services/modal", "@square/glass-ui/services/focus"], function (_exports, _dialogModal, _modal, _focus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MODAL_LAYOUT = void 0;
  var KEY_ENTER = 13;
  var MODAL_LAYOUT = {
    SINGLE_SECTION: 'SINGLE_SECTION',
    DEFAULT: 'DEFAULT'
  };
  _exports.MODAL_LAYOUT = MODAL_LAYOUT;

  var _default = Ember.Component.extend({
    layout: _dialogModal.default,
    attributeBindings: ['data-test-modal'],
    'data-test-modal': Ember.computed.readOnly('title'),
    autofocusSelector: null,
    hasDividers: true,
    hasSecondaryActionsBlock: false,
    title: null,
    classNames: ['dialog', 'dialog--modal'],
    classNameBindings: ['isShowingDividers:dialog--modal--with-dividers'],
    ariaRole: 'dialog',
    dialogLayout: MODAL_LAYOUT.DEFAULT,
    // internal use only: reads from the public API but can be changed
    isShowingDividers: Ember.computed.reads('hasDividers'),
    modalService: Ember.inject.service('modal'),
    flash: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // add a resize event handler to force dividers if the modal content scrolls


      if (!this.get('hasDividers')) {
        this._windowEventListener = function () {
          return Ember.run.debounce(_this, _this._forceDividersWhenContentScrolls, 50);
        };

        window.addEventListener('resize', this._windowEventListener);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (!this.get('hasDividers')) {
        this._forceDividersWhenContentScrolls();
      }
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this._windowEventListener);
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === KEY_ENTER && !(0, _focus.isActionableElement)(e.target) && this.onEnterKeyDown) {
        this.onEnterKeyDown(e);
      }
    },
    _forceDividersWhenContentScrolls: function _forceDividersWhenContentScrolls() {
      if (this.get('isDestroyed')) {
        return;
      }

      var contentElement = this.element.querySelector('.dialog--modal__content');

      if (!contentElement) {
        return;
      }

      var height = contentElement.offsetHeight;
      var scrollHeight = contentElement.scrollHeight; // something about the fact that padding changes makes for
      // weird rounding issues. Allowing a difference of 1 removes
      // some visual glitching.

      if (scrollHeight - height > 1) {
        this.set('isShowingDividers', true);
      } else if (!this.get('hasDividers')) {
        this.set('isShowingDividers', false);
      }
    },
    actions: {
      close: function close() {
        this.get('modalService').maybeCloseOnEvent(_modal.CLOSE_X);
      }
    }
  });

  _exports.default = _default;
});