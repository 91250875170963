define("@square/glass-ui/templates/components/dialog-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qe0zlnex",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dialog--modal__footer-spinner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"loading loading-is-active\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"loading-indicator-small-dark\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,[[30,[36,4],null,[[\"button-primary\",\"button-secondary\",\"button\",\"button-close-x\",\"icon-close-x\",\"button-back-arrow\"],[[30,[36,2],[\"dialog-actions/button\"],[[\"rank\",\"context\"],[\"primary\",[35,3]]]],[30,[36,2],[\"dialog-actions/button\"],[[\"rank\",\"context\"],[\"secondary\",[35,3]]]],[30,[36,2],[\"dialog-actions/button\"],[[\"context\"],[[35,3]]]],[30,[36,2],[\"dialog-actions/button-close-x\"],null],[30,[36,2],[\"svg/icon-close-x\"],[[\"size\",\"color\",\"data-test-dialog-close-icon\"],[\"large\",\"medium\",true]]],[30,[36,2],[\"dialog-actions/button-back-arrow\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isShowingSpinner\",\"if\",\"component\",\"context\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/dialog-actions.hbs"
    }
  });

  _exports.default = _default;
});