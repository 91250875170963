import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { NavigationServiceObject } from 'bridge-dashboard/app/services/navigation';

interface AppLayoutAppLayoutSideNavArgs {}

// this component and its compadres (app-layout-sidenav-parent, app-layout-sidenav-link)
// were copied from the Dashboard repo
// they encompass the navigation menu found on the left side of the app
// the navigation structure is stubbed out in a service
export default class AppLayoutAppLayoutSideNav extends Component {
  constructor(owner: unknown, args: AppLayoutAppLayoutSideNavArgs) {
    super(owner, args);
    this.router.on('routeDidChange', this.syncActivePageLabel);
    this.syncActivePageLabel();
  }

  @service navigation!: NavigationServiceObject;
  @service router!: RouterService;

  @tracked
  currentPageLabel: string = '';

  syncActivePageLabel = () => {
    this.currentPageLabel = this.navigation.currentPageLabel();
  };
}
