define("@square/glass-ui/templates/components/banner-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/x27QcYN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"banner-flash__wrapper\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"banner-flash__icon\"],[12],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,6],[[35,5]],[[\"color\"],[[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,\"data-test-banner-flash-body\",\"\"],[14,0,\"banner-flash__body\"],[12],[2,\"\\n\"],[6,[37,8],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,8],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"banner-flash__close\"],[4,[38,1],[[32,0],[35,0]],null],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"size\",\"width\",\"height\"],[\"small\",\"10\",\"10\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dismiss\",\"action\",\"svg/icon-close-x\",\"message\",\"iconColor\",\"iconComponentName\",\"component\",\"isActive\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/banner-flash.hbs"
    }
  });

  _exports.default = _default;
});