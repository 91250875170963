/**
## SettingsManager

Manages settings forms by handling form events and validating user input.

```example
<SettingsManager
  @formMap={{this.settingsFormMap}}
  @formStructure={{this.configBlueprint}}
  @handleCompletedForm={{this.handleCompletedForm}}
  @onStandalonePage={{@onStandalonePage}}
/>
```

A general overview of the way form entry and submission works:
- All form fields rendered on the page are parsed for their names and value and placed into a single-level object `formMap`
- If a field doesn’t have a value, the value of that field in the `formMap` is `undefined`
- If any fields are undefined, the form is considered incomplete
- When a user changes a form field, the entire form is iterated over and every field which is not `undefined` is validated according to its yup validations
In summary, a form is not considered complete until all visible fields have a valid value, and only form fields which have a value are validated.


### Parameters
 * @param {ConfigFormBlueprint} [formStructure] [Object used to generate form UI.]
 * @param {Object} [formMap] [A single-level object with: property names describing the structure of the settings request, and values of the form fields.]
 * @param {Function} [handleCompletedForm] [Action used to passed completed form data to parent components.]
 * @param {boolean} [onStandalonePage] [True for forms that are on a page and not a sheet. Determines styling (mostly justification).]
*/

import { transformAll } from '@demvsystems/yup-ast';
import { action, set } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';
import * as yup from 'yup';
import ModalService from '@square/glass-ui/addon/services/modal';
import { containsStringValues } from '../helpers/contains-string-values';
import Config, {
  ConfigFormBlueprint,
  ConsolidatedFieldResource,
  ConsolidatedMappingResource,
  FieldResourceMetadata,
  FormFieldObject,
  FormFieldOptionObject,
  GetSettingsOptionsResponse,
  LoadingStrategy,
  FormSection,
} from '../services/config';
import getFieldNameMatcher, {
  Matcher,
} from '@bridge/home-engine/utils/matcher';
import { SettingsFormType } from '@bridge/home-engine/models/interface/settings-form-type';
import BridgeApiException from 'bridge-dashboard/app/types/bridge-api-exception';
import FlashService from '@square/glass-ui/addon/services/flash';
import { MarketInputTextCustomEvent, MarketToggleCustomEvent } from '@market/web-components/dist/types/components';
import { SettingsOption } from '@bridge/home-engine/models/interface/settings-option';

interface SetFieldValuesArg {
  fieldMap: { [key: string]: string };
  formMap: { [key: string]: string };
  formStructure: ConfigFormBlueprint;
}

interface SetFieldStateArgObject {
  fieldNames: Array<string>;
  newState: string;
}

interface AbbreviatedFormFieldObject {
  name: string | null;
  value: string | boolean | number | AbbreviatedFormFieldObject | null;
}

interface SettingsManagerArgs {
  settingsTarget: SettingsFormType;
  formStructure: ConfigFormBlueprint;
  formMap: { [key: string]: string };
  handleCompletedForm: Function;
  onStandalonePage: boolean;
  configObjectId: string;
}

export default class SettingsManager extends Component<SettingsManagerArgs> {
  // on instantiation, the sheet determines the form's control fields (for conditional rendering),
  // creates the key-value formMap
  // and creates the Yup validation schema based off the content of the form object
  constructor(owner: unknown, args: SettingsManagerArgs) {
    super(owner, args);
    this.resetAndFindControlFields();
    this.iterateAndActOverFormObject(this.createFormMapPairs, {});
    this.createYupSchema();

    this.updateFormMapAndValidate();
    this.lazyLoadOptions(args.settingsTarget, args.configObjectId);
  }

  // no types for these services; they are imported from @square/glass-ui library and have no defined types
  @service flash!: FlashService;
  @service modal!: ModalService;

  @tracked controlFields: Array<FormFieldObject> = [];
  @tracked stringifiedControlFields = '';
  @tracked formStructure = this.args.formStructure;
  @service router!: RouterService;
  @service data!: Data;
  @service config!: Config;

  formMap: { [key: string]: any } = {};
  yupSchema!: yup.ObjectSchema<any>;
  yupShape: yup.ObjectSchemaDefinition<any> = {};
  validationErrors: Array<yup.ValidationError> = [];

  // triggers flash sheet error banner with message
  onError(message: string): void {
    if (this.args.onStandalonePage) {
      this.flash.globalError(message, {
        dismiss: () => {
          this.flash.clearGlobalMessage();
        },
      });
    } else {
      this.flash.sheetError(message, {
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
    }
  }

  // sets null field values to empty strings
  // used to validate fields that have not been interacted with by the user,
  // as null fields are ignored when validating
  denullifyField(field: FormFieldObject): void {
    if (field.value === null) {
      set(field, 'value', '');
    }
  }

  // sets field values which are empty strings to null
  nullifyField(field: FormFieldObject): void {
    if (field.value === '') {
      set(field, 'value', null);
    }
  }

  setFieldValue(
    obj: FormFieldObject,
    key: keyof FormFieldObject,
    value: any
  ): any {
    set(obj, key, value);
  }

  // handles input event
  // updates the formMap and validates all fields
  @action
  handleInput(event: Event): void {
    debounce(this, this.updateFieldValue, event, 750);
  }

  @action
  handleInputMarket(event: MarketInputTextCustomEvent<HTMLInputElement>): void {
    // Due to one-way data binding in Market (vs two-way in GlassUI), the form
    // structure field's value property has to be "manually" set here
    const field = this.findFormObjectField('name', event.target.name)!;
    field.value = event.detail.value;
    this.handleInput(event);
  }

  updateFieldValue(event: Event) {
    /* @ts-ignore name and value exist on these event target properties */
    const fieldName = event.target.name;
    /* @ts-ignore name and value exist on these event target properties */
    const fieldValue = event.target.value;
    this.updateFormMapAndValidate(fieldName, fieldValue);
  }

  // handles focus out event
  // updates the formMap and validates all fields
  @action
  handleFocusOut(event: Event): void {
    /* @ts-ignore name and value exist on these event target properties */
    const fieldName = event.target.name;
    /* @ts-ignore name and value exist on these event target properties */
    const fieldValue = event.target.value;
    this.updateFormMapAndValidate(fieldName, fieldValue);
  }

  // handles switch toggle/value change event
  // updates control fields as switches are used as controls
  // updates the formMap and validates all fields
  @action
  handleSwitchChange(
    fieldName: string,
    fieldChecked: boolean,
    fieldValue: string
  ): void {
    this.resetAndFindControlFields(fieldChecked, fieldValue);
    this.updateFormMapAndValidate(fieldName, fieldChecked);
  }

  @action
  handleSwitchChangeMarket(
    fieldName: string,
    fieldValue: string,
    event: MarketToggleCustomEvent<any>
  ): void {
    if (event) {
      const field = this.findFormObjectField('name', fieldName)!;
      set(field, 'checked', event.detail.current);
      this.resetAndFindControlFields(event.detail.current, fieldValue);
      this.updateFormMapAndValidate(fieldName, event.detail.current);
    }
  }

  // handles select value change event
  // updates control fields as selects are used as controls
  // updates the formMap and validates all fields
  @action
  handleSelectChange(fieldName: string, fieldValue: string): void {
    this.updateFormMapAndValidate(fieldName, fieldValue);
    this.resetAndFindControlFields();
  }

  // handles searchable input value change event
  // updates the formMap and validates all fields
  @action
  handleSearchableChange(fieldName: string, value: SettingsOption): void {
    // due to the structure of the Glass SqFieldInputSearchable,
    // the field has to be found and its value has to be "manually" updated
    const field = this.findFormObjectField('name', fieldName)!;
    let effectiveValue: SettingsOption | string | undefined = value;
    if(!this.isMigratedToSettingsOption(field)) {
      //backward compatible with String setting fields until we've fully migrated to SettingsOption
      effectiveValue = value?.value;
    }
    set(field, 'value', effectiveValue);
    this.updateFormMapAndValidate(fieldName, effectiveValue);
  }

  /**
   * Until all 'option' fields are migrated, this is needed to determine
   * whether to use 'option' or string values in persistence
   * by settings-manager
   */
  private isMigratedToSettingsOption(field: FormFieldObject) : boolean {
    return field.yup_validations?.[0]?.[0] === 'yup.object';
  }

  // handles searchable input dropdown menu closing event
  // updates the formMap and validates all fields
  @action
  handleSearchableDropdownClose(fieldName: string, fieldValue: any): void {
    // handle the edge case where a user select a searchable but does not select a value
    // null values are ignored by default as, in other inputs, it signifies
    // the user has not interacted with the field
    let value = '';

    value = fieldValue;
    if (typeof fieldValue === 'object' && fieldValue !== undefined) {
      value = fieldValue.value;
    }
    this.updateFormMapAndValidate(fieldName, value);
  }

  @action
  handleCheckboxChange(fieldName: string, checkValue: boolean): void {
    this.updateFormMapAndValidate(fieldName, checkValue);
  }

  @action
  handleCheckboxChangeMarket(fieldName: string, event: CustomEvent): void {
    if (event) {
      // Due to one-way data binding in Market (vs two-way in GlassUI), the form
      // structure field's checked property has to be "manually" set here
      const field = this.findFormObjectField('name', fieldName)!;
      field.checked = event.detail.current;

      this.updateFormMapAndValidate(fieldName, event.detail.current);
    }
  }

  @action
  handleCheckboxTableChange(
    fieldName: string,
    fieldCheckValueObj: FormFieldOptionObject
  ): void {
    let checkboxSetStringValue;

    if (!fieldCheckValueObj.checked) {
      checkboxSetStringValue = [
        ...this.formMap[fieldName],
        fieldCheckValueObj.value,
      ];
    } else {
      checkboxSetStringValue = this.formMap[fieldName].filter(
        (option: any) => option !== fieldCheckValueObj.value
      );
    }

    const checkboxSetfield = this.findFormObjectField('name', fieldName);

    set(checkboxSetfield, 'value', checkboxSetStringValue);
    this.updateFormMapAndValidate(fieldName, checkboxSetStringValue);
  }

  // handles changes to any member of a checkbox set
  // creates single string value based on checked boxes within set
  // updates form field in master configuration object, formMap, and validates
  @action
  handleCheckboxSetChange(
    fieldName: string,
    _checkValue: boolean,
    newOptionsArray: Array<FormFieldOptionObject>,
    _changedOptionIndex: number,
    _optionValue: string,
    _option: FormFieldOptionObject
  ): void {
    const checkboxSetStringValue = newOptionsArray
      .filter((option) => option.checked === true)
      .map((filteredOption) => filteredOption.value);
    const checkboxSetfield = this.findFormObjectField('name', fieldName);
    set(checkboxSetfield, 'value', checkboxSetStringValue);
    this.updateFormMapAndValidate(fieldName, checkboxSetStringValue);
  }

  // updates form field in master configuration object, formMap, and validates
  // Ember set() notifies object listeners, changes property value
  @action
  handleSearchableDropdown(
    fieldName: string,
    fieldValue: string[]
  ) {
    const field = this.findFormObjectField('name', fieldName)!;
    set(field, 'value', fieldValue);
    this.updateFormMapAndValidate(fieldName, fieldValue)
  }

  getFieldBase(field: FormFieldObject) {
    const splitFields = field.name.split(/(?=\[)/);
    splitFields.pop();
    return splitFields.join('');
  }

  reassembleFormStructure(newFields: FormFieldObject[], sectionIndex: number) {
    const newSection = {
      ...this.formStructure.sections[sectionIndex],
      fields: newFields,
    };

    const newSections = [
      ...this.formStructure.sections.slice(0, sectionIndex),
      newSection,
      ...this.formStructure.sections.slice(sectionIndex + 1),
    ];

    this.formStructure = {
      ...this.formStructure,
      sections: newSections,
    };

    this.updateFormMapAndValidate();
  }

  @action
  deleteTableRow(field: FormFieldObject, sectionIndex: number) {
    const toDeleteFieldBase = this.getFieldBase(field);
    const section = this.formStructure.sections[sectionIndex];
    const newFields = section.fields.filter(
      (field) => !field.name.includes(toDeleteFieldBase)
    );

    this.reassembleFormStructure(newFields, sectionIndex);
  }

  @action
  handleNewTableItemSubmit(sectionIndex: number, value: any) {
    const section = this.formStructure.sections[sectionIndex];
    const sectionsFieldLength = section.fields.length;
    const {
      hidden_field_name_template: hiddenFieldNameTemplate,
      field_name_template: fielNameTemplate,
      options,
    } = this.formStructure.mapping_field_sources[sectionIndex];
    let index = '0';

    if (sectionsFieldLength > 1) {
      index = (
        Number.parseInt(
          section.fields[sectionsFieldLength - 1].name.match(/\d+/)![0]
        ) + 1
      ).toString();
    }

    const hiddenField = {
      name: hiddenFieldNameTemplate.replace('*', index),
      value,
      element: 'hidden',
    };
    const field = {
      name: fielNameTemplate.replace('*', index),
      value: [],
      label: value,
      options: options.map((o) => ({ ...o })),
    };

    const newFields = [
      ...section.fields,
      hiddenField,
      field,
    ] as FormFieldObject[];
    this.reassembleFormStructure(newFields, sectionIndex);
  }

  @action
  addTableRowHandler(section: FormSection, index: number): void {
    const sectionsFieldLength = section.fields.length;
    const lastField = section.fields[sectionsFieldLength - 1];
    const secondToLastField = section.fields[sectionsFieldLength - 2];

    const newNameLastField = lastField.name.replace(
      /\d+/g,
      (sectionsFieldLength / 2) as any
    );
    const newNameSecondToLastField = secondToLastField.name.replace(
      /\d+/g,
      (sectionsFieldLength / 2) as any
    );
    const newFieldOne = {
      ...lastField,
      label: '',
      name: newNameLastField,
      options: lastField.options?.map((option: any) => ({
        ...option,
        checked: false,
      })),
      value: [],
    };

    const newFieldTwo = {
      ...secondToLastField,
      name: newNameSecondToLastField,
      value: '',
    };

    const newSectionTwo = {
      ...section,
      fields: [...section.fields, newFieldTwo, newFieldOne],
    };

    const newSections = [
      ...this.formStructure.sections.slice(0, index),
      newSectionTwo,
      ...this.formStructure.sections.slice(index + 1),
    ];

    this.formStructure = {
      ...this.formStructure,
      sections: newSections,
    };

    this.updateFormMapAndValidate();
  }

  // resets the formMap (reduced & mapped version of formConfig master object)
  // validates fields in formMap (only relevant/visible fields)
  async updateFormMapAndValidate(
    currentFieldName: string | null = null,
    currentFieldValue: string | boolean | Array<string> | SettingsOption | null = null
  ): Promise<boolean> {
    this.formMap = {};
    this.iterateAndActOverFormObject(this.createFormMapPairs, {
      name: currentFieldName,
      value: currentFieldValue,
    });
    return this.validateFields(currentFieldName!);
  }

  // finds and sets fields that determine which other fields are rendered
  resetAndFindControlFields(checked = true, value = ''): void {
    this.controlFields = [];
    this.iterateAndActOverFormObject(this.setControlFields, {
      checked,
      value,
    });
    const controlFieldValues: string[] = this.controlFields.map(
      (field) => field.value
    );
    this.controlFields = this.controlFields.filter((controlField) => {
      if (!controlField.required_for) {
        return true;
      } else {
        return controlFieldValues.some((controlFieldValue) =>
          controlField.required_for?.includes(controlFieldValue)
        );
      }
    });
    this.stringifiedControlFields = this.stringifyControlFields();
  }

  // the control field is denoted in the JSON blueprint according to the below property
  setControlFields = (
    field: FormFieldObject,
    fieldCheckValueObj: FormFieldOptionObject
  ): void => {
    const { checked, value } = fieldCheckValueObj;
    // a switch field with undefined for the required_for property is a control field
    if (field.control_field) {
      // add switches that just have been toggled on or are already toggled on
      if (
        (field.element === 'input' || field.element === 'hidden') &&
        field.type === 'switch'
      ) {
        if (checked && field.value === value) {
          this.controlFields.push(field);
        } else if (field.checked && field.value !== value) {
          this.controlFields.push(field);
        }
      }
    }
  };

  // convert array of control fields to a sort of CSV
  stringifyControlFields = (): string => {
    const controlFieldValues = this.controlFields.map((field) => field.value);
    return controlFieldValues.join(',');
  };

  // general method for iterating over and acting on each field
  iterateAndActOverFormObject(callback: Function, argObj: Object) {
    this.formStructure.sections.forEach((section) => {
      if (section.fields)
        section.fields.forEach((field) => {
          callback(field, argObj);
        });
    });
  }

  // general method for finding and returning a particular field
  findFormObjectField = (
    fieldProperty: string,
    fieldValue: string
  ): FormFieldObject => {
    const property = fieldProperty;
    const value = fieldValue;
    let matchedField: FormFieldObject;
    this.formStructure.sections.forEach((section) => {
      if (!section['two_column'] && section.fields) {
        section.fields.forEach((field) => {
          if (field[property as keyof FormFieldObject] === value) {
            matchedField = field;
          }
        });
      }
    });
    // TODO: properly handle the logic and type for if property is empty
    return matchedField! as FormFieldObject;
  };

  private findFormObjectFields(
    property: string,
    matcher: Matcher
  ): FormFieldObject[] {
    const fields: FormFieldObject[] = [];
    this.formStructure.sections.forEach((section) => {
      if (!section['two_column'] && section.fields) {
        section.fields.forEach((field) => {
          if (matcher(field[property as keyof FormFieldObject])) {
            fields.push(field);
          }
        });
      }
    });
    return fields;
  }

  // validates fields using yup.js
  async validateFields(currentFieldName = ''): Promise<boolean> {
    return (
      this.yupSchema
        // abortEarly is false to capture ALL validation errors
        .validate(this.formMap, { abortEarly: false })
        .then((transformedFieldValuePairs) => {
          // assign fields' values yup value transformations (ex: trimming)
          this.iterateAndActOverFormObject(this.setFieldValues, {
            fieldMap: transformedFieldValuePairs,
            formMap: this.formMap,
          });

          // if all fields are valid, clear the validationErrors array,
          // reset form field states to default, and clear banners
          this.validationErrors = [];

          if (!Object.values(this.formMap).includes(undefined)) {
            if (this.args.onStandalonePage) {
              this.flash.clearGlobalMessage();
            } else {
              this.flash.clearSheetMessage();
            }
            this.args.handleCompletedForm(this.formMap, false);
          } else {
            this.args.handleCompletedForm(this.formMap, true);
          }

          this.iterateAndActOverFormObject(this.setFieldState, {
            fieldNames: [currentFieldName],
            newState: 'isDefault',
          });

          return true;
        })
        .catch((error) => {
          // assign fields' values yup value transformations (ex: trimming)
          this.iterateAndActOverFormObject(this.setFieldValues, {
            fieldMap: error.value,
            formMap: this.formMap,
          });

          // if any field is invalid, save yup error objects in validationErrors array,
          // create errorMessage string for flash banner, and
          // update invalid form fields with invalid state
          this.args.handleCompletedForm(null, true);
          this.validationErrors = error.inner;

          const fieldNames = error.inner.map((validationError: { path: any }) =>
            this.formatValidationErrorPath(validationError.path)
          );

          this.iterateAndActOverFormObject(this.setFieldState, {
            fieldNames,
            newState: 'isInvalid',
          });

          const uniquErrorMessages = error.inner
            .map((validationError: { message: any }) => validationError.message)
            .filter(
              (value: any, index: number, self: string | any[]) =>
                self.indexOf(value) === index
            )
            .join(' • ');

          this.onError(uniquErrorMessages);

          return false;
        })
    );
  }

  private formatValidationErrorPath(path: string) {
    /*
     * Address edge case where Yup describes path of top-level key with '.' char
     * with array string with single value. All paths are top-level in form map.
     * Ex: netsuite.accountId => [\"netsuite.accountID\"].
     *
     * See https://github.com/jquense/yup/pull/539 for more info.
     */
    if (path[0] === '[') {
      return JSON.parse(path).get(0);
    } else {
      return path;
    }
  }

  private setFieldValues(
    field: FormFieldObject,
    { fieldMap, formMap }: SetFieldValuesArg
  ): void {
    const fieldValue = fieldMap[field.name];
    if (field.yup_validations && fieldValue) {
      formMap[field.name] = fieldValue;
      if (
        field.element === 'select-searchable' &&
        !field.is_custom_value_allowed
      ) {
        const option = field.options?.find(
          (option: FormFieldOptionObject) => option.value === fieldValue
        );
        const optionValue = option && option.value;
        set(field, 'value', optionValue ?? field.value);
      } else {
        set(field, 'value', fieldValue);
      }
    }
  }

  // sets field.state to given state if name matches, otherwise sets to default
  // POTENTIAL DANGER HERE if field not previously default, but since this event
  // triggers on focus-out (which doesn't happen with isDisabled or readOnly)
  // issues have not been encountered as of 5/14/20
  setFieldState = (
    field: FormFieldObject,
    { fieldNames, newState }: SetFieldStateArgObject
  ): void => {
    if (fieldNames.includes(field.name)) {
      set(field, 'state', newState);
    } else {
      // restore valid state if field marked as invalid
      const currentState =
        field.state === 'isInvalid' ? 'isDefault' : field.state;
      set(field, 'state', currentState);
    }
  };

  // creates the schema needed to validate objects using Yup
  createYupSchema(): void {
    this.iterateAndActOverFormObject(this.createYupSchemaItem, {});
    this.yupSchema = yup.object().shape(this.yupShape);
  }

  // creates the schema item needed to build the overal Yup schema
  createYupSchemaItem = (field: FormFieldObject): void => {
    if (field.yup_validations) {
      // transformAll takes array of strings and converts them to yup validations
      this.yupShape[field.name] = transformAll(field.yup_validations);
    } else {
      // no validation? no validation.
      this.yupShape[field.name] = yup.string().nullable();
    }
  };

  // creates key-value pairs of formMap, which will ultimately be sent with request
  createFormMapPairs = (
    field: FormFieldObject,
    currentFieldObj: AbbreviatedFormFieldObject = { name: null, value: null }
  ) => {
    // handle case of initializing form map
    if (!this.formMap) {
      this.formMap = {};
    }

    let value;
    if (field.name === currentFieldObj.name) {
      /*
       * Set potentially null form field in args.formMap to value of empty
       * focused/activated form field. Handles edge case of user focusing but
       * not changing field (field changes automatically change linked objects
       * in Ember). Does not apply to SqInputSelectSearchable fields because
       * value for SqInputSelectSearchable field is object
       */
      if (
        currentFieldObj.value === '' &&
        field.element !== 'select-searchable'
      ) {
        set(field, 'value', '');
      }
      value = currentFieldObj.value;
    } else {
      if (field.type === 'checkbox' || field.type === 'switch') {
        value = field.checked;
      } else if (
        typeof field.value === 'object' &&
        field.value !== null &&
        !Array.isArray(field.value)
      ) {
        if(this.isMigratedToSettingsOption(field)) {
          value = field.value;
        } else {
          value = field.value.value;
        }
        /*
         * By default, JS will assign undefined as value of property.
         * A form is complete when formMap contains no undefined values.
         * Optional fields are not be considered for form completion.
         */
      } else if (!field.yup_validations && !field.value) {
        value = null;
      } else {
        value = field.value;
      }
    }

    // ultimately, the field must be needed by control field to be part of formMap
    const isControlledField = containsStringValues([
      field.required_for!,
      this.stringifiedControlFields,
    ]);
    if (isControlledField) {
      this.formMap[field.name] = value;
    }
  };

  private findFieldsByName(field_name: string): FormFieldObject[] {
    if (field_name.includes(`*`)) {
      return this.findFormObjectFields('name', getFieldNameMatcher(field_name));
    } else {
      return [this.findFormObjectField('name', field_name)!];
    }
  }

  private getFieldResourcesByFieldName(
    field_name: string
  ): ConsolidatedFieldResource[] {
    const resources: ConsolidatedFieldResource[] = [];
    const fieldNameMatcher = getFieldNameMatcher(field_name);
    this.formStructure.resources.forEach((cfr: ConsolidatedFieldResource) => {
      const copy: ConsolidatedFieldResource = { ...cfr };
      copy.field_resource_metadata = copy.field_resource_metadata.filter(
        (metadata: FieldResourceMetadata) =>
          fieldNameMatcher(metadata.field_name)
      );
      if (copy.field_resource_metadata.length > 0) {
        resources.push(copy);
      }
    });
    return resources;
  }

  private lazyLoadOptions(
    settingsTarget: SettingsFormType,
    configObjectId?: string
  ) {
    if (
      this.formStructure.mapping_field_option_resources &&
      this.formStructure.resources
    ) {
      this.formStructure.mapping_field_option_resources
        .filter((mfos) => mfos.loading_strategy == LoadingStrategy.LAZY)
        .forEach((mfos) =>
          this.lazyLoadOption(mfos, settingsTarget, configObjectId)
        );
    }
  }

  fieldsFailedToLazyLoad: { [key: string]: string[] } = {};

  private lazyLoadOption(mfos: ConsolidatedMappingResource, settingsTarget: SettingsFormType, configObjectId?: string) {
    const metadata = mfos.option_resource_metadata;
    interface FieldAndState {
      field: FormFieldObject;
      state: string;
    }
    const fieldsAndOriginalState = new Map<string, FieldAndState>();
    this.findFieldsByName(metadata.field_name).forEach(
      (field: FormFieldObject) => {
        fieldsAndOriginalState.set(field.name, {
          field,
          state: field.state,
        });
        set(field, 'state', 'isLoading');
      }
    );
    if (fieldsAndOriginalState.size > 0) {
      const cfrs: ConsolidatedFieldResource[] =
        this.getFieldResourcesByFieldName(metadata.field_name);
      this.config
        .fetchFieldOptions(settingsTarget, mfos, cfrs, configObjectId)
        .then((response: GetSettingsOptionsResponse) => {
          for (const dataItem of response.data) {
            const fieldName = dataItem.field_name;
            const fieldAndState = fieldsAndOriginalState.get(fieldName);
            if (fieldAndState) {
              console.debug(
                `Set ${fieldAndState.field.name} ${
                  dataItem.target_property
                } to ${JSON.stringify(dataItem.field_value)}`
              );
              set(
                fieldAndState.field,
                dataItem.target_property as keyof FormFieldObject,
                dataItem.field_value
              );
            } else {
              console.debug(`${fieldName} was not found in fieldAndState.`);
            }
          }
        })
        .catch((error: BridgeApiException) => {
          for (const fieldAndState of fieldsAndOriginalState.values()) {
            fieldAndState.state = 'isInvalid';
            const field = fieldAndState?.field;
            let display = field?.label ? field?.label : field?.name;
            if (display) {
              this.fieldsFailedToLazyLoad[error.message] === undefined ?
                this.fieldsFailedToLazyLoad[error.message] = [display] :
                this.fieldsFailedToLazyLoad[error.message].push(display);
            }
          }
          this.onError(this.formatLazyLoadErrors());
        })
        .finally(() => {
          fieldsAndOriginalState.forEach((fieldAndState: FieldAndState) => {
            if (fieldAndState.field.state == 'isLoading') {
              // todo: when we make more robust search fields, this might need to be revisited since we disable fields on isLoading
              set(fieldAndState.field, 'state', fieldAndState.state);
            }
          });
        });
    }
  }

  formatLazyLoadErrors() {
    let message = 'Failed to load options for ';
    let details = Object.keys(this.fieldsFailedToLazyLoad)
      .map((error) => `${this.fieldsFailedToLazyLoad[error].join(', ')}. ${error}`)
    console.log(this.fieldsFailedToLazyLoad);
    console.log(details);
    return message + details.join(' ');
  }
}
