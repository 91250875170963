define("@square/glass-ui/components/sq-switch", ["exports", "@square/glass-ui/templates/components/sq-switch"], function (_exports, _sqSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqSwitch.default,
    tagName: 'span',
    classNames: ['option-toggle', 'switch'],
    classNameBindings: ['checked:switch--is-on', 'checked:option-toggle--is-selected', 'disabled:option-toggle--is-disabled', 'disabled:switch--is-disabled'],
    attributeBindings: ['data-test-switch', 'data-test-switch-on'],
    'data-test-switch': Ember.computed('value', function () {
      return this.get('value') || true;
    }),
    'data-test-switch-on': Ember.computed.readOnly('checked'),
    checked: false,
    actions: {
      onChange: function onChange(checked) {
        if (this.action) {
          this.action(checked, this.get('value'));
        }

        this.set('checked', checked);
      }
    }
  });

  _exports.default = _default;
});