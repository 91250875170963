define("@square/glass-ui/templates/components/sq-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kAI+ep/Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"data-test-button-loading-indicator\",\"size\",\"class\",\"trackColor\",\"color\",\"isActive\"],[true,\"small\",\"button__spinner\",[35,2],[35,1],true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[14,0,\"button__label\"],[12],[2,\"\\n\"],[6,[37,5],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"spinnerColor\",\"spinnerTrackColor\",\"svg/icon-radial-progress\",\"isShowingSpinner\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-button.hbs"
    }
  });

  _exports.default = _default;
});