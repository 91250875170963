define("@square/glass-ui/components/video-player/duration", ["exports", "@square/glass-ui/templates/components/video-player/duration"], function (_exports, _duration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _duration.default,
    tagName: 'span',
    classNames: ['video-player__overlay__duration'],
    attributeBindings: ['data-test-video-player-overlay-duration'],
    'data-test-video-player-overlay-duration': true
  });

  _exports.default = _default;
});