define("@square/glass-ui/components/deprecated/sheet-layout/headerbar-close", ["exports", "@square/glass-ui/templates/components/deprecated/sheet-layout/headerbar-close"], function (_exports, _headerbarClose) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _headerbarClose.default,
    screen: Ember.inject.service(),
    tagName: '',
    // passed in
    cancelComponent: null,
    closeAction: null
  });

  _exports.default = _default;
});