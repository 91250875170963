import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';
import { EngineRouterService } from 'ember-engines-router-service';
import FlashService from '@square/glass-ui/addon/services/flash';
import { PluginApplication } from '../../models/services/plugin-application/plugin-application';
import { Source } from '../../models/services/source/source';
import SquareAuth from '../../services/square-auth';

interface PluginsWizardSheetArgs {}

export default class PluginsWizardSheet extends Component<PluginsWizardSheetArgs> {
  @service data!: Data;
  @service flash!: FlashService;
  @service router!: EngineRouterService;
  @service squareAuth!: SquareAuth;

  squareAccountSheetNavStep = {
    key: 'SQUARE_KEY',
    label: 'Square Account',
  };
  pluginSelectionSheetNavStep = {
    key: 'PLUGIN_KEY',
    label: 'Plugin Type',
  };
  authorizationSetupSheetNavStep = {
    key: 'SETUP_KEY',
    label: 'Authorization & Setup',
  };

  selectorControlObjects = [
    this.squareAccountSheetNavStep,
    this.pluginSelectionSheetNavStep,
    this.authorizationSetupSheetNavStep,
  ];
  selectorControlItems = this.selectorControlObjects.map(
    (object) => object.key
  );

  @tracked
  currentStep = this.selectorControlObjects[0];

  @tracked
  merchantApplicationCreated: boolean = false;

  merchantToken: string | null = null;

  @tracked
  nextStepDisabled: boolean = true;

  @tracked
  nextStepLoading: boolean = false;

  @tracked
  selectedSquareSource: Source | null = null;

  @tracked
  selectedPlugin: PluginApplication | null = null;

  @action
  handleClose(): void {
    (this.args as { pluginsRouteRefreshHandler: () => {} }).pluginsRouteRefreshHandler();
    this.router.transitionTo('authorized-route.plugins');
  }

  @action
  didSelectTab(tab: string): void {
    // If the user clicks on the same breadcrumb they are currently on, return
    if (this.currentStep.key === tab) {
      return;
    }

    // Clear all error messages
    this.flash.clearSheetMessage();

    // Change the current step to the new step
    this.currentStep = this.selectorControlObjects[
      this.selectorControlItems.indexOf(tab)
    ];

    this.nextStepDisabled = !this.merchantToken;
    this.nextStepLoading = false;
  }

  @action
  async handleContinue(selectCallback: Function): Promise<void> {
    this.nextStepLoading = true;
    this.nextStepDisabled = true;

    try {
      if (
        this.currentStep.key === this.pluginSelectionSheetNavStep.key &&
        !this.merchantToken
      ) {
        await this.createMerchantApplication();
      }
      selectCallback();
    } catch (error) {
      this.nextStepLoading = false;
      this.flash.sheetError(error.message, {
        dismiss: () => {
          this.flash.clearSheetMessage();
        },
      });
    }
  }

  @action
  setContinueDisabled(newContinueButtonDisabledState: boolean): void {
    this.nextStepDisabled = newContinueButtonDisabledState;
  }

  get isSquareAccountDropdownDisabled(): boolean {
    return Boolean(this.merchantToken);
  }

  get squareAuthorizationLink(): string {
    return this.squareAuth.getSquarePluginAuthorizationLink();
  }

  @action
  handleSquareAccountDropdownSelection(source: Source): void {
    this.selectedSquareSource = source;
    this.setContinueDisabled(false);
  }

  get isPluginDropdownDisabled(): boolean {
    return Boolean(this.merchantToken);
  }

  @action
  handlePluginDropdownSelection(plugin: PluginApplication): void {
    this.selectedPlugin = plugin;
    this.setContinueDisabled(false);
  }

  async createMerchantApplication(): Promise<void> {
    this.merchantToken = (
      await this.data.createMerchantApplication(
        this.selectedSquareSource!.square_account!.merchant_id,
        this.selectedPlugin!.square_id
      )
    ).merchant_token;
  }
}
