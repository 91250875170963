define("@square/glass-ui/components/more-actions-menu-trigger", ["exports", "@square/glass-ui/templates/components/more-actions-menu-trigger"], function (_exports, _moreActionsMenuTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  This component renders a div representing the trigger to open a context menu.
  It is often used as the trigger in dropdowns (using [sq-dropdown](/components/glass/sq-dropdown.html)).
  
  ```example
  @title Example
  
  @hbs
  <div style="padding: 20px 0;">
    {{more-actions-menu-trigger}}
  </div>
  ```
  
  ```example
  @title Example in Dropdown
  @description click `...` to see the dropdown's popover.
  @hbs
  <div style="padding: 20px 0;">
  {{#sq-dropdown class="item-list-more-action-dropdown" as |dropdown|}}
    {{#dropdown.trigger}}
      {{more-actions-menu-trigger}}
    {{/dropdown.trigger}}
    {{#dropdown.popover class="popover-list popover--fly-down"}}
      <div class="popover-list__item">this is a popover</div>
    {{/dropdown.popover}}
  {{/sq-dropdown}}
  </div>
  ```
  */
  var _default = Ember.Component.extend({
    layout: _moreActionsMenuTrigger.default,
    classNames: ['more-actions-menu__trigger'],
    click: function click() {
      this.triggerAction();
    }
  });

  _exports.default = _default;
});