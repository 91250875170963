import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { EngineRouterService } from 'ember-engines-router-service';
export default class AuthorizedRouteIntegrationSyncDataInventoryLevels extends Controller {

  @service router!: EngineRouterService;

  queryParams: Array<string> = ['state', 'entity_id'];

  entity_id: string | null = null;
  state: string | null = null;

  @action
  refreshModel() {
    this.send('refreshCurrentRoute');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/inventory-levels': AuthorizedRouteIntegrationSyncDataInventoryLevels;
  }
}
