define("@square/glass-ui/templates/components/sq-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xg0t+4pS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[15,\"data-test-radio-input\",[30,[36,2],[[35,1],true],null]],[14,0,\"option-toggle__input radio__input\"],[15,3,[34,3]],[15,2,[34,1]],[15,\"disabled\",[34,4]],[15,\"checked\",[34,5]],[15,\"tabindex\",[34,6]],[15,\"onChange\",[30,[36,7],[[32,0],\"onChange\"],[[\"value\"],[\"target.checked\"]]]],[14,4,\"radio\"],[12],[13],[2,\"\\n\"],[6,[37,8],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"class\",\"width\",\"height\"],[\"option-toggle__mark radio__mark\",16,16]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg/icon-radio\",\"value\",\"or\",\"fieldName\",\"disabled\",\"selected\",\"tabindex\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-radio.hbs"
    }
  });

  _exports.default = _default;
});