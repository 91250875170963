define("@square/glass-ui/components/selector-control", ["exports", "@square/glass-ui/components/selector-control/navigation/item", "@square/glass-ui/components/selector-control/navigation/button", "@square/glass-ui/templates/components/selector-control"], function (_exports, _item, _button, _selectorControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectorControl.default,
    tagName: '',
    // passed in array of item key strings
    items: Ember.computed(function () {
      return [];
    }),
    currentItem: null,
    currentItemIndex: Ember.computed('currentItem', 'items.[]', function () {
      return this.get('items').indexOf(this.get('currentItem'));
    }),
    type: _item.SELECTOR_TYPE.TAB,
    // This will only ever contain a single items for tabs and segments since the activeItem is
    // also the current item, but for steps it may be multiple items, as active steps include the
    // current step as well as previous steps
    activeItems: Ember.computed('currentItemIndex', 'items.[]', 'type', function () {
      var currentItemIndex = this.get('currentItemIndex'); // If this is a step, then we need to populate the activeItems array with the
      // current step and the preceding steps

      if (this.get('type') === _item.SELECTOR_TYPE.STEP) {
        return this.get('items').slice(0, currentItemIndex + 1); // Otherwise, we can just return an array with a single item — the item matching
        // the currentItemIndex position in the items array
      } else {
        return [this.get('items')[currentItemIndex]];
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super(); // Assert that we've passed some items


      (false && !(this.get('items').length > 0) && Ember.assert('You must pass an items array to {{selector-control}}', this.get('items').length > 0)); // If we weren't passed a current item, set it to the first item

      if (!this.get('currentItem')) {
        this.set('currentItem', this.get('items')[0]);
      }
    },
    isFirstItem: Ember.computed.equal('currentItemIndex', 0),
    isLastItem: Ember.computed('items.length', 'currentItemIndex', function () {
      return this.get('currentItemIndex') === this.get('items.length') - 1;
    }),
    setPreviousItem: function setPreviousItem() {
      if (!this.get('isFirstItem')) {
        var _this$get;

        // We need to set the currentItem instead of decrementing the currentItemIndex
        // because the currentItemIndex is actually computed from the currentItem
        this.set('currentItem', this.get('items')[this.get('currentItemIndex') - 1]);
        (_this$get = this.get('didSelectItem')) === null || _this$get === void 0 ? void 0 : _this$get(this.currentItem);
      }
    },
    setNextItem: function setNextItem() {
      if (!this.get('isLastItem')) {
        var _this$get2;

        // We need to set the currentItem instead of incrementing the currentItemIndex
        // because the currentItemIndex is actually computed from the currentItem
        this.set('currentItem', this.get('items')[this.get('currentItemIndex') + 1]);
        (_this$get2 = this.get('didSelectItem')) === null || _this$get2 === void 0 ? void 0 : _this$get2(this.currentItem);
      }
    },
    actions: {
      selectItem: function selectItem(item) {
        var _this$get3;

        this.set('currentItem', item);
        (_this$get3 = this.get('didSelectItem')) === null || _this$get3 === void 0 ? void 0 : _this$get3(item);
      },
      goToStep: function goToStep(direction) {
        switch (direction) {
          case _button.SELECTOR_NAV_ACTION_TYPE.NEXT:
            this.setNextItem();
            break;

          case _button.SELECTOR_NAV_ACTION_TYPE.PREVIOUS:
            this.setPreviousItem();
            break;

          default:
            (false && !(false) && Ember.assert("Unknown direction: ".concat(direction)));
        }
      }
    }
  });

  _exports.default = _default;
});