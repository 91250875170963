import { helper } from '@ember/component/helper';
import { ConfigFormBlueprint, FormSection } from '../services/config';

interface GetMappingFieldSourceForSectionArgumentObject {
  formStructure: ConfigFormBlueprint;
  section: FormSection;
}

export function getMappingFieldSourceForSection(
  _params: any,
  { formStructure, section }: GetMappingFieldSourceForSectionArgumentObject
) {
  if (formStructure.mapping_field_sources) {
    return formStructure.mapping_field_sources.find(
      (source) => source.section_label === section.label
    );
  }
  return
}

export default helper(getMappingFieldSourceForSection);
