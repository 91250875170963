import { CategoryAggregationMapping } from "./category-aggregation-mapping";

export interface UpsertCategoryAggregationMappingsRequest {
    mappings: CategoryAggregationMapping[]
}

export function toUpsertCategoryAggregationMappingsRequest(categoryAggregationMappings: CategoryAggregationMapping[]): UpsertCategoryAggregationMappingsRequest {
    return {
        mappings: categoryAggregationMappings
    }
}