import Service from '@ember/service';
import { ListDailyInvoiceLocationsResponse } from '../models/services/daily-invoices/list-daily-invoice-locations-response';
import { inject as service }  from '@ember/service';
import FetchHandler from 'bridge-dashboard/app/services/fetch-handler';

import { SearchDailyInvoiceResponse } from '../models/services/daily-invoices/search-daily-invoices-response';
import { SearchDailyInvoiceRequest } from '../models/services/daily-invoices/search-daily-invoice-request';
import { RetrieveDailyInvoiceDetailsResponse } from '../models/services/daily-invoices/retrieve-daily-invoice-details';

/**
 * Primarily used to providing type safe stubs for testing.
 */
export interface IDailyInvoicesService {
  retrieveDailyInvoiceDetails(integrationId: string, group_id: string): Promise<RetrieveDailyInvoiceDetailsResponse>;
  listDailyInvoiceLocations(integrationId: string): Promise<ListDailyInvoiceLocationsResponse>;
  searchDailyInvoices(integrationId: string, request: SearchDailyInvoiceRequest): Promise<SearchDailyInvoiceResponse>;
}

/**
 * Provides data for the Daily Invoices view.
 * 
 * Depends on the {@link FetchHandler}
 */
export default class DailyInvoicesService extends Service implements IDailyInvoicesService {
  @service fetchHandler!: FetchHandler;

  retrieveDailyInvoiceDetails(integrationId: string, group_id: string): Promise<RetrieveDailyInvoiceDetailsResponse> {
    return this.fetchHandler.doGetAndHandleResponse<RetrieveDailyInvoiceDetailsResponse>(
      `/api/v1/integrations/${integrationId}/daily-invoices/${group_id}/details`)
  }

  listDailyInvoiceLocations(integrationId: string): Promise<ListDailyInvoiceLocationsResponse> {
    return this.fetchHandler.doGetAndHandleResponse<ListDailyInvoiceLocationsResponse>(
      `/api/v1/integrations/${integrationId}/daily-invoices/locations`)
  }

  searchDailyInvoices(integrationId: string, request: SearchDailyInvoiceRequest): Promise<SearchDailyInvoiceResponse> {
    return this.fetchHandler.doPostAndHandleResponse<SearchDailyInvoiceResponse>(
      `/api/v1/integrations/${integrationId}/daily-invoices`, request);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'daily-invoices-service': DailyInvoicesService;
  }
}
