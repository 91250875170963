import SyncTypes from '../services/sync-type-status/sync-job-type';

import { DropdownFilterUnit } from './dropdown-filter-unit';

enum FILTER_CRITERION_TYPE {
  LOCATION = 'LOCATION',
  STATE = 'STATE',
  SYNC_TYPE = 'SYNC_TYPE',
}

const syncTypeUnits: Array<DropdownFilterUnit> = [
  {
    type: FILTER_CRITERION_TYPE.SYNC_TYPE,
    label: 'Bank Transfers',
    value: SyncTypes.BANK_TRANSFERS,
    unitActive: true,
  },
  {
    type: FILTER_CRITERION_TYPE.SYNC_TYPE,
    label: 'Catalog',
    value: SyncTypes.CATALOG,
    unitActive: true,
  },
  {
    type: FILTER_CRITERION_TYPE.SYNC_TYPE,
    label: 'Customers',
    value: SyncTypes.CUSTOMERS,
    unitActive: true,
  },
  {
    type: FILTER_CRITERION_TYPE.SYNC_TYPE,
    label: 'Inventory Changes',
    value: SyncTypes.INVENTORY_CHANGES,
    unitActive: true,
  },
  {
    type: FILTER_CRITERION_TYPE.SYNC_TYPE,
    label: 'Inventory Levels',
    value: SyncTypes.INVENTORY_LEVELS,
    unitActive: true,
  },
  {
    type: FILTER_CRITERION_TYPE.SYNC_TYPE,
    label: 'Orders',
    value: SyncTypes.ORDERS,
    unitActive: true,
  },
];

export { FILTER_CRITERION_TYPE, syncTypeUnits };
