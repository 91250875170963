/**
 * See the tests in @bridge/home-engine/tests/unit/utils/form-names-object-parser-test.ts
 * for examples of output.
 * 
 * Recursively builds objects or arrays based on notation of key. Handles nested
 * and unnested cases.
 * 
 * Used to convert single-level object/hashmap/dict of form names and values
 * to deeper objects suitable for making requests.
 * 
 * @param param0 a key-value pair, typically starting the form name-value, respectively.
 * @param object the object to be populated, typically starting with an empty object.
 */
function generateNestedObjectFromPathedKey([key, value]: [String, any], object: { [key: string]: any}) {
  // split on "[" using forward-looking regex
  const [outer, ...inner] = key.split(/(?=\[)/);
  // create outer key by stripping brackets if present
  const outerKey = outer[0] === '[' && outer.slice(-1) === ']' ? outer.slice(1, -1) : outer;
  // if no inner key, assign value
  if (inner.length === 0) {
    object[outerKey] = value;
    return object;
  // if single inner key, assign value or add to array depending on key type
  } else if (inner.length === 1) {
    // strip brackets always present on inner keys
    const innerKey = inner[0].slice(1, -1);
    if (object[outerKey] === undefined) {
      object[outerKey] = isNaN(parseInt(innerKey)) ? {} : [];
    }
    object[outerKey][innerKey] = value;
    return object;
  } else {
    const innerKey = inner[0].slice(1, -1);
    // if inner key is not a number, nest value in deeper object
    if (innerKey.length > 1 && isNaN(parseInt(innerKey))) {
      if (object[outerKey] === undefined) object[outerKey] = {};
      const newKey = innerKey + inner.slice(1).join('');
      object = generateNestedObjectFromPathedKey([newKey, value], object[outerKey]);
    // if inner key is a number, nest value in array
    } else {
      if (object[outerKey] === undefined) object[outerKey] = [];
      const newKey = inner.slice(1).join('');
      const index = parseInt(innerKey);
      const arrayObject = object[outerKey][index] ? object[outerKey][index] : {};
      object[outerKey][index] = generateNestedObjectFromPathedKey([newKey, value], arrayObject);
    }
    return object;
  }
}


// TODO: actually type this right so it returns the correct type.

// courtesy of https://stackoverflow.com/a/57625661/10864518
// recursively removes all null and undefined values from form object
// note: incomplete mappings are removed in the SettingsFormController
function cleanEmpty (obj: {[k: string]: any}): {[k: string]: any} {
  if (Array.isArray(obj)) { 
    return obj
        .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
        .filter(v => !(v == null)); 
  } else { 
    return Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
        .reduce((a, [k, v]: [k: string, v: any]) => (v == null ? a : (a[k]=v, a)), {} as {[k: string]: any});
  } 
}

/**
 * Iterates over object to generate deeper object with schema specified in keys.
 * 
 * @param object consisting of single level of form name-value pairs
 */
export default function formNamesObjectParser(object: Object) {
  const responseObject = {};
  Object.entries(object).forEach(([key, value]) => {
    generateNestedObjectFromPathedKey([key, value], responseObject)
  });
  return cleanEmpty(responseObject);
}
