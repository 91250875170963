/**
## SettingsForm

Glass-UI-powered form that allows for programmatic generation of fields from particularly-structured objects.
See https://docs.google.com/document/d/1pWykbf-E_Dt9bf3vD12x3g9pwmrzoQ1MnfPmJ6IbozI/edit#heading=h.1zcizwo4x94t for a description.

```example
 <SettingsForm
  @formObject={{this.args.configModel}}
  @focusOutHandler={{this.handleFocusOut}}
  @switchChangeHandler={{this.handleSwitchChange}}
  @switchClickHandler={{this.handleSwitchClick}}
  @selectChangeHandler={{this.handleSelectChange}}
  @searchableChangeHandler={{this.handleSearchableChange}}
  @searchableCloseHandler={{this.handleSearchableDropdownClose}}
  @checkboxSetChangeHandler={{this.handleCheckboxSetChange}}
  @controlFields={{this.stringifiedControlFields}}
  @submitHandler={{this.handleSave}}
  />
```

### Parameters
 * @param {ConfigurationObject} [formObject] [The form object containing data for rendering each section and field.]
 * @param {action} [focusOutHandler] [Event handler for when focus shifts from an input or select field.]
 * @param {action} [switchChangeHandler] [Event handler for when a <SqOptionSwitch /> is toggled.]
 * @param {action} [switchClickHandler] [Event handler for when a <SqOptionSwitch /> is clicked.]
 * @param {action} [selectChangeHandler] [Event handler for when a <SqFieldSelect /> value is changed.]
 * @param {action} [searchableChangeHandler] [Event handler for when a <SqFieldInputSearchable /> value is changed.]
 * @param {action} [searchableCloseHandler] [Event handler for when a <SqFieldInputSearchable /> is closed.]
 * @param {action} [checkboxSetChangeHandler] [Event handler for when a <SqFieldCheckboxes /> value is changed.]
 * @param {string} [controlFields] [Comma-delimited string containing values of fields which should be visible.]
 * @param {Object} [flashContext] [Flash context to pass form fields, as per https://glass-docs.sqprod.co/components/glass/sq-form.html.]
 * @param {action} [submitHandler] [Event handler for form submission.]
*/

import Component from '@glimmer/component';

export default class SettingsForm extends Component { 
  defaultInputSearchableFieldOption: object = { label: "", value: "" }
}
