define("@square/glass-ui/components/selector-control/content/section", ["exports", "@square/glass-ui/templates/components/selector-control/content/section"], function (_exports, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _section.default,
    tagName: '',
    isActive: Ember.computed('currentItem', 'key', function () {
      return this.get('currentItem') === this.get('key');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();

      (false && !(Ember.isPresent(this.get('key'))) && Ember.assert('You must pass a `key` to {{selector-control/content/section}}', Ember.isPresent(this.get('key'))));
    }
  });

  _exports.default = _default;
});