import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Modal from '@square/glass-ui/addon/services/modal';

import {
  FormFieldObject,
  FormFieldOptionObject,
  FormSection,
} from '../services/config';

import { OptionsTableModalOptions } from './options-table-modal';

interface OptionsTableArgs {
  addTableRowHandler(section: FormSection, index: number): void;
  checkboxTableChangeHandler(fieldName: string, checkValue: boolean): void;
  deleteTableRow(field: FormFieldObject, sectionIndex: number): void;
  handleNewTableItemSubmit(
    sectionIndex: number,
    value: string | number | boolean
  ): void;
  options: FormFieldOptionObject[];
  placeholder: string;
  section: FormSection;
  sectionIndex: number;
  tip: string;
}

/*
 * see https://glass-docs.sqprod.co/glass/docs/development/adding-modals.html
 * for details on implementing GlassUI modals
 */
export default class OptionsTable extends Component<OptionsTableArgs> {
  @service modal!: Modal;

  @action
  openModal(): void {
    this.modal
      .open('options-table-modal', <OptionsTableModalOptions>{
        placeholder: this.args.placeholder,
        title: `New ${this.args.placeholder}`,
        tip: this.args.tip,
      })
      .then((value) => {
        if (value) {
          // user clicked "OK"
          this.args.handleNewTableItemSubmit(this.args.sectionIndex, value);
        }
      });
  }
}
