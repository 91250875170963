define("@square/glass-ui/templates/components/sq-card/attributes-list/attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZRjDUJ25",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"if\",\"label\",\"sq-card/attributes-list/attribute/label\",\"sq-card/attributes-list/attribute/value\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-card/attributes-list/attribute.hbs"
    }
  });

  _exports.default = _default;
});