define("@square/glass-ui/components/svg/icon-play", ["exports", "@square/glass-ui/components/svg/svg-icon", "@square/glass-ui/templates/components/svg/icon-play"], function (_exports, _svgIcon, _iconPlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svgIcon.default.extend({
    layout: _iconPlay.default,
    classNames: ['svg-icon--play'],
    width: 32,
    height: 32,
    viewBox: '0 0 32 32'
  });

  _exports.default = _default;
});