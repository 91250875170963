define("@square/glass-ui/components/sq-option-switch", ["exports", "@square/glass-ui/templates/components/sq-option-switch"], function (_exports, _sqOptionSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqOptionSwitch.default,
    tagName: 'label',
    classNames: ['option', 'option--switch'],
    classNameBindings: ['checked:option--is-selected', 'checked:switch-option--is-on', 'disabled:option--is-disabled'],
    attributeBindings: ['data-test-option-switch', 'data-test-option-switch-checked'],
    'data-test-option-switch': Ember.computed('label', 'value', function () {
      return this.get('label') || this.get('value') || true;
    }),
    'data-test-option-switch-on': Ember.computed.readOnly('checked'),
    checked: false
  });

  _exports.default = _default;
});