define("@square/glass-ui/components/deprecated/sq-form-checkbox", ["exports", "@square/glass-ui/templates/components/deprecated/sq-form-checkbox"], function (_exports, _sqFormCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _sqFormCheckbox.default,
    autofocus: false,
    isIndeterminate: Ember.computed.alias('indeterminate'),
    isDisabled: Ember.computed.alias('disabled'),
    isHorizontal: false,
    tagName: 'label',
    classNames: ['form-checkbox'],
    classNameBindings: ['isDisabled:form-checkbox--is-disabled', 'isHorizontal:form-checkbox--horizontal', 'isIndeterminate:form-checkbox--indeterminate'],
    change: function change() {
      if (this.action) {
        this.action(this.get('checked'));
      }
    }
  });

  _exports.default = _default;
});