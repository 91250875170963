define("@square/glass-ui/components/sq-file-select-link", ["exports", "@square/glass-ui/templates/components/sq-file-select-link"], function (_exports, _sqFileSelectLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  `SqFileSelectLink` allows you to create a fake "link" that, when
  clicked, opens a browser default file select window, then sets
  the value of the component to the first file selected.
  
  This component does not currently support multiple file selecting,
  but could probably easily be altered to do so.
  
  This component WILL NOT WORK without associated CSS, currently located at
  `app/assets/stylesheets/bridgeport/components/_file-select-link.sass`
  
  ```handlebars
  {{sq-file-select-link value=csvFile linkText=(t "your.key")}}
  ```
  */
  var _default = Ember.Component.extend({
    layout: _sqFileSelectLink.default,
    fileTypeWhitelist: null,
    classNames: ['file-select-link'],
    disabled: false,
    actions: {
      fileInputChanged: function fileInputChanged(e) {
        // The `e.files` fallback is only here for testing, as `target` is readonly
        // checking event.originalEvent for the file is necessary for events triggered by jQuery
        var files;

        if (e.target && e.target.files && e.target.files.length > 0) {
          files = e.target.files;
        } else if (e.originalEvent && e.originalEvent.files && e.originalEvent.files.length > 0) {
          files = e.originalEvent.files;
        } else {
          files = e.files;
        }

        var file = files && files[0];

        if (file) {
          this.set('value', file);

          if (this.fileChanged) {
            this.fileChanged(e, file);
          }
        }
      }
    }
  });

  _exports.default = _default;
});