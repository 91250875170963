import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Data from 'bridge-dashboard/app/services/data';
import { SettingsTableButtonObject } from '../../../../models/interface/settings-table-button-object';
import FlashService from '@square/glass-ui/addon/services/flash';
import BridgeApiException from 'bridge-dashboard/app/types/bridge-api-exception';

interface SettingsTableColumnObject {
  label: string;
  key: string;
  formatSuccess: boolean;
}

export default class AuthorizedRouteIntegrationSettingsLocations extends Controller {
  @service data!: Data;
  @service flash!: FlashService;

  // array of objects that describe what attributes of each model type should be displayed (key),
  // as well as what the heading of each column should read (label)
  // and whether the column text contents should be formated to indicate success/failure
  locationTableColumns: Array<SettingsTableColumnObject> = [
    { label: 'Square Name', key: 'square_name', formatSuccess: false },
    { label: 'Square ID', key: 'square_id', formatSuccess: false },
    { label: 'External ID', key: 'external_id', formatSuccess: false },
    { label: 'Status', key: 'status', formatSuccess: true },
  ];

  locationResourceParam = {
    name: 'location',
    key: 'square_id',
  };

  // objects that describe buttons for location table
  addLocationButtonObject: SettingsTableButtonObject = {
    label: 'Sync Locations',
    disabled: false,
    action: this.syncLocations,
  };

  @tracked
  areLocationsSyncing = false;

  @action
  syncLocations(): void {
    this.areLocationsSyncing = true;
    this.data.syncLocations().then(
      (_square_locations) => {
        this.send('refreshCurrentRoute');
        this.areLocationsSyncing = false;
        this.flash.globalSuccess('Square locations successfully synced.');
      },
      (error: BridgeApiException) => {
        this.flash.globalError(`Failed to sync Square locations. ${error}`, {
          dismiss: () => {
            this.flash.clearGlobalMessage();
          },
        });
      }
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/settings/locations': AuthorizedRouteIntegrationSettingsLocations;
  }
}
