import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AuthorizedRouteIntegrationSyncDataBankTransfersShow extends Controller {

  previousPage = 'authorized-route.integration.sync-data.bank-transfers'

  @action
  close() {
    this.transitionToRoute('authorized-route.integration.sync-data.bank-transfers');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/integration/sync-data/bank-transfers/show': AuthorizedRouteIntegrationSyncDataBankTransfersShow;
  }
}
