define("@square/glass-ui/templates/components/sq-choice-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sHxcHIYH",
    "block": "{\"symbols\":[\"radio\",\"checkbox\",\"&default\"],\"statements\":[[6,[37,8],[[30,[36,14],[[35,13],\"checkbox\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,12],null,[[\"action\",\"checked\",\"class\",\"data-test-choice-block\",\"label\",\"labelSecondary\",\"disabled\",\"value\"],[[35,10],[35,0],[30,[36,9],[\"choice-block\",\" \",[30,[36,8],[[35,0],\"choice-block--is-selected\"],null],\" \"],null],[30,[36,7],[[35,0],true],null],[35,6],[35,5],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,[[30,[36,2],null,[[\"label\",\"label-secondary\",\"selected-content\"],[[32,2,[\"label\"]],[32,2,[\"label-secondary\"]],[30,[36,1],[\"sq-choice-block/selected-content\"],[[\"selected\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,11],null,[[\"action\",\"class\",\"data-test-choice-block\",\"label\",\"labelSecondary\",\"selected\",\"disabled\",\"value\"],[[35,10],[30,[36,9],[\"choice-block\",\" \",[30,[36,8],[[35,0],\"choice-block--is-selected\"],null],\" \"],null],[30,[36,7],[[35,0],true],null],[35,6],[35,5],[35,0],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,[[30,[36,2],null,[[\"label\",\"label-secondary\",\"selected-content\"],[[32,1,[\"label\"]],[32,1,[\"label-secondary\"]],[30,[36,1],[\"sq-choice-block/selected-content\"],[[\"selected\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selected\",\"component\",\"hash\",\"value\",\"disabled\",\"labelSecondary\",\"label\",\"or\",\"if\",\"concat\",\"action\",\"sq-option-radio\",\"sq-option-checkbox\",\"style\",\"eq\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-choice-block.hbs"
    }
  });

  _exports.default = _default;
});