export function fuzzySearch (needle: string, haystack: string, capSensitive = false) {
    
    const needleStr = capSensitive ? needle : needle.toLowerCase()
    const haystackStr = capSensitive ? haystack : haystack.toLowerCase()

    var hlen = haystackStr.length;
    var nlen = needleStr.length;
    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needleStr === haystackStr;
    }


    outer: for (var i = 0, j = 0; i < nlen; i++) {
      var nch = needleStr.codePointAt(i);
      while (j < hlen) {
        if (haystackStr.codePointAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }
  