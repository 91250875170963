define("@square/glass-ui/utils/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.matchMedia = matchMedia;

  /*
   * Just some stubbable functions for testing purposes.
   */
  function matchMedia(queryString) {
    return window.matchMedia(queryString);
  }
});