import Engine from 'ember-engines/engine';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from './config/environment';

/**
 * All routes and services used by engine addons must be declared here
 * AND in the parent's app.js file.
 */
const { modulePrefix } = config;
const Eng = Engine.extend({
  Resolver,
  // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
  dependencies: {
    externalRoutes: ['login'],
    services: [
      'auth',
      'current-user',
      'data',
      'fetch-handler',
      'flash',
      'navigation',
      'popover-manager',
    ],
  },
  modulePrefix,
});

loadInitializers(Eng, modulePrefix);

export default Eng;
