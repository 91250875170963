define("@square/glass-ui/templates/components/sq-billboard/media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VEPNHXdE",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[35,5]],[[\"width\",\"height\",\"class\"],[[35,1],[35,0],\"billboard__media__svg\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"class\",\"src\",\"width\",\"height\"],[\"billboard__media__image\",[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"height\",\"width\",\"src\",\"responsive-image\",\"if\",\"name\",\"component\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-billboard/media.hbs"
    }
  });

  _exports.default = _default;
});