define("@square/glass-ui/components/dialog-header/title", ["exports", "@square/glass-ui/components/dialog-actions", "@square/glass-ui/templates/components/dialog-header/title"], function (_exports, _dialogActions, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _title.default,
    tagName: 'h2',
    attributeBindings: ['data-test-dialog-title'],
    classNames: ['dialog__header__title'],
    classNameBindings: ['contextClass'],
    'data-test-dialog-title': true,
    contextClass: Ember.computed('context', function () {
      switch (this.get('context')) {
        case _dialogActions.CONTEXT.BLADE:
          return 'dialog--blade__header__title';

        case _dialogActions.CONTEXT.MODAL:
          return 'dialog--modal__header__title';

        case _dialogActions.CONTEXT.SHEET:
          return 'dialog--sheet__header__title';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});