import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { Gateway, Source } from '../../models/services/source/source';
import _ from 'lodash';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import { tracked } from '@glimmer/tracking';

export default class AuthorizedRouteNewIntegration extends Controller {
  queryParams: Array<string> = ['code', 'error_message', 'source_state'];

  @service currentUser!: CurrentUser;

  @tracked code!: string;

  @tracked error_message!: string;

  source_state!: string | null;

  get decodedSourceState(): string | null {
    if (this.source_state) {
      try {
        return JSON.parse(atob(this.source_state));
      } catch (error: any) {
        return null;
      }
    } else {
      return null;
    }
  }

  get externalSources() {
    return this.model.filter(
      (source: Source) => source.gateway !== Gateway.SQUARE
    );
  }

  get signedG2CDownloadUrl() {
    return this.model.signedG2CDownloadUrl;
  }

  get squareSources() {
    if (!_.isEmpty(this.currentUser.currentIntegration)) {
      return this.currentUser.currentSquareSource;
    } else {
      return this.model.filter(
        (source: Source) => source.gateway === Gateway.SQUARE
      );
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'authorized-route/new-integration': AuthorizedRouteNewIntegration;
  }
}
