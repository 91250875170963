import { helper } from '@ember/component/helper';

// general method to extra a value from an object
export function extractValue(params: Array<any> /*, hash*/): any {
  const [sourceRow, attribute] = params;
  if (!sourceRow) return;
  return sourceRow[attribute];
}

export default helper(extractValue);
