import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Instance as PopperInstance, createPopper, Modifier, State } from '@popperjs/core';


enum KeyboardEventCodes {
    ArrowDown = 'ArrowDown',
    ArrowUp = 'ArrowUp',
    Enter = 'Enter',
    Tab = 'Tab',
    Escape = 'Escape',
  }
  
  const LIST_OPTION_HIGHLIGHTED_CLASS =
  'market-searchable-dropdown-row--highlighted';

function visibleInViewport(element: Element | null): boolean {
    if (!element) {
      return false;
    }
  
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }


/**
 * On an interval, attempts to scroll an element into view until it is visible
 * in the viewport.
 * @param selector Valid element selector.
 * @param interval
 */
 function scrollIntoView(selector: string, interval = 100): void {
    const scrollInterval = window.setInterval(() => {
      const element = document.querySelector(selector);
      if (visibleInViewport(element)) {
        window.clearInterval(scrollInterval);
        return;
      }
  
      if (element) {
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
        window.clearInterval(scrollInterval);
      }
    }, interval);
  }
  
const sameWidthPopperModifier: Modifier<
  'sameWidthModifier',
  { name: string; enabled: boolean; phase: string }
> = {
  name: 'sameWidthModifier',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: { state: State }) => {
    // eslint-disable-next-line no-param-reassign
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: { state: State }) => {
    // eslint-disable-next-line no-param-reassign
    state.elements.popper.style.width = `${
      (state.elements.reference as HTMLElement).offsetWidth
    }px`;
  },
};
 function createSameWidthPopper(
  triggerElement: HTMLElement,
  popperElement: HTMLElement
): PopperInstance {
  return createPopper(triggerElement, popperElement, {
    modifiers: [
      sameWidthPopperModifier,
      { name: 'flip', options: { fallbackPlacements: ['top-start'] } },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
    placement: 'bottom-start',
    strategy: 'absolute',
  });
}



/**
 * Creates a listener function that should be used to listen to click events in order
 * to trigger some behaviour whenever a click occurs outside of the target element
 * (e.g. user clicking outside of popover closes popover).
 *
 * @export
 * @param {Node} componentElement
 * @param {() => void} onOutsideClick
 * @param {String[]} ignoredClassNames If an element with any of these classes is found
 * in the path of the event, the click will also be ignored.
 * @returns {(event: Event) => void}
 */
 function makeOutsideClickListener(
    onOutsideClick: () => void,
    ignoredElements: Node[],
    ignoredClassNames?: string[]
  ): (event: Event) => void {
    return function onClose(event: Event): void {
      if (!event.target) {
        return;
      }
  
      const element = event.target as HTMLInputElement;
      const clickIsWithinElement = ignoredElements.some((ignoredElement) =>
        ignoredElement.contains(element)
      );
  
      const eventPath = (event as MouseEvent).composedPath();
      const clickIsFromIgnoredElement =
        ignoredClassNames &&
        eventPath.find((eventTarget) => {
          const element = eventTarget as Element;
          return ignoredClassNames.includes(element.className);
        }) !== undefined;
  
      if (!clickIsWithinElement && !clickIsFromIgnoredElement) {
        onOutsideClick();
      }
    };
  }
function makeKeyDownListenerMap(
    listenerMap: Record<string, (event: KeyboardEvent) => void>,
    stopPropagation = true
  ): (event: KeyboardEvent) => void {
    return function onKeyDown(event: KeyboardEvent): void {
      const key = event.key;
      if (listenerMap[key] === undefined) {
        return;
      }
  
      if (stopPropagation) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
      }
  
      listenerMap[key](event);
    };
  }


interface MarketSearchableDropdownArgs {
  onEmptySubmit?(): void;
  onPopoverOpen(): void;
  onPopoverClose(): void;
  onInputFocus(): void;
  onInputBlur(): void;
  onInputChange(value: string): void;
  onListChange(value: string): void;
  onListCreate(value: string): void;
  onListScrollToBottom(): void;
  registerClosePopover?(closePopover: () => void): void;
  openDropdownWithEmptyQuery?: boolean;
  query: string;
  listHeader: string;
}

/**
 * Renders a text input component, and a list of Market rows in a popover when that
 * input is clicked.
 *
 * @export
 * @class MarketSearchableDropdown
 * @extends {MarketSearchableComponent}
 */
export default class MarketSearchableDropdown extends Component<MarketSearchableDropdownArgs> {
  @tracked isPopoverVisible = false;

  private triggerElement!: HTMLElement;
  private popperElement!: HTMLElement;
  private popperInstance!: PopperInstance;
  private closeListener?: (this: Window, event: MouseEvent) => void;
  private inputKeydownListener?: (
    this: HTMLInputElement,
    event: KeyboardEvent
  ) => void;
  private resetHighlightedIndex!: () => void;

  private get shouldOpenDropdownWithEmptyQuery() {
    return this.args.openDropdownWithEmptyQuery ?? true;
  }

  @action
  registerResetHighlightedIndex(resetHighlightedIndex: () => void): void {
    this.resetHighlightedIndex = resetHighlightedIndex;
  }

  @action
  registerClosePopover(): void {
    if (this.args.registerClosePopover) {
      this.args.registerClosePopover(this.closePopover);
    }
  }

  removeListeners(): void {
    if (this.closeListener) {
      window.removeEventListener('click', this.closeListener);
    }
  }

  @action
  closePopover(): void {
    const shouldIgnoreInput = this.isDestroyed || this.isDestroying;
    if (shouldIgnoreInput || !this.isPopoverVisible) {
      return;
    }

    delete this.popperElement.dataset.show;
    this.isPopoverVisible = false;
    this.resetHighlightedIndex();
    this.args?.onPopoverClose?.();
  }

  @action
  onEnterKey(success: boolean): void {
    this.openDropdown();

    if (success) {
      this.closePopover();
    }

    // No item was selected and the query is empty
    if (!success && !this.args.query) {
      this.args.onEmptySubmit?.();
    }
  }

  @action
  openDropdown(): void {
    if (this.isPopoverVisible) {
      return;
    }

    this.args?.onInputFocus?.();
    this.openPopover(this.args.query);
    this.scrollHighlightedRowIntoView();
  }

  @action
  onMarketInputClick(): void {
    this.openDropdown();
  }

  @action
  onInputBlur(): void {
    this.closePopover();
    this.args?.onInputBlur?.();
  }

  @action
  onInputChange(value: string): void {
    this.openPopover(value);
    this.args?.onInputChange?.(value);

    if (!this.shouldOpenDropdownWithEmptyQuery && !value) {
      this.closePopover();
    }
  }

  @action
  onListChange(value: string): void {
    this.closePopover();
    this.args?.onListChange?.(value);
  }

  @action
  onListCreate(value: string): void {
    this.closePopover();
    this.args?.onListCreate?.(value);
  }

  @action
  attachInputListeners(element: HTMLInputElement): void {
    this.triggerElement = element;
    this.inputKeydownListener = makeKeyDownListenerMap(
      {
        // These calls will be no-ops if the popover is already open.
        [KeyboardEventCodes.ArrowDown]: this.openDropdown.bind(this),
        [KeyboardEventCodes.ArrowUp]: this.openDropdown.bind(this),
        [KeyboardEventCodes.Enter]: this.openDropdown.bind(this),
        // Allow for events to bubble up to the popover listener.
      },
      false
    );
    element.addEventListener('keydown', this.inputKeydownListener);
  }

  @action
  attachPopoverListeners(element: HTMLElement): void {
    this.popperElement = element;
    this.closeListener = makeOutsideClickListener(
      this.closePopover.bind(this),
      [this.popperElement, this.triggerElement],
      ['market-searchable-dropdown__popover']
    );
    window.addEventListener('click', this.closeListener);

    this.popperInstance = createSameWidthPopper(
      this.triggerElement,
      this.popperElement
    );
  }

  private openPopover(query: string): void {
    if (!query && !this.shouldOpenDropdownWithEmptyQuery) {
      return;
    }

    this.popperElement.dataset.show = '';
    this.isPopoverVisible = true;
    this.popperInstance.update();
    this.args?.onPopoverOpen?.();
  }

  private scrollHighlightedRowIntoView(): void {
    scrollIntoView(LIST_OPTION_HIGHLIGHTED_CLASS);
  }

  willDestroy(): void {
    super.willDestroy();
    this.removeListeners();
  }
}