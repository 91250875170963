import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Data from 'bridge-dashboard/app/services/data';

export default class AuthorizedRouteIntegrationSyncDataBankTransfersShow extends Route {

  @service data!: Data;

  model(params: any) {
    return this.data.getSyncEntityGroupStatus(params.pair_id);
  }
}
