define("@square/glass-ui/templates/components/sq-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N0pHEWSd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"table\"],[15,0,[31,[\"sq-table__table \",[30,[36,1],[[35,2],\"sq-table__table--fixed-layout\"],null],\" \",[30,[36,1],[[35,0],\"sq-table__table--sticky-header\"],null]]]],[12],[2,\"\\n  \"],[18,1,[[30,[36,4],null,[[\"header\",\"body\"],[[30,[36,3],[\"sq-table/header\"],null],[30,[36,3],[\"sq-table/body\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"useStickyHeaders\",\"if\",\"useFixedLayout\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@square/glass-ui/templates/components/sq-table.hbs"
    }
  });

  _exports.default = _default;
});